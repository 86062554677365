import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearKittyError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.KITTY_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const addKitty = (kitty_name: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.KITTY_HTTP_LOADING
    });

    request({ method: "post", pathname: "addKitty", data: { kitty_name } })
      .then((response) => {
        dispatch({
          type: actions.KITTY_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.KITTY_HTTP_ERROR, error, dispatch);
      });
  };
};

export const updateKitty = (_id: string, kitty_name: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.KITTY_HTTP_LOADING
    });

    request({ method: "post", pathname: "updateKitty", data: { _id, kitty_name } })
      .then((response) => {
        dispatch({
          type: actions.KITTY_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.KITTY_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteKitty = (_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.KITTY_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteKitty", data: { _id } })
      .then((response) => {
        dispatch({
          type: actions.KITTY_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.KITTY_HTTP_ERROR, error, dispatch);
      });
  };
};
