import React, { useEffect, useState } from "react";
import { Switch, Route, Link, Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AppState, AppTheme, ProfileReducerState } from "../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  IconButton,
  Hidden,
  Drawer,
  Divider,
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@material-ui/core";
import {
  Menu,
  Person,
  PowerSettingsNew,
  People,
  Event,
  Notifications,
  MenuBook,
  AccountBalance,
  PlaylistAddCheck,
  AttachMoney,
  Public,
  AccountBalanceWallet,
  School,
  AccountTree,
  PhotoLibrary
} from "@material-ui/icons";
import logoImg from "../../assets/logo.png";
import { logOut } from "../../store/actions";
import { checkUserAccess } from "../../util/permission-checker";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import DashboardProfile from "./Profile/Profile";
import DashboardUsers from "./Users/Users";
import DashboardEvents from "./Events/Events";
import DashboardProjects from "./Projects/Projects";
import DashboardBlogs from "./Blogs/Blogs";
import DashboardGallaries from "./Gallaries/Gallaries";
import DashboardAttendances from "./Attendances/Attendances";
import DashboardVerses from "./Verses/Verses";
import DashboardContributions from "./Contributions/Contributions";
import DashboardKitties from "./Kitties/Kitties";
import DashboardNotifications from "./Notifications/Notifications";
import DashboardDiscipleshipClasses from "./DiscipleshipClasses/DiscipleshipClasses";
import DashboardMinistries from "./Ministries/Ministries";
import DashboardMinistriesMinistry from "./Ministries/Ministry/Ministry";
import DashboardMinistriesMinistryLandingPage from "./Ministries/Ministry/LandingPage/LandingPage";
import DashboardMinistriesMinistryMembers from "./Ministries/Ministry/Members/Members";
import DashboardMinistriesMinistryContributions from "./Ministries/Ministry/Contributions/Contributions";
import DashboardMinistriesMinistryKitties from "./Ministries/Ministry/Kitties/Kitties";
import Error404 from "../Error404"

interface DashboardProps {
  profile: ProfileReducerState;
  loggedIn: boolean;
  appTheme: AppTheme;
  logOut: () => void;
}

const useStyles = ( drawerWidth: number) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "flex"
      },
      logo: {
        height: "100px",
        display: "block",
        margin: "auto"
      },
      drawer: {
        [theme.breakpoints.up("sm")]: {
          width: drawerWidth,
          flexShrink: 0
        }
      },
      appBar: {
        [theme.breakpoints.up("sm")]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth
        }
      },
      menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
          display: "none"
        }
      },
      drawerPaper: {
        width: drawerWidth
      },
      content: {
        flexGrow: 1
      },
      toolbar: theme.mixins.toolbar
    })
  );

const Dashboard: React.FC<DashboardProps> = (props) => {
  const styles = useStyles(200)();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const history = useHistory();

  const { loggedIn, profile, logOut } = props;

  useEffect(() => {
    if (!loggedIn) {
      history.push("/account/login#logged-out-successfully");
    }
  }, [loggedIn, history]);

  const menuStyle = (contains: string) => (window.location.pathname.startsWith("/dashboard/" + contains) ? { color: "#0587b2" } : {});

  const menuSection = (
    <div>
      <img src={logoImg} alt="logo" className={styles.logo}></img>
      <br />
      <Divider />
      <List>
        <ListItem button component={Link} to={"/dashboard/profile"}>
          <ListItemIcon>
            <Person style={menuStyle("profile")} />
          </ListItemIcon>
          <ListItemText primary="My Profile" style={menuStyle("profile")} />
        </ListItem>
      </List>
      <Divider />
      <List>
        {[
          { to: "/dashboard/users", text: "Users", icon: <People />, contains: "users", permission: "view-users" },
          { to: "/dashboard/ministries", text: "Ministries", icon: <AccountBalance />, contains: "ministries", permission: "view-ministries" },
          {
            to: "/dashboard/discipleship-classes",
            text: "Discipleship Classes",
            icon: <School />,
            contains: "discipleship-classes",
            permission: "view-discipleship-classes"
          },
          {
            to: "/dashboard/galleries",
            text: "Photo Galleries",
            icon: <PhotoLibrary />,
            contains: "galleries",
            permission: "view-galleries"
          },
          { to: "/dashboard/projects", text: "Projects", icon: <AccountTree />, contains: "projects", permission: "view-projects" },
          { to: "/dashboard/events", text: "Events", icon: <Event />, contains: "events", permission: "view-events" },
          { to: "/dashboard/verses", text: "Daily Verses", icon: <MenuBook />, contains: "verses", permission: "view-verses" },
          {
            to: "/dashboard/notifications",
            text: "Notifications",
            icon: <Notifications />,
            contains: "notifications",
            permission: "send-notifications"
          },
          { to: "/dashboard/kitties", text: "Kitties", icon: <AccountBalanceWallet />, contains: "kitties", permission: "view-kitties" },
          {
            to: "/dashboard/contributions",
            text: "Contributions",
            icon: <AttachMoney />,
            contains: "contributions",
            permission: "view-contributions"
          },
          { to: "/dashboard/attendances", text: "Attendance", icon: <PlaylistAddCheck />, contains: "attendances", permission: "view-attendances" },
          { to: "/dashboard/blogs", text: "Blogs", icon: <Public />, contains: "blogs", permission: "view-blogs" }
        ].map((element, index) => (
          <React.Fragment key={index}>
            {checkUserAccess(element.permission, profile.permissions) && (
              <ListItem component={Link} to={element.to} button>
                <ListItemIcon style={menuStyle(element.contains)}>{element.icon}</ListItemIcon>
                <ListItemText primary={element.text} style={menuStyle(element.contains)} />
              </ListItem>
            )}
          </React.Fragment>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={() => logOut()}>
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={styles.root}>
      <AppBar position="fixed" className={styles.appBar}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={() => setIsDrawerOpen(true)} className={styles.menuButton}>
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap>
            PEFA Church Gimu: Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={styles.drawer}>
        <Hidden smUp>
          <Drawer
            container={window.document.body}
            variant="temporary"
            anchor="left"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            classes={{ paper: styles.drawerPaper }}
            ModalProps={{ keepMounted: true }}>
            {menuSection}
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          <Drawer classes={{ paper: styles.drawerPaper }} variant="permanent" open>
            {menuSection}
          </Drawer>
        </Hidden>
      </nav>
      <main className={styles.content}>
        <div className={styles.toolbar} />
        <Switch>
          <Route exact path="/dashboard">
            <Redirect to="/dashboard/profile" />
          </Route>
          <Route exact path="/dashboard/profile" component={DashboardProfile} />
          <Route exact path="/dashboard/users" component={DashboardUsers} />
          <Route exact path="/dashboard/events" component={DashboardEvents} />
          <Route exact path="/dashboard/projects" component={DashboardProjects} />
          <Route exact path="/dashboard/blogs" component={DashboardBlogs} />
          <Route exact path="/dashboard/attendances" component={DashboardAttendances} />
          <Route exact path="/dashboard/verses" component={DashboardVerses} />
          <Route exact path="/dashboard/notifications" component={DashboardNotifications} />
          <Route exact path="/dashboard/contributions" component={DashboardContributions} />
          <Route exact path="/dashboard/kitties" component={DashboardKitties} />
          <Route exact path="/dashboard/galleries" component={DashboardGallaries} />
          <Route exact path="/dashboard/discipleship-classes" component={DashboardDiscipleshipClasses} />
          <Route exact path="/dashboard/ministries" component={DashboardMinistries} />
          <Route exact path="/dashboard/ministries/:ministry_id/:ministry_name/" component={DashboardMinistriesMinistry} />
          <Route exact path="/dashboard/ministries/:ministry_id/:ministry_name/landing-page" component={DashboardMinistriesMinistryLandingPage} />
          <Route exact path="/dashboard/ministries/:ministry_id/:ministry_name/members" component={DashboardMinistriesMinistryMembers} />
          <Route exact path="/dashboard/ministries/:ministry_id/:ministry_name/contributions" component={DashboardMinistriesMinistryContributions} />
          <Route exact path="/dashboard/ministries/:ministry_id/:ministry_name/kitties" component={DashboardMinistriesMinistryKitties} />
          <Route component={Error404} />
        </Switch>
      </main>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    logOut: () => dispatch(logOut())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    profile: state.profile,
    loggedIn: state.login.loggedIn,
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
