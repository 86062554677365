import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearGalleryError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.GALLERY_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const addGallery = (title: string, description: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.GALLERY_HTTP_LOADING
    });

    request({ method: "post", pathname: "addGallery", data: { title, description } })
      .then((response) => {
        dispatch({
          type: actions.GALLERY_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.GALLERY_HTTP_ERROR, error, dispatch);
      });
  };
};

export const updateGallery = (_id: string, title: string, description: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.GALLERY_HTTP_LOADING
    });

    request({ method: "post", pathname: "updateGallery", data: { _id, title, description } })
      .then((response) => {
        dispatch({
          type: actions.GALLERY_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.GALLERY_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteGallery = (_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.GALLERY_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteGallery", data: { _id } })
      .then((response) => {
        dispatch({
          type: actions.GALLERY_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.GALLERY_HTTP_ERROR, error, dispatch);
      });
  };
};

export const addGalleryPhotos = (_id: string, photos: string[]) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.GALLERY_HTTP_LOADING
    });

    request({ method: "post", pathname: "addGalleryPhotos", data: { _id, photos: JSON.stringify(photos) } })
      .then((response) => {
        dispatch({
          type: actions.GALLERY_PHOTOS_ADDED,
          payload: {
            gallery_id: _id,
            photos
          }
        });

        dispatch({
          type: actions.GALLERY_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.GALLERY_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteGalleryPhoto = (_id: string, photo_url: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.GALLERY_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteGalleryPhoto", data: { _id, photo_url } })
      .then((response) => {
        dispatch({
          type: actions.GALLERY_PHOTO_DELETED,
          payload: {
            gallery_id: _id,
            photo_url
          }
        });

        dispatch({
          type: actions.GALLERY_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.GALLERY_HTTP_ERROR, error, dispatch);
      });
  };
};
