const AppPermissions = [
  {
    name: "View Users",
    category: "Users",
    permission: "view-users",
    dependancies: []
  },
  {
    name: "Add New User",
    category: "Users",
    permission: "add-user",
    dependancies: ["view-users"]
  },
  {
    name: "Edit Existing User Details",
    category: "Users",
    permission: "edit-user",
    dependancies: ["view-users"]
  },
  {
    name: "Reset Password For User's Without Email and Mobile No",
    category: "Users",
    permission: "reset-user-password",
    dependancies: ["view-users"]
  },
  {
    name: "Disable/Enable Existing User",
    category: "Users",
    permission: "edit-user-status",
    dependancies: ["view-users"]
  },
  {
    name: "Update Permissions Of Users",
    category: "Users",
    permission: "edit-user-permissions",
    dependancies: ["view-users"]
  },
  {
    name: "View Events",
    category: "Events",
    permission: "view-events",
    dependancies: []
  },
  {
    name: "Add New Event",
    category: "Events",
    permission: "add-event",
    dependancies: ["view-events"]
  },
  {
    name: "Edit Existing Event Details",
    category: "Events",
    permission: "edit-event",
    dependancies: ["view-events"]
  },
  {
    name: "Delete Existing Event Details",
    category: "Events",
    permission: "delete-event",
    dependancies: ["view-events"]
  },
  {
    name: "View Blogs",
    category: "Blogs",
    permission: "view-blogs",
    dependancies: []
  },
  {
    name: "Add New Blog",
    category: "Blogs",
    permission: "add-blog",
    dependancies: ["view-blogs"]
  },
  {
    name: "Edit Existing Blog Details",
    category: "Blogs",
    permission: "edit-blog",
    dependancies: ["view-blogs"]
  },
  {
    name: "Delete Existing Blog Details",
    category: "Blogs",
    permission: "delete-blog",
    dependancies: ["view-blogs"]
  },
  {
    name: "View Projects",
    category: "Projects",
    permission: "view-projects",
    dependancies: []
  },
  {
    name: "Add New Project",
    category: "Projects",
    permission: "add-project",
    dependancies: ["view-projects"]
  },
  {
    name: "Edit Existing Project Details",
    category: "Projects",
    permission: "edit-project",
    dependancies: ["view-projects"]
  },
  {
    name: "Delete Existing Project Details",
    category: "Projects",
    permission: "delete-project",
    dependancies: ["view-projects"]
  },
  {
    name: "View Galleries",
    category: "Galleries",
    permission: "view-galleries",
    dependancies: []
  },
  {
    name: "Add New Gallery",
    category: "Galleries",
    permission: "add-gallery",
    dependancies: ["view-galleries"]
  },
  {
    name: "Edit Existing Gallery Details",
    category: "Galleries",
    permission: "edit-gallery",
    dependancies: ["view-galleries"]
  },
  {
    name: "Delete Existing Gallery Details",
    category: "Galleries",
    permission: "delete-gallery",
    dependancies: ["view-galleries"]
  },
  {
    name: "View Attendances",
    category: "Attendance",
    permission: "view-attendances",
    dependancies: []
  },
  {
    name: "Add New Attendance",
    category: "Attendance",
    permission: "add-attendance",
    dependancies: ["view-attendances"]
  },
  {
    name: "Edit Existing Attendance Details",
    category: "Attendance",
    permission: "edit-attendance",
    dependancies: ["view-attendances"]
  },
  {
    name: "Delete Existing Attendance Details",
    category: "Attendance",
    permission: "delete-attendance",
    dependancies: ["view-attendances"]
  },
  {
    name: "View User Attendance List",
    category: "Attendance",
    permission: "view-user-attendances",
    dependancies: ["view-attendances"]
  },
  {
    name: "Add User To Attendance List",
    category: "Attendance",
    permission: "add-user-to-attendance",
    dependancies: ["view-attendances", "view-user-attendances", "view-users"]
  },
  {
    name: "Delete User From Attendance List",
    category: "Attendance",
    permission: "delete-user-from-attendance",
    dependancies: ["view-attendances", "view-user-attendances"]
  },
  {
    name: "View Verses",
    category: "Verses",
    permission: "view-verses",
    dependancies: []
  },
  {
    name: "Add New Verse",
    category: "Verses",
    permission: "add-verse",
    dependancies: ["view-verses"]
  },
  {
    name: "Edit Existing Verse Details",
    category: "Verses",
    permission: "edit-verse",
    dependancies: ["view-verses"]
  },
  {
    name: "Delete Existing Verse Details",
    category: "Verses",
    permission: "delete-verse",
    dependancies: ["view-verses"]
  },
  {
    name: "View Contributions",
    category: "Contributions",
    permission: "view-contributions",
    dependancies: []
  },
  {
    name: "Add New Contribution",
    category: "Contributions",
    permission: "add-contribution",
    dependancies: ["view-contributions"]
  },
  {
    name: "Edit Existing Contribution Details",
    category: "Contributions",
    permission: "edit-contribution",
    dependancies: ["view-contributions"]
  },
  {
    name: "Delete Existing Contribution Details",
    category: "Contributions",
    permission: "delete-contribution",
    dependancies: ["view-contributions"]
  },
  {
    name: "View User Contribution List",
    category: "Contributions",
    permission: "view-user-contributions",
    dependancies: ["view-contributions"]
  },
  {
    name: "Add User To Contribution List",
    category: "Contributions",
    permission: "add-user-to-contribution",
    dependancies: ["view-contributions", "view-user-contributions", "view-users"]
  },
  {
    name: "Delete User From Contribution List",
    category: "Contributions",
    permission: "delete-user-from-contribution",
    dependancies: ["view-contributions", "view-user-contributions"]
  },
  {
    name: "View Discipleship Classes",
    category: "Discipleship Classes",
    permission: "view-discipleship-classes",
    dependancies: []
  },
  {
    name: "Add New Discipleship Class",
    category: "Discipleship Classes",
    permission: "add-discipleship-class",
    dependancies: ["view-discipleship-classes"]
  },
  {
    name: "Edit Existing Discipleship Class Details",
    category: "Discipleship Classes",
    permission: "edit-discipleship-class",
    dependancies: ["view-discipleship-classes"]
  },
  {
    name: "Delete Existing Discipleship Class Details",
    category: "Discipleship Classes",
    permission: "delete-discipleship-class",
    dependancies: ["view-discipleship-classes"]
  },
  {
    name: "View Discipleship Class Student List",
    category: "Discipleship Classes",
    permission: "view-discipleship-class-students",
    dependancies: ["view-discipleship-classes"]
  },
  {
    name: "Add User To Discipleship Class Student List",
    category: "Discipleship Classes",
    permission: "add-discipleship-class-student",
    dependancies: ["view-discipleship-classes", "view-discipleship-class-students", "view-users"]
  },
  {
    name: "Delete User From Discipleship Class Student List",
    category: "Discipleship Classes",
    permission: "delete-discipleship-class-student",
    dependancies: ["view-discipleship-classes", "view-discipleship-class-students"]
  },
  {
    name: "Send Notifications",
    category: "Notifications",
    permission: "send-notifications",
    dependancies: ["view-users"]
  },
  {
    name: "View Ministries",
    category: "Ministries",
    permission: "view-ministries",
    dependancies: []
  },
  {
    name: "View Kitties",
    category: "Kitties",
    permission: "view-kitties",
    dependancies: []
  },
  {
    name: "Add New Kitty",
    category: "Kitties",
    permission: "add-kitty",
    dependancies: ["view-kitties"]
  },
  {
    name: "Edit Existing Kitty Details",
    category: "Kitties",
    permission: "edit-kitty",
    dependancies: ["view-kitties"]
  },
  {
    name: "Delete Existing Kitty Details",
    category: "Kitties",
    permission: "delete-kitty",
    dependancies: ["view-kitties"]
  },
  {
    name: "View Kitty Subtraction List",
    category: "Kitties",
    permission: "view-kitty-subtractions",
    dependancies: ["view-kitties"]
  },
  {
    name: "Add New Kitty Subtraction",
    category: "Kitties",
    permission: "add-kitty-subtraction",
    dependancies: ["view-kitties", "view-kitty-subtractions", "view-users"]
  },
  {
    name: "Delete Subtraction From Kitty Subtraction List",
    category: "Kitties",
    permission: "delete-kitty-subtraction",
    dependancies: ["view-kitties", "view-kitty-subtractions"]
  },
  {
    name: "View Kitty Linked Contribution List",
    category: "Kitties",
    permission: "view-kitty-linked-contributions",
    dependancies: ["view-kitties"]
  },
  {
    name: "Add New Kitty Linked Contribution",
    category: "Kitties",
    permission: "add-kitty-linked-contribution",
    dependancies: ["view-kitties", "view-kitty-linked-contributions", "view-contributions"]
  },
  {
    name: "Delete Contribution From Kitty Linked Contribution List",
    category: "Kitties",
    permission: "delete-kitty-linked-contribution",
    dependancies: ["view-kitties", "view-kitty-linked-contributions"]
  }
];

const sectionPermissions = [
  {
    section: "view-users",
    allowed: [
      {
        type: "and",
        permissions: ["view-users"]
      }
    ]
  },
  {
    section: "add-user",
    allowed: [
      {
        type: "and",
        permissions: ["view-users", "add-user"]
      }
    ]
  },
  {
    section: "edit-user",
    allowed: [
      {
        type: "and",
        permissions: ["view-users", "edit-user"]
      }
    ]
  },
  {
    section: "reset-user-password",
    allowed: [
      {
        type: "and",
        permissions: ["view-users", "reset-user-password"]
      }
    ]
  },
  {
    section: "edit-user-status",
    allowed: [
      {
        type: "and",
        permissions: ["view-users", "edit-user-status"]
      }
    ]
  },
  {
    section: "edit-user-permissions",
    allowed: [
      {
        type: "and",
        permissions: ["view-users", "edit-user-permissions"]
      }
    ]
  },
  {
    section: "view-events",
    allowed: [
      {
        type: "and",
        permissions: ["view-events"]
      }
    ]
  },
  {
    section: "add-event",
    allowed: [
      {
        type: "and",
        permissions: ["view-events", "add-event"]
      }
    ]
  },
  {
    section: "edit-event",
    allowed: [
      {
        type: "and",
        permissions: ["view-events", "edit-event"]
      }
    ]
  },
  {
    section: "delete-event",
    allowed: [
      {
        type: "and",
        permissions: ["view-events", "delete-event"]
      }
    ]
  },
  {
    section: "view-blogs",
    allowed: [
      {
        type: "and",
        permissions: ["view-blogs"]
      }
    ]
  },
  {
    section: "add-blog",
    allowed: [
      {
        type: "and",
        permissions: ["view-blogs", "add-blog"]
      }
    ]
  },
  {
    section: "edit-blog",
    allowed: [
      {
        type: "and",
        permissions: ["view-blogs", "edit-blog"]
      }
    ]
  },
  {
    section: "delete-blog",
    allowed: [
      {
        type: "and",
        permissions: ["view-blogs", "delete-blog"]
      }
    ]
  },
  {
    section: "view-galleries",
    allowed: [
      {
        type: "and",
        permissions: ["view-galleries"]
      }
    ]
  },
  {
    section: "add-gallery",
    allowed: [
      {
        type: "and",
        permissions: ["view-galleries", "add-gallery"]
      }
    ]
  },
  {
    section: "edit-gallery",
    allowed: [
      {
        type: "and",
        permissions: ["view-galleries", "edit-gallery"]
      }
    ]
  },
  {
    section: "delete-gallery",
    allowed: [
      {
        type: "and",
        permissions: ["view-galleries", "delete-gallery"]
      }
    ]
  },
  {
    section: "view-projects",
    allowed: [
      {
        type: "and",
        permissions: ["view-projects"]
      }
    ]
  },
  {
    section: "add-project",
    allowed: [
      {
        type: "and",
        permissions: ["view-projects", "add-project"]
      }
    ]
  },
  {
    section: "edit-project",
    allowed: [
      {
        type: "and",
        permissions: ["view-projects", "edit-project"]
      }
    ]
  },
  {
    section: "delete-project",
    allowed: [
      {
        type: "and",
        permissions: ["view-projects", "delete-project"]
      }
    ]
  },
  {
    section: "view-attendances",
    allowed: [
      {
        type: "and",
        permissions: ["view-attendances"]
      }
    ]
  },
  {
    section: "add-attendance",
    allowed: [
      {
        type: "and",
        permissions: ["view-attendances", "add-attendance"]
      }
    ]
  },
  {
    section: "edit-attendance",
    allowed: [
      {
        type: "and",
        permissions: ["view-attendances", "edit-attendance"]
      }
    ]
  },
  {
    section: "delete-attendance",
    allowed: [
      {
        type: "and",
        permissions: ["view-attendances", "delete-attendance"]
      }
    ]
  },
  {
    section: "view-user-attendances",
    allowed: [
      {
        type: "and",
        permissions: ["view-attendances", "view-user-attendances"]
      }
    ]
  },
  {
    section: "add-user-attendance",
    allowed: [
      {
        type: "and",
        permissions: ["view-attendances", "view-user-attendances", "add-user-attendance", "view-users"]
      }
    ]
  },
  {
    section: "delete-user-attendance",
    allowed: [
      {
        type: "and",
        permissions: ["view-attendances", "view-user-attendances", "delete-user-attendance"]
      }
    ]
  },
  {
    section: "view-verses",
    allowed: [
      {
        type: "and",
        permissions: ["view-verses"]
      }
    ]
  },
  {
    section: "add-verse",
    allowed: [
      {
        type: "and",
        permissions: ["view-verses", "add-verse"]
      }
    ]
  },
  {
    section: "edit-verse",
    allowed: [
      {
        type: "and",
        permissions: ["view-verses", "edit-verse"]
      }
    ]
  },
  {
    section: "delete-verse",
    allowed: [
      {
        type: "and",
        permissions: ["view-verses", "delete-verse"]
      }
    ]
  },
  {
    section: "view-contributions",
    allowed: [
      {
        type: "and",
        permissions: ["view-contributions"]
      }
    ]
  },
  {
    section: "add-contribution",
    allowed: [
      {
        type: "and",
        permissions: ["view-contributions", "add-contribution"]
      }
    ]
  },
  {
    section: "edit-contribution",
    allowed: [
      {
        type: "and",
        permissions: ["view-contributions", "edit-contribution"]
      }
    ]
  },
  {
    section: "delete-contribution",
    allowed: [
      {
        type: "and",
        permissions: ["view-contributions", "delete-contribution"]
      }
    ]
  },
  {
    section: "view-user-contributions",
    allowed: [
      {
        type: "and",
        permissions: ["view-contributions", "view-user-contributions"]
      }
    ]
  },
  {
    section: "add-user-contribution",
    allowed: [
      {
        type: "and",
        permissions: ["view-contributions", "view-user-contributions", "add-user-contribution", "view-users"]
      }
    ]
  },
  {
    section: "delete-user-contribution",
    allowed: [
      {
        type: "and",
        permissions: ["view-contributions", "view-user-contributions", "delete-user-contribution"]
      }
    ]
  },
  {
    section: "send-notifications",
    allowed: [
      {
        type: "and",
        permissions: ["send-notifications", "view-users"]
      }
    ]
  },
  {
    section: "view-ministries",
    allowed: [
      {
        type: "and",
        permissions: ["view-ministries"]
      }
    ]
  },
  {
    section: "view-kitties",
    allowed: [
      {
        type: "and",
        permissions: ["view-kitties"]
      }
    ]
  },
  {
    section: "add-kitty",
    allowed: [
      {
        type: "and",
        permissions: ["view-kitties", "add-kitty"]
      }
    ]
  },
  {
    section: "edit-kitty",
    allowed: [
      {
        type: "and",
        permissions: ["view-kitties", "edit-kitty"]
      }
    ]
  },
  {
    section: "delete-kitty",
    allowed: [
      {
        type: "and",
        permissions: ["view-kitties", "delete-kitty"]
      }
    ]
  },
  {
    section: "view-kitty-subtractions",
    allowed: [
      {
        type: "and",
        permissions: ["view-kitties", "view-kitty-subtractions"]
      }
    ]
  },
  {
    section: "add-kitty-subtraction",
    allowed: [
      {
        type: "and",
        permissions: ["view-kitties", "view-kitty-subtractions", "add-kitty-subtraction", "view-users"]
      }
    ]
  },
  {
    section: "delete-kitty-subtraction",
    allowed: [
      {
        type: "and",
        permissions: ["view-kitties", "view-kitty-subtractions", "delete-kitty-subtraction"]
      }
    ]
  },
  {
    section: "view-kitty-linked-contributions",
    allowed: [
      {
        type: "and",
        permissions: ["view-kitties", "view-kitty-linked-contributions"]
      }
    ]
  },
  {
    section: "add-kitty-linked-contribution",
    allowed: [
      {
        type: "and",
        permissions: ["view-kitties", "view-kitty-linked-contributions", "add-kitty-linked-contribution", "view-contributions"]
      }
    ]
  },
  {
    section: "delete-kitty-linked-contribution",
    allowed: [
      {
        type: "and",
        permissions: ["view-kitties", "view-kitty-linked-contributions", "delete-kitty-linked-contribution"]
      }
    ]
  },
  {
    section: "view-discipleship-classes",
    allowed: [
      {
        type: "and",
        permissions: ["view-discipleship-classes"]
      }
    ]
  },
  {
    section: "add-discipleship-class",
    allowed: [
      {
        type: "and",
        permissions: ["view-discipleship-classes", "add-discipleship-class"]
      }
    ]
  },
  {
    section: "edit-discipleship-class",
    allowed: [
      {
        type: "and",
        permissions: ["view-discipleship-classes", "edit-discipleship-class"]
      }
    ]
  },
  {
    section: "delete-discipleship-class",
    allowed: [
      {
        type: "and",
        permissions: ["view-discipleship-classes", "delete-discipleship-class"]
      }
    ]
  },
  {
    section: "view-discipleship-class-students",
    allowed: [
      {
        type: "and",
        permissions: ["view-discipleship-classes", "view-discipleship-class-students"]
      }
    ]
  },
  {
    section: "add-discipleship-class-student",
    allowed: [
      {
        type: "and",
        permissions: ["view-discipleship-classes", "view-discipleship-class-students", "add-discipleship-class-student", "view-users"]
      }
    ]
  },
  {
    section: "delete-discipleship-class-student",
    allowed: [
      {
        type: "and",
        permissions: ["view-discipleship-classes", "view-discipleship-class-students", "delete-discipleship-class-student"]
      }
    ]
  }
];

const Ministries = [
  { name: "Uwezo Upendo Touch Group", id: "uwezo-upendo-touch-group" },
  { name: "Almasi Touch Group", id: "almasi-touch-group" },
  { name: "Children Ministry", id: "children-ministry" },
  { name: "Youth Ministry", id: "youth-ministry" }
];

const AppMinistryPermissions = [
  {
    name: "Access Ministry: {MINISTRY_NAME}",
    permission: "view-ministry-{MINISTRY_ID}",
    dependancies: ["view-ministries"]
  },
  {
    name: "View Landing Page",
    permission: "view-ministry-{MINISTRY_ID}-landing-page",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}"]
  },
  {
    name: "Edit Landing Page",
    permission: "edit-ministry-{MINISTRY_ID}-landing-page",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}", "view-ministry-{MINISTRY_ID}-landing-page"]
  },
  {
    name: "View Membership",
    permission: "view-ministry-{MINISTRY_ID}-membership",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}"]
  },
  {
    name: "Add Membership",
    permission: "add-ministry-{MINISTRY_ID}-membership",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}", "view-ministry-{MINISTRY_ID}-membership"]
  },
  {
    name: "Delete Membership",
    permission: "delete-ministry-{MINISTRY_ID}-membership",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}", "view-ministry-{MINISTRY_ID}-membership"]
  },
  {
    name: "View Contributions",
    permission: "view-ministry-{MINISTRY_ID}-contributions",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}"]
  },
  {
    name: "Add Contributions",
    permission: "add-ministry-{MINISTRY_ID}-contributions",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}", "view-ministry-{MINISTRY_ID}-contributions"]
  },
  {
    name: "Edit Contributions",
    permission: "edit-ministry-{MINISTRY_ID}-contributions",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}", "view-ministry-{MINISTRY_ID}-contributions"]
  },
  {
    name: "Delete Contributions",
    permission: "delete-ministry-{MINISTRY_ID}-contributions",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}", "view-ministry-{MINISTRY_ID}-contributions"]
  },
  {
    name: "View Member Contributions",
    permission: "view-ministry-{MINISTRY_ID}-member-contributions",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}", "view-ministry-{MINISTRY_ID}-contributions"]
  },
  {
    name: "Add Member Contribution",
    permission: "add-ministry-{MINISTRY_ID}-member-contribution",
    dependancies: [
      "view-ministries",
      "view-ministry-{MINISTRY_ID}",
      "view-ministry-{MINISTRY_ID}-contributions",
      "view-ministry-{MINISTRY_ID}-member-contributions",
      "view-users"
    ]
  },
  {
    name: "Delete Member Contribution",
    permission: "delete-ministry-{MINISTRY_ID}-member-contribution",
    dependancies: [
      "view-ministries",
      "view-ministry-{MINISTRY_ID}",
      "view-ministry-{MINISTRY_ID}-contributions",
      "view-ministry-{MINISTRY_ID}-member-contributions"
    ]
  },
  {
    name: "View Kitties",
    permission: "view-ministry-{MINISTRY_ID}-kitties",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}"]
  },
  {
    name: "Add Kitty",
    permission: "add-ministry-{MINISTRY_ID}-kitties",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}", "view-ministry-{MINISTRY_ID}-kitties"]
  },
  {
    name: "Edit Kitty",
    permission: "edit-ministry-{MINISTRY_ID}-kitties",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}", "view-ministry-{MINISTRY_ID}-kitties"]
  },
  {
    name: "Delete Kitty",
    permission: "delete-ministry-{MINISTRY_ID}-kitties",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}", "view-ministry-{MINISTRY_ID}-kitties"]
  },
  {
    name: "View Kitty Subtractions",
    permission: "view-ministry-{MINISTRY_ID}-kitty-subtractions",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}", "view-ministry-{MINISTRY_ID}-kitties"]
  },
  {
    name: "Add Kitty Subtraction",
    permission: "add-ministry-{MINISTRY_ID}-kitty-subtraction",
    dependancies: [
      "view-ministries",
      "view-ministry-{MINISTRY_ID}",
      "view-ministry-{MINISTRY_ID}-kitties",
      "view-ministry-{MINISTRY_ID}-kitty-subtractions",
      "view-users"
    ]
  },
  {
    name: "Delete Kitty Subtraction",
    permission: "delete-ministry-{MINISTRY_ID}-kitty-subtraction",
    dependancies: [
      "view-ministries",
      "view-ministry-{MINISTRY_ID}",
      "view-ministry-{MINISTRY_ID}-kitties",
      "view-ministry-{MINISTRY_ID}-kitty-subtractions"
    ]
  },
  {
    name: "View Kitty Linked Contributions",
    permission: "view-ministry-{MINISTRY_ID}-kitty-linked-contributions",
    dependancies: ["view-ministries", "view-ministry-{MINISTRY_ID}", "view-ministry-{MINISTRY_ID}-kitties"]
  },
  {
    name: "Add Kitty Contribution Link",
    permission: "add-ministry-{MINISTRY_ID}-kitty-linked-contributions",
    dependancies: [
      "view-ministries",
      "view-ministry-{MINISTRY_ID}",
      "view-ministry-{MINISTRY_ID}-kitties",
      "view-ministry-{MINISTRY_ID}-kitty-linked-contributions",
      "view-ministry-{MINISTRY_ID}-contributions"
    ]
  },
  {
    name: "Delete Kitty Contribution Link",
    permission: "delete-ministry-{MINISTRY_ID}-kitty-linked-contributions",
    dependancies: [
      "view-ministries",
      "view-ministry-{MINISTRY_ID}",
      "view-ministry-{MINISTRY_ID}-kitties",
      "view-ministry-{MINISTRY_ID}-kitty-linked-contributions"
    ]
  }
];

const AppPermissionsGropuedByMinistryId: {
  [ministry_id: string]: Array<{ name: string; permission: string; dependancies: string[]; category: string }>;
} = {};

AppMinistryPermissions.forEach((appMinistryPermission) => {
  Ministries.forEach((ministry) => {
    const appMinistryPermissionCopy = JSON.parse(JSON.stringify(appMinistryPermission));
    appMinistryPermissionCopy.category = ministry.name;
    appMinistryPermissionCopy.name = appMinistryPermissionCopy.name.replace("{MINISTRY_NAME}", ministry.name);
    appMinistryPermissionCopy.permission = appMinistryPermissionCopy.permission.replace("{MINISTRY_ID}", ministry.id);
    appMinistryPermissionCopy.dependancies = appMinistryPermissionCopy.dependancies.map((dependancy: string) => {
      return dependancy.replace("{MINISTRY_ID}", ministry.id);
    });

    if (AppPermissionsGropuedByMinistryId[ministry.id]) {
      AppPermissionsGropuedByMinistryId[ministry.id].push(appMinistryPermissionCopy);
    } else {
      AppPermissionsGropuedByMinistryId[ministry.id] = [appMinistryPermissionCopy];
    }

    sectionPermissions.push({
      section: appMinistryPermissionCopy.permission,
      allowed: [
        {
          type: "and",
          permissions: [appMinistryPermissionCopy.permission, ...appMinistryPermissionCopy.dependancies]
        }
      ]
    });
  });
});

Object.keys(AppPermissionsGropuedByMinistryId).forEach((ministry_id) => {
  AppPermissionsGropuedByMinistryId[ministry_id].forEach((appPermissions) => {
    AppPermissions.push(appPermissions);
  });
});

const checkUserAccess = (section: string, userPermissions: Array<string> | null): boolean => {
  if (!userPermissions) {
    return false;
  }

  if (userPermissions.find((userPermission) => userPermission === "admin")) {
    return true;
  }

  const sectionPermission = sectionPermissions.find((permission) => permission.section === section);

  if (!sectionPermission) {
    return false;
  }

  const hasAccess: Array<boolean> = [];

  sectionPermission.allowed.forEach((allowedPermission) => {
    if (allowedPermission.type === "and") {
      const allPermissions = allowedPermission.permissions.filter((permission) =>
        userPermissions.find((userPermission) => userPermission === permission)
      );

      hasAccess.push(allPermissions.length === allowedPermission.permissions.length);
    }

    if (allowedPermission.type === "or") {
      let hasAnyPermission = false;
      allowedPermission.permissions.forEach((permission) => {
        if (userPermissions.find((userPermission) => userPermission === permission)) {
          hasAnyPermission = true;
        }
      });
      hasAccess.push(hasAnyPermission);
    }
  });

  return hasAccess.some((permission) => permission === true);
};

export { checkUserAccess, AppPermissions };
