import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, BlogInterface, BlogReducerState, FileReducerState } from "../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { Create } from "@material-ui/icons";
import validator from "validator";
import { addBlog, clearBlogError, clearFileError, updateBlog, uploadFiles } from "../../../../store/actions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { generateFileName } from "../../../../util/generate-file-name";
import { v4 as uuidv4 } from "uuid";
import request from "../../../../util/request";
import axios from "axios";

interface UpsertBlogDetailsFormProps {
  appTheme: AppTheme;
  blog: BlogReducerState;
  blogData: BlogInterface | null;
  file: FileReducerState;
  updateBlog: (_id: string, title: string, content: string, cover_img: string) => void;
  addBlog: (title: string, content: string, cover_img: string) => void;
  uploadFiles: (filePaths: Array<string>, files: FileList) => void;
  clearBlogError: () => void;
  clearFileError: () => void;
}

interface UpsertBlogDetailsModalProps {
  open: boolean;
  blogData: BlogInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      }
    })
  );

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote", "alignment"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"]
  ]
};

const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image"];

const UpsertBlogDetailsForm: React.FC<UpsertBlogDetailsFormProps> = (props) => {
  const { appTheme, blog, file, blogData, addBlog, updateBlog, clearBlogError, clearFileError, uploadFiles } = props;

  const [_id] = useState<string>(blogData ? blogData.blog_id : "");
  const [title, setTitle] = useState<string>(blogData ? blogData.title : "");
  const [content, setContent] = useState<string>(blogData ? blogData.content : "");
  const [coverImg, setCoverImg] = useState<string>(blogData ? blogData.cover_img : "");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [filesUploaded, setFilesUploaded] = useState<Array<string>>([]);
  const [uploadingContentImages, setUploadingContentImages] = useState<boolean>(false);

  const loading = blog.loading || file.loading || uploadingContentImages;

  const styles = useStyles(appTheme)();

  useEffect(() => {
    if (file.response && filesUploaded.length > 0) {
      setCoverImg(`https://cdn.pefachurchgimu.org/${filesUploaded[0]}`);

      clearFileError();

      setFilesUploaded([]);
    }
  }, [file.response, filesUploaded, clearFileError]);

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "title":
        setTitle(value);
        break;
      case "content":
        setContent(value);
        break;
      case "coverImg":
        setCoverImg(value);
        break;
      default:
        break;
    }
  };

  const submitForm = async () => {
    try {
      if (validator.isEmpty(title)) {
        setErrorMessage("The blog title entered is not valid");
      } else if (validator.isEmpty(content)) {
        setErrorMessage("The blog content entered is not valid");
      } else if (validator.isEmpty(coverImg)) {
        setErrorMessage("The blog cover image has not been uploaded");
      } else {
        setErrorMessage("");

        const images = content.split(`<img src="data:`);

        let parsedContent: string = content;

        for (let i = 0; i < images.length; i++) {
          if (i === 0) {
            continue;
          }
          const [fileType, fileData] = images[i].split('"').shift()?.split(";") ?? ["", ""];

          const url = await uploadImage(fileType, fileData.split(",")?.pop() ?? "");
          if (!(url instanceof Error)) parsedContent = parsedContent.replace(`<img src="data:${fileType};${fileData}`, `<img src="${url}`);
        }

        if (blogData) {
          updateBlog(_id, title, parsedContent, coverImg);
        } else {
          addBlog(title, parsedContent, coverImg);
        }
      }
    } catch (error) {
      setErrorMessage("An error occurred while trying to upload images. Error: " + (error as Error).message);
    }
  };

  const uploadImage = async (fileType: string, fileData: string): Promise<string | Error> => {
    try {
      setUploadingContentImages(true);
      const ext = fileType.split("/").pop() ?? "png";
      const filePath = generateFileName("blogs", "content-image/" + uuidv4(), ext);
      const file = b64toBlob(fileData, "image/png");
      const result = await request({ method: "post", pathname: "getS3UploadUrl", data: { filePath, fileType } });
      await axios.put(result.data.content.url, file, { headers: { "Content-Type": fileType } });
      return `https://cdn.pefachurchgimu.org/${filePath}`;
    } catch (error) {
      return error as Error;
    } finally {
      setUploadingContentImages(false);
    }
  };

  const b64toBlob = (b64Data: string, contentType: string = "", sliceSize = 512): Blob => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  useEffect(() => {
    const requestData = file.error ? file : blog.error ? blog : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [file, blog]);

  useEffect(() => {
    return () => {
      clearBlogError();
      clearFileError();
    };
  }, [clearBlogError, clearFileError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            value={title}
            onChange={(event) => {
              textFieldValueChanged("title", event.target.value);
            }}
            placeholder="Title (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Create className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          {coverImg ? (
            <img className={styles.img} alt={title} src={coverImg} />
          ) : (
            <Typography variant="body1" align="center" color="error">
              No Cover Image Uploaded Yet
            </Typography>
          )}
          <br />
          <Button disabled={loading} fullWidth color="primary" size="large" component="label">
            <input
              onChange={(event) => {
                if (event.target.files && event.target.files.length > 0) {
                  const filesPaths = [generateFileName("blogs", "blog-" + uuidv4(), event.target.files[0].type)];
                  setFilesUploaded(filesPaths);
                  uploadFiles(filesPaths, event.target.files);
                }
              }}
              type="file"
              hidden
              accept="image/*"
            />
            {loadingIndicator} {coverImg ? "Replace Blog Cover Image" : "Upload Blog Cover Image"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <ReactQuill
            readOnly={loading}
            onChange={(text) => textFieldValueChanged("content", text)}
            value={content}
            theme="snow"
            modules={modules}
            formats={formats}></ReactQuill>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} {props.blogData ? "Update Blog Details" : "Add New Blog"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    updateBlog: (_id: string, title: string, content: string, cover_img: string) => dispatch(updateBlog(_id, title, content, cover_img)),
    addBlog: (title: string, content: string, cover_img: string) => dispatch(addBlog(title, content, cover_img)),
    clearBlogError: () => dispatch(clearBlogError()),
    uploadFiles: (filePaths: Array<string>, files: FileList) => dispatch(uploadFiles(filePaths, files)),
    clearFileError: () => dispatch(clearFileError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    file: state.file,
    blog: state.blog,
    appTheme: state.general.theme
  };
};

const UpsertBlogDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(UpsertBlogDetailsForm);

const UpsertBlogDetailsModal: React.FC<UpsertBlogDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="lg"
      title={props.blogData ? "Update Blog Details" : "Add New Blog"}
      component={<UpsertBlogDetailsFormComponent blogData={props.blogData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UpsertBlogDetailsModal;
