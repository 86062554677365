import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearNotificationError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.NOTIFICATION_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const sendNotification = (subject: string, message: string, user_ids: Array<string>) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.NOTIFICATION_HTTP_LOADING
    });

    request({ method: "post", pathname: "sendNotification", data: { subject, message, user_ids: JSON.stringify(user_ids) } })
      .then((response) => {
        dispatch({
          type: actions.NOTIFICATION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.NOTIFICATION_HTTP_ERROR, error, dispatch);
      });
  };
};
