import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { alpha, Breadcrumbs, Card, CardActionArea, CardContent, Container, Paper, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { makeFirstLetterInEveryWordUpperCase } from "../../util/title-formater";
import Error404 from "../Error404";
import { useMinistry } from "../../hooks/useMinistry";
import { ErrorView } from "../../components";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        paddingTop: 40
      },
      title: {
        color: alpha(theme.palette.common.black, 0.5),
        fontSize: "2rem",
        fontWeight: 700,
        marginBottom: 30
      },
      card: {
        width: "100%",
        maxWidth: "100%",
        marginBottom: 30
      },
      paperSkeleton: {
        marginBottom: 30
      },
      ministryText: {
        lineHeight: "1.5em",
        fontSize: "1.25em"
      },
      breadcrumbLink: {
        textDecoration: "none",
        color: "#2f89fc",
        "&:hover": {
          color: alpha("#2f89fc", 0.5)
        }
      },
      icon: {
        color: "#2f89fc"
      }
    })
  );

const Ministry: React.FC = () => {
  const styles = useStyles()();

  const { pathname } = useParams<{ pathname: string }>();

  const { ministryLoading, ministryError, ministry, getMinistry } = useMinistry();

  useEffect(() => {
    window.scroll(0, 0);
    getMinistry(pathname);
  }, [getMinistry,pathname]);

  if (ministryError) {
    return <ErrorView error={ministryError} onRetry={() => getMinistry(pathname)} />;
  }

  if (!ministryLoading && !ministry) {
    return <Error404 />;
  }

  const description = ministry?.replace(/<\/?[^>]+(>|$)/g, "").substr(0, 150);

  const title=makeFirstLetterInEveryWordUpperCase(pathname.split("-").join(" "))

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content="PEFA Church Gimu" />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={`${window.location.origin}/logo.png`} />
      </Helmet>
      <div className={styles.root}>
        <Container maxWidth="lg">
          <Typography variant="h1" className={styles.title}>
            {ministryLoading ? "Loading ..." : title}
          </Typography>
          <Breadcrumbs>
            <Link color="inherit" to="/" className={styles.breadcrumbLink}>
              Home
            </Link>
            <Link color="inherit" to="/ministry" className={styles.breadcrumbLink}>
              Ministry
            </Link>
            {title.includes("Touch Group") && <Typography color="textPrimary">Touch Group</Typography>}
            <Typography color="textPrimary">{title}</Typography>
          </Breadcrumbs>
          <br />
          <React.Fragment>
            {ministryLoading ? (
              <Paper className={styles.paperSkeleton}>
                <Skeleton style={{ marginBottom: 25 }} variant="rect" height={350} />
                <div style={{ padding: 15 }}>
                  <Skeleton style={{ marginBottom: 5 }} variant="text" />
                  <Skeleton style={{ marginBottom: 5 }} variant="text" />
                  <Skeleton style={{ marginBottom: 5 }} variant="text" />
                  <Skeleton style={{ marginBottom: 5 }} variant="text" />
                </div>
              </Paper>
            ) : (
              <React.Fragment>
              <Card className={styles.card}>
                <CardActionArea>
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={styles.ministryText}
                      dangerouslySetInnerHTML={{ __html: ministry?.replace(/<img/g, '<img width="100%"').replace(/<br>/g, "") ?? "" }}
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
              <br/>
              <br/>
              </React.Fragment>
            )}
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Ministry;
