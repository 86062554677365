import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Container, Divider, Grid, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Mail, Phone } from "@material-ui/icons";
import facebookImg from "../assets/facebook.png";
import youtubeImg from "../assets/youtube.png";
import instagramImg from "../assets/instagram.png";
import appStoreImg from "../assets/appStore.png";
import playStoreImg from "../assets/playStore.png";
import logoImg from "../assets/logo.png";

interface FooterProps {}

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: "#2f2e2e",
        width: "100%"
      },
      logo: {
        height: 150,
        display: "block",
        margin: "0px auto",
        [theme.breakpoints.up("md")]: {
          height: 250
        }
      },
      subTitle: {
        color: "#6c6c6b"
      },
      text: {
        fontWeight: 300,
        color: "#cfcfcf",
        "& > b": {
          fontWeight: 400,
          color: theme.palette.common.white
        }
      },
      footerSectionTitle: {
        color: "#cfcfcf",
        fontWeight: 300,
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
          textAlign: "left"
        }
      },
      footerSectionDividerShort: {
        background: theme.palette.primary.main,
        height: 4
      },
      footerSectionDividerLong: {
        background: "#cfcfcf",
        marginTop: 2
      },
      bottomText: {
        color: "#6c6c6b",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
          textAlign: "left"
        }
      },
      bottomTextLink: {
        color: "#cfcfcf",
        textDecoration: "none",
        "&:hover": {
          color: theme.palette.common.white
        }
      },
      bottomSection: {
        backgroundColor: "#242323",
        padding: "10px"
      },
      quickLinks: {
        fontWeight: 300,
        color: "#cfcfcf",
        textDecoration: "none",
        "&:hover": {
          color: "#fff"
        }
      }
    })
  );

const Footer: React.FC<FooterProps> = () => {
  const styles = useStyles()();

  const FooterSectionHeader = ({ name }: { name: string }) => {
    return (
      <Grid container spacing={1} direction="column" style={{ width: "100%", marginBottom: 10 }}>
        <Grid item>
          <Typography variant="subtitle1" className={styles.footerSectionTitle}>
            {name}
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <Divider className={styles.footerSectionDividerShort} />
            </Grid>
            <Grid item xs={7}>
              <Divider className={styles.footerSectionDividerLong} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={styles.root}>
      <Container maxWidth="md">
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <img className={styles.logo} src={logoImg} alt="PEFA Church Gimu" />
            <br />
            <br />
          </Grid>
          <Grid item xs={12} md={6}>
            <FooterSectionHeader name="Quick Links" />
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item>
                <List>
                  {[
                    { text: "Home", link: "/" },
                    { text: "Mission", link: "/mission" },
                    { text: "Leadership", link: "/leadership" },
                    { text: "Blog", link: "/blog" }
                  ].map((menuItem, index) => (
                    <ListItem button key={index} component={Link} to={menuItem.link} className={styles.quickLinks}>
                      <ListItemText primary={menuItem.text} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item>
                <List>
                  {[
                    { text: "Events", link: "/events" },
                    { text: "Gallery", link: "/gallery" },
                    { text: "Projects", link: "/projects" },
                    { text: "Discipleship Classes", link: "/discipleship-classes" }
                  ].map((menuItem, index) => (
                    <ListItem button key={index} component={Link} to={menuItem.link} className={styles.quickLinks}>
                      <ListItemText primary={menuItem.text} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <FooterSectionHeader name="Our Services" />
            {[
              { title: "Morning Devotion", text: "Monday - Friday: 5:30 am - 6:30 am" },
              { title: "Lunch Hours", text: "Monday - Friday: 1:00 pm - 2:00 pm" },
              { title: "Evening Service", text: "Tuesday: 7:00 pm - 8:00 pm" },
              { title: "Sunday Main Service", text: "Sunday: 9:00 am - 12:00 noon" },
              { title: "Outreach", text: "Sartuday: 12:00 noon - 2:00 pm" }
            ].map((serviceDetails, index) => (
              <div key={index} style={{ marginBottom: 15 }}>
                <Typography style={{ marginBottom: 5 }} className={styles.subTitle} variant="body1">
                  {serviceDetails.title}
                </Typography>
                <Typography style={{ marginLeft: 15 }} className={styles.text} variant="body2">
                  {serviceDetails.text}
                </Typography>
              </div>
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            <FooterSectionHeader name="Download Our Mobile App" />
            <Typography className={styles.text} variant="body1">
              Download either our Android/IOS app to stay connected:
            </Typography>
            <br />
            <br />
            <Grid container spacing={3} direction="column" alignItems="center">
              <Grid item>
                <a href="https://play.google.com/store/apps/details?id=org.pefachurchgimu" target="_blank" rel="noreferrer">
                  <img height={70} src={playStoreImg} alt="Play Store" />
                </a>
              </Grid>
              <Grid item>
                <a href="https://apps.apple.com/us/app/facebook/id1638649653" target="_blank" rel="noreferrer">
                  <img height={70} src={appStoreImg} alt="App Store" />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <FooterSectionHeader name="Visit Us" />
            <Typography className={styles.text} variant="body1">
              We are located at: <b>Gimu Estate, Athi River, Machakos, Kenya</b>
            </Typography>
            <br />
            <br />
            <iframe
              title="PEFA Church Gimu - Athi River"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.554050503276!2d36.99921001503223!3d-1.4424222989480278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f75a69393c80f%3A0x4ec3c88c24ff4818!2sPEFA%20Church%20Gimu!5e0!3m2!1sen!2ske!4v1651496502145!5m2!1sen!2ske"
              width="100%"
              height="250"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"></iframe>
          </Grid>
          <Grid item xs={12} md={6}>
            <FooterSectionHeader name="Contact Us" />
            <Typography className={styles.text} variant="body1">
              Reach out to us through any of our social media handles:
            </Typography>
            <br />
            <br />
            <Grid container spacing={4} justifyContent="center">
              <Grid item>
                <a href="https://www.facebook.com/PEFAChurchGimu.Ke" target="_blank" rel="noreferrer">
                  <img height={50} src={facebookImg} alt="Facebook" />
                </a>
              </Grid>
              <Grid item>
                <a href="https://www.youtube.com/channel/UChK8BC2sreAixI4qllI4HeQ" target="_blank" rel="noreferrer">
                  <img height={50} src={youtubeImg} alt="Youtube" />
                </a>
              </Grid>
              <Grid item>
                <a href="https://www.instagram.com/pefachurchgimuAthi River" target="_blank" rel="noreferrer">
                  <img height={45} src={instagramImg} alt="Instagram" />
                </a>
              </Grid>
            </Grid>
            <br />
            <br />
            <Typography className={styles.text} variant="body1">
              Call/Email us via:
            </Typography>
            <br />
            <br />
            <Grid container spacing={3} direction="column">
              {[
                { icon: <Phone color="primary" />, text: "+254 720 410 722", link: "tel:+254720410722" },
                { icon: <Mail color="primary" />, text: "info@pefachurchgimu.org", link: "mailto:info@pefachurchgimu.org" }
              ].map((contactDetails, index) => (
                <Grid item key={index}>
                  <a href={contactDetails.link} style={{ textDecoration: "none" }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>{contactDetails.icon}</Grid>
                      <Grid item>
                        <Typography className={styles.text} variant="body2">
                          {contactDetails.text}
                        </Typography>
                      </Grid>
                    </Grid>
                  </a>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
        <br />
      </Container>
      <div className={styles.bottomSection}>
        <Container maxWidth="md">
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12} md={9}>
              <Typography variant="body2" className={styles.bottomText}>
                Copyright © {new Date().getFullYear()} PEFA Church Gimu. All rights reserved
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container spacing={3} justifyContent="space-between">
                <Grid item>
                  <Typography variant="body2" className={styles.bottomTextLink} to="/privacy-policy" component={Link}>
                    Privacy Policy
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" className={styles.bottomTextLink} to="/terms-of-use" component={Link}>
                    Terms of Use
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
