import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  AppState,
  AppTheme,
  ProfileReducerState,
  GalleryInterface,
  GalleryReducerState,
  GalleriesReducerState,
  FileReducerState
} from "../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Container, List, ListItem, ListItemAvatar, ListItemSecondaryAction, IconButton, Button, CircularProgress } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { DialogModal } from "../../../../components";
import { addGalleryPhotos, clearFileError, clearGalleryError, deleteGalleryPhoto, uploadFiles } from "../../../../store/actions";
import { checkUserAccess } from "../../../../util/permission-checker";
import { generateFileName } from "../../../../util/generate-file-name";
import { v4 as uuidv4 } from "uuid";

interface GalleryPhotosProps {
  file: FileReducerState;
  galleries: GalleriesReducerState;
  gallery: GalleryReducerState;
  galleryData: GalleryInterface | null;
  profile: ProfileReducerState;
  appTheme: AppTheme;
  clearGalleryError: () => void;
  addGalleryPhotos: (_id: string, photos: string[]) => void;
  deleteGalleryPhoto: (_id: string, photo_url: string) => void;
  uploadFiles: (filePaths: Array<string>, files: FileList) => void;
  clearFileError: () => void;
}

interface GalleryPhotoModalProps {
  open: boolean;
  galleryData: GalleryInterface | null;
  onDismissed: () => void;
}

const useStyles = () =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      container: {
        width: "400px",
        height: "60vh",
        maxHeight: "60vh",
        overflowY: "scroll"
      },
      img: {
        width: "300px",
        height: "250px"
      }
    })
  );

const GalleryPhotos: React.FC<GalleryPhotosProps> = (props) => {
  const {
    galleryData,
    gallery,
    galleries,
    profile,
    file,
    deleteGalleryPhoto,
    addGalleryPhotos,
    uploadFiles,
    clearGalleryError,
    clearFileError
  } = props;

  const [_id] = useState<string>(galleryData ? galleryData.gallery_id : "");
  const [photos, setPhotos] = useState<string[]>([]);
  const [filesUploaded, setFilesUploaded] = useState<Array<string>>([]);

  useEffect(() => {
    if (file.response && filesUploaded.length > 0) {
      addGalleryPhotos(
        _id,
        filesUploaded.map((fileUploaded) => `https://cdn.pefachurchgimu.org/${fileUploaded}`)
      );

      clearFileError();

      setFilesUploaded([]);
    }
  }, [_id, file.response, filesUploaded, addGalleryPhotos, clearFileError]);

  useEffect(() => {
    return () => {
      clearGalleryError();
      clearFileError();
    };
  }, [clearGalleryError, clearFileError]);

  useEffect(() => {
    setPhotos(galleries.data.find((gallaryDetails) => gallaryDetails.gallery_id === _id)?.photos ?? []);
  }, [galleries.data, _id]);

  const styles = useStyles()();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearGalleryError();
    };
  }, [clearGalleryError]);

  useEffect(() => {
    if (!gallery.error && gallery.response && gallery.response.data.status !== "Ok") {
      clearGalleryError();

      setDialogOptions({ open: true, body: gallery.response.data.status });
    }
  }, [gallery.error, gallery.response, _id, clearGalleryError]);

  useEffect(() => {
    const requestData = gallery.error ? gallery : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [gallery]);

  let loadingIndicator = null;

  if (file.loading || galleries.loading || gallery.loading)
    loadingIndicator = <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />;

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <Container maxWidth="md">
        <List component="div" className={styles.container}>
          {photos.map((photo, index) => (
            <ListItem key={index} alignItems="flex-start">
              <ListItemAvatar>
                <img className={styles.img} src={photo} alt={`${galleryData?.title} index ${index}`} />
              </ListItemAvatar>
              {checkUserAccess("edit-gallery", profile.permissions) && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      if (window.confirm("Would you like to delete this gallery photo ?")) {
                        deleteGalleryPhoto(_id, photo);
                      }
                    }}>
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
        <Button
          disabled={!checkUserAccess("edit-gallery", profile.permissions)}
          variant="outlined"
          size="large"
          fullWidth
          color="primary"
          component="label">
          <input
            onChange={(event) => {
              if (event.target.files && event.target.files.length > 0) {
                const filesPaths = [];
                for (let i = 0; i < event.target.files.length; i++) {
                  filesPaths.push(generateFileName("gallery-" + galleryData?.gallery_id, "gallery-photo-" + uuidv4(), event.target.files[i].type));
                }
                setFilesUploaded(filesPaths);
                uploadFiles(filesPaths, event.target.files);
              }
            }}
            type="file"
            multiple
            hidden
            accept="image/*"
          />
          {loadingIndicator} Add New Gallery Photos
        </Button>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    clearGalleryError: () => dispatch(clearGalleryError()),
    addGalleryPhotos: (_id: string, photos: string[]) => dispatch(addGalleryPhotos(_id, photos)),
    deleteGalleryPhoto: (_id: string, photo_url: string) => dispatch(deleteGalleryPhoto(_id, photo_url)),
    uploadFiles: (filePaths: Array<string>, files: FileList) => dispatch(uploadFiles(filePaths, files)),
    clearFileError: () => dispatch(clearFileError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    file: state.file,
    gallery: state.gallery,
    galleries: state.galleries,
    profile: state.profile,
    appTheme: state.general.theme
  };
};

const GalleryPhotosComponent = connect(mapStateToProps, mapDispatchToProps)(GalleryPhotos);

const GalleryPhotoModal: React.FC<GalleryPhotoModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="lg"
      title="Gallery Photos"
      component={<GalleryPhotosComponent galleryData={props.galleryData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default GalleryPhotoModal;
