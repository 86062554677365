import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, ResetReducerState } from "../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Grid, Container, Paper, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { Lock } from "@material-ui/icons";
import { verifyResetPasswordCode, clearResetPasswordCodeError } from "../../store/actions";
import { DialogModal } from "../../components";
import validator from "validator";

interface VerifyResetPasswordCodeProps {
  loggedIn: boolean;
  reset: ResetReducerState;
  appTheme: AppTheme;
  verifyResetPasswordCode: (code: string) => void;
  clearResetPasswordCodeError: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 30,
        marginTop: 20,
        marginBottom: 20
      },
      title: {
        color: appTheme.titleTextColor
      },
      text: {
        color: theme.palette.text.primary
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      }
    })
  );

const VerifyResetPasswordCode: React.FC<VerifyResetPasswordCodeProps> = (props) => {
  const history = useHistory();

  const [code, setCode] = useState<string>("");
  const { appTheme, reset, loggedIn, verifyResetPasswordCode, clearResetPasswordCodeError } = props;

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  const submitForm = () => {
    if (!validator.isByteLength(code, { min: 6 })) {
      setDialogOptions({ open: true, body: "Code entered should be 6 characters in length" });
    } else {
      verifyResetPasswordCode(code);
    }
  };

  useEffect(() => {
    const requestData = reset.error ? reset : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [reset]);

  useEffect(() => {
    if (loggedIn) {
      history.push("/dashboard/account/profile");
    }
  }, [loggedIn, history]);

  useEffect(() => {
    if (reset.verified_code) {
      history.push("/account/reset/password");
    }
  }, [reset.verified_code, history]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearResetPasswordCodeError();
    };
  }, [clearResetPasswordCodeError]);

  let loadingIndicator = null;

  const loading = reset.loading;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <Container maxWidth="xs">
        <Grid container alignItems="center" style={{ minHeight: "85vh" }}>
          <Grid item xs={11}>
            <Paper className={styles.paper}>
              <form
                style={{ width: "100%" }}
                onSubmit={(event) => {
                  event.preventDefault();
                  submitForm();
                }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography variant="h5" align="center" className={styles.title}>
                      Verify Reset Password Code
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" align="center" className={styles.text}>
                      A 6 - digit code has been sent to your email. Also, if you cannot find the email please check spam folder
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      required
                      type="text"
                      value={code}
                      onChange={(event) => {
                        setCode(event.target.value);
                      }}
                      placeholder="Reset Password Code"
                      className={styles.textField}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock className={styles.textFieldIcon} />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth disabled={reset.loading} size="large" type="submit" variant="contained" color="primary">
                      {loadingIndicator}Verify Reset Password Code
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    verifyResetPasswordCode: (code: string) => dispatch(verifyResetPasswordCode(code)),
    clearResetPasswordCodeError: () => dispatch(clearResetPasswordCodeError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    loggedIn: state.login.loggedIn,
    appTheme: state.general.theme,
    reset: state.reset
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyResetPasswordCode);
