import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, ProfileReducerState, AppTheme, FileReducerState, PasswordReducerState } from "../../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Grid, Container, Paper, Avatar, Typography, CircularProgress } from "@material-ui/core";
import { DialogModal } from "../../../components";
import { clearProfileError, updateProfile, clearFileError, uploadFiles } from "../../../store/actions";
import { generateFileName } from "../../../util/generate-file-name";
import userImg from "../../../assets/user-img.png";
import ChangePasswordModal from "./Modals/ChangePasswordDialog";
import ChangeProfileModal from "./Modals/ChangeProfileDialog";
import { v4 as uuidv4 } from "uuid";

interface ProfileProps {
  file: FileReducerState;
  profile: ProfileReducerState;
  password: PasswordReducerState;
  appTheme: AppTheme;
  updateProfileImg: (img: string) => void;
  clearProfileError: () => void;
  uploadFiles: (filePaths: Array<string>, files: FileList) => void;
  clearFileError: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 30,
        marginTop: 20,
        marginBottom: 20
      },
      avatar: {
        height: 150,
        width: 150,
        display: "block",
        margin: "auto",
        border: "1px solid black"
      },
      icon: {
        color: theme.palette.text.primary,
        padding: 10,
        borderRadius: 30,
        backgroundColor: appTheme.iconBackgroundColor,
        "&:hover": {
          backgroundColor: appTheme.iconBackgroundHoverColor
        }
      }
    })
  );

const Profile: React.FC<ProfileProps> = (props) => {
  const { appTheme, profile, file, password, updateProfileImg, clearProfileError, uploadFiles, clearFileError } = props;

  const styles = useStyles(appTheme)();

  const [filesUploaded, setFilesUploaded] = useState<Array<string>>([]);
  const [changePasswordModelOpen, setChangePasswordModelOpen] = useState<boolean>(false);
  const [changeProfileModelOpen, setChangeProfileModelOpen] = useState<boolean>(false);

  useEffect(() => {
    if (file.response && filesUploaded.length > 0) {
      updateProfileImg(`https://cdn.pefachurchgimu.org/${filesUploaded[0]}`);

      clearFileError();

      setFilesUploaded([]);
    }
  }, [file.response, filesUploaded, updateProfileImg, clearFileError]);

  useEffect(() => {
    if (!profile.error && profile.response) {
      clearProfileError();

      if (changeProfileModelOpen) {
        setChangeProfileModelOpen(false);

        setDialogOptions({ open: true, body: "Profile updated successfully" });
      } else {
        setDialogOptions({ open: true, body: "Profile image updated successfully" });
      }
    }
  }, [profile.error, profile.response, changeProfileModelOpen, clearProfileError]);

  useEffect(() => {
    if (!password.error && password.response) {
      setChangePasswordModelOpen(false);

      setDialogOptions({ open: true, body: "Password changed successfully" });
    }
  }, [password.error, password.response]);

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return () => {
      clearFileError();
      clearProfileError();
    };
  }, [clearFileError, clearProfileError]);

  useEffect(() => {
    const requestData = file.error ? file : profile.error ? profile : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [profile, file]);

  let loadingIndicator = null;

  const loading = profile.loading || file.loading;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <ChangePasswordModal open={changePasswordModelOpen} onDismissed={() => setChangePasswordModelOpen(false)} />
      <ChangeProfileModal open={changeProfileModelOpen} onDismissed={() => setChangeProfileModelOpen(false)} />
      <Container maxWidth="md">
        <Grid container alignItems="center" style={{ minHeight: "85vh" }}>
          <Grid item xs={12} sm={11} md={11}>
            <Paper className={styles.paper}>
              <Typography variant="h3" color="primary">
                Profile
              </Typography>
              <br />
              <Grid container alignItems="flex-start" spacing={5}>
                <Grid item xs={12} sm={4}>
                  <Avatar className={styles.avatar} alt={profile.name} src={profile.img ?? userImg} />
                  <br />
                  <Button disabled={loading} fullWidth color="primary" component="label">
                    <input
                      onChange={(event) => {
                        if (event.target.files && event.target.files.length > 0) {
                          const filesPaths = [generateFileName("profiles", "profile-" + uuidv4(), event.target.files[0].type)];
                          setFilesUploaded(filesPaths);
                          uploadFiles(filesPaths, event.target.files);
                        }
                      }}
                      type="file"
                      hidden
                      accept="image/*"
                    />
                    {loadingIndicator} Update Image
                  </Button>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Grid container spacing={2} direction="column">
                    {[
                      { title: "Name", key: "name" },
                      { title: "Username", key: "username" },
                      { title: "Email", key: "email" },
                      { title: "Mobile No", key: "mobile_no" },
                      { title: "Gender", key: "gender" },
                      { title: "Date Of Birth", key: "dob" },
                      { title: "Residence", key: "residence" },
                      { title: "Type", key: "user_type" },
                      { title: "Status", key: "status" },
                      { title: "Permissions", key: "permissions" }
                    ].map((element, index) => {
                      const data = (profile as unknown as { [key: string]: any })[element.key] ?? "[ Not Set ]";
                      return (
                        <Grid item xs={12} key={index}>
                          <Grid container spacing={2}>
                            <Grid item>
                              <Typography variant="subtitle1" color="primary">
                                {`${element.title}:`}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle1">{Array.isArray(data) ? data.join(",") : data ?? "[ Not Set ]"}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <br />
                  </Grid>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                      <Button disabled={loading} variant="contained" color="primary" onClick={() => setChangeProfileModelOpen(true)}>
                        Update Profile
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button disabled={loading} variant="contained" color="primary" onClick={() => setChangePasswordModelOpen(true)}>
                        Update Password
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    uploadFiles: (filePaths: Array<string>, files: FileList) => dispatch(uploadFiles(filePaths, files)),
    updateProfileImg: (img: string) => dispatch(updateProfile({ img })),
    clearProfileError: () => dispatch(clearProfileError()),
    clearFileError: () => dispatch(clearFileError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    file: state.file,
    profile: state.profile,
    password: state.password,
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
