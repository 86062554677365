import React from "react";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from "@material-ui/core";
import { AppState, AppTheme } from "../interfaces";

interface DialogModalProps {
  contentType?: "text" | "custom";
  title?: string;
  body?: string;
  component?: React.ReactElement;
  open: boolean;
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  acceptText?: string;
  dismissText?: string;
  onAccepted?: () => void;
  onDismissed: () => void;
  appTheme: AppTheme;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      title: {
        color: appTheme.titleTextColor,
        backgroundColor: appTheme.section.backgroundColor
      },
      content: {
        color: theme.palette.text.primary,
        backgroundColor: appTheme.section.backgroundColor
      },
      actions: {
        backgroundColor: appTheme.section.backgroundColor
      }
    })
  );

const DialogModal: React.FC<DialogModalProps> = (props) => {
  const styles = useStyles(props.appTheme)();

  let maxWidth: "xs" | "sm" | "md" | "lg" | "xl" = "xs",
    fullWidth = false,
    title = "PEFA Church | Gimu - Athi River",
    dismissText = "Close",
    contentType = "text";

  if (props.contentType) {
    contentType = props.contentType;
  }

  if (props.title) {
    title = props.title;
  }

  if (props.dismissText) {
    dismissText = props.dismissText;
  }

  if (props.maxWidth) {
    maxWidth = props.maxWidth;
  }

  if (props.fullWidth) {
    fullWidth = props.fullWidth;
  }

  if (!props.open) {
    return null;
  }

  return (
    <Dialog maxWidth={maxWidth} fullWidth={fullWidth} keepMounted open={props.open} onClose={props.onDismissed}>
      <DialogTitle className={styles.title}>{title}</DialogTitle>
      <DialogContent className={styles.content}>
        {contentType === "text" ? (
          <DialogContentText color="inherit" style={{ textAlign: "justify" }}>
            {props.body}
          </DialogContentText>
        ) : props.component ? (
          props.component
        ) : null}
      </DialogContent>
      <DialogActions className={styles.actions}>
        {props.acceptText && props.onAccepted && (
          <Button onClick={props.onAccepted} color="primary">
            {props.acceptText}
          </Button>
        )}
        <Button onClick={props.onDismissed} color="primary">
          {dismissText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps)(React.memo(DialogModal));
