import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";
import CryptoES from "crypto-es";

export const clearPasswordError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.PASSWORD_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const changePassword = (current_password: string, new_password: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.PASSWORD_HTTP_LOADING
    });

    request({
      method: "post",
      pathname: "updatePassword",
      data: { current_password: CryptoES.SHA256(current_password), new_password: CryptoES.SHA256(new_password) }
    })
      .then((response) => {
        dispatch({
          type: actions.PASSWORD_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.PASSWORD_HTTP_ERROR, error, dispatch);
      });
  };
};
