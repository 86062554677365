import React from "react";
import DialogModal from "../../../../components/DialogModal";
import { BlogInterface } from "../../../../interfaces";

interface ViewBlogContentModalProps {
  open: boolean;
  blogData: BlogInterface | null;
  onDismissed: () => void;
}

const ViewBlogContentModal: React.FC<ViewBlogContentModalProps> = (props) => {
  const html = props.blogData ? props.blogData.content.replace(/<img/g, '<img width="100%"').replace(/<br>/g, "") : "";

  return (
    <DialogModal
      maxWidth="lg"
      title="Blog Content"
      component={
        <div style={{ width: "100%", maxWidth: "100%" }}>
          <img alt={props.blogData?.title} src={props.blogData?.cover_img} style={{ width: "100%", height: "auto" }} />
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      }
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default ViewBlogContentModal;
