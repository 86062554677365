import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import { store } from "..";
import errorHandler from "../../util/error-handler";

export const initApp = () => {
  return (dispatch: Dispatch): void => {
    const token = store.getState().token.refresh;

    if (token) {
      dispatch({
        type: actions.APP_HTTP_LOADING
      });

      request({ method: "post", pathname: "refreshAccessToken", data: { token: token } })
        .then((response) => {
          dispatch({
            type: actions.APP_HTTP_COMPLETE,
            payload: {
              response: response
            }
          });

          dispatch({
            type: actions.LOGGING_IN_USER,
            payload: {
              ...response.data.content
            }
          });

          dispatch({
            type: actions.LOG_IN_USER
          });
        })
        .catch((error) => {
          errorHandler(actions.APP_HTTP_ERROR, error, dispatch);
        });
    } else {
      dispatch({
        type: actions.APP_HTTP_COMPLETE,
        payload: {
          response: null
        }
      });
    }
  };
};
