import React, { useState } from "react";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import { alpha, createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  IconButton,
  Drawer,
  AppBar,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid,
  Container,
  Popper,
  Hidden,
  Collapse
} from "@material-ui/core";
import { Close, ExpandLess, ExpandMore, Menu as MenuIcon } from "@material-ui/icons";
import logoWithoutBannerImg from "../assets/logoWithoutBanner.png";
import Footer from "./Footer";
import Index from "./Index/Index";
import Blog from "./Blog/Blog";
import Article from "./Blog/Article/Article";
import Projects from "./Projects/Projects";
import Project from "./Projects/Project/Project";
import Gallery from "./Gallery/Gallery";
import Events from "./Events/Events";
import Ministry from "./Ministry/Ministry";
import Error404 from "./Error404";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./TermsOfUse/TermsOfUse";


interface HomeProps {}

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      logo: {
        height: "100px",
        display: "block",
        margin: "auto"
      },
      appBar: {
        padding: 10
      },
      menuButton: {
        [theme.breakpoints.up("sm")]: {
          marginRight: theme.spacing(2),
          display: "none"
        }
      },
      drawerPaper: {
        width: 250,
        backgroundColor:"#e4e6eb",
      },
      content: {
        width: "100%",
        padding: 0,
        margin: 0
      },
      toolbar: theme.mixins.toolbar,
      navButton: {
        color: "#2f2e2e",
        "&:hover": {
          color:"#fff"
        }
      },
      activeNavButton: {
        color:"#fff",
        "&:hover": {
          color:alpha("#fff",0.75)
        }
      },
      navButtonPopper: {
        color: "#2f2e2e",
        fontSize:12,
        "&:hover": {
          color:"#fff",
          backgroundColor: theme.palette.primary.main
        }
      },
      activeNavButtonPopper: {
        color:"#fff",
        backgroundColor: theme.palette.primary.main,
        fontSize:12,
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
        }
      },
      navDrawerButton: {
        borderRadius: "0px 60px 60px 0px",
        color:"#fff !important",
        backgroundColor:theme.palette.primary.main,
        boxShadow: "0px 7px 12px 0px rgb(95 118 232 / 21%)",
        marginTop:'5px',
        marginBottom:'5px',
        width:'98%'
      },
      navDrawerSubMenuButton: {
        borderRadius: "0px 60px 60px 0px",
        color:"#fff !important",
        backgroundColor:theme.palette.primary.main,
        boxShadow: "0px 7px 12px 0px rgb(95 118 232 / 21%)",
        marginTop:'15px',
        marginBottom:'10px',
        width:'85%',
        paddingLeft:40
      }
    })
  );

const Home: React.FC<HomeProps> = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const styles = useStyles()();

  const [menuAnchors, setMenuAnchors] = useState<{ [key: string]: any }>({});
  const [expandDrawerMenu, setExpandDrawerMenu] = useState<{ [key: string]: boolean }>({});

  const navigationMenuItems = [
    {
      text: "About",
      subMenu: [
        { text: "Mission", link: "/mission" },
        { text: "Leadership", link: "/leadership" }
      ]
    },
    { text: "Blog", link: "/blog" },
    {
      text: "Ministries",
      subMenu: [
        { text: "Men's Ministry", link: "/ministry/mens-ministry" },
        { text: "Women's Ministry", link: "/ministry/womens-ministry" },
        { text: "Youth Ministry", link: "/ministry/youth-ministry" },
        { text: "Children Ministry", link: "/ministry/children-ministry" },
        { text: "Care Ministry", link: "/ministry/care-ministry" },
        { text: "Media Ministry", link: "/ministry/media-ministry" },
        { text: "Uwezo Upendo TG", link: "/ministry/uwezo-upendo-touch-group" },
        { text: "Almasi TG", link: "/ministry/almasi-touch-group" },
        { text: "Nawiri TG", link: "/ministry/nawiri-touch-group" },
        { text: "Tuvune TG", link: "/ministry/tuvune-touch-group" },
        { text: "Wema TG", link: "/ministry/wema-touch-group" },
      ]
    },
    { text: "Events", link: "/events" },
    { text: "More", subMenu: [
        { text: "Gallery", link: "/gallery" },
        { text: "Projects", link: "/projects" },
        { text: "Discipleship Classes", link: "/discipleship-classes" }
      ] 
    }
  ]

  const menuSection = (
    <React.Fragment>
      <div style={{background:"#0587b2",padding:"10px 10px 40px 5px"}}>
        <IconButton style={{float:"right",color:"#fff"}} size="medium" onClick={() => setIsDrawerOpen(false)}>
          <Close />
        </IconButton>
        <Link to="/" style={{textDecoration:"none"}}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <img height={45} src={logoWithoutBannerImg} alt="PEFA Church Gimu" />
            </Grid>
            <Grid item>
              <Grid container spacing={0} direction="column" justifyContent="center">
                <Grid item>
                  <Typography variant="h6" style={{ fontSize: 16, color: "#000" }}>
                    PEFA CHURCH GIMU
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" style={{ fontSize: 12,fontFamily: "Dancing Script",color:"#fff" }}>
                    Touching Families to Impact The World
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Link>
      </div>
      <br />
      
      <List>
        {navigationMenuItems.map((menuItem, index) => {
          const menuId:string=`${menuItem.text.replace("'","").split(" ").join("-").toLocaleLowerCase()}-menu`

          const expanded:boolean = Boolean(expandDrawerMenu[menuId])

          if(menuItem.subMenu) {
            return (
              <React.Fragment key={index}>
                <ListItem onClick={() => setExpandDrawerMenu({[menuId]:!expanded})} className={styles.navDrawerButton}>
                  <ListItemText primary={menuItem.text} />
                  {expanded?<ExpandLess /> : <ExpandMore />}
                </ListItem>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {
                      menuItem.subMenu.map((subMenuItem,index) => (
                        <ListItem key={index} component={Link} to={subMenuItem.link} className={styles.navDrawerSubMenuButton} onClick={() => setIsDrawerOpen(false)}>
                          <ListItemText primary={subMenuItem.text} />
                        </ListItem>
                      ))
                    }
                  </Collapse>
                <br/>
              </React.Fragment>
            )
          }

          return (
            <React.Fragment>
              <ListItem key={index} component={Link} to={menuItem.link} className={styles.navDrawerButton} onClick={() => setIsDrawerOpen(false)}>
                <ListItemText primary={menuItem.text} />
              </ListItem>
              <br/>
            </React.Fragment>
          )
        })}
      </List>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <AppBar position="fixed" className={styles.appBar} onMouseEnter={() => setMenuAnchors({})}>
        <Container maxWidth="md">
          <Grid container spacing={1} alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={() => setIsDrawerOpen(true)} className={styles.menuButton}>
                    <MenuIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Link to="/" style={{textDecoration:"none"}}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <img height={45} src={logoWithoutBannerImg} alt="PEFA Church Gimu" />
                      </Grid>
                      <Grid item>
                        <Grid container spacing={0} direction="column" justifyContent="center">
                          <Grid item>
                            <Typography variant="h6" style={{ fontSize: 16, color: "#000" }}>
                              PEFA CHURCH GIMU
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2" style={{ fontSize: 12,fontFamily: "Dancing Script",color:"#fff" }}>
                              Touching Families to Impact The World
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Hidden xsDown>
                <div style={{ padding: 10 }}>
                  <Grid container spacing={2} alignItems="center">
                    {navigationMenuItems.map((menuItem, index) => {

                      const isActive = menuItem.subMenu?menuItem.subMenu.some(subMenuItem => window.location.pathname === subMenuItem.link):window.location.pathname === menuItem.link

                      if (menuItem.subMenu) {
                        const menuId:string=`${menuItem.text.replace("'","").split(" ").join("-").toLocaleLowerCase()}-menu`
                        return (
                          <Grid item key={index}>
                            <Button className={isActive?styles.activeNavButton:styles.navButton} onMouseEnter={event => setMenuAnchors({[menuId]:event.currentTarget})} aria-controls={menuId} aria-haspopup="true">{menuItem.text.toUpperCase()}</Button>
                            <Popper
                              id={menuId}
                              anchorEl={menuAnchors[menuId]}
                              keepMounted
                              open={Boolean(menuAnchors[menuId])}
                              onMouseLeave={() => setMenuAnchors({})}
                              style={{zIndex:1200,backgroundColor: "#fff",borderRadius:5,width:200,boxShadow: "2px 2px rgba(0, 0, 0, 0.2)"}}
                            >
                              {
                                menuItem.subMenu.map((subMenuItem,index) => (
                                  <Button component={Link} to={subMenuItem.link} fullWidth key={index} className={window.location.pathname === subMenuItem.link?styles.activeNavButtonPopper:styles.navButtonPopper}>{subMenuItem.text}</Button>
                                ))
                              }
                            </Popper>
                          </Grid>
                        );
                      }

                      return (
                        <Grid item key={index}>
                          <Button onMouseEnter={() => setMenuAnchors({})} className={isActive?styles.activeNavButton:styles.navButton} component={Link} to={menuItem.link}>{menuItem.text.toUpperCase()}</Button>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </Hidden>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Container>
      </AppBar>
      <Drawer classes={{ paper: styles.drawerPaper }} open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        {menuSection}
      </Drawer>
      <div style={{ height: 80 }} />
      <Switch>
        <Route exact path="/" component={Index} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/blog/:pathname" component={Article} />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/projects/:pathname" component={Project} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/ministry"><Redirect to="/" /></Route>
        <Route exact path="/ministry/:pathname" component={Ministry} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-of-use" component={TermsOfUse} />
        <Route component={Error404} />
      </Switch>
      <Footer />
    </React.Fragment>
  );
};

export default Home;
