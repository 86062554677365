import { AnyAction } from "redux";
import actions from "../../util/actions";
import { KittyLinkedContributionsReducerState } from "../../interfaces";

const initialState: KittyLinkedContributionsReducerState = {
  initial: false,
  loading: true,
  error: null,
  response: null,
  search: "",
  page: 0,
  pages: 0,
  data: [],
  total: 0,
  per_page: 20
};

const reducer = (state = initialState, action: AnyAction): KittyLinkedContributionsReducerState => {
  switch (action.type) {
    case actions.KITTY_LINKED_CONTRIBUTIONS_HTTP_LOADING:
      return { ...state, response: null, loading: true, error: null };
    case actions.KITTY_LINKED_CONTRIBUTIONS_HTTP_ERROR:
      if (action.payload.clear) {
        return { ...state, response: null, loading: false, error: null };
      }
      return { ...state, response: null, loading: false, error: { ...action.payload } };
    case actions.KITTY_LINKED_CONTRIBUTIONS_HTTP_COMPLETE:
      return { ...state, initial: true, response: action.payload.response, loading: false, error: null };
    case actions.SET_KITTY_LINKED_CONTRIBUTIONS_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
