import { AnyAction } from "redux";
import actions from "../../util/actions";
import { TokenReducerState } from "../../interfaces";
import { getItem, setItem } from "../../util/storage";

const initialState: TokenReducerState = {
  refresh: getItem("refresh_token")
};

const reducer = (state = initialState, action: AnyAction): TokenReducerState => {
  switch (action.type) {
    case actions.LOGGING_IN_USER:
      setItem("refresh_token", action.payload.refresh_token);
      return { ...state, refresh: action.payload.refresh_token };
    case actions.LOG_OUT_USER:
      setItem("refresh_token", "");
      return { ...state, refresh: null };
    case actions.SET_REFRESH_TOKEN:
      setItem("refresh_token", action.payload.refresh_token);
      return { ...state, refresh: action.payload.refresh_token };
    default:
      return state;
  }
};

export default reducer;
