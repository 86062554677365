import React from "react";
import { createStyles, makeStyles, Theme, alpha } from "@material-ui/core/styles";
import MaterialSwitch from "@material-ui/core/Switch";

interface SwitchProps {
  checked: boolean;
}

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1)
      },
      switchBase: {
        padding: 1,
        "&$checked": {
          transform: "translateX(16px)",
          color: theme.palette.common.white,
          "& + $track": {
            backgroundColor: theme.palette.primary.main,
            opacity: 1,
            border: "none"
          }
        },
        "&$focusVisible $thumb": {
          color: "#52d869",
          border: "6px solid #fff"
        }
      },
      thumb: {
        width: 24,
        height: 24
      },
      track: {
        borderRadius: 13,
        backgroundColor: alpha("#3e3e3f", 0.75),
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"])
      },
      checked: {},
      focusVisible: {}
    })
  );

const Switch: React.FC<SwitchProps> = (props) => {
  const styles = useStyles()();

  return (
    <MaterialSwitch
      focusVisibleClassName={styles.focusVisible}
      disableRipple
      classes={{ root: styles.root, switchBase: styles.switchBase, thumb: styles.thumb, track: styles.track, checked: styles.checked }}
      checked={props.checked}
    />
  );
};

export default React.memo(Switch);
