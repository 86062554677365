import { AnyAction } from "redux";
import actions from "../../util/actions";
import { GalleriesReducerState } from "../../interfaces";

const initialState: GalleriesReducerState = {
  initial: false,
  loading: true,
  error: null,
  response: null,
  search: "",
  page: 0,
  pages: 0,
  data: [],
  total: 0,
  per_page: 20
};

const reducer = (state = initialState, action: AnyAction): GalleriesReducerState => {
  switch (action.type) {
    case actions.GALLERIES_HTTP_LOADING:
      return { ...state, response: null, loading: true, error: null };
    case actions.GALLERIES_HTTP_ERROR:
      if (action.payload.clear) {
        return { ...state, response: null, loading: false, error: null };
      }
      return { ...state, response: null, loading: false, error: { ...action.payload } };
    case actions.GALLERIES_HTTP_COMPLETE:
      return { ...state, initial: true, response: action.payload.response, loading: false, error: null };
    case actions.SET_GALLERIES_DETAILS:
      return { ...state, ...action.payload };
    case actions.GALLERY_PHOTOS_ADDED:
      console.log("Triggered:", actions.GALLERY_PHOTOS_ADDED);
      return {
        ...state,
        data: state.data.map((gallery) => {
          console.log(gallery, action.payload, gallery.gallery_id, action.payload.gallery_id);
          if (gallery.gallery_id === action.payload.gallery_id) {
            gallery.photos = gallery.photos.concat(action.payload.photos);
            console.log(gallery.photos.length);
          }
          return gallery;
        })
      };
    case actions.GALLERY_PHOTO_DELETED:
      return {
        ...state,
        data: state.data.map((gallery) => {
          if (gallery.gallery_id === action.payload.gallery_id)
            gallery.photos = gallery.photos.filter((photo_url) => photo_url !== action.payload.photo_url);
          return gallery;
        })
      };
    default:
      return state;
  }
};

export default reducer;
