import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearMinistryLandingPageError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_LANDING_PAGE_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const getMinistryLandingPage = (ministry_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_LANDING_PAGE_HTTP_LOADING
    });

    request({
      method: "get",
      pathname: "getMinistryLandingPage",
      params: { ministry_id }
    })
      .then((response) => {
        dispatch({
          type: actions.MINISTRY_LANDING_PAGE_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });

        dispatch({
          type: actions.SET_MINISTRY_LANDING_PAGE_DETAILS,
          payload: {
            content: response.data.content
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.MINISTRY_LANDING_PAGE_HTTP_ERROR, error, dispatch);
      });
  };
};

export const updateMinistryLandingPage = (ministry_id: string, content: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_LANDING_PAGE_HTTP_LOADING
    });

    request({
      method: "post",
      pathname: "updateMinistryLandingPage",
      data: { ministry_id, content }
    })
      .then((response) => {
        dispatch({
          type: actions.MINISTRY_LANDING_PAGE_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });

        dispatch({
          type: actions.SET_MINISTRY_LANDING_PAGE_DETAILS,
          payload: {
            content: response.data.content
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.MINISTRY_LANDING_PAGE_HTTP_ERROR, error, dispatch);
      });
  };
};
