import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, ProfileReducerState, UserInterface, UserReducerState, UsersReducerState } from "../../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Container,
  Paper,
  Avatar,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { DialogModal, ErrorView } from "../../../components";
import { clearUserError, enableDisableUser, getUsers, resetUserPassword } from "../../../store/actions";
import userImg from "../../../assets/user-img.png";
import { checkUserAccess } from "../../../util/permission-checker";
import PopupMenuDialog from "../../../components/PopupMenuDialog";
import UpsertUserDetailsModal from "./Modals/UpsertUserDetailsDialog";
import ChangeUserPermissionsModal from "./Modals/ChangeUserPermissionsDialog";

interface UsersProps {
  users: UsersReducerState;
  user: UserReducerState;
  profile: ProfileReducerState;
  appTheme: AppTheme;
  getUsers: (search: string, page: number, per_page: number) => void;
  clearUserError: () => void;
  resetUserPassword: (_id: string) => void;
  enableDisableUser: (_id: string, status: string) => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 30,
        marginTop: 20,
        marginBottom: 20
      },
      avatar: {
        height: 40,
        width: 40,
        display: "block",
        margin: "auto",
        border: "1px solid black"
      },
      icon: {
        color: theme.palette.text.primary,
        padding: 10,
        borderRadius: 30,
        backgroundColor: appTheme.iconBackgroundColor,
        "&:hover": {
          backgroundColor: appTheme.iconBackgroundHoverColor
        }
      },
      searchButton: {
        marginRight: "-21px",
        height: "55px",
        borderRadius: "30px",
        width: "100px"
      },
      textInput: {
        marginTop: "5px",
        width: "300px"
      },
      textInputControl: {
        borderRadius: "30px",
        backgroundColor: appTheme.section.backgroundColor
      },
      container: {
        minHeight: "60vh"
      },
      textInputIcon: {
        color: "#000"
      }
    })
  );

const Users: React.FC<UsersProps> = (props) => {
  const { appTheme, users, user, profile, getUsers, clearUserError, resetUserPassword, enableDisableUser } = props;

  const [search, setSearch] = useState<string>(users.search);
  const [page, setPage] = useState<number>(users.page);
  const [perPage, setPerPage] = useState<number>(users.per_page ?? 20);
  const [selectedRecord, setSelectedRecord] = useState<UserInterface | null>(null);
  const [popUpDialogOpen, setPopUpDialogOpen] = useState<boolean>(false);

  const [upsertUserDetailsModelOpen, setUpsertUserDetailsModelOpen] = useState<boolean>(false);
  const [changeUserPermissionsModelOpen, setChangeUserPermissionsModelOpen] = useState<boolean>(false);

  const popUpMenuItems = useMemo(() => {
    return [
      { text: "Update User Details", permission: "edit-user" },
      { text: "Edit User Permissions", permission: "edit-user-permissions" },
      { text: "Reset User Password", permission: "reset-user-password" },
      { text: "Disable/Enable User", permission: "edit-user-status" }
    ];
  }, []);

  const [menuItems, setMenuItems] = useState<Array<string>>(
    popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text)
  );

  useEffect(() => {
    setMenuItems(popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text));
  }, [popUpMenuItems, profile.permissions]);

  useEffect(() => {
    getUsers(search, page, perPage);
  }, [getUsers, search, page, perPage]);

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearUserError();
    };
  }, [clearUserError]);

  useEffect(() => {
    if (!user.error && user.response) {
      clearUserError();

      setSelectedRecord(null);

      setUpsertUserDetailsModelOpen(false);

      setChangeUserPermissionsModelOpen(false);

      setDialogOptions({ open: true, body: user.response.data.status });

      getUsers("", 0, perPage);
    }
  }, [user.error, user.response, perPage, getUsers, clearUserError]);

  useEffect(() => {
    if (upsertUserDetailsModelOpen || changeUserPermissionsModelOpen) {
      return;
    }

    const requestData = user.error ? user : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [user, upsertUserDetailsModelOpen, changeUserPermissionsModelOpen]);

  const onMenuItemSelected = (menuItem: string) => {
    setPopUpDialogOpen(false);

    if (!selectedRecord) {
      return;
    }

    switch (menuItem) {
      case "Update User Details":
        setUpsertUserDetailsModelOpen(true);
        break;
      case "Edit User Permissions":
        if (selectedRecord && selectedRecord.permissions && selectedRecord.permissions.filter((permission) => permission === "admin").length === 1) {
          setDialogOptions({ open: true, body: "You cannot edit permissions for a super admin" });
        } else if (selectedRecord && selectedRecord.user_type && selectedRecord.user_type !== "Member") {
          setDialogOptions({ open: true, body: "You cannot edit permissions for a user who is not a member" });
        } else {
          setChangeUserPermissionsModelOpen(true);
        }

        break;
      case "Reset User Password":
        if (window.confirm("Would you like to reset the password of this user ?")) {
          resetUserPassword(selectedRecord.user_id);
        }
        break;
      case "Disable/Enable User":
        if (selectedRecord.status) {
          const message = selectedRecord.status === "active" ? "Would you like to deactivate this user ?" : "Would you like to activate this user ?";
          if (window.confirm(message)) {
            enableDisableUser(selectedRecord.user_id, selectedRecord.status);
          }
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <UpsertUserDetailsModal open={upsertUserDetailsModelOpen} onDismissed={() => setUpsertUserDetailsModelOpen(false)} userData={selectedRecord} />
      <ChangeUserPermissionsModal
        open={changeUserPermissionsModelOpen}
        onDismissed={() => setChangeUserPermissionsModelOpen(false)}
        userData={selectedRecord}
      />
      <PopupMenuDialog
        open={popUpDialogOpen}
        onDismissed={() => setPopUpDialogOpen(false)}
        onMenuItemSelected={onMenuItemSelected}
        menuItems={menuItems}
      />
      <Container maxWidth="lg">
        {users.error ? (
          <ErrorView error={users.error} onRetry={() => getUsers(search, page, perPage)} />
        ) : (
          <React.Fragment>
            <Paper className={styles.paper}>
              <Grid container justifyContent="space-between" spacing={0}>
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      {(users.loading || user.loading) && <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />}
                    </Grid>
                    <Grid item>
                      <Typography variant="h3" color="primary">
                        Users
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(event) => {
                      setSearch(event.currentTarget.value);
                    }}
                    error={false}
                    value={search}
                    variant="outlined"
                    placeholder="Search ..."
                    className={styles.textInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search className={styles.textInputIcon} />
                        </InputAdornment>
                      ),
                      classes: { root: styles.textInputControl }
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <TableContainer className={styles.container}>
                <Table stickyHeader aria-label="users">
                  <TableHead>
                    <TableRow>
                      {[
                        { label: "Profile Image", minWidth: 50 },
                        { label: "ID", minWidth: 100 },
                        { label: "Name", minWidth: 150 },
                        { label: "Type", minWidth: 100 },
                        { label: "Username", minWidth: 80 },
                        { label: "Email", minWidth: 100 },
                        { label: "Mobile No", minWidth: 100 },
                        { label: "Gender", minWidth: 50 },
                        { label: "Residence", minWidth: 150 },
                        { label: "DOB", minWidth: 120 },
                        { label: "Status", minWidth: 100 },
                        { label: "Permissions", minWidth: 100 },
                        { label: "Options", minWidth: 100 }
                      ].map((column) => (
                        <TableCell key={column.label} align="center" style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.data.map((user: UserInterface) => {
                      const _id = user.user_id;
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={_id}>
                          {[
                            "img",
                            "user_id",
                            "name",
                            "user_type",
                            "username",
                            "email",
                            "mobile_no",
                            "gender",
                            "residence",
                            "dob",
                            "status",
                            "permissions",
                            "options"
                          ].map((column) => {
                            const key = `${_id}-${column}`;

                            const data = (user as unknown as { [key: string]: any })[column];
                            if (column === "options") {
                              if (menuItems.length === 0) return <TableCell key={key} align="center"></TableCell>;
                              return (
                                <TableCell key={key} align="center">
                                  <Button
                                    variant="outlined"
                                    disabled={menuItems.length === 0}
                                    onClick={() => {
                                      setSelectedRecord(user);
                                      setPopUpDialogOpen(true);
                                    }}>
                                    Options
                                  </Button>
                                </TableCell>
                              );
                            } else if (column === "permissions") {
                              return (
                                <TableCell key={key} align="center">
                                  {data ? `${data.length}` : "0"}
                                </TableCell>
                              );
                            } else if (column === "img") {
                              return (
                                <TableCell key={key} align="center">
                                  <Avatar className={styles.avatar} alt={user.name} src={data ? data : userImg} />
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={key} align="center">
                                  {data}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container justifyContent="space-between" style={{ margin: "0px 10px 0px 10px" }}>
                <Grid item>
                  <Button
                    disabled={!checkUserAccess("add-user", profile.permissions)}
                    variant="outlined"
                    size="large"
                    onClick={() => {
                      setSelectedRecord(null);
                      setUpsertUserDetailsModelOpen(true);
                    }}>
                    Add New User
                  </Button>
                </Grid>
                <Grid item>
                  <TablePagination
                    component="div"
                    count={users.total}
                    page={page}
                    onPageChange={(_, page) => setPage(page)}
                    rowsPerPage={perPage}
                    rowsPerPageOptions={[20, 50, 100, 1000]}
                    onRowsPerPageChange={(event) => {
                      setPerPage(parseInt(event.target.value));
                      setPage(0);
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getUsers: (search: string, page: number, per_page: number) => dispatch(getUsers(search, page, per_page)),
    clearUserError: () => dispatch(clearUserError()),
    resetUserPassword: (_id: string) => dispatch(resetUserPassword(_id)),
    enableDisableUser: (_id: string, status: string) => dispatch(enableDisableUser(_id, status))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    users: state.users,
    user: state.user,
    profile: state.profile,
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
