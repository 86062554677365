import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  AppState,
  AppTheme,
  ProfileReducerState,
  MinistryKittiesReducerState,
  MinistryKittyInterface,
  MinistryKittyReducerState
} from "../../../../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Container,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment,
  Paper
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { DialogModal, ErrorView } from "../../../../../components";
import { clearMinistryKittyError, getMinistryKitties, deleteMinistryKitty } from "../../../../../store/actions";
import { checkUserAccess } from "../../../../../util/permission-checker";
import PopupMenuDialog from "../../../../../components/PopupMenuDialog";
import UpsertMinistryKittyDetailsDialog from "./Modals/UpsertMinistryKittyDetailsDialog";
import MinistryKittySubtractionsDialog from "./Modals/MinistryKittySubtractionsDialog";
import MinistryKittyLinkedContributionsDialog from "./Modals/MinistryKittyLinkedContributionsDialog";
import { Link, useParams } from "react-router-dom";

interface KittiesProps {
  ministryKitties: MinistryKittiesReducerState;
  ministryKitty: MinistryKittyReducerState;
  profile: ProfileReducerState;
  appTheme: AppTheme;
  getMinistryKitties: (ministry_id: string, search: string, page: number, per_page: number) => void;
  clearMinistryKittyError: () => void;
  deleteMinistryKitty: (ministry_kitty_id: string) => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 15,
        marginTop: 10,
        marginBottom: 10
      },
      icon: {
        color: theme.palette.text.primary,
        padding: 10,
        borderRadius: 30,
        backgroundColor: appTheme.iconBackgroundColor,
        "&:hover": {
          backgroundColor: appTheme.iconBackgroundHoverColor
        }
      },
      searchButton: {
        marginRight: "-21px",
        height: "55px",
        borderRadius: "30px",
        width: "100px"
      },
      textInput: {
        marginTop: "5px",
        width: "300px"
      },
      textInputControl: {
        borderRadius: "30px",
        backgroundColor: appTheme.section.backgroundColor
      },
      container: {
        minHeight: "60vh"
      },
      textInputIcon: {
        color: "#000"
      }
    })
  );

const Kitties: React.FC<KittiesProps> = (props) => {
  const { appTheme, ministryKitties, ministryKitty, profile, getMinistryKitties, deleteMinistryKitty, clearMinistryKittyError } = props;

  const { ministry_id, ministry_name } = useParams<{ ministry_id: string; ministry_name: string }>();

  const [search, setSearch] = useState<string>(ministryKitties.search);
  const [page, setPage] = useState<number>(ministryKitties.page);
  const [perPage, setPerPage] = useState<number>(ministryKitties.per_page ?? 20);
  const [selectedRecord, setSelectedRecord] = useState<MinistryKittyInterface | null>(null);
  const [popUpDialogOpen, setPopUpDialogOpen] = useState<boolean>(false);

  const popUpMenuItems = useMemo(() => {
    return [
      { text: "View Kitty Linked Contributions", permission: "view-ministry-" + ministry_id + "-kitty-linked-contributions" },
      { text: "View Kitty Subtractions", permission: "view-ministry-" + ministry_id + "-kitty-subtractions" },
      { text: "Edit Kitty Details", permission: "edit-ministry-" + ministry_id + "-kitties" },
      { text: "Delete Kitty", permission: "delete-ministry-" + ministry_id + "-kitties" }
    ];
  }, [ministry_id]);

  const [menuItems, setMenuItems] = useState<Array<string>>(
    popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text)
  );

  useEffect(() => {
    setMenuItems(popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text));
  }, [popUpMenuItems, profile.permissions]);

  const [upsertMinistryKittyDetailsModalOpen, setUpsertMinistryKittyDetailsDialogOpen] = useState<boolean>(false);
  const [ministryKittySubtractionModalOpen, setMinistryKittySubtractionModalOpen] = useState<boolean>(false);
  const [ministryKittyLinkedContributionModalOpen, setMinistryKittyLinkedContributionModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getMinistryKitties(ministry_id, search, page, perPage);
  }, [ministry_id, search, page, perPage, getMinistryKitties]);

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearMinistryKittyError();
    };
  }, [clearMinistryKittyError]);

  useEffect(() => {
    if (!ministryKitty.error && ministryKitty.response && ministryKitty.response.data.status !== "Ok") {
      clearMinistryKittyError();

      setSelectedRecord(null);

      setUpsertMinistryKittyDetailsDialogOpen(false);

      setMinistryKittySubtractionModalOpen(false);

      setDialogOptions({ open: true, body: ministryKitty.response.data.status });

      getMinistryKitties(ministry_id, "", 0, perPage);
    }
  }, [ministryKitty.error, ministryKitty.response, ministry_id, perPage, getMinistryKitties, clearMinistryKittyError]);

  useEffect(() => {
    if (!upsertMinistryKittyDetailsModalOpen && !ministryKittySubtractionModalOpen && !ministryKittyLinkedContributionModalOpen)
      getMinistryKitties(ministry_id, search, page, perPage);
  }, [
    upsertMinistryKittyDetailsModalOpen,
    ministryKittySubtractionModalOpen,
    ministryKittyLinkedContributionModalOpen,
    ministry_id,
    search,
    page,
    perPage,
    getMinistryKitties
  ]);

  useEffect(() => {
    if (upsertMinistryKittyDetailsModalOpen || ministryKittySubtractionModalOpen || ministryKittyLinkedContributionModalOpen) {
      return;
    }

    const requestData = ministryKitty.error ? ministryKitty : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [upsertMinistryKittyDetailsModalOpen, ministryKittySubtractionModalOpen, ministryKittyLinkedContributionModalOpen, ministryKitty]);

  const onMenuItemSelected = (menuItem: string) => {
    setPopUpDialogOpen(false);

    if (!selectedRecord) {
      return;
    }

    switch (menuItem) {
      case "View Kitty Linked Contributions":
        setMinistryKittyLinkedContributionModalOpen(true);
        break;
      case "View Kitty Subtractions":
        setMinistryKittySubtractionModalOpen(true);
        break;
      case "Edit Kitty Details":
        setUpsertMinistryKittyDetailsDialogOpen(true);
        break;
      case "Delete Kitty":
        if (window.confirm("Would you like to delete this kitties ?")) {
          deleteMinistryKitty(selectedRecord.ministry_kitty_id);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <UpsertMinistryKittyDetailsDialog
        open={upsertMinistryKittyDetailsModalOpen}
        onDismissed={() => setUpsertMinistryKittyDetailsDialogOpen(false)}
        ministryKittyData={selectedRecord}
      />
      <MinistryKittySubtractionsDialog
        open={ministryKittySubtractionModalOpen}
        onDismissed={() => setMinistryKittySubtractionModalOpen(false)}
        ministryKittyData={selectedRecord}
      />
      <MinistryKittyLinkedContributionsDialog
        open={ministryKittyLinkedContributionModalOpen}
        onDismissed={() => setMinistryKittyLinkedContributionModalOpen(false)}
        ministryKittyData={selectedRecord}
      />
      <PopupMenuDialog
        open={popUpDialogOpen}
        onDismissed={() => setPopUpDialogOpen(false)}
        onMenuItemSelected={onMenuItemSelected}
        menuItems={menuItems}
      />
      <Container maxWidth="lg">
        <Paper className={styles.paper}>
          {!upsertMinistryKittyDetailsModalOpen && ministryKitties.error ? (
            <ErrorView error={ministryKitties.error} onRetry={() => getMinistryKitties(ministry_id, search, page, perPage)} />
          ) : (
            <React.Fragment>
              <Grid container justifyContent="space-between" spacing={0}>
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      {!upsertMinistryKittyDetailsModalOpen && ministryKitties.loading && (
                        <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography variant="h3" color="primary">
                        Kitties
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <TextField
                        onChange={(event) => {
                          setSearch(event.currentTarget.value);
                        }}
                        error={false}
                        value={search}
                        variant="outlined"
                        placeholder="Search ..."
                        className={styles.textInput}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search className={styles.textInputIcon} />
                            </InputAdornment>
                          ),
                          classes: { root: styles.textInputControl }
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        style={{ marginTop: 10 }}
                        component={Link}
                        to={`/dashboard/ministries/${ministry_id}/${ministry_name}`}
                        variant="outlined"
                        size="large">
                        Go Back
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <TableContainer className={styles.container}>
                <Table stickyHeader aria-label="user-attendances">
                  <TableHead>
                    <TableRow>
                      {[
                        { label: "ID", minWidth: 100 },
                        { label: "Kitty Name", minWidth: 250 },
                        { label: "Kitty Balance", minWidth: 150 },
                        { label: "Total Contributions", minWidth: 150 },
                        { label: "Total Subtractions", minWidth: 150 },
                        { label: "Options", minWidth: 100 }
                      ].map((column) => (
                        <TableCell key={column.label} align="center" style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ministryKitties.data.map((ministryKitty: MinistryKittyInterface) => {
                      const _id = ministryKitty.ministry_kitty_id;
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={_id}>
                          {["ministry_kitty_id", "kitty_name", "kitty_balance", "kitty_contributions", "kitty_subtractions", "options"].map(
                            (column) => {
                              const key = `${_id}-${column}`;

                              const data = (ministryKitty as unknown as { [key: string]: any })[column];
                              if (column === "options") {
                                return (
                                  <TableCell key={key} align="center">
                                    <Button
                                      variant="outlined"
                                      disabled={menuItems.length === 0}
                                      onClick={() => {
                                        setSelectedRecord(ministryKitty);
                                        setPopUpDialogOpen(true);
                                      }}>
                                      Options
                                    </Button>
                                  </TableCell>
                                );
                              } else if (column === "kitty_balance" || column === "kitty_subtractions" || column === "kitty_contributions") {
                                return (
                                  <TableCell key={key} align="center">
                                    {new Intl.NumberFormat("en-KE", { style: "currency", currency: "KES" }).format(parseFloat(data))}
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell key={key} align="center">
                                    {data}
                                  </TableCell>
                                );
                              }
                            }
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container justifyContent="space-between" style={{ margin: "0px 10px 0px 10px" }}>
                <Grid item>
                  <Button
                    disabled={!checkUserAccess("add-ministry-" + ministry_id + "-kitties", profile.permissions)}
                    variant="outlined"
                    size="large"
                    onClick={() => {
                      setSelectedRecord(null);
                      setUpsertMinistryKittyDetailsDialogOpen(true);
                    }}>
                    Add New Kitty
                  </Button>
                </Grid>
                <Grid item>
                  <TablePagination
                    component="div"
                    count={ministryKitties.total}
                    page={page}
                    onPageChange={(_, page) => setPage(page)}
                    rowsPerPage={perPage}
                    rowsPerPageOptions={[20, 50, 100, 1000]}
                    onRowsPerPageChange={(event) => {
                      setPerPage(parseInt(event.target.value));
                      setPage(0);
                    }}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </Paper>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getMinistryKitties: (ministry_id: string, search: string, page: number, per_page: number) =>
      dispatch(getMinistryKitties(ministry_id, search, page, per_page)),
    clearMinistryKittyError: () => dispatch(clearMinistryKittyError()),
    deleteMinistryKitty: (ministry_kitty_id: string) => dispatch(deleteMinistryKitty(ministry_kitty_id))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    ministryKitties: state.ministryKitties,
    ministryKitty: state.ministryKitty,
    profile: state.profile,
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Kitties);
