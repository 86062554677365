import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, AttendanceReducerState, AttendanceInterface, UserInterface, UsersReducerState } from "../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { CheckCircle, Person } from "@material-ui/icons";
import validator from "validator";
import { addUserAttendance, clearAttendanceError, clearUserError, getUsers } from "../../../../store/actions";

interface AddUserAttendanceDetailsFormProps {
  appTheme: AppTheme;
  attendance: AttendanceReducerState;
  attendanceData: AttendanceInterface | null;
  users: UsersReducerState;
  addUserAttendance: (_id: string, user_id: string, temp?: number) => void;
  clearAttendanceError: () => void;
  clearUserError: () => void;
  getUsers: (search: string) => void;
}

interface AddUserAttendanceDetailsModalProps {
  open: boolean;
  attendanceData: AttendanceInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      },
      autocompleteItems: {
        position: "absolute",
        border: "1px solid #d4d4d4",
        borderBottom: "none",
        borderTop: "none",
        top: "100%",
        left: "0",
        right: "0",
        zIndex: 16000
      },
      autocompleteItem: {
        padding: "10px",
        cursor: "pointer",
        backgroundColor: "#fff",
        borderBottom: "1px solid #d4d4d4"
      },
      autocomplete: {
        position: "relative",
        display: "inline-block",
        width: "100%"
      }
    })
  );

const AddUserAttendanceDetailsForm: React.FC<AddUserAttendanceDetailsFormProps> = (props) => {
  const { appTheme, attendance, attendanceData, users, addUserAttendance, clearAttendanceError, clearUserError, getUsers } = props;

  const [user, setUser] = useState<UserInterface | null>();
  const [userSearch, setUserSearch] = useState<string>("");
  const [temp, setTemp] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loading = attendance.loading;

  const styles = useStyles(appTheme)();

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "temp":
        setTemp(value);
        break;
      default:
        break;
    }
  };

  const validateUnrequired = (field: string, value: string) => {
    if (field === "temp") {
      if (attendanceData?.requires_temp_check) {
        if (validator.isEmpty(value)) {
          setErrorMessage("The tempreture entered is not valid");
          return false;
        } 
        return true;
      } 
      return true;
    }
    return false
  };

  const submitForm = () => {
    if (!user) {
      setErrorMessage("Please select a user before procceding");
    } else if (!validateUnrequired("temp", temp)) {
    } else {
      if (attendanceData?.requires_temp_check) {
        if (Number.isNaN(parseFloat(temp))) {
          setErrorMessage("The tempreture entered is not valid");
          return;
        }
      }

      setErrorMessage("");

      const _id = attendanceData ? attendanceData.attendance_id : "",
        user_id = user ? user.user_id : "";

      if (attendanceData?.requires_temp_check) {
        addUserAttendance(_id, user_id, parseFloat(temp));
      } else {
        addUserAttendance(_id, user_id);
      }
    }
  };

  useEffect(() => {
    const requestData = attendance.error ? attendance : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [attendance]);

  useEffect(() => {
    getUsers("");
    return () => {
      clearAttendanceError();
      clearUserError();
    };
  }, [getUsers, clearAttendanceError, clearUserError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <div className={styles.autocomplete}>
            <InputBase
              required
              type="text"
              value={userSearch}
              onChange={(event) => {
                const value = event.target.value;
                if (user?.name !== value) {
                  if (value.length > 0) {
                    getUsers(value);
                  }

                  setUser(null);

                  setUserSearch(value);
                }
              }}
              placeholder="User (Required)"
              className={styles.textField}
              startAdornment={
                <InputAdornment position="start">
                  <Person className={styles.textFieldIcon} />
                </InputAdornment>
              }
            />

            {!user && userSearch.length > 0 && (
              <div className={styles.autocompleteItems}>
                {users.data.map((user: UserInterface) => (
                  <div
                    key={user?.user_id}
                    className={styles.autocompleteItem}
                    onClick={() => {
                      setUser(user);
                      setUserSearch(user.name);
                    }}>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="body1">{"Name: " + user.name}</Typography>
                      </Grid>
                      {user.mobile_no && (
                        <Grid item>
                          <Typography variant="body1">{"Mobile No: " + user.mobile_no}</Typography>
                        </Grid>
                      )}
                      {user.email && (
                        <Grid item>
                          <Typography variant="body1">{"Email: " + user.email}</Typography>
                        </Grid>
                      )}
                      <Grid item>
                        <Typography variant="body1">{"Type: " + user.user_type}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{"Residence: " + user.residence}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </div>
            )}
          </div>
          {!user && userSearch.length > 0 && <div style={{ height: users.data.length * 130 }}></div>}
        </Grid>
        {attendanceData?.requires_temp_check && (
          <Grid item xs={12}>
            <InputBase
              type="number"
              value={temp}
              inputProps={{ max: 40, min: 20, step: "any" }}
              onChange={(event) => {
                textFieldValueChanged("temp", event.target.value);
              }}
              placeholder="Tempreture Amount (Required)"
              className={styles.textField}
              startAdornment={
                <InputAdornment position="start">
                  <CheckCircle className={styles.textFieldIcon} />
                </InputAdornment>
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} Add New User Attendance
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    addUserAttendance: (_id: string, user_id: string, temp?: number) => dispatch(addUserAttendance(_id, user_id, temp)),
    clearAttendanceError: () => dispatch(clearAttendanceError()),
    clearUserError: () => dispatch(clearUserError()),
    getUsers: (search: string) => dispatch(getUsers(search, 0, 5))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    attendance: state.attendance,
    users: state.users,
    appTheme: state.general.theme
  };
};

const AddUserAttendanceDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(AddUserAttendanceDetailsForm);

const AddUserAttendanceDetailsModal: React.FC<AddUserAttendanceDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title="Add New User Attendance"
      component={<AddUserAttendanceDetailsFormComponent attendanceData={props.attendanceData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default AddUserAttendanceDetailsModal;
