import { useCallback, useState } from "react";
import request from "../util/request";

const useMinistry = () => {
  const [ministryLoading, setMinistryLoading] = useState<boolean>(false);
  const [ministryError, setMinistryError] = useState<{
    status?: number;
    data?: any;
    message: string;
  } | null>(null);
  const [ministry, setMinistry] = useState<string>();


  const getMinistry = useCallback(async (_id: string) => {
    try {
      setMinistryLoading(true);
      setMinistryError(null);
      const response = await request({ pathname: "getRawMinistryLandingPage", method: "GET", params: { _id } });
      setMinistry(response.data.content);
    } catch (e) {
      const err = e as any;
      if (err.response && err.response.status) {
        setMinistryError({
          message: err.message,
          status: err.response.status,
          data: err.response.data
        });
      } else {
        setMinistryError({
          message: err.message
        });
      }
    } finally {
      setMinistryLoading(false);
    }
  },[])

  return {
    ministryLoading,
    ministryError,
    ministry,
    getMinistry
  };
};

export { useMinistry };
