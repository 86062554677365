import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  AppState,
  AppTheme,
  ProfileReducerState,
  UserContributionInterface,
  ContributionReducerState,
  ContributionInterface
} from "../../../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Container,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { DialogModal, ErrorView } from "../../../../components";
import { clearContributionError, getUserContribution, deleteUserContribution } from "../../../../store/actions";
import { checkUserAccess } from "../../../../util/permission-checker";
import PopupMenuDialog from "../../../../components/PopupMenuDialog";
import AddUserContributionDetailsModal from "./AddUserContributionDetailsModal";
import dateformat from "dateformat";

interface UserContributionsProps {
  contribution: ContributionReducerState;
  contributionData: ContributionInterface | null;
  profile: ProfileReducerState;
  appTheme: AppTheme;
  getUserContribution: (_id: string, search: string, page: number, per_page: number) => void;
  clearContributionError: () => void;
  deleteUserContribution: (_id: string, subtractor_id: string) => void;
}

interface UserContributionModalProps {
  open: boolean;
  contributionData: ContributionInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      icon: {
        color: theme.palette.text.primary,
        padding: 10,
        borderRadius: 30,
        backgroundColor: appTheme.iconBackgroundColor,
        "&:hover": {
          backgroundColor: appTheme.iconBackgroundHoverColor
        }
      },
      searchButton: {
        marginRight: "-21px",
        height: "55px",
        borderRadius: "30px",
        width: "100px"
      },
      textInput: {
        marginTop: "5px",
        width: "300px"
      },
      textInputControl: {
        borderRadius: "30px",
        backgroundColor: appTheme.section.backgroundColor
      },
      container: {
        minHeight: "60vh"
      },
      textInputIcon: {
        color: "#000"
      }
    })
  );

const UserContributions: React.FC<UserContributionsProps> = (props) => {
  const { appTheme, contributionData, contribution, profile, getUserContribution, deleteUserContribution, clearContributionError } = props;

  const [_id] = useState<string>(contributionData ? contributionData.contribution_id : "");
  const [search, setSearch] = useState<string>(contribution.search);
  const [page, setPage] = useState<number>(contribution.page);
  const [perPage, setPerPage] = useState<number>(contribution.per_page ?? 20);
  const [selectedRecord, setSelectedRecord] = useState<UserContributionInterface | null>(null);
  const [popUpDialogOpen, setPopUpDialogOpen] = useState<boolean>(false);

  const popUpMenuItems = useMemo(() => {
    return [{ text: "Delete User Contribution", permission: "delete-user-contribution" }];
  }, []);

  const [menuItems, setMenuItems] = useState<Array<string>>(
    popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text)
  );

  useEffect(() => {
    setMenuItems(popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text));
  }, [popUpMenuItems, profile.permissions]);

  const [addUserContributionDetailsModalOpen, setAddUserContributionDetailsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getUserContribution(_id, search, page, perPage);
  }, [_id, search, page, perPage, getUserContribution]);

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearContributionError();
    };
  }, [clearContributionError]);

  useEffect(() => {
    if (!contribution.error && contribution.response && contribution.response.data.status !== "Ok") {
      clearContributionError();

      setSelectedRecord(null);

      setAddUserContributionDetailsModalOpen(false);

      setDialogOptions({ open: true, body: contribution.response.data.status });

      getUserContribution(_id, "", 0, perPage);
    }
  }, [contribution.error, contribution.response, _id, perPage, getUserContribution, clearContributionError]);

  useEffect(() => {
    if (addUserContributionDetailsModalOpen) {
      return;
    }

    const requestData = contribution.error ? contribution : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [addUserContributionDetailsModalOpen, contribution]);

  const onMenuItemSelected = (menuItem: string) => {
    setPopUpDialogOpen(false);

    if (!selectedRecord) {
      return;
    }

    switch (menuItem) {
      case "Delete User Contribution":
        if (window.confirm("Would you like to delete this user contribution ?")) {
          deleteUserContribution(_id, selectedRecord.contribution_id);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <AddUserContributionDetailsModal
        open={addUserContributionDetailsModalOpen}
        onDismissed={() => {
          setSearch("");
          setPage(0);
          setAddUserContributionDetailsModalOpen(false);
        }}
        contributionData={contributionData}
      />
      <PopupMenuDialog
        open={popUpDialogOpen}
        onDismissed={() => setPopUpDialogOpen(false)}
        onMenuItemSelected={onMenuItemSelected}
        menuItems={menuItems}
      />
      <Container maxWidth="lg">
        {!addUserContributionDetailsModalOpen && contribution.error ? (
          <ErrorView error={contribution.error} onRetry={() => getUserContribution(_id, search, page, perPage)} />
        ) : (
          <React.Fragment>
            <Grid container justifyContent="space-between" spacing={0}>
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>{contribution.loading && <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />}</Grid>
                  <Grid item>
                    <Typography variant="h3" color="primary">
                      User Contributions
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  onChange={(contribution) => {
                    setSearch(contribution.currentTarget.value);
                  }}
                  error={false}
                  value={search}
                  variant="outlined"
                  placeholder="Search ..."
                  className={styles.textInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search className={styles.textInputIcon} />
                      </InputAdornment>
                    ),
                    classes: { root: styles.textInputControl }
                  }}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer className={styles.container}>
              <Table stickyHeader aria-label="user-contributions">
                <TableHead>
                  <TableRow>
                    {[
                      { label: "ID", minWidth: 100 },
                      { label: "Name", minWidth: 250 },
                      { label: "Date", minWidth: 100 },
                      { label: "Amount", minWidth: 80 },
                      { label: "Type", minWidth: 100 },
                      { label: "Transaction Details", minWidth: 250 },
                      { label: "Options", minWidth: 100 }
                    ].map((column) => (
                      <TableCell key={column.label} align="center" style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contribution.data.map((userContribution: UserContributionInterface) => {
                    const _id = userContribution.contribution_id;
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={_id}>
                        {["contributor_id", "name", "date", "amount", "contributor_type", "transaction_details", "options"].map((column) => {
                          const key = `${_id}-${column}`;

                          const data = (userContribution as unknown as { [key: string]: any })[column];
                          if (column === "options") {
                            return (
                              <TableCell key={key} align="center">
                                <Button
                                  variant="outlined"
                                  disabled={menuItems.length === 0}
                                  onClick={() => {
                                    setSelectedRecord(userContribution);
                                    setPopUpDialogOpen(true);
                                  }}>
                                  Options
                                </Button>
                              </TableCell>
                            );
                          } else if (column === "date") {
                            return (
                              <TableCell key={key} align="center">
                                {dateformat(data, "ddd mmm dd yyyy")}
                              </TableCell>
                            );
                          } else if (column === "amount") {
                            return (
                              <TableCell key={key} align="center">
                                {new Intl.NumberFormat("en-KE", { style: "currency", currency: "KES" }).format(parseFloat(data))}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={key} align="center">
                                {data}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container justifyContent="space-between" style={{ margin: "0px 10px 0px 10px" }}>
              <Grid item>
                <Button
                  disabled={!checkUserAccess("add-user-contribution", profile.permissions)}
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setSelectedRecord(null);
                    setAddUserContributionDetailsModalOpen(true);
                  }}>
                  Add New User Contribution
                </Button>
              </Grid>
              <Grid item>
                <TablePagination
                  component="div"
                  count={contribution.total}
                  page={page}
                  onPageChange={(_, page) => setPage(page)}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[20, 50, 100, 1000]}
                  onRowsPerPageChange={(contribution) => {
                    setPerPage(parseInt(contribution.target.value));
                    setPage(0);
                  }}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getUserContribution: (_id: string, search: string, page: number, per_page: number) => dispatch(getUserContribution(_id, search, page, per_page)),
    clearContributionError: () => dispatch(clearContributionError()),
    deleteUserContribution: (_id: string, subtractor_id: string) => dispatch(deleteUserContribution(_id, subtractor_id))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    contribution: state.contribution,
    profile: state.profile,
    appTheme: state.general.theme
  };
};

const UserContributionsComponent = connect(mapStateToProps, mapDispatchToProps)(UserContributions);

const UserContributionModal: React.FC<UserContributionModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="lg"
      title="User Contribution"
      component={<UserContributionsComponent contributionData={props.contributionData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UserContributionModal;
