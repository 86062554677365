import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearForgotPasswordError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.FORGOT_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const sendResetPasswordCode = (username: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.FORGOT_HTTP_LOADING
    });

    request({ method: "post", pathname: "sendResetPasswordCode", data: { username } })
      .then((response) => {
        dispatch({
          type: actions.FORGOT_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });

        dispatch({
          type: actions.SET_USER_HAS_FORGOT
        });

        dispatch({
          type: actions.UNSET_USER_HAS_FORGOT
        });
      })
      .catch((error) => {
        errorHandler(actions.FORGOT_HTTP_ERROR, error, dispatch);
      });
  };
};
