import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  alpha,
  Avatar,
  Breadcrumbs,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Typography
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useEvent } from "../../hooks/useEvent";
import { ErrorView } from "../../components";
import {Calendar,momentLocalizer} from 'react-big-calendar';
import "./react-big-calendar.css"
import moment from "moment"
import { ArrowBack, LocationOn } from "@material-ui/icons";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%"
      },
      title: {
        color: alpha(theme.palette.common.black, 0.1),
        fontSize: "3rem",
        fontWeight: 700,
        marginBottom: 30
      },
      breadcrumbLink: {
        textDecoration: "none",
        color: "#2f89fc",
        "&:hover": {
          color: alpha("#2f89fc", 0.5)
        }
      },
      list: {
        height:"100%",
        overflowY:"scroll"
      }
    })
  );
  type CalendarEvent = {
    id:string,data_index:number,title:string,allDay:boolean,start:Date,end:Date}

const Event: React.FC = () => {
  const styles = useStyles()();

  const {eventsError, events,upCommingEvents, getEvents } = useEvent();
  const [calendarEvents,setCalendarEvents] = useState<CalendarEvent[]>([])
  const [selectedCalendarEvent,setSelectedCalendarEvent] = useState<CalendarEvent | null>(null)
  

  useEffect(() => {
    setCalendarEvents(events.map((event,index) => ({
      id:event._id,
      data_index:index,
      title:event.name,
      allDay:false,
      start:new Date(event.event_date+" "+event.start_time),
      end:new Date(event.event_date+" "+event.end_time)
    })))

  },[events])

  const localizer = momentLocalizer(moment)

  useEffect(() => {
    window.scroll(0, 0);
    getEvents();
  }, [getEvents]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Event</title>
        <meta
          name="description"
          content="Get a glimpse of how its like to fellowship at PEFA Church Gimu"
        />
        <link rel="canonical" href={window.location.origin + "/event"} />
        <meta property="og:site_name" content="PEFA Church Gimu" />
        <meta property="og:title" content="PEFA Church Gimu | Event" />
        <meta
          property="og:description"
          content="Get a glimpse of how its like to fellowship at PEFA Church Gimu"
        />
        <meta property="og:url" content={window.location.origin} />
        <meta property="og:image" content={`${window.location.origin}/logo.png`} />
      </Helmet>
      <div className={styles.root}>
        <Container maxWidth="lg">
          <Typography variant="h1" className={styles.title}>
            Events
          </Typography>
          <Breadcrumbs>
            <Link color="inherit" to="/" className={styles.breadcrumbLink}>
              Home
            </Link>
            <Typography color="textPrimary">Events</Typography>
          </Breadcrumbs>
          <br />
          {eventsError ? (
            <ErrorView error={eventsError} onRetry={() => getEvents()} />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8}>
                <div style={{backgroundColor:"#fff",marginTop:10,marginBottom:20,padding:15}}>
                  <Calendar localizer={localizer} style={{height:'65vh',width:"100%"}} events={calendarEvents}  startAccessor="start" endAccessor="end" onSelectEvent={event => 
                  { 
                    setSelectedCalendarEvent(event)
                  }}/>
                  <br/>
                  <br/>
                  <Typography align="center" variant="body1">NB: The following times are in East African Time (GMT +03:00)</Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div style={{backgroundColor:"#fff",height:'70vh',width:"100%",padding:15,marginTop:10,marginBottom:20}}>
                <br/>
                <Grid container justifyContent="space-between" alignContent="center">
                  <Grid item>
                    {selectedCalendarEvent && <IconButton onClick={() => setSelectedCalendarEvent(null)} color="primary" aria-label="back arrow"><ArrowBack /></IconButton>}
                  </Grid>
                  <Grid item>
                    <Typography align="center" variant="h5">{selectedCalendarEvent?selectedCalendarEvent.title:"Upcomming Events"}</Typography>
                  </Grid>
                  <Grid item>
                  </Grid>
                </Grid>
                <br/>
                <List className={styles.list}>
                  {
                    (selectedCalendarEvent?[events[selectedCalendarEvent.data_index]]:upCommingEvents).map((event,index) => (
                      <React.Fragment key={index}>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar variant="square" alt={event.name} src={event.cover_img} style={{height:80,width:80}} />
                          </ListItemAvatar>
                          <Grid container spacing={1} style={{marginLeft:5}}>
                            <Grid item xs={12}>
                              <Typography variant="h6" color="primary">{event.name}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={1} alignContent="center">
                                <Grid item>
                                  <LocationOn style={{fontSize:20}} />
                                </Grid>
                                <Grid item>
                                  <Typography variant="body2" color="primary" style={{opacity:0.95}}>{event.location}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="caption" style={{fontWeight:100}}>{event.description}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="caption" color="textSecondary">{`${event.event_date} from ${event.start_time} to ${event.end_time}`}</Typography>
                            </Grid>
                          </Grid>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </React.Fragment>

                    ))
                  }
                  
                  </List>
                </div>
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Event;
