import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearEventError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.EVENT_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const addEvent = (name: string, location: string, description: string, cover_img: string, start_time: number, end_time: number) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.EVENT_HTTP_LOADING
    });

    request({ method: "post", pathname: "addEvent", data: { name, location, description, cover_img, start_time, end_time } })
      .then((response) => {
        dispatch({
          type: actions.EVENT_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.EVENT_HTTP_ERROR, error, dispatch);
      });
  };
};

export const updateEvent = (
  _id: string,
  name: string,
  location: string,
  description: string,
  cover_img: string,
  start_time: number,
  end_time: number
) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.EVENT_HTTP_LOADING
    });

    request({ method: "post", pathname: "updateEvent", data: { _id, name, location, description, cover_img, start_time, end_time } })
      .then((response) => {
        dispatch({
          type: actions.EVENT_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.EVENT_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteEvent = (_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.EVENT_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteEvent", data: { _id } })
      .then((response) => {
        dispatch({
          type: actions.EVENT_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.EVENT_HTTP_ERROR, error, dispatch);
      });
  };
};
