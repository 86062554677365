import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearMinistryKittyError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_KITTY_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const addMinistryKitty = (ministry_id: string, kitty_name: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_KITTY_HTTP_LOADING
    });

    request({ method: "post", pathname: "addMinistryKitty", data: { ministry_id, kitty_name } })
      .then((response) => {
        dispatch({
          type: actions.MINISTRY_KITTY_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.MINISTRY_KITTY_HTTP_ERROR, error, dispatch);
      });
  };
};

export const updateMinistryKitty = (_id: string, ministry_id: string, kitty_name: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_KITTY_HTTP_LOADING
    });

    request({ method: "post", pathname: "updateMinistryKitty", data: { _id, ministry_id, kitty_name } })
      .then((response) => {
        dispatch({
          type: actions.MINISTRY_KITTY_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.MINISTRY_KITTY_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteMinistryKitty = (_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_KITTY_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteMinistryKitty", data: { _id } })
      .then((response) => {
        dispatch({
          type: actions.MINISTRY_KITTY_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.MINISTRY_KITTY_HTTP_ERROR, error, dispatch);
      });
  };
};
