import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, EventInterface, EventReducerState, FileReducerState } from "../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { CalendarToday, Create, AccessAlarm, Room } from "@material-ui/icons";
import validator from "validator";
import { addEvent, clearEventError, updateEvent, clearFileError, uploadFiles } from "../../../../store/actions";
import dateformat from "dateformat";
import { generateFileName } from "../../../../util/generate-file-name";
import { v4 as uuidv4 } from "uuid";

interface UpsertEventDetailsFormProps {
  appTheme: AppTheme;
  file: FileReducerState;
  event: EventReducerState;
  eventData: EventInterface | null;
  updateEvent: (_id: string, name: string, location: string, description: string, cover_img: string, start_time: number, end_time: number) => void;
  addEvent: (name: string, location: string, description: string, cover_img: string, start_time: number, end_time: number) => void;
  clearEventError: () => void;
  uploadFiles: (filePaths: Array<string>, files: FileList) => void;
  clearFileError: () => void;
}

interface UpsertEventDetailsModalProps {
  open: boolean;
  eventData: EventInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      }
    })
  );

const UpsertEventDetailsForm: React.FC<UpsertEventDetailsFormProps> = (props) => {
  const { appTheme, event, file, eventData, addEvent, updateEvent, clearEventError, clearFileError, uploadFiles } = props;

  const [_id] = useState<string>(eventData ? eventData.event_id : "");
  const [name, setName] = useState<string>(eventData ? eventData.name : "");
  const [location, setLocation] = useState<string>(eventData ? eventData.location : "");
  const [description, setDescription] = useState<string>(eventData ? eventData.description : "");
  const [coverImg, setCoverImg] = useState<string>(eventData ? eventData.cover_img : "");
  const [startDate, setStartDate] = useState<string>(eventData ? dateformat(new Date(eventData.start_time ?? 0), "yyyy-mm-dd") : "");
  const [startTime, setStartTime] = useState<string>(eventData ? dateformat(new Date(eventData.start_time ?? 0), "HH:MM") : "00:00");
  const [endDate, setEndDate] = useState<string>(eventData ? dateformat(new Date(eventData.end_time ?? 0), "yyyy-mm-dd") : "");
  const [endTime, setEndTime] = useState<string>(eventData ? dateformat(new Date(eventData.end_time ?? 0), "HH:MM") : "00:00");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [filesUploaded, setFilesUploaded] = useState<Array<string>>([]);

  const loading = event.loading || file.loading;

  const styles = useStyles(appTheme)();

  useEffect(() => {
    if (file.response && filesUploaded.length > 0) {
      setCoverImg(`https://cdn.pefachurchgimu.org/${filesUploaded[0]}`);

      clearFileError();

      setFilesUploaded([]);
    }
  }, [file.response, filesUploaded, clearFileError]);

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "name":
        setName(value);
        break;
      case "location":
        setLocation(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "coverImg":
        setCoverImg(value);
        break;
      case "startDate":
        setStartDate(value);
        break;
      case "startTime":
        setStartTime(value);
        break;
      case "endDate":
        setEndDate(value);
        break;
      case "endTime":
        setEndTime(value);
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (validator.isEmpty(name)) {
      setErrorMessage("The event name entered is not valid");
    } else if (validator.isEmpty(location)) {
      setErrorMessage("The event location entered is not valid");
    } else if (validator.isEmpty(description)) {
      setErrorMessage("The event description entered is not valid");
    } else if (validator.isEmpty(coverImg)) {
      setErrorMessage("The event cover image has not been uploaded");
    } else if (validator.isEmpty(startDate)) {
      setErrorMessage("The event start date entered is not valid");
    } else if (validator.isEmpty(startTime)) {
      setErrorMessage("The event start time entered is not valid");
    } else if (validator.isEmpty(endDate)) {
      setErrorMessage("The event end date entered is not valid");
    } else if (validator.isEmpty(endTime)) {
      setErrorMessage("The event end time entered is not valid");
    } else {
      const start_time = new Date(startDate + "T" + startTime + ":00.000+00:00").getTime(),
        end_time = new Date(endDate + "T" + endTime + ":00.000+00:00").getTime();

      if (start_time > end_time) {
        setErrorMessage("Start date & time cannot be greater than end date & time");
        return;
      }

      setErrorMessage("");

      if (eventData) {
        updateEvent(_id, name, location, description, coverImg, start_time, end_time);
      } else {
        addEvent(name, location, description, coverImg, start_time, end_time);
      }
    }
  };

  useEffect(() => {
    const requestData = event.error ? event : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [event]);

  useEffect(() => {
    return () => {
      clearEventError();
    };
  }, [clearEventError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            value={name}
            onChange={(event) => {
              textFieldValueChanged("name", event.target.value);
            }}
            placeholder="Name (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <CalendarToday className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            type="text"
            required
            value={location}
            onChange={(event) => {
              textFieldValueChanged("location", event.target.value);
            }}
            placeholder="Location (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Room className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            type="text"
            required
            value={description}
            onChange={(event) => {
              textFieldValueChanged("description", event.target.value);
            }}
            placeholder="Description (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Create className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          {coverImg ? (
            <img className={styles.img} alt={name} src={coverImg} />
          ) : (
            <Typography variant="body1" align="center" color="error">
              No Cover Image Uploaded Yet
            </Typography>
          )}
          <br />
          <Button disabled={loading} fullWidth color="primary" size="large" component="label">
            <input
              onChange={(event) => {
                if (event.target.files && event.target.files.length > 0) {
                  const filesPaths = [generateFileName("events", "event-" + uuidv4(), event.target.files[0].type)];
                  setFilesUploaded(filesPaths);
                  uploadFiles(filesPaths, event.target.files);
                }
              }}
              type="file"
              hidden
              accept="image/*"
            />
            {loadingIndicator} {coverImg ? "Replace Event Cover Image" : "Upload Event Cover Image"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Start Date (Required)
          </Typography>
          <InputBase
            required
            inputProps={{ min: dateformat(new Date(), "yyyy-mm-dd") }}
            type="date"
            defaultValue={startDate}
            onChange={(event) => {
              textFieldValueChanged("startDate", event.target.value);
            }}
            placeholder="Start Date (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <CalendarToday className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Start Time (Required)
          </Typography>
          <InputBase
            required
            type="time"
            defaultValue={startTime}
            onChange={(event) => {
              textFieldValueChanged("startTime", event.target.value);
            }}
            placeholder="Start Time (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <AccessAlarm className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            End Date (Required)
          </Typography>
          <InputBase
            required
            inputProps={{ min: dateformat(new Date(), "yyyy-mm-dd") }}
            type="date"
            defaultValue={endDate}
            onChange={(event) => {
              textFieldValueChanged("endDate", event.target.value);
            }}
            placeholder="End Date (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <CalendarToday className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            End Time (Required)
          </Typography>
          <InputBase
            required
            type="time"
            defaultValue={endTime}
            onChange={(event) => {
              textFieldValueChanged("endTime", event.target.value);
            }}
            placeholder="End Time (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <AccessAlarm className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} {props.eventData ? "Update Event Details" : "Add New Event"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    updateEvent: (_id: string, name: string, location: string, description: string, cover_img: string, start_time: number, end_time: number) =>
      dispatch(updateEvent(_id, name, location, description, cover_img, start_time, end_time)),
    addEvent: (name: string, location: string, description: string, cover_img: string, start_time: number, end_time: number) =>
      dispatch(addEvent(name, location, description, cover_img, start_time, end_time)),
    clearEventError: () => dispatch(clearEventError()),
    uploadFiles: (filePaths: Array<string>, files: FileList) => dispatch(uploadFiles(filePaths, files)),
    clearFileError: () => dispatch(clearFileError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    file: state.file,
    event: state.event,
    appTheme: state.general.theme
  };
};

const UpsertEventDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(UpsertEventDetailsForm);

const UpsertEventDetailsModal: React.FC<UpsertEventDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title={props.eventData ? "Update Event Details" : "Add New Event"}
      component={<UpsertEventDetailsFormComponent eventData={props.eventData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UpsertEventDetailsModal;
