import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { alpha, Breadcrumbs, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Paper, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import dateformat from "dateformat";
import { makeFirstLetterInEveryWordUpperCase } from "../../../util/title-formater";
import Error404 from "../../Error404";
import { Visibility } from "@material-ui/icons";
import { useBlog } from "../../../hooks/useBlog";
import { ErrorView } from "../../../components";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        paddingTop: 40
      },
      title: {
        color: alpha(theme.palette.common.black, 0.5),
        fontSize: "2rem",
        fontWeight: 700,
        marginBottom: 30
      },
      card: {
        width: "100%",
        maxWidth: "100%",
        marginBottom: 30
      },
      paperSkeleton: {
        marginBottom: 30
      },
      articleText: {
        lineHeight: "1.5em",
        fontSize: "1.25em"
      },
      breadcrumbLink: {
        textDecoration: "none",
        color: "#2f89fc",
        "&:hover": {
          color: alpha("#2f89fc", 0.5)
        }
      },
      icon: {
        color: "#2f89fc"
      }
    })
  );

const Article: React.FC = () => {
  const styles = useStyles()();

  const { pathname } = useParams<{ pathname: string }>();

  const { blogLoading, blogError, blog, getBlog } = useBlog();

  const _id = pathname.split("-").pop() ?? "";

  useEffect(() => {
    window.scroll(0, 0);
    getBlog(_id);
  }, [getBlog,_id]);

  if (blogError) {
    return <ErrorView error={blogError} onRetry={() => getBlog(_id)} />;
  }

  if (!blogLoading && !blog) {
    return <Error404 />;
  }

  const url = `/blog/${blog?.title
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .replace(/ /g, "-")
    .toLowerCase()}-${blog?._id}`;

  const description = blog?.content.replace(/<\/?[^>]+(>|$)/g, "").substr(0, 150);

  return (
    <React.Fragment>
      <Helmet>
        <title>{blog?.title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={window.location.origin + url} />
        <meta property="og:title" content={blog?.title} />
        <meta property="og:site_name" content="PEFA Church Gimu" />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={window.location.origin + url} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={blog?.cover_img ?? ""} />
        <meta property="article:author" content={"PEFA Church Gimu"} />
        <meta property="article:published_time" content={new Date(blog?.added_on ?? 0).toISOString()} />
        <meta property="article:modified_time " content={new Date(blog?.added_on ?? 0).toISOString()} />
      </Helmet>
      <div className={styles.root}>
        <Container maxWidth="lg">
          <Typography variant="h1" className={styles.title}>
            {blogLoading ? "Loading ..." : makeFirstLetterInEveryWordUpperCase(blog?.title ?? "")}
          </Typography>
          <Breadcrumbs>
            <Link color="inherit" to="/" className={styles.breadcrumbLink}>
              Home
            </Link>
            <Link color="inherit" to="/blog" className={styles.breadcrumbLink}>
              Blog
            </Link>
            <Typography color="textPrimary">{blog?.title}</Typography>
          </Breadcrumbs>
          <br />
          <React.Fragment>
            {blogLoading ? (
              <Paper className={styles.paperSkeleton}>
                <Skeleton style={{ marginBottom: 25 }} variant="rect" height={350} />
                <div style={{ padding: 15 }}>
                  <Skeleton style={{ marginBottom: 25 }} variant="text" width="25%" />
                  <Skeleton style={{ marginBottom: 25 }} variant="rect" height={20} />
                  <Skeleton style={{ marginBottom: 5 }} variant="text" />
                  <Skeleton style={{ marginBottom: 5 }} variant="text" />
                  <Skeleton style={{ marginBottom: 5 }} variant="text" />
                  <Skeleton style={{ marginBottom: 5 }} variant="text" />
                </div>
              </Paper>
            ) : (
              <React.Fragment>
              <Card className={styles.card}>
                <CardActionArea>
                  <CardMedia component="img" alt={blog?.title} height="350" image={blog?.cover_img ?? ""} title={blog?.title} />
                  <CardContent>
                    <Grid container justifyContent="space-between" spacing={3}>
                      <Grid item>
                        <Typography variant="caption" color="textSecondary">
                          {dateformat(blog?.added_on ?? 0, "ddd mmm dd yyyy")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Visibility className={styles.icon} />
                          </Grid>
                          <Grid item>
                            <Typography variant="body1" color="textSecondary">
                              {blog?.views}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <br />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={styles.articleText}
                      dangerouslySetInnerHTML={{ __html: blog?.content.replace(/<img/g, '<img width="100%"').replace(/<br>/g, "") ?? "" }}
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
              <br />
              <br />
              </React.Fragment>
            )}
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Article;
