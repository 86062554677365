import { useCallback, useState } from "react";
import { ProjectInterface } from "../interfaces";
import request from "../util/request";

type Project = Omit<ProjectInterface, "project_id"> & { _id: string };

const useProject = () => {
  const [projectsLoading, setProjectsLoading] = useState<boolean>(false);
  const [projectLoading, setProjectLoading] = useState<boolean>(false);
  const [projectsError, setProjectsError] = useState<{
    status?: number;
    data?: any;
    message: string;
  } | null>(null);
  const [projectError, setProjectError] = useState<{
    status?: number;
    data?: any;
    message: string;
  } | null>(null);
  const [more, setMore] = useState<boolean>(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [project, setProject] = useState<Project>();

  const getProjects = useCallback(async (search: string = "",skip:number) => {
    try {
      setProjectsLoading(true);
      setProjectsError(null);
      const response = await request({ pathname: "searchRawProjects", method: "GET", params: { search, skip } });
      setProjects(prev => prev.concat(response.data.content["data"]));
      setMore(response.data.content["more"]);
    } catch (e) {
      const err = e as any;
      if (err.response && err.response.status) {
        setProjectsError({
          message: err.message,
          status: err.response.status,
          data: err.response.data
        });
      } else {
        setProjectsError({
          message: err.message
        });
      }
    } finally {
      setProjectsLoading(false);
    }
  },[])

  const getProject = useCallback(async (_id: string) => {
    try {
      setProjectLoading(true);
      setProjectError(null);
      const response = await request({ pathname: "getRawProject", method: "GET", params: { _id } });
      setProject(response.data.content);
    } catch (e) {
      const err = e as any;
      if (err.response && err.response.status) {
        setProjectError({
          message: err.message,
          status: err.response.status,
          data: err.response.data
        });
      } else {
        setProjectError({
          message: err.message
        });
      }
    } finally {
      setProjectLoading(false);
    }
  },[])

  return {
    projectsLoading,
    projectLoading,
    projectsError,
    projectError,
    project,
    more,
    projects,
    getProjects,
    getProject
  };
};

export { useProject };
