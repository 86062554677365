import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearMinistryKittySubtractionError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_KITTY_SUBTRACTION_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const addMinistryKittySubtraction = (
  _id: string,
  subtractor_id: string,
  subtractor_type: "Other" | "User",
  amount: number,
  date: string,
  transaction_details: string,
  transaction_cost: number
) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_KITTY_SUBTRACTION_HTTP_LOADING
    });

    request({
      method: "post",
      pathname: "addMinistryKittySubtraction",
      data: { _id, subtractor_id, subtractor_type, amount, date, transaction_details, transaction_cost }
    })
      .then((response) => {
        dispatch({
          type: actions.MINISTRY_KITTY_SUBTRACTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.MINISTRY_KITTY_SUBTRACTION_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteMinistryKittySubtraction = (_id: string, subtraction_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_KITTY_SUBTRACTION_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteMinistryKittySubtraction", data: { _id, subtraction_id } })
      .then((response) => {
        dispatch({
          type: actions.MINISTRY_KITTY_SUBTRACTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.MINISTRY_KITTY_SUBTRACTION_HTTP_ERROR, error, dispatch);
      });
  };
};
