import { useCallback, useState } from "react";
import { BlogInterface } from "../interfaces";
import request from "../util/request";

type Blog = Omit<BlogInterface, "blog_id"> & { _id: string };

const useBlog = () => {
  const [blogsLoading, setBlogsLoading] = useState<boolean>(false);
  const [blogLoading, setBlogLoading] = useState<boolean>(false);
  const [blogsError, setBlogsError] = useState<{
    status?: number;
    data?: any;
    message: string;
  } | null>(null);
  const [blogError, setBlogError] = useState<{
    status?: number;
    data?: any;
    message: string;
  } | null>(null);
  const [more, setMore] = useState<boolean>(false);
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [blog, setBlog] = useState<Blog>();

  const getBlogs = useCallback(async (search: string = "",skip:number) => {
    try {
      setBlogsLoading(true);
      setBlogsError(null);
      const response = await request({ pathname: "searchRawBlogs", method: "GET", params: { search, skip } });
      setBlogs(prev => prev.concat(response.data.content["data"]));
      setMore(response.data.content["more"]);
    } catch (e) {
      const err = e as any;
      if (err.response && err.response.status) {
        setBlogsError({
          message: err.message,
          status: err.response.status,
          data: err.response.data
        });
      } else {
        setBlogsError({
          message: err.message
        });
      }
    } finally {
      setBlogsLoading(false);
    }
  },[])

  const getBlog = useCallback(async (_id: string) => {
    try {
      setBlogLoading(true);
      setBlogError(null);
      const responses = await Promise.all([
        request({ pathname: "blogContentViewed", method: "GET", params: { blog_id: _id } }),
        request({ pathname: "getRawBlog", method: "GET", params: { _id } })
      ]);
      setBlog(responses[1].data.content);
    } catch (e) {
      const err = e as any;
      if (err.response && err.response.status) {
        setBlogError({
          message: err.message,
          status: err.response.status,
          data: err.response.data
        });
      } else {
        setBlogError({
          message: err.message
        });
      }
    } finally {
      setBlogLoading(false);
    }
  },[])

  return {
    blogsLoading,
    blogLoading,
    blogsError,
    blogError,
    blog,
    more,
    blogs,
    getBlogs,
    getBlog
  };
};

export { useBlog };
