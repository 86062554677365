import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { alpha, Button, Container, Grid, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { ErrorOutline } from "@material-ui/icons";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        minHeight:"65vh"
      },
      grid: {
        padding: 50
      },
      button: {
        borderRadius: 30,
        width: 200,
        height: 50,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        textTransform: "none",
        "&:hover": {
          backgroundColor: alpha("#2f89fc", 0.9)
        }
      },
      icon: {
        color: theme.palette.primary.main,
        fontSize: 150
      }
    })
  );

const Error404: React.FC = () => {
  const styles = useStyles()();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const isAdmin: boolean = (process.env["REACT_APP_ADMIN_ALLOWED_URLS"] ?? "").split(",").includes(window.origin);

  return (
    <React.Fragment>
      {
        !isAdmin && (
        <Helmet>
          <title>404 Error | Page Not Found</title>
          <meta name="description" content="The following page does not exists" />
          <meta property="og:site_name" content="Purity International Consultancy LTD" />
          <meta property="og:title" content="404 Error | Page Not Found" />
          <meta property="og:description" content="The following page does not exists" />
        </Helmet>
        )
      }
      <Container maxWidth="md" className={styles.root}>
        <Grid container direction="column" spacing={2} className={styles.grid} alignItems="center">
          <Grid item>
            <ErrorOutline className={styles.icon} />
          </Grid>
          <Grid item>
            <Typography variant="h1">404</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" align="center">This page appears to be missing or invalid</Typography>
          </Grid>
          <Grid item>
            <Button className={styles.button} variant="contained" size="large" component={Link} to="/">
              Go Home
            </Button>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Error404;
