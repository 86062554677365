import React from "react";
import { connect } from "react-redux";
import { AppState, AppTheme, ProfileReducerState } from "../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, Container, Paper, Typography, ListItem, List, ListItemText, Button, Divider, ListItemSecondaryAction } from "@material-ui/core";
import { Link, useParams, useLocation } from "react-router-dom";
import { checkUserAccess } from "../../../../util/permission-checker";
import { ArrowForwardIos } from "@material-ui/icons";

interface MinistryProps {
  profile: ProfileReducerState;
  appTheme: AppTheme;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 15,
        marginTop: 10,
        marginBottom: 10,
        height: "60vh",
        overflowY: "scroll"
      }
    })
  );

const Ministry: React.FC<MinistryProps> = (props) => {
  const location = useLocation();

  const { ministry_id, ministry_name } = useParams<{ ministry_id: string; ministry_name: string }>();

  const { appTheme, profile } = props;

  const styles = useStyles(appTheme)();
  let menuItems = [
    { text: "Manage Landing Page", permission: `view-ministry-${ministry_id}-landing-page`, path: "landing-page" },
    { text: "Manage Members", permission: `view-ministry-${ministry_id}-membership`, path: "members" },
    { text: "Manage Kitties", permission: `view-ministry-${ministry_id}-kitties`, path: "kitties" },
    { text: "Manage Contributions", permission: `view-ministry-${ministry_id}-contributions`, path: "contributions" }
  ];

  if (ministry_id === "children-ministry") {
    //menuItems.push({ text: "Manage Questionnaires", permission: `view-ministry-${ministry_id}-questionnaires`, path: "questionnaires" });

    menuItems = menuItems.filter((menuItem) => menuItem.text !== "Manage Members");
  }

  return (
    <div className={styles.root}>
      <Container maxWidth="md">
        <Paper className={styles.paper}>
          <Grid container justifyContent="space-between" spacing={0}>
            <Grid item>
              <Typography variant="h3" color="primary">
                {decodeURIComponent(ministry_name)}
              </Typography>
            </Grid>
            <Grid item>
              <Button component={Link} to="/dashboard/ministries" variant="outlined" size="large">
                Go Back
              </Button>
            </Grid>
          </Grid>
          <br />
          <List component="div">
            {menuItems.map((menuItem, index) => {
              const hasPermission = checkUserAccess(menuItem.permission, profile.permissions);
              if (!hasPermission) return null;

              return (
                <React.Fragment key={index}>
                  <ListItem button component={Link} to={`${location.pathname}/${menuItem.path}`}>
                    <ListItemText primary={menuItem.text} />
                    <ListItemSecondaryAction>
                      <ArrowForwardIos />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        </Paper>
      </Container>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    profile: state.profile,
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps)(Ministry);
