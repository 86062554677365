import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import request, { client } from "./util/request";
import axios from "axios";
import actions from "./util/actions";
import queryString from "query-string";
import { BrowserRouter } from "react-router-dom";

client.interceptors.request.use(
  (config) => {
    const state = store.getState();

    const { udid, os, model, appVersion } = state.device;

    if (config.method) {
      if (config.method.toString().toUpperCase() === "GET") {
        if (config.params) {
          config.params = { ...config.params, udid: udid, os: os, model: model, app_version: appVersion.toString(), push_id_token: "N/A" };
        } else {
          config.params = { udid: udid, os: os, model: model, app_version: appVersion.toString(), push_id_token: "N/A" };
        }

        config.url = config.url + "?" + queryString.stringify(config.params);

        config.params = null;
      } else {
        if (config.params) {
          config.url = config.url + "?" + queryString.stringify(config.params);

          config.params = null;
        }

        if (config.data) {
          config.data = queryString.stringify({
            ...queryString.parse(config.data),
            udid: udid,
            os: os,
            model: model,
            app_version: appVersion.toString(),
            push_id_token: "N/A"
          });
        } else {
          config.data = queryString.stringify({ udid: udid, os: os, model: model, app_version: appVersion.toString(), push_id_token: "N/A" });
        }
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    try {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.status === "Access Token Expired") {
          await request({
            method: "post",
            pathname: "refreshAccessToken",
            data: {
              token: store.getState().token.refresh
            }
          });

          try {
            const response = await axios(error.config);
            return Promise.resolve(response);
          } catch (retryError) {
            return Promise.reject(error);
          }
        }

        if (error.response.status === 401 && error.response.data.status === "Refresh Token Expired") {
          throw new Error("Refresh Token Expired. Log Out User");
        }
      }
      return Promise.reject(error);
    } catch (err) {
      store.dispatch({
        type: actions.LOG_OUT_USER
      });

      window.location.replace("/account/login?continueTo=" + window.location.pathname + "#session-expired");

      return Promise.reject(error);
    }
  }
);

ReactDOM.hydrate(
  <Provider store={store}>
  <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
