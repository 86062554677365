import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, FullVerseInterface, VerseInterface, VerseReducerState } from "../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { List, CircularProgress, ListItem, ListItemText, Divider, Typography } from "@material-ui/core";
import { clearVerseError, getFullVerse } from "../../../../store/actions";
import { ErrorView } from "../../../../components";
import { getBookName } from "../../../../util/bible";

interface ViewFullVerseViewProps {
  appTheme: AppTheme;
  verse: VerseReducerState;
  verseData: VerseInterface | null;
  getFullVerse: (_id: string) => void;
  clearVerseError: () => void;
}

interface ViewFullVerseModalProps {
  open: boolean;
  verseData: VerseInterface | null;
  onDismissed: () => void;
}

const useStyles = () =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      listSection: {
        backgroundColor: "inherit"
      },
      ul: {
        backgroundColor: "inherit",
        padding: 0
      }
    })
  );

const ViewFullVerseView: React.FC<ViewFullVerseViewProps> = (props) => {
  const {  verse, verseData, getFullVerse, clearVerseError } = props;

  const [_id] = useState<string>(verseData ? verseData.verse_id : "");

  const styles = useStyles()();

  useEffect(() => {
    getFullVerse(_id);
    return () => {
      clearVerseError();
    };
  }, [_id, getFullVerse, clearVerseError]);

  return (
    <React.Fragment>
      {verse.loading ? (
        <CircularProgress color="primary" />
      ) : verse.error ? (
        <ErrorView error={verse.error} onRetry={() => getFullVerse(_id)} />
      ) : (
        <List className={styles.root}>
          {verse.fullVerse.map((data: FullVerseInterface, key: number) => {
            const isHeaderVisble = key === 0 ? true : verse.fullVerse[key - 1].bId !== data.bId;
            return (
              <React.Fragment>
                {isHeaderVisble && <Typography variant="subtitle1">{getBookName(data.bId)}</Typography>}
                <ListItem key={key}>
                  {data.vId === 0 && (
                    <Typography variant="h3" style={{ marginRight: 10 }}>
                      {data.cId + 1}
                    </Typography>
                  )}
                  <Typography variant="caption" style={{ marginRight: 10 }} color="textSecondary">
                    {data.vId + 1}
                  </Typography>
                  <ListItemText primary={data.verse} style={{ fontSize: 20 }} />
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getFullVerse: (_id: string) => dispatch(getFullVerse(_id)),
    clearVerseError: () => dispatch(clearVerseError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    verse: state.verse,
    appTheme: state.general.theme
  };
};

const ViewFullVerseViewComponent = connect(mapStateToProps, mapDispatchToProps)(ViewFullVerseView);

const ViewFullVerseModal: React.FC<ViewFullVerseModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="lg"
      title={props.verseData ? props.verseData.verse : ""}
      component={<ViewFullVerseViewComponent verseData={props.verseData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default ViewFullVerseModal;
