import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, ProfileReducerState, KittiesReducerState, KittyInterface, KittyReducerState } from "../../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Container,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment,
  Paper
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { DialogModal, ErrorView } from "../../../components";
import { clearKittyError, getKitties, deleteKitty } from "../../../store/actions";
import { checkUserAccess } from "../../../util/permission-checker";
import PopupMenuDialog from "../../../components/PopupMenuDialog";
import UpsertKittyDetailsDialog from "./Modals/UpsertKittyDetailsDialog";
import KittySubtractionsDialog from "./Modals/KittySubtractionsDialog";
import KittyLinkedContributionsDialog from "./Modals/KittyLinkedContributionsDialog";

interface KittiesProps {
  kitties: KittiesReducerState;
  kitty: KittyReducerState;
  profile: ProfileReducerState;
  appTheme: AppTheme;
  getKitties: (search: string, page: number, per_page: number) => void;
  clearKittyError: () => void;
  deleteKitty: (kitty_id: string) => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 15,
        marginTop: 10,
        marginBottom: 10
      },
      icon: {
        color: theme.palette.text.primary,
        padding: 10,
        borderRadius: 30,
        backgroundColor: appTheme.iconBackgroundColor,
        "&:hover": {
          backgroundColor: appTheme.iconBackgroundHoverColor
        }
      },
      searchButton: {
        marginRight: "-21px",
        height: "55px",
        borderRadius: "30px",
        width: "100px"
      },
      textInput: {
        marginTop: "5px",
        width: "300px"
      },
      textInputControl: {
        borderRadius: "30px",
        backgroundColor: appTheme.section.backgroundColor
      },
      container: {
        minHeight: "60vh"
      },
      textInputIcon: {
        color: "#000"
      }
    })
  );

const Kitties: React.FC<KittiesProps> = (props) => {
  const { appTheme, kitties, kitty, profile, getKitties, deleteKitty, clearKittyError } = props;

  const [search, setSearch] = useState<string>(kitties.search);
  const [page, setPage] = useState<number>(kitties.page);
  const [perPage, setPerPage] = useState<number>(kitties.per_page ?? 20);
  const [selectedRecord, setSelectedRecord] = useState<KittyInterface | null>(null);
  const [popUpDialogOpen, setPopUpDialogOpen] = useState<boolean>(false);

  const popUpMenuItems = useMemo(() => {
    return [
      { text: "View Kitty Linked Contributions", permission: "view-kitty-linked-contributions" },
      { text: "View Kitty Subtractions", permission: "view-kitty-subtractions" },
      { text: "Edit Kitty Details", permission: "edit-kitty" },
      { text: "Delete Kitty", permission: "delete-kitty" }
    ];
  }, []);

  const [menuItems, setMenuItems] = useState<Array<string>>(
    popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text)
  );

  useEffect(() => {
    setMenuItems(popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text));
  }, [popUpMenuItems, profile.permissions]);

  const [upsertKittyDetailsModalOpen, setUpsertKittyDetailsDialogOpen] = useState<boolean>(false);
  const [kittySubtractionModalOpen, setKittySubtractionModalOpen] = useState<boolean>(false);
  const [kittyLinkedContributionModalOpen, setKittyLinkedContributionModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getKitties(search, page, perPage);
  }, [search, page, perPage, getKitties]);

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearKittyError();
    };
  }, [clearKittyError]);

  useEffect(() => {
    if (!kitty.error && kitty.response && kitty.response.data.status !== "Ok") {
      clearKittyError();

      setSelectedRecord(null);

      setUpsertKittyDetailsDialogOpen(false);

      setKittySubtractionModalOpen(false);

      setDialogOptions({ open: true, body: kitty.response.data.status });

      getKitties("", 0, perPage);
    }
  }, [kitty.error, kitty.response, perPage, getKitties, clearKittyError]);

  useEffect(() => {
    if (!upsertKittyDetailsModalOpen && !kittySubtractionModalOpen && !kittyLinkedContributionModalOpen) getKitties(search, page, perPage);
  }, [upsertKittyDetailsModalOpen, kittySubtractionModalOpen, kittyLinkedContributionModalOpen, search, page, perPage, getKitties]);

  useEffect(() => {
    if (upsertKittyDetailsModalOpen || kittySubtractionModalOpen || kittyLinkedContributionModalOpen) {
      return;
    }

    const requestData = kitty.error ? kitty : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [upsertKittyDetailsModalOpen, kittySubtractionModalOpen, kittyLinkedContributionModalOpen, kitty]);

  const onMenuItemSelected = (menuItem: string) => {
    setPopUpDialogOpen(false);

    if (!selectedRecord) {
      return;
    }

    switch (menuItem) {
      case "View Kitty Linked Contributions":
        setKittyLinkedContributionModalOpen(true);
        break;
      case "View Kitty Subtractions":
        setKittySubtractionModalOpen(true);
        break;
      case "Edit Kitty Details":
        setUpsertKittyDetailsDialogOpen(true);
        break;
      case "Delete Kitty":
        if (window.confirm("Would you like to delete this kitties ?")) {
          deleteKitty(selectedRecord.kitty_id);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <UpsertKittyDetailsDialog
        open={upsertKittyDetailsModalOpen}
        onDismissed={() => setUpsertKittyDetailsDialogOpen(false)}
        kittyData={selectedRecord}
      />
      <KittySubtractionsDialog open={kittySubtractionModalOpen} onDismissed={() => setKittySubtractionModalOpen(false)} kittyData={selectedRecord} />
      <KittyLinkedContributionsDialog
        open={kittyLinkedContributionModalOpen}
        onDismissed={() => setKittyLinkedContributionModalOpen(false)}
        kittyData={selectedRecord}
      />
      <PopupMenuDialog
        open={popUpDialogOpen}
        onDismissed={() => setPopUpDialogOpen(false)}
        onMenuItemSelected={onMenuItemSelected}
        menuItems={menuItems}
      />
      <Container maxWidth="lg">
        <Paper className={styles.paper}>
          {!upsertKittyDetailsModalOpen && kitties.error ? (
            <ErrorView error={kitties.error} onRetry={() => getKitties(search, page, perPage)} />
          ) : (
            <React.Fragment>
              <Grid container justifyContent="space-between" spacing={0}>
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      {!upsertKittyDetailsModalOpen && kitties.loading && (
                        <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography variant="h3" color="primary">
                        Kitties
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(event) => {
                      setSearch(event.currentTarget.value);
                    }}
                    error={false}
                    value={search}
                    variant="outlined"
                    placeholder="Search ..."
                    className={styles.textInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search className={styles.textInputIcon} />
                        </InputAdornment>
                      ),
                      classes: { root: styles.textInputControl }
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <TableContainer className={styles.container}>
                <Table stickyHeader aria-label="user-attendances">
                  <TableHead>
                    <TableRow>
                      {[
                        { label: "ID", minWidth: 100 },
                        { label: "Kitty Name", minWidth: 250 },
                        { label: "Kitty Balance", minWidth: 150 },
                        { label: "Total Contributions", minWidth: 150 },
                        { label: "Total Subtractions", minWidth: 150 },
                        { label: "Options", minWidth: 100 }
                      ].map((column) => (
                        <TableCell key={column.label} align="center" style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {kitties.data.map((kitty: KittyInterface) => {
                      const _id = kitty.kitty_id;
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={_id}>
                          {["kitty_id", "kitty_name", "kitty_balance", "kitty_contributions", "kitty_subtractions", "options"].map((column) => {
                            const key = `${_id}-${column}`;

                            const data = (kitty as unknown as { [key: string]: any })[column];
                            if (column === "options") {
                              return (
                                <TableCell key={key} align="center">
                                  <Button
                                    variant="outlined"
                                    disabled={menuItems.length === 0}
                                    onClick={() => {
                                      setSelectedRecord(kitty);
                                      setPopUpDialogOpen(true);
                                    }}>
                                    Options
                                  </Button>
                                </TableCell>
                              );
                            } else if (column === "kitty_balance" || column === "kitty_subtractions" || column === "kitty_contributions") {
                              return (
                                <TableCell key={key} align="center">
                                  {new Intl.NumberFormat("en-KE", { style: "currency", currency: "KES" }).format(parseFloat(data))}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={key} align="center">
                                  {data}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container justifyContent="space-between" style={{ margin: "0px 10px 0px 10px" }}>
                <Grid item>
                  <Button
                    disabled={!checkUserAccess("add-kitty", profile.permissions)}
                    variant="outlined"
                    size="large"
                    onClick={() => {
                      setSelectedRecord(null);
                      setUpsertKittyDetailsDialogOpen(true);
                    }}>
                    Add New Kitty
                  </Button>
                </Grid>
                <Grid item>
                  <TablePagination
                    component="div"
                    count={kitties.total}
                    page={page}
                    onPageChange={(_, page) => setPage(page)}
                    rowsPerPage={perPage}
                    rowsPerPageOptions={[20, 50, 100, 1000]}
                    onRowsPerPageChange={(event) => {
                      setPerPage(parseInt(event.target.value));
                      setPage(0);
                    }}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </Paper>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getKitties: (search: string, page: number, per_page: number) => dispatch(getKitties(search, page, per_page)),
    clearKittyError: () => dispatch(clearKittyError()),
    deleteKitty: (kitty_id: string) => dispatch(deleteKitty(kitty_id))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    kitties: state.kitties,
    kitty: state.kitty,
    profile: state.profile,
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Kitties);
