import { useCallback, useState } from "react";
import { EventInterface } from "../interfaces";
import request from "../util/request";

type Event = Omit<EventInterface, "event_id" | "start_time" | "end_time"> & { _id: string,event_date:string,start_time:string,end_time: string };

const useEvent = () => {
  const [eventsLoading, setEventsLoading] = useState<boolean>(false);
  const [eventsError, setEventsError] = useState<{
    status?: number;
    data?: any;
    message: string;
  } | null>(null);
  const [events, setEvents] = useState<Event[]>([]);
  const [upCommingEvents,setUpCommingEvents] = useState<Event[]>([]);

  const getEvents = useCallback(async () => {
    try {
      setEventsLoading(true);
      setEventsError(null);
      const response = await request({ pathname: "getRawEvents", method: "GET" });
      setEvents(response.data.content);
      setUpCommingEvents((response.data.content as Event[]).filter(event=> new Date().getTime() <= new Date(event.event_date+" "+event.end_time).getTime()))
    } catch (e) {
      const err = e as any;
      if (err.response && err.response.status) {
        setEventsError({
          message: err.message,
          status: err.response.status,
          data: err.response.data
        });
      } else {
        setEventsError({
          message: err.message
        });
      }
    } finally {
      setEventsLoading(false);
    }
  },[])
  
  return {
    eventsLoading,
    eventsError,
    events,
    getEvents,
    upCommingEvents
  };
};

export { useEvent };
