const actions = {
  //app
  APP_HTTP_LOADING: "APP_HTTP_LOADING",
  APP_HTTP_ERROR: "APP_HTTP_ERROR",
  APP_HTTP_COMPLETE: "APP_HTTP_COMPLETE",

  // attendance
  ATTENDANCE_HTTP_LOADING: "ATTENDANCE_HTTP_LOADING",
  ATTENDANCE_HTTP_ERROR: "ATTENDANCE_HTTP_ERROR",
  ATTENDANCE_HTTP_COMPLETE: "ATTENDANCE_HTTP_COMPLETE",
  SET_ATTENDANCE_DETAILS: "SET_ATTENDANCE_DETAILS",

  // attendances
  ATTENDANCES_HTTP_LOADING: "ATTENDANCES_HTTP_LOADING",
  ATTENDANCES_HTTP_ERROR: "ATTENDANCES_HTTP_ERROR",
  ATTENDANCES_HTTP_COMPLETE: "ATTENDANCES_HTTP_COMPLETE",
  SET_ATTENDANCES_DETAILS: "SET_ATTENDANCES_DETAILS",

  // blog
  BLOG_HTTP_LOADING: "BLOG_HTTP_LOADING",
  BLOG_HTTP_ERROR: "BLOG_HTTP_ERROR",
  BLOG_HTTP_COMPLETE: "BLOG_HTTP_COMPLETE",

  // blogs
  BLOGS_HTTP_LOADING: "BLOGS_HTTP_LOADING",
  BLOGS_HTTP_ERROR: "BLOGS_HTTP_ERROR",
  BLOGS_HTTP_COMPLETE: "BLOGS_HTTP_COMPLETE",
  SET_BLOGS_DETAILS: "SET_BLOGS_DETAILS",

  // contribution
  CONTRIBUTION_HTTP_LOADING: "CONTRIBUTION_HTTP_LOADING",
  CONTRIBUTION_HTTP_ERROR: "CONTRIBUTION_HTTP_ERROR",
  CONTRIBUTION_HTTP_COMPLETE: "CONTRIBUTION_HTTP_COMPLETE",
  SET_CONTRIBUTION_DETAILS: "SET_CONTRIBUTION_DETAILS",

  // contrbutions
  CONTRIBUTIONS_HTTP_LOADING: "CONTRIBUTIONS_HTTP_LOADING",
  CONTRIBUTIONS_HTTP_ERROR: "CONTRIBUTIONS_HTTP_ERROR",
  CONTRIBUTIONS_HTTP_COMPLETE: "CONTRIBUTIONS_HTTP_COMPLETE",
  SET_CONTRIBUTIONS_DETAILS: "SET_CONTRIBUTIONS_DETAILS",

  // discipleship-class
  DISCIPLESHIP_CLASS_HTTP_LOADING: "DISCIPLESHIP_CLASS_HTTP_LOADING",
  DISCIPLESHIP_CLASS_HTTP_ERROR: "DISCIPLESHIP_CLASS_HTTP_ERROR",
  DISCIPLESHIP_CLASS_HTTP_COMPLETE: "DISCIPLESHIP_CLASS_HTTP_COMPLETE",
  SET_DISCIPLESHIP_CLASS_DETAILS: "SET_DISCIPLESHIP_CLASS_DETAILS",

  // discipleship-classes
  DISCIPLESHIP_CLASSES_HTTP_LOADING: "DISCIPLESHIP_CLASSES_HTTP_LOADING",
  DISCIPLESHIP_CLASSES_HTTP_ERROR: "DISCIPLESHIP_CLASSES_HTTP_ERROR",
  DISCIPLESHIP_CLASSES_HTTP_COMPLETE: "DISCIPLESHIP_CLASSES_HTTP_COMPLETE",
  SET_DISCIPLESHIP_CLASSES_DETAILS: "SET_DISCIPLESHIP_CLASSES_DETAILS",

  // entities
  ENTITIES_HTTP_LOADING: "ENTITIES_HTTP_LOADING",
  ENTITIES_HTTP_ERROR: "ENTITIES_HTTP_ERROR",
  ENTITIES_HTTP_COMPLETE: "ENTITIES_HTTP_COMPLETE",
  SET_ENTITIES_DETAILS: "SET_ENTITIES_DETAILS",

  // event
  EVENT_HTTP_LOADING: "EVENT_HTTP_LOADING",
  EVENT_HTTP_ERROR: "EVENT_HTTP_ERROR",
  EVENT_HTTP_COMPLETE: "EVENT_HTTP_COMPLETE",

  // events
  EVENTS_HTTP_LOADING: "EVENTS_HTTP_LOADING",
  EVENTS_HTTP_ERROR: "EVENTS_HTTP_ERROR",
  EVENTS_HTTP_COMPLETE: "EVENTS_HTTP_COMPLETE",
  SET_EVENTS_DETAILS: "SET_EVENTS_DETAILS",

  //file
  FILE_HTTP_LOADING: "FILE_HTTP_LOADING",
  FILE_HTTP_ERROR: "FILE_HTTP_ERROR",
  FILE_HTTP_COMPLETE: "FILE_HTTP_COMPLETE",

  //forgot
  FORGOT_HTTP_LOADING: "FORGOT_HTTP_LOADING",
  FORGOT_HTTP_ERROR: "FORGOT_HTTP_ERROR",
  FORGOT_HTTP_COMPLETE: "FORGOT_HTTP_COMPLETE",
  SET_USER_HAS_FORGOT: "SET_USER_HAS_FORGOTIED",
  UNSET_USER_HAS_FORGOT: "UNSET_USER_HAS_FORGOT",

  // gallery
  GALLERY_HTTP_LOADING: "GALLERY_HTTP_LOADING",
  GALLERY_HTTP_ERROR: "GALLERY_HTTP_ERROR",
  GALLERY_HTTP_COMPLETE: "GALLERY_HTTP_COMPLETE",
  GALLERY_PHOTOS_ADDED: "GALLERY_PHOTOS_ADDED",
  GALLERY_PHOTO_DELETED: "GALLERY_PHOTO_DELETED",

  // galleries
  GALLERIES_HTTP_LOADING: "GALLERIES_HTTP_LOADING",
  GALLERIES_HTTP_ERROR: "GALLERIES_HTTP_ERROR",
  GALLERIES_HTTP_COMPLETE: "GALLERIES_HTTP_COMPLETE",
  SET_GALLERIES_DETAILS: "SET_GALLERIES_DETAILS",

  // kitties
  KITTIES_HTTP_LOADING: "KITTIES_HTTP_LOADING",
  KITTIES_HTTP_ERROR: "KITTIES_HTTP_ERROR",
  KITTIES_HTTP_COMPLETE: "KITTIES_HTTP_COMPLETE",
  SET_KITTIES_DETAILS: "SET_KITTIES_DETAILS",

  // kitty
  KITTY_HTTP_LOADING: "KITTY_HTTP_LOADING",
  KITTY_HTTP_ERROR: "KITTY_HTTP_ERROR",
  KITTY_HTTP_COMPLETE: "KITTY_HTTP_COMPLETE",

  // kitty linked contributions
  KITTY_LINKED_CONTRIBUTIONS_HTTP_LOADING: "KITTY_LINKED_CONTRIBUTIONS_HTTP_LOADING",
  KITTY_LINKED_CONTRIBUTIONS_HTTP_ERROR: "KITTY_LINKED_CONTRIBUTIONS_HTTP_ERROR",
  KITTY_LINKED_CONTRIBUTIONS_HTTP_COMPLETE: "KITTY_LINKED_CONTRIBUTIONS_HTTP_COMPLETE",
  SET_KITTY_LINKED_CONTRIBUTIONS_DETAILS: "SET_KITTY_LINKED_CONTRIBUTIONS_DETAILS",

  // kitty linked contribution
  KITTY_LINKED_CONTRIBUTION_HTTP_LOADING: "KITTY_LINKED_CONTRIBUTION_HTTP_LOADING",
  KITTY_LINKED_CONTRIBUTION_HTTP_ERROR: "KITTY_LINKED_CONTRIBUTION_HTTP_ERROR",
  KITTY_LINKED_CONTRIBUTION_HTTP_COMPLETE: "KITTY_LINKED_CONTRIBUTION_HTTP_COMPLETE",

  // kitty subtractions
  KITTY_SUBTRACTIONS_HTTP_LOADING: "KITTY_SUBTRACTIONS_HTTP_LOADING",
  KITTY_SUBTRACTIONS_HTTP_ERROR: "KITTY_SUBTRACTIONS_HTTP_ERROR",
  KITTY_SUBTRACTIONS_HTTP_COMPLETE: "KITTY_SUBTRACTIONS_HTTP_COMPLETE",
  SET_KITTY_SUBTRACTIONS_DETAILS: "SET_KITTY_SUBTRACTIONS_DETAILS",

  // kitty subtraction
  KITTY_SUBTRACTION_HTTP_LOADING: "KITTY_SUBTRACTION_HTTP_LOADING",
  KITTY_SUBTRACTION_HTTP_ERROR: "KITTY_SUBTRACTION_HTTP_ERROR",
  KITTY_SUBTRACTION_HTTP_COMPLETE: "KITTY_SUBTRACTION_HTTP_COMPLETE",

  //login
  LOGIN_HTTP_LOADING: "LOGIN_HTTP_LOADING",
  LOGIN_HTTP_ERROR: "LOGIN_HTTP_ERROR",
  LOGIN_HTTP_COMPLETE: "LOGIN_HTTP_COMPLETE",
  LOGGING_IN_USER: "LOGGING_IN_USER",
  LOG_IN_USER: "LOG_IN_USER",
  LOG_OUT_USER: "LOG_OUT_USER",

  //notification
  NOTIFICATION_HTTP_LOADING: "NOTIFICATION_HTTP_LOADING",
  NOTIFICATION_HTTP_ERROR: "NOTIFICATION_HTTP_ERROR",
  NOTIFICATION_HTTP_COMPLETE: "NOTIFICATION_HTTP_COMPLETE",

  // ministries
  MINISTRIES_HTTP_LOADING: "MINISTRIES_HTTP_LOADING",
  MINISTRIES_HTTP_ERROR: "MINISTRIES_HTTP_ERROR",
  MINISTRIES_HTTP_COMPLETE: "MINISTRIES_HTTP_COMPLETE",
  SET_MINISTRIES_DETAILS: "SET_MINISTRIES_DETAILS",

  // ministry contribution
  MINISTRY_CONTRIBUTION_HTTP_LOADING: "MINISTRY_CONTRIBUTION_HTTP_LOADING",
  MINISTRY_CONTRIBUTION_HTTP_ERROR: "MINISTRY_CONTRIBUTION_HTTP_ERROR",
  MINISTRY_CONTRIBUTION_HTTP_COMPLETE: "MINISTRY_CONTRIBUTION_HTTP_COMPLETE",

  // ministry member contribution
  MINISTRY_MEMBER_CONTRIBUTION_HTTP_LOADING: "MINISTRY_MEMBER_CONTRIBUTION_HTTP_LOADING",
  MINISTRY_MEMBER_CONTRIBUTION_HTTP_ERROR: "MINISTRY_MEMBER_CONTRIBUTION_HTTP_ERROR",
  MINISTRY_MEMBER_CONTRIBUTION_HTTP_COMPLETE: "MINISTRY_MEMBER_CONTRIBUTION_HTTP_COMPLETE",
  SET_MINISTRY_MEMBER_CONTRIBUTION_DETAILS: "SET_MINISTRY_MEMBER_CONTRIBUTION_DETAILS",

  // ministry contributions
  MINISTRY_CONTRIBUTIONS_HTTP_LOADING: "MINISTRY_CONTRIBUTIONS_HTTP_LOADING",
  MINISTRY_CONTRIBUTIONS_HTTP_ERROR: "MINISTRY_CONTRIBUTIONS_HTTP_ERROR",
  MINISTRY_CONTRIBUTIONS_HTTP_COMPLETE: "MINISTRY_CONTRIBUTIONS_HTTP_COMPLETE",
  SET_MINISTRY_CONTRIBUTIONS_DETAILS: "SET_MINISTRY_CONTRIBUTIONS_DETAILS",

  // ministry kitties
  MINISTRY_KITTIES_HTTP_LOADING: "MINISTRY_KITTIES_HTTP_LOADING",
  MINISTRY_KITTIES_HTTP_ERROR: "MINISTRY_KITTIES_HTTP_ERROR",
  MINISTRY_KITTIES_HTTP_COMPLETE: "MINISTRY_KITTIES_HTTP_COMPLETE",
  SET_MINISTRY_KITTIES_DETAILS: "SET_MINISTRY_KITTIES_DETAILS",

  // ministry kitty
  MINISTRY_KITTY_HTTP_LOADING: "MINISTRY_KITTY_HTTP_LOADING",
  MINISTRY_KITTY_HTTP_ERROR: "MINISTRY_KITTY_HTTP_ERROR",
  MINISTRY_KITTY_HTTP_COMPLETE: "MINISTRY_KITTY_HTTP_COMPLETE",

  // ministry kitty linked contributions
  MINISTRY_KITTY_LINKED_CONTRIBUTIONS_HTTP_LOADING: "MINISTRY_KITTY_LINKED_CONTRIBUTIONS_HTTP_LOADING",
  MINISTRY_KITTY_LINKED_CONTRIBUTIONS_HTTP_ERROR: "MINISTRY_KITTY_LINKED_CONTRIBUTIONS_HTTP_ERROR",
  MINISTRY_KITTY_LINKED_CONTRIBUTIONS_HTTP_COMPLETE: "MINISTRY_KITTY_LINKED_CONTRIBUTIONS_HTTP_COMPLETE",
  SET_MINISTRY_KITTY_LINKED_CONTRIBUTIONS_DETAILS: "SET_MINISTRY_KITTY_LINKED_CONTRIBUTIONS_DETAILS",

  // ministry kitty linked contribution
  MINISTRY_KITTY_LINKED_CONTRIBUTION_HTTP_LOADING: "MINISTRY_KITTY_LINKED_CONTRIBUTION_HTTP_LOADING",
  MINISTRY_KITTY_LINKED_CONTRIBUTION_HTTP_ERROR: "MINISTRY_KITTY_LINKED_CONTRIBUTION_HTTP_ERROR",
  MINISTRY_KITTY_LINKED_CONTRIBUTION_HTTP_COMPLETE: "MINISTRY_KITTY_LINKED_CONTRIBUTION_HTTP_COMPLETE",

  // ministry kitty subtractions
  MINISTRY_KITTY_SUBTRACTIONS_HTTP_LOADING: "MINISTRY_KITTY_SUBTRACTIONS_HTTP_LOADING",
  MINISTRY_KITTY_SUBTRACTIONS_HTTP_ERROR: "MINISTRY_KITTY_SUBTRACTIONS_HTTP_ERROR",
  MINISTRY_KITTY_SUBTRACTIONS_HTTP_COMPLETE: "MINISTRY_KITTY_SUBTRACTIONS_HTTP_COMPLETE",
  SET_MINISTRY_KITTY_SUBTRACTIONS_DETAILS: "SET_MINISTRY_KITTY_SUBTRACTIONS_DETAILS",

  // ministry kitty subtraction
  MINISTRY_KITTY_SUBTRACTION_HTTP_LOADING: "MINISTRY_KITTY_SUBTRACTION_HTTP_LOADING",
  MINISTRY_KITTY_SUBTRACTION_HTTP_ERROR: "MINISTRY_KITTY_SUBTRACTION_HTTP_ERROR",
  MINISTRY_KITTY_SUBTRACTION_HTTP_COMPLETE: "MINISTRY_KITTY_SUBTRACTION_HTTP_COMPLETE",

  // ministry landingpage
  MINISTRY_LANDING_PAGE_HTTP_LOADING: "MINISTRY_LANDING_PAGE_HTTP_LOADING",
  MINISTRY_LANDING_PAGE_HTTP_ERROR: "MINISTRY_LANDING_PAGE_HTTP_ERROR",
  MINISTRY_LANDING_PAGE_HTTP_COMPLETE: "MINISTRY_LANDING_PAGE_HTTP_COMPLETE",
  SET_MINISTRY_LANDING_PAGE_DETAILS: "SET_MINISTRY_LANDING_PAGE_DETAILS",

  // ministry membership
  MINISTRY_MEMBERSHIP_HTTP_LOADING: "MINISTRY_MEMBERSHIP_HTTP_LOADING",
  MINISTRY_MEMBERSHIP_HTTP_ERROR: "MINISTRY_MEMBERSHIP_HTTP_ERROR",
  MINISTRY_MEMBERSHIP_HTTP_COMPLETE: "MINISTRY_MEMBERSHIP_HTTP_COMPLETE",
  SET_MINISTRY_MEMBERSHIP_DETAILS: "SET_MINISTRY_MEMBERSHIP_DETAILS",

  // profile
  PASSWORD_HTTP_LOADING: "PASSWORD_HTTP_LOADING",
  PASSWORD_HTTP_ERROR: "PASSWORD_HTTP_ERROR",
  PASSWORD_HTTP_COMPLETE: "PASSWORD_HTTP_COMPLETE",

  // profile
  PROFILE_HTTP_LOADING: "PROFILE_HTTP_LOADING",
  PROFILE_HTTP_ERROR: "PROFILE_HTTP_ERROR",
  PROFILE_HTTP_COMPLETE: "PROFILE_HTTP_COMPLETE",
  CHANGE_USER_PROFILE: "CHANGE_USER_PROFILE",

  // project
  PROJECT_HTTP_LOADING: "PROJECT_HTTP_LOADING",
  PROJECT_HTTP_ERROR: "PROJECT_HTTP_ERROR",
  PROJECT_HTTP_COMPLETE: "PROJECT_HTTP_COMPLETE",

  // projects
  PROJECTS_HTTP_LOADING: "PROJECTS_HTTP_LOADING",
  PROJECTS_HTTP_ERROR: "PROJECTS_HTTP_ERROR",
  PROJECTS_HTTP_COMPLETE: "PROJECTS_HTTP_COMPLETE",
  SET_PROJECTS_DETAILS: "SET_PROJECTS_DETAILS",

  //reset
  RESET_HTTP_LOADING: "RESET_HTTP_LOADING",
  RESET_HTTP_ERROR: "RESET_HTTP_ERROR",
  RESET_HTTP_COMPLETE: "RESET_HTTP_COMPLETE",
  SET_USER_HAS_VERIFIED_RESET_CODE: "SET_USER_HAS_VERIFIED_RESET_CODE",
  UNSET_USER_HAS_VERIFIED_RESET_CODE: "UNSET_USER_HAS_VERIFIED_RESET_CODE",
  SET_USER_HAS_RESET_PASSWORD: "SET_USER_HAS_RESET_PASSWORD",
  UNSET_USER_HAS_RESET_PASSWORD: "UNSET_USER_HAS_RESET_PASSWORD",

  // token
  SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",

  // user
  USER_HTTP_LOADING: "USER_HTTP_LOADING",
  USER_HTTP_ERROR: "USER_HTTP_ERROR",
  USER_HTTP_COMPLETE: "USER_HTTP_COMPLETE",

  // users
  USERS_HTTP_LOADING: "USERS_HTTP_LOADING",
  USERS_HTTP_ERROR: "USERS_HTTP_ERROR",
  USERS_HTTP_COMPLETE: "USERS_HTTP_COMPLETE",
  SET_USERS_DETAILS: "SET_USERS_DETAILS",

  // verse
  VERSE_HTTP_LOADING: "VERSE_HTTP_LOADING",
  VERSE_HTTP_ERROR: "VERSE_HTTP_ERROR",
  VERSE_HTTP_COMPLETE: "VERSE_HTTP_COMPLETE",
  SET_FULL_VERSE_DETAILS: "SET_FULL_VERSE_DETAILS",

  // verses
  VERSES_HTTP_LOADING: "VERSES_HTTP_LOADING",
  VERSES_HTTP_ERROR: "VERSES_HTTP_ERROR",
  VERSES_HTTP_COMPLETE: "VERSES_HTTP_COMPLETE",
  SET_VERSES_DETAILS: "SET_VERSES_DETAILS"
};

export default actions;
