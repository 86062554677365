import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, AttendanceInterface, AttendanceReducerState } from "../../../../interfaces";
import { createStyles, makeStyles} from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { CalendarToday, Create } from "@material-ui/icons";
import validator from "validator";
import { addAttendance, clearAttendanceError, updateAttendance } from "../../../../store/actions";
import dateformat from "dateformat";
import { Switch } from "../../../../components";

interface UpsertAttendanceDetailsFormProps {
  appTheme: AppTheme;
  attendance: AttendanceReducerState;
  attendanceData: AttendanceInterface | null;
  updateAttendance: (_id: string, title: string, date: string) => void;
  addAttendance: (title: string, date: string, requires_temp_check: boolean) => void;
  clearAttendanceError: () => void;
}

interface UpsertAttendanceDetailsModalProps {
  open: boolean;
  attendanceData: AttendanceInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      }
    })
  );

const UpsertAttendanceDetailsForm: React.FC<UpsertAttendanceDetailsFormProps> = (props) => {
  const { appTheme, attendance, attendanceData, addAttendance, updateAttendance, clearAttendanceError } = props;

  const [_id] = useState<string>(attendanceData ? attendanceData.attendance_id : "");
  const [title, setTitle] = useState<string>(attendanceData ? attendanceData.title : "");
  const [date, setDate] = useState<string>(attendanceData ? attendanceData.date : "");
  const [requiresTempCheck, setRequiresTempCheck] = useState<boolean>(attendanceData ? attendanceData.requires_temp_check ?? true : true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loading = attendance.loading;

  const styles = useStyles(appTheme)();

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "title":
        setTitle(value);
        break;
      case "date":
        setDate(value);
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (validator.isEmpty(title)) {
      setErrorMessage("The attendance title entered is not valid");
    } else if (validator.isEmpty(date)) {
      setErrorMessage("The attendance date entered is not valid");
    } else {
      setErrorMessage("");

      if (attendanceData) {
        updateAttendance(_id, title, date);
      } else {
        addAttendance(title, date, requiresTempCheck);
      }
    }
  };

  useEffect(() => {
    const requestData = attendance.error ? attendance : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [attendance]);

  useEffect(() => {
    return () => {
      clearAttendanceError();
    };
  }, [clearAttendanceError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            value={title}
            onChange={(event) => {
              textFieldValueChanged("title", event.target.value);
            }}
            placeholder="Title (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Create className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Date of Attendance (Required)
          </Typography>
          <InputBase
            required
            inputProps={{ max: dateformat(new Date(), "yyyy-mm-dd") }}
            type="date"
            defaultValue={date}
            onChange={(event) => {
              textFieldValueChanged("date", event.target.value);
            }}
            placeholder="Date of Attendance (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <CalendarToday className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        {!attendanceData && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Requires Tempreture Check
            </Typography>
            <Typography variant="body1" onClick={() => setRequiresTempCheck(!requiresTempCheck)}>
              <Switch checked={requiresTempCheck} />
            </Typography>
            <br />
          </Grid>
        )}
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} {props.attendanceData ? "Update Attendance Details" : "Add New Attendance"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    updateAttendance: (_id: string, title: string, date: string) => dispatch(updateAttendance(_id, title, date)),
    addAttendance: (title: string, date: string, requires_temp_check: boolean) => dispatch(addAttendance(title, date, requires_temp_check)),
    clearAttendanceError: () => dispatch(clearAttendanceError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    attendance: state.attendance,
    appTheme: state.general.theme
  };
};

const UpsertAttendanceDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(UpsertAttendanceDetailsForm);

const UpsertAttendanceDetailsModal: React.FC<UpsertAttendanceDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title={props.attendanceData ? "Update Attendance Details" : "Add New Attendance"}
      component={<UpsertAttendanceDetailsFormComponent attendanceData={props.attendanceData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UpsertAttendanceDetailsModal;
