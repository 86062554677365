import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";
import { ProfileReducerState } from "../../interfaces";

export const clearProfileError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.PROFILE_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export type ProfileUpdateArgs = Partial<
  Pick<ProfileReducerState, "name" | "img" | "email" | "mobile_no" | "dob" | "username" | "residence" | "gender">
>;

export const updateProfile = (args: ProfileUpdateArgs) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.PROFILE_HTTP_LOADING
    });

    request({
      method: "post",
      pathname: "updateProfile",
      data: { ...args }
    })
      .then((response) => {
        dispatch({
          type: actions.PROFILE_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });

        dispatch({
          type: actions.CHANGE_USER_PROFILE,
          payload: {
            ...response.data.content
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.PROFILE_HTTP_ERROR, error, dispatch);
      });
  };
};
