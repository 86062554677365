import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearMinistryMemberContributionError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_MEMBER_CONTRIBUTION_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const getMinistryMemberContribution = (_id: string, search: string, page: number, per_page: number) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_MEMBER_CONTRIBUTION_HTTP_LOADING
    });

    request({
      method: "get",
      pathname: "getMemberMinistryContribution",
      params: { _id, search, page, per_page }
    })
      .then((response) => {
        dispatch({
          type: actions.MINISTRY_MEMBER_CONTRIBUTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });

        dispatch({
          type: actions.SET_MINISTRY_MEMBER_CONTRIBUTION_DETAILS,
          payload: {
            ...response.data.content
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.MINISTRY_MEMBER_CONTRIBUTION_HTTP_ERROR, error, dispatch);
      });
  };
};

export const addMinistryMemberContribution = (
  _id: string,
  contributor_id: string,
  contributor_type: "Other" | "User",
  amount: number,
  date: string,
  transaction_details: string
) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_MEMBER_CONTRIBUTION_HTTP_LOADING
    });

    request({
      method: "post",
      pathname: "addMemberMinistryContribution",
      data: { _id, contributor_id, contributor_type, amount, date, transaction_details }
    })
      .then((response) => {
        dispatch({
          type: actions.MINISTRY_MEMBER_CONTRIBUTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.MINISTRY_MEMBER_CONTRIBUTION_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteMinistryMemberContribution = (_id: string, contribution_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_MEMBER_CONTRIBUTION_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteMemberMinistryContribution", data: { _id, contribution_id } })
      .then((response) => {
        dispatch({
          type: actions.MINISTRY_MEMBER_CONTRIBUTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.MINISTRY_MEMBER_CONTRIBUTION_HTTP_ERROR, error, dispatch);
      });
  };
};
