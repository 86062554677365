import React, { useState, useEffect } from "react";
import DialogModal from "../../../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, MinistryContributionReducerState, MinistryContributionInterface } from "../../../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { CalendarToday, Create, Edit } from "@material-ui/icons";
import validator from "validator";
import { addMinistryContribution, clearMinistryContributionError, updateMinistryContribution } from "../../../../../../store/actions";
import { useParams } from "react-router-dom";

interface UpsertMinistryContributionDetailsFormProps {
  appTheme: AppTheme;
  ministryContribution: MinistryContributionReducerState;
  ministryContributionData: MinistryContributionInterface | null;
  updateMinistryContribution: (
    contribution_id: string,
    ministry_id: string,
    title: string,
    instructions: string,
    from_date: string,
    to_date: string,
    target: number
  ) => void;
  addMinistryContribution: (ministry_id: string, title: string, instructions: string, from_date: string, to_date: string, target: number) => void;
  clearMinistryContributionError: () => void;
}

interface UpsertMinistryContributionDetailsModalProps {
  open: boolean;
  ministryContributionData: MinistryContributionInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      }
    })
  );

const UpsertMinistryContributionDetailsForm: React.FC<UpsertMinistryContributionDetailsFormProps> = (props) => {
  const {
    appTheme,
    ministryContribution,
    ministryContributionData,
    addMinistryContribution,
    updateMinistryContribution,
    clearMinistryContributionError
  } = props;

  const { ministry_id } = useParams<{ ministry_id: string; ministry_name: string }>();

  const [_id] = useState<string>(ministryContributionData ? ministryContributionData.contribution_id : "");
  const [title, setTitle] = useState<string>(ministryContributionData ? ministryContributionData.title : "");
  const [instructions, setInstructions] = useState<string>(ministryContributionData ? ministryContributionData.instructions : "");
  const [fromDate, setFromDate] = useState<string>(ministryContributionData ? ministryContributionData.from_date : "");
  const [toDate, setToDate] = useState<string>(ministryContributionData ? ministryContributionData.to_date : "");
  const [target, setTarget] = useState<string>(ministryContributionData ? ministryContributionData.target.toString() ?? "" : "");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loading = ministryContribution.loading;

  const styles = useStyles(appTheme)();

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "title":
        setTitle(value);
        break;
      case "instructions":
        setInstructions(value);
        break;
      case "fromDate":
        setFromDate(value);
        break;
      case "toDate":
        setToDate(value);
        break;
      case "target":
        setTarget(value);
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (validator.isEmpty(title)) {
      setErrorMessage("The ministryContribution title entered is not valid");
    } else if (validator.isEmpty(instructions)) {
      setErrorMessage("The ministryContribution instructions entered are not valid");
    } else if (instructions.length > 150) {
      setErrorMessage("The ministryContribution instructions entered cannot be more than 150 characters in length");
    } else if (validator.isEmpty(fromDate)) {
      setErrorMessage("The ministryContribution from date entered is not valid");
    } else if (validator.isEmpty(toDate)) {
      setErrorMessage("The ministryContribution to date entered is not valid");
    } else if (validator.isEmpty(target)) {
      setErrorMessage("The ministryContribution target entered is not valid");
    } else {
      const start_time = new Date(fromDate + " 00:00:00").getTime(),
        end_time = new Date(toDate + " 00:00:00").getTime();

      if (start_time > end_time) {
        setErrorMessage("Start ministryContribution cannot be greater than to ministryContribution date");
        return;
      }

      if (Number.isNaN(parseFloat(target))) {
        setErrorMessage("The ministryContribution target entered is not valid");
        return;
      }

      setErrorMessage("");

      if (ministryContributionData) {
        updateMinistryContribution(_id, ministry_id, title, instructions, fromDate, toDate, parseFloat(target));
      } else {
        addMinistryContribution(ministry_id, title, instructions, fromDate, toDate, parseFloat(target));
      }
    }
  };

  useEffect(() => {
    const requestData = ministryContribution.error ? ministryContribution : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [ministryContribution]);

  useEffect(() => {
    return () => {
      clearMinistryContributionError();
    };
  }, [clearMinistryContributionError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            value={title}
            onChange={(event) => {
              textFieldValueChanged("title", event.target.value);
            }}
            placeholder="Title (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Create className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            required
            multiline
            rows={4}
            type="text"
            value={instructions}
            onChange={(event) => {
              textFieldValueChanged("instructions", event.target.value);
            }}
            placeholder="Contribution Instrcutions (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Edit className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Contributions From (Required)
          </Typography>
          <InputBase
            required
            type="date"
            defaultValue={fromDate}
            onChange={(event) => {
              textFieldValueChanged("fromDate", event.target.value);
            }}
            placeholder="Contributions From (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <CalendarToday className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Contributions To (Required)
          </Typography>
          <InputBase
            required
            type="date"
            defaultValue={toDate}
            onChange={(event) => {
              textFieldValueChanged("toDate", event.target.value);
            }}
            placeholder="Contributions To (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <CalendarToday className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            required
            type="number"
            value={target}
            onChange={(event) => {
              textFieldValueChanged("target", event.target.value);
            }}
            placeholder="Target (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Create className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} {props.ministryContributionData ? "Update Contribution Details" : "Add New Contribution"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    updateMinistryContribution: (
      contribution_id: string,
      ministry_id: string,
      title: string,
      instructions: string,
      from_date: string,
      to_date: string,
      target: number
    ) => dispatch(updateMinistryContribution(contribution_id, ministry_id, title, instructions, from_date, to_date, target)),
    addMinistryContribution: (ministry_id: string, title: string, instructions: string, from_date: string, to_date: string, target: number) =>
      dispatch(addMinistryContribution(ministry_id, title, instructions, from_date, to_date, target)),
    clearMinistryContributionError: () => dispatch(clearMinistryContributionError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    ministryContribution: state.ministryContribution,
    appTheme: state.general.theme
  };
};

const UpsertMinistryContributionDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(UpsertMinistryContributionDetailsForm);

const UpsertMinistryContributionDetailsModal: React.FC<UpsertMinistryContributionDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title={props.ministryContributionData ? "Update Contribution Details" : "Add New Contribution"}
      component={<UpsertMinistryContributionDetailsFormComponent ministryContributionData={props.ministryContributionData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UpsertMinistryContributionDetailsModal;
