import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearContributionError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.CONTRIBUTION_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const addContribution = (title: string, instructions: string, from_date: string, to_date: string, target: number) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.CONTRIBUTION_HTTP_LOADING
    });

    request({ method: "post", pathname: "addContribution", data: { title, instructions, from_date, to_date, target } })
      .then((response) => {
        dispatch({
          type: actions.CONTRIBUTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.CONTRIBUTION_HTTP_ERROR, error, dispatch);
      });
  };
};

export const updateContribution = (_id: string, title: string, instructions: string, from_date: string, to_date: string, target: number) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.CONTRIBUTION_HTTP_LOADING
    });

    request({ method: "post", pathname: "updateContribution", data: { _id, title, instructions, from_date, to_date, target } })
      .then((response) => {
        dispatch({
          type: actions.CONTRIBUTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.CONTRIBUTION_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteContribution = (_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.CONTRIBUTION_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteContribution", data: { _id } })
      .then((response) => {
        dispatch({
          type: actions.CONTRIBUTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.CONTRIBUTION_HTTP_ERROR, error, dispatch);
      });
  };
};

export const getUserContribution = (_id: string, search: string, page: number, per_page: number) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.CONTRIBUTION_HTTP_LOADING
    });

    request({
      method: "get",
      pathname: "getUserContribution",
      params: { _id, search, page, per_page }
    })
      .then((response) => {
        dispatch({
          type: actions.CONTRIBUTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });

        dispatch({
          type: actions.SET_CONTRIBUTION_DETAILS,
          payload: {
            ...response.data.content
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.CONTRIBUTION_HTTP_ERROR, error, dispatch);
      });
  };
};

export const addUserContribution = (
  _id: string,
  contributor_id: string,
  contributor_type: "Other" | "User",
  amount: number,
  date: string,
  transaction_details: string
) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.CONTRIBUTION_HTTP_LOADING
    });

    request({
      method: "post",
      pathname: "addUserContribution",
      data: { _id, contributor_id, contributor_type, amount, date, transaction_details }
    })
      .then((response) => {
        dispatch({
          type: actions.CONTRIBUTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.CONTRIBUTION_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteUserContribution = (_id: string, contribution_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.CONTRIBUTION_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteUserContribution", data: { _id, contribution_id } })
      .then((response) => {
        dispatch({
          type: actions.CONTRIBUTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.CONTRIBUTION_HTTP_ERROR, error, dispatch);
      });
  };
};
