import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  AppState,
  AppTheme,
  ContributionsReducerState,
  KittyLinkedContributionReducerState,
  ContributionInterface,
  KittyInterface
} from "../../../../interfaces";
import { createStyles, makeStyles} from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { addKittyLinkedContribution, clearKittyLinkedContributionError, clearUserError, getContributions } from "../../../../store/actions";
import { useParams } from "react-router-dom";
import dateformat from "dateformat";

interface AddKittyLinkedContributionDetailsFormProps {
  appTheme: AppTheme;
  kittyLinkedContribution: KittyLinkedContributionReducerState;
  contributions: ContributionsReducerState;
  kittyData: KittyInterface | null;
  addKittyLinkedContribution: (id: string, Contribution_id: string) => void;
  clearKittyLinkedContributionError: () => void;
  clearUserError: () => void;
  getContributions: (id: string, search: string) => void;
}

interface AddKittyLinkedContributionDetailsModalProps {
  open: boolean;
  kittyData: KittyInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      },
      autocompleteItems: {
        position: "absolute",
        border: "1px solid #d4d4d4",
        borderBottom: "none",
        borderTop: "none",
        top: "100%",
        left: "0",
        right: "0",
        zIndex: 16000
      },
      autocompleteItem: {
        padding: "10px",
        cursor: "pointer",
        backgroundColor: "#fff",
        borderBottom: "1px solid #d4d4d4"
      },
      autocomplete: {
        position: "relative",
        display: "inline-block",
        width: "100%"
      }
    })
  );

const AddKittyLinkedContributionDetailsForm: React.FC<AddKittyLinkedContributionDetailsFormProps> = (props) => {
  const {
    appTheme,
    contributions,
    kittyData,
    kittyLinkedContribution,
    addKittyLinkedContribution,
    clearKittyLinkedContributionError,
    clearUserError,
    getContributions
  } = props;

  const { id } = useParams<{ id: string; name: string }>();

  const [contribution, setContribution] = useState<ContributionInterface | null>();
  const [contributionSearch, setContributionSearch] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loading = kittyLinkedContribution.loading;

  const styles = useStyles(appTheme)();

  const submitForm = () => {
    if (!contribution) {
      setErrorMessage("Please select a  contribution before procceding");
    } else {
      addKittyLinkedContribution(kittyData ? kittyData.kitty_id : "", contribution.contribution_id);
    }
  };

  useEffect(() => {
    const requestData = kittyLinkedContribution.error ? kittyLinkedContribution : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [kittyLinkedContribution]);

  useEffect(() => {
    getContributions(id, "");
    return () => {
      clearKittyLinkedContributionError();
      clearUserError();
    };
  }, [id, getContributions, clearKittyLinkedContributionError, clearUserError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <div className={styles.autocomplete}>
            <InputBase
              required
              type="text"
              value={contributionSearch}
              onChange={(event) => {
                const value = event.target.value;
                if (contribution?.title !== value) {
                  if (value.length > 0) {
                    getContributions(id, value);
                  }

                  setContribution(null);

                  setContributionSearch(value);
                }
              }}
              placeholder="Contribution (Required)"
              className={styles.textField}
              startAdornment={
                <InputAdornment position="start">
                  <Person className={styles.textFieldIcon} />
                </InputAdornment>
              }
            />

            {!contribution && contributionSearch.length > 0 && (
              <div className={styles.autocompleteItems}>
                {contributions.data.map((contribution: ContributionInterface) => (
                  <div
                    key={contribution?.contribution_id}
                    className={styles.autocompleteItem}
                    onClick={() => {
                      setContribution(contribution);
                      setContributionSearch(contribution.title);
                    }}>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="body1">{"Title: " + contribution.title}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {"Target: " + new Intl.NumberFormat("en-KE", { style: "currency", currency: "KES" }).format(contribution.target)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{"From Date: " + dateformat(contribution.from_date, "ddd mmm dd yyyy")}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{"To Date: " + dateformat(contribution.to_date, "ddd mmm dd yyyy")}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </div>
            )}
          </div>
          {!contribution && contributionSearch.length > 0 && <div style={{ height: contributions.data.length * 100 }}></div>}
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} Add New Kitty Linked Contribution
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    addKittyLinkedContribution: (id: string, contribution_id: string) => dispatch(addKittyLinkedContribution(id, contribution_id)),
    clearKittyLinkedContributionError: () => dispatch(clearKittyLinkedContributionError()),
    clearUserError: () => dispatch(clearUserError()),
    getContributions: (search: string) => dispatch(getContributions(search, 0, 10))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    kittyLinkedContribution: state.kittyLinkedContribution,
    contributions: state.contributions,
    appTheme: state.general.theme
  };
};

const AddKittyLinkedContributionDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(AddKittyLinkedContributionDetailsForm);

const AddKittyLinkedContributionDetailsModal: React.FC<AddKittyLinkedContributionDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title="Add New  Kitty Linked Contribution"
      component={<AddKittyLinkedContributionDetailsFormComponent kittyData={props.kittyData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default AddKittyLinkedContributionDetailsModal;
