import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, NotificationReducerState, UsersReducerState } from "../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Paper,
  Typography,
  CircularProgress,
  Step,
  StepButton,
  Stepper,
  Button,
  InputBase,
  InputAdornment
} from "@material-ui/core";
import { DialogModal, ErrorView, TransferList } from "../../../components";
import { clearUserError, getUsers, sendNotification, clearNotificationError } from "../../../store/actions";
import { ArrayValue } from "../../../components/TransferList";
import { Edit } from "@material-ui/icons";
import validator from "validator";

interface VersesProps {
  users: UsersReducerState;
  notification: NotificationReducerState;
  appTheme: AppTheme;
  getUsers: () => void;
  sendNotification: (subject: string, message: string, user_ids: Array<string>) => void;
  clearUserError: () => void;
  clearNotificationError: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 15,
        marginTop: 10,
        marginBottom: 10
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      }
    })
  );

const Verses: React.FC<VersesProps> = (props) => {
  const { appTheme, users, notification, getUsers, sendNotification, clearUserError, clearNotificationError } = props;

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [step, setStep] = useState<number>(0);
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [members, setMembers] = useState<ArrayValue>([]);
  const [guests, setGuests] = useState<ArrayValue>([]);
  const [selectedMembers, setSelectedMembers] = useState<ArrayValue>([]);
  const [selectedGuests, setSelectedGuests] = useState<ArrayValue>([]);

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  useEffect(() => {
    setMembers((users.data as unknown[] as ArrayValue).filter((user) => user.user_type === "Member"));
    setGuests((users.data as unknown[] as ArrayValue).filter((user) => user.user_type === "Guest"));
  }, [users]);

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getUsers();
    return () => {
      clearUserError();
      clearNotificationError();
    };
  }, [getUsers, clearUserError, clearNotificationError]);

  useEffect(() => {
    if (!notification.error && notification.response) {
      clearNotificationError();

      setDialogOptions({ open: true, body: notification.response.data.status });

      setStep(0);

      setSelectedGuests([]);

      setSelectedMembers([]);

      setSubject("");

      setMessage("");

      getUsers();
    }
  }, [
    notification.error,
    notification.response,
    clearNotificationError,
    setStep,
    setSelectedGuests,
    setSelectedMembers,
    getUsers,
    setSubject,
    setMessage
  ]);

  useEffect(() => {
    const requestData = notification.error ? notification : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [notification]);

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "subject":
        setSubject(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const submitForm = (): void => {
    let errorMessage: string = "";

    if (validator.isEmpty(subject)) {
      errorMessage = "The notification subject entered is not valid";
    } else if (validator.isEmpty(message)) {
      errorMessage = "The notification message entered are not valid";
    } else if (message.length > 350) {
      errorMessage = "The notification message entered cannot be more than 350 characters in length";
    } else {
    }

    if (errorMessage.length > 0) {
      setDialogOptions({
        open: true,
        body: errorMessage
      });
      return;
    }

    sendNotification(
      subject,
      message,
      [...selectedMembers, ...selectedGuests].map((user) => user["user_id"] as string)
    );
  };

  let loadingIndicator = null;

  if (notification.loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <Container maxWidth="md">
        {users.error ? (
          <ErrorView error={users.error} onRetry={() => getUsers()} />
        ) : (
          <React.Fragment>
            <div>
              <Paper className={styles.paper}>
                <Grid container justifyContent="space-between" spacing={0}>
                  <Grid item>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        {(users.loading || notification.loading) && <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />}
                      </Grid>
                      <Grid item>
                        <Typography variant="h3" color="primary">
                          Notifications
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button
                          disabled={notification.loading || step === 0}
                          variant="outlined"
                          size="large"
                          onClick={() => {
                            setStep(step - 1);
                          }}>
                          Previous
                        </Button>
                      </Grid>
                      <Grid item>
                        {step !== 2 && (
                          <Button
                            disabled={notification.loading}
                            variant="outlined"
                            size="large"
                            onClick={() => {
                              if (step === 1 && selectedMembers.length === 0 && selectedGuests.length === 0) {
                                setDialogOptions({
                                  open: true,
                                  body: "Please select atleast one guest or member to send the notification to before proceeding"
                                });
                                return;
                              }

                              setStep(step + 1);
                            }}>
                            {step === 2 ? "Send Notification" : "Next"}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Stepper alternativeLabel nonLinear activeStep={step}>
                  {["Select Members", "Select Guests", "Compose Message"].map((stepLabel: string, stepIndex: number) => {
                    return (
                      <Step key={stepLabel}>
                        <StepButton completed={stepIndex < step}>{stepLabel}</StepButton>
                      </Step>
                    );
                  })}
                </Stepper>
                {!users.loading && step === 2 && (
                  <Container maxWidth="sm" style={{ margin: "auto" }}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={12}>
                        <InputBase
                          required
                          type="text"
                          value={subject}
                          onChange={(event) => {
                            textFieldValueChanged("subject", event.target.value);
                          }}
                          placeholder="Subject (Required)"
                          className={styles.textField}
                          startAdornment={
                            <InputAdornment position="start">
                              <Edit className={styles.textFieldIcon} />
                            </InputAdornment>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputBase
                          required
                          multiline
                          rows={5}
                          type="text"
                          value={message}
                          onChange={(event) => {
                            textFieldValueChanged("message", event.target.value);
                          }}
                          placeholder="Message (Required)"
                          className={styles.textField}
                          startAdornment={
                            <InputAdornment position="start">
                              <Edit className={styles.textFieldIcon} />
                            </InputAdornment>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          disabled={notification.loading}
                          size="large"
                          variant="contained"
                          color="primary"
                          onClick={() => submitForm()}>
                          {loadingIndicator} Send Notification
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                )}
              </Paper>
              {!users.loading && step === 0 && (
                <TransferList
                  id="user_id"
                  display="name"
                  width={360}
                  height={"45vh"}
                  leftTitle="Members"
                  rightTitle="Selected"
                  left={members}
                  right={selectedMembers}
                  setLeft={setMembers}
                  setRight={setSelectedMembers}
                />
              )}
              {!users.loading && step === 1 && (
                <TransferList
                  id="user_id"
                  display="name"
                  width={360}
                  height={"45vh"}
                  leftTitle="Guests"
                  rightTitle="Selected"
                  left={guests}
                  right={selectedGuests}
                  setLeft={setGuests}
                  setRight={setSelectedGuests}
                />
              )}
            </div>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getUsers: () => dispatch(getUsers("", 0, 10000)),
    sendNotification: (subject: string, message: string, user_ids: Array<string>) => dispatch(sendNotification(subject, message, user_ids)),
    clearUserError: () => dispatch(clearUserError()),
    clearNotificationError: () => dispatch(clearNotificationError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    users: state.users,
    notification: state.notification,
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Verses);
