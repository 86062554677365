import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { alpha, Container, Grid, Typography } from "@material-ui/core";
import churchImg from "../../../assets/church.png";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        backgroundColor: "#fff",
        paddingTop: 60,
        paddingBottom: 60
      },
      title: {
        color: alpha(theme.palette.common.black, 0.1),
        fontSize: "4.75rem",
        fontWeight: 700,
        width: "100%"
      },
      subTitle: {
        color: theme.palette.common.black,
        fontSize: "1.75rem",
        width: "100%"
      },
      text: {
        color: alpha(theme.palette.common.black, 0.5),
        wordWrap: "break-word",
        fontSize: 18,
        display: "block"
      },
      img: {
        width: "100%",
        height: 400,
        objectFit: "contain",
        [theme.breakpoints.down("sm")]: {
          height: 250,
          objectFit: "contain"
        }
      }
    })
  );

const About: React.FC = () => {
  const styles = useStyles()();

  return (
    <div className={styles.root}>
      <Container maxWidth="md">
        <Grid container alignContent="space-between" spacing={5}>
          <Grid item xs={12} sm={12} md={6}>
            <img src={churchImg} className={styles.img} alt="PEFA Church Gimu" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={styles.title}>ABOUT</Typography>
                <Typography variant="h2" className={styles.subTitle} align="center">
                  Welcome To PEFA Church Gimu
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={styles.text} align="justify">
                  Welcome to PEFA Church Gimu - Athi River where we touch families to impact their world. Our focus: Fellowship, Worship, Evangelism
                  and Ministry. A place where you can glow and grow in the word of God.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default About;
