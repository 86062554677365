import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  AppState,
  AppTheme,
  ProfileReducerState,
  MinistryContributionInterface,
  MinistryContributionsReducerState,
  MinistryContributionReducerState
} from "../../../../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Container,
  Paper,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { DialogModal, ErrorView } from "../../../../../components";
import { clearMinistryContributionError, getMinistryContributions, deleteMinistryContribution } from "../../../../../store/actions";
import { checkUserAccess } from "../../../../../util/permission-checker";
import PopupMenuDialog from "../../../../../components/PopupMenuDialog";
import dateformat from "dateformat";
import UpsertMinistryContributionDetailsModal from "./Modals/UpsertMinistryContributionDetailsDialog";
import MinistryMemberContributionsDialog from "./Modals/MinistryMemberContributionsDialog";
import { Link, useParams } from "react-router-dom";

interface MinistryContributionsProps {
  ministryContributions: MinistryContributionsReducerState;
  ministryContribution: MinistryContributionReducerState;
  profile: ProfileReducerState;
  appTheme: AppTheme;
  getMinistryContributions: (ministry_id: string, search: string, page: number, per_page: number) => void;
  clearMinistryContributionError: () => void;
  deleteMinistryContribution: (_id: string) => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 30,
        marginTop: 20,
        marginBottom: 20
      },
      icon: {
        color: theme.palette.text.primary,
        padding: 10,
        borderRadius: 30,
        backgroundColor: appTheme.iconBackgroundColor,
        "&:hover": {
          backgroundColor: appTheme.iconBackgroundHoverColor
        }
      },
      searchButton: {
        marginRight: "-21px",
        height: "55px",
        borderRadius: "30px",
        width: "100px"
      },
      textInput: {
        marginTop: "5px",
        width: "300px"
      },
      textInputControl: {
        borderRadius: "30px",
        backgroundColor: appTheme.section.backgroundColor
      },
      container: {
        minHeight: "60vh"
      },
      textInputIcon: {
        color: "#000"
      }
    })
  );

const MinistryContributions: React.FC<MinistryContributionsProps> = (props) => {
  const {
    appTheme,
    ministryContributions,
    ministryContribution,
    profile,
    getMinistryContributions,
    deleteMinistryContribution,
    clearMinistryContributionError
  } = props;

  const { ministry_id, ministry_name } = useParams<{ ministry_id: string; ministry_name: string }>();

  const [search, setSearch] = useState<string>(ministryContributions.search);
  const [page, setPage] = useState<number>(ministryContributions.page);
  const [perPage, setPerPage] = useState<number>(ministryContributions.per_page ?? 20);
  const [selectedRecord, setSelectedRecord] = useState<MinistryContributionInterface | null>(null);
  const [popUpDialogOpen, setPopUpDialogOpen] = useState<boolean>(false);

  const [upsertMinistryContributionDetailsModelOpen, setUpsertMinistryContributionDetailsModelOpen] = useState<boolean>(false);
  const [ministryMemberContributionModelOpen, setMinistryMemberContributionModelOpen] = useState<boolean>(false);

  const popUpMenuItems = useMemo(() => {
    return [
      { text: "View Member Contributions", permission: "view-ministry-" + ministry_id + "-member-contributions" },
      { text: "Update Contribution Details", permission: "edit-ministry-" + ministry_id + "-contributions" },
      { text: "Delete Contribution", permission: "delete-ministry-" + ministry_id + "-contributions" }
    ];
  }, [ministry_id]);
  const [menuItems, setMenuItems] = useState<Array<string>>(
    popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text)
  );

  useEffect(() => {
    setMenuItems(popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text));
  }, [popUpMenuItems, profile.permissions]);

  useEffect(() => {
    getMinistryContributions(ministry_id, search, page, perPage);
  }, [ministry_id, search, page, perPage, getMinistryContributions]);

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearMinistryContributionError();
    };
  }, [clearMinistryContributionError]);

  useEffect(() => {
    if (ministryMemberContributionModelOpen) {
      return;
    }

    if (!ministryContribution.error && ministryContribution.response) {
      if (ministryContribution.response.data.status === "Ok") {
        clearMinistryContributionError();
        return;
      }
      clearMinistryContributionError();

      setSelectedRecord(null);

      setUpsertMinistryContributionDetailsModelOpen(false);

      setMinistryMemberContributionModelOpen(false);

      setDialogOptions({ open: true, body: ministryContribution.response.data.status });

      getMinistryContributions(ministry_id, "", 0, perPage);
    }
  }, [
    ministry_id,
    ministryMemberContributionModelOpen,
    ministryContribution.error,
    ministryContribution.response,
    perPage,
    getMinistryContributions,
    clearMinistryContributionError
  ]);

  useEffect(() => {
    if (upsertMinistryContributionDetailsModelOpen || ministryMemberContributionModelOpen) {
      return;
    }

    const requestData = ministryContribution.error ? ministryContribution : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [ministryContribution, ministryMemberContributionModelOpen, upsertMinistryContributionDetailsModelOpen]);

  const onMenuItemSelected = (menuItem: string) => {
    setPopUpDialogOpen(false);

    if (!selectedRecord) {
      return;
    }

    switch (menuItem) {
      case "View Member Contributions":
        setMinistryMemberContributionModelOpen(true);
        break;
      case "Update Contribution Details":
        setUpsertMinistryContributionDetailsModelOpen(true);
        break;
      case "Delete Contribution":
        if (window.confirm("Would you like to delete this contribution ?")) {
          deleteMinistryContribution(selectedRecord.contribution_id);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <UpsertMinistryContributionDetailsModal
        open={upsertMinistryContributionDetailsModelOpen}
        onDismissed={() => setUpsertMinistryContributionDetailsModelOpen(false)}
        ministryContributionData={selectedRecord}
      />
      <MinistryMemberContributionsDialog
        open={ministryMemberContributionModelOpen}
        onDismissed={() => {
          setMinistryMemberContributionModelOpen(false);
          getMinistryContributions(ministry_id, search, page, perPage);
        }}
        ministryContributionData={selectedRecord}
      />
      <PopupMenuDialog
        open={popUpDialogOpen}
        onDismissed={() => setPopUpDialogOpen(false)}
        onMenuItemSelected={onMenuItemSelected}
        menuItems={menuItems}
      />
      <Container maxWidth="lg">
        {ministryContributions.error ? (
          <ErrorView error={ministryContributions.error} onRetry={() => getMinistryContributions(ministry_id, search, page, perPage)} />
        ) : (
          <React.Fragment>
            <Paper className={styles.paper}>
              <Grid container justifyContent="space-between" spacing={0}>
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      {(ministryContributions.loading || ministryContribution.loading) && (
                        <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography variant="h3" color="primary">
                        Contributions
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <TextField
                        onChange={(event) => {
                          setSearch(event.currentTarget.value);
                        }}
                        error={false}
                        value={search}
                        variant="outlined"
                        placeholder="Search ..."
                        className={styles.textInput}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search className={styles.textInputIcon} />
                            </InputAdornment>
                          ),
                          classes: { root: styles.textInputControl }
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        style={{ marginTop: 10 }}
                        component={Link}
                        to={`/dashboard/ministries/${ministry_id}/${ministry_name}`}
                        variant="outlined"
                        size="large">
                        Go Back
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <TableContainer className={styles.container}>
                <Table stickyHeader aria-label="ministry-contributions">
                  <TableHead>
                    <TableRow>
                      {[
                        { label: "ID", minWidth: 100 },
                        { label: "Title", minWidth: 250 },
                        { label: "Target", minWidth: 100 },
                        { label: "Contributions", minWidth: 100 },
                        { label: "Contribution Instructions", minWidth: 250 },
                        { label: "From Date", minWidth: 200 },
                        { label: "To Date", minWidth: 200 },
                        { label: "Options", minWidth: 100 }
                      ].map((column) => (
                        <TableCell key={column.label} align="center" style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ministryContributions.data.map((contribution: MinistryContributionInterface) => {
                      const _id = contribution.contribution_id;
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={_id}>
                          {["contribution_id", "title", "target", "contributions", "instructions", "from_date", "to_date", "options"].map(
                            (column) => {
                              const key = `${_id}-${column}`;

                              const data = (contribution as unknown as { [key: string]: any })[column];
                              if (column === "options") {
                                if (menuItems.length === 0) return <TableCell key={key} align="center"></TableCell>;
                                return (
                                  <TableCell key={key} align="center">
                                    <Button
                                      variant="outlined"
                                      disabled={menuItems.length === 0}
                                      onClick={() => {
                                        setSelectedRecord(contribution);
                                        setPopUpDialogOpen(true);
                                      }}>
                                      Options
                                    </Button>
                                  </TableCell>
                                );
                              } else if (column === "from_date" || column === "to_date") {
                                return (
                                  <TableCell key={key} align="center">
                                    {dateformat(data, "ddd mmm dd yyyy")}
                                  </TableCell>
                                );
                              } else if (column === "target" || column === "contributions") {
                                return (
                                  <TableCell key={key} align="center">
                                    {new Intl.NumberFormat("en-KE", { style: "currency", currency: "KES" }).format(parseFloat(data))}
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell key={key} align="center">
                                    {data}
                                  </TableCell>
                                );
                              }
                            }
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container justifyContent="space-between" style={{ margin: "0px 10px 0px 10px" }}>
                <Grid item>
                  <Button
                    disabled={!checkUserAccess("add-ministry-" + ministry_id + "-contributions", profile.permissions)}
                    variant="outlined"
                    size="large"
                    onClick={() => {
                      setSelectedRecord(null);
                      setUpsertMinistryContributionDetailsModelOpen(true);
                    }}>
                    Add New Contributuion
                  </Button>
                </Grid>
                <Grid item>
                  <TablePagination
                    component="div"
                    count={ministryContributions.total}
                    page={page}
                    onPageChange={(_, page) => setPage(page)}
                    rowsPerPage={perPage}
                    rowsPerPageOptions={[20, 50, 100, 1000]}
                    onRowsPerPageChange={(ministryContribution) => {
                      setPerPage(parseInt(ministryContribution.target.value));
                      setPage(0);
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getMinistryContributions: (ministry_id: string, search: string, page: number, per_page: number) =>
      dispatch(getMinistryContributions(ministry_id, search, page, per_page)),
    clearMinistryContributionError: () => dispatch(clearMinistryContributionError()),
    deleteMinistryContribution: (_id: string) => dispatch(deleteMinistryContribution(_id))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    ministryContributions: state.ministryContributions,
    ministryContribution: state.ministryContribution,
    profile: state.profile,
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MinistryContributions);
