import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, ProjectInterface, ProjectReducerState, FileReducerState } from "../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress, Select } from "@material-ui/core";
import { Create } from "@material-ui/icons";
import validator from "validator";
import { addProject, clearProjectError, clearFileError, updateProject, uploadFiles } from "../../../../store/actions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { generateFileName } from "../../../../util/generate-file-name";
import { v4 as uuidv4 } from "uuid";
import request from "../../../../util/request";
import axios from "axios";

interface UpsertProjectDetailsFormProps {
  appTheme: AppTheme;
  project: ProjectReducerState;
  projectData: ProjectInterface | null;
  file: FileReducerState;
  updateProject: (_id: string, title: string, status: string, content: string, cover_img: string) => void;
  addProject: (title: string, status: string, content: string, cover_img: string) => void;
  uploadFiles: (filePaths: Array<string>, files: FileList) => void;
  clearProjectError: () => void;
  clearFileError: () => void;
}

interface UpsertProjectDetailsModalProps {
  open: boolean;
  projectData: ProjectInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      }
    })
  );

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote", "alignment"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"]
  ]
};

const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image"];

const UpsertProjectDetailsForm: React.FC<UpsertProjectDetailsFormProps> = (props) => {
  const { appTheme, project, file, projectData, addProject, updateProject, clearProjectError, clearFileError, uploadFiles } = props;

  const [_id] = useState<string>(projectData ? projectData.project_id : "");
  const [title, setTitle] = useState<string>(projectData ? projectData.title : "");
  const [status, setStatus] = useState<string>(projectData ? projectData.status : "pending");
  const [content, setContent] = useState<string>(projectData ? projectData.content : "");
  const [coverImg, setCoverImg] = useState<string>(projectData ? projectData.cover_img : "");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [filesUploaded, setFilesUploaded] = useState<Array<string>>([]);
  const [uploadingContentImages, setUploadingContentImages] = useState<boolean>(false);

  const loading = project.loading || file.loading || uploadingContentImages;

  const styles = useStyles(appTheme)();

  useEffect(() => {
    if (file.response && filesUploaded.length > 0) {
      setCoverImg(`https://cdn.pefachurchgimu.org/${filesUploaded[0]}`);

      clearFileError();

      setFilesUploaded([]);
    }
  }, [file.response, filesUploaded, clearFileError]);

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "title":
        setTitle(value);
        break;
      case "status":
        setStatus(value);
        break;
      case "content":
        setContent(value);
        break;
      case "coverImg":
        setCoverImg(value);
        break;
      default:
        break;
    }
  };

  const submitForm = async () => {
    try {
      if (validator.isEmpty(title)) {
        setErrorMessage("The project title entered is not valid");
      } else if (validator.isEmpty(status)) {
        setErrorMessage("The project status selected is not valid");
      } else if (validator.isEmpty(content)) {
        setErrorMessage("The project content entered is not valid");
      } else if (validator.isEmpty(coverImg)) {
        setErrorMessage("The project cover image has not been uploaded");
      } else {
        setErrorMessage("");

        const images = content.split(`<img src="data:`);

        let parsedContent: string = content;

        for (let i = 0; i < images.length; i++) {
          if (i === 0) {
            continue;
          }
          const [fileType, fileData] = images[i].split('"').shift()?.split(";") ?? ["", ""];

          const url = await uploadImage(fileType, fileData.split(",")?.pop() ?? "");
          if (!(url instanceof Error)) parsedContent = parsedContent.replace(`<img src="data:${fileType};${fileData}`, `<img src="${url}`);
        }

        if (projectData) {
          updateProject(_id, title, status, parsedContent, coverImg);
        } else {
          addProject(title, status, parsedContent, coverImg);
        }
      }
    } catch (error) {
      setErrorMessage("An error occurred while trying to upload images. Error: " + (error as Error).message);
    }
  };

  const uploadImage = async (fileType: string, fileData: string): Promise<string | Error> => {
    try {
      setUploadingContentImages(true);
      const ext = fileType.split("/").pop() ?? "png";
      const filePath = generateFileName("projects", "content-image/" + uuidv4(), ext);
      const file = b64toBlob(fileData, "image/png");
      const result = await request({ method: "post", pathname: "getS3UploadUrl", data: { filePath, fileType } });
      await axios.put(result.data.content.url, file, { headers: { "Content-Type": fileType } });
      return `https://cdn.pefachurchgimu.org/${filePath}`;
    } catch (error) {
      return error as Error;
    } finally {
      setUploadingContentImages(false);
    }
  };

  const b64toBlob = (b64Data: string, contentType: string = "", sliceSize = 512): Blob => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  useEffect(() => {
    const requestData = file.error ? file : project.error ? project : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [file, project]);

  useEffect(() => {
    return () => {
      clearProjectError();
      clearFileError();
    };
  }, [clearProjectError, clearFileError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            value={title}
            onChange={(event) => {
              textFieldValueChanged("title", event.target.value);
            }}
            placeholder="Title (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Create className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Status (Required)
          </Typography>
          <Select
            native
            className={styles.textField}
            value={status}
            onChange={(event) => {
              textFieldValueChanged("status", event.target.value as string);
            }}>
            <option value="pending">Pending Project</option>
            <option value="ongoing">Project Ongoing</option>
            <option value="complete">Project Completed</option>
          </Select>
        </Grid>
        <Grid item xs={12}>
          {coverImg ? (
            <img className={styles.img} alt={title} src={coverImg} />
          ) : (
            <Typography variant="body1" align="center" color="error">
              No Cover Image Uploaded Yet
            </Typography>
          )}
          <br />
          <Button disabled={loading} fullWidth color="primary" size="large" component="label">
            <input
              onChange={(event) => {
                if (event.target.files && event.target.files.length > 0) {
                  const filesPaths = [generateFileName("projects", "project-" + uuidv4(), event.target.files[0].type)];
                  setFilesUploaded(filesPaths);
                  uploadFiles(filesPaths, event.target.files);
                }
              }}
              type="file"
              hidden
              accept="image/*"
            />
            {loadingIndicator} {coverImg ? "Replace Project Cover Image" : "Upload Project Cover Image"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <ReactQuill
            readOnly={loading}
            onChange={(text) => textFieldValueChanged("content", text)}
            value={content}
            theme="snow"
            modules={modules}
            formats={formats}></ReactQuill>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} {props.projectData ? "Update Project Details" : "Add New Project"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    updateProject: (_id: string, title: string, status: string, content: string, cover_img: string) =>
      dispatch(updateProject(_id, title, status, content, cover_img)),
    addProject: (title: string, status: string, content: string, cover_img: string) => dispatch(addProject(title, status, content, cover_img)),
    clearProjectError: () => dispatch(clearProjectError()),
    uploadFiles: (filePaths: Array<string>, files: FileList) => dispatch(uploadFiles(filePaths, files)),
    clearFileError: () => dispatch(clearFileError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    file: state.file,
    project: state.project,
    appTheme: state.general.theme
  };
};

const UpsertProjectDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(UpsertProjectDetailsForm);

const UpsertProjectDetailsModal: React.FC<UpsertProjectDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="lg"
      title={props.projectData ? "Update Project Details" : "Add New Project"}
      component={<UpsertProjectDetailsFormComponent projectData={props.projectData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UpsertProjectDetailsModal;
