import React from "react";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, List, Card, CardHeader, ListItem, ListItemText, ListItemIcon, Checkbox, Button, Divider } from "@material-ui/core";
import { AppState, AppTheme } from "../interfaces";

type Value = {
  [key: string]: unknown;
};

export type ArrayValue = Array<Value>;

interface TransferListProps {
  appTheme: AppTheme;
  id: string;
  width: number | string;
  height: number | string;
  display: string;
  leftTitle: string;
  rightTitle: string;
  left: ArrayValue;
  right: ArrayValue;
  setLeft: (left: ArrayValue) => void;
  setRight: (left: ArrayValue) => void;
}

const mapToIndex = (arrayValue: ArrayValue, value: Value, id: string): number => {
  for (let i = 0; i < arrayValue.length; i++) {
    if (arrayValue[i][id] === value[id]) return i;
  }
  return -1;
};

const not = (a: ArrayValue, b: ArrayValue, id: string): ArrayValue => {
  return a.filter((value: Value) => mapToIndex(b, value, id) === -1);
};

const intersection = (a: ArrayValue, b: ArrayValue, id: string): ArrayValue => {
  return a.filter((value: Value) => mapToIndex(b, value, id) !== -1);
};

const union = (a: ArrayValue, b: ArrayValue, id: string): ArrayValue => {
  return [...a, ...not(b, a, id)];
};

const useStyles = ( width: number | string, height: number | string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        margin: "auto"
      },
      cardHeader: {
        padding: theme.spacing(1, 2)
      },
      list: {
        width: width,
        height: height,
        backgroundColor: theme.palette.background.paper,
        overflow: "auto"
      },
      button: {
        margin: theme.spacing(0.5, 0)
      }
    })
  );

const TransferList: React.FC<TransferListProps> = (props) => {
  const {  id, display, width, height, leftTitle, rightTitle, left, right, setLeft, setRight } = props;

  const styles = useStyles(width, height)();

  const [checked, setChecked] = React.useState<ArrayValue>([]);
  const leftChecked = intersection(checked, left, id);
  const rightChecked = intersection(checked, right, id);

  const handleToggle = (value: Value): void => {
    const currentIndex = mapToIndex(checked, value, id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: ArrayValue): number => intersection(checked, items, id).length;

  const handleToggleAll = (items: ArrayValue): void => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items, id));
    } else {
      setChecked(union(checked, items, id));
    }
  };

  const handleCheckedRight = (): void => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked, id));
    setChecked(not(checked, leftChecked, id));
  };

  const handleCheckedLeft = (): void => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked, id));
    setChecked(not(checked, rightChecked, id));
  };

  const ChoiceList: React.FC<{ title: string; items: ArrayValue }> = ({ title, items }) => (
    <Card>
      <CardHeader
        className={styles.cardHeader}
        avatar={
          <Checkbox
            color="primary"
            onClick={() => handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={styles.list} dense component="div" role="list">
        {items.map((value: Value, index: number) => {
          const labelId = `transfer-list-all-item-${index}-label`;
          return (
            <ListItem key={index} role="listitem" button onClick={() => handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  color="primary"
                  checked={mapToIndex(checked, value, id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value[display] as string} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" className={styles.root}>
      <Grid item>
        <ChoiceList title={leftTitle} items={left} />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={styles.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right">
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={styles.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left">
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <ChoiceList title={rightTitle} items={right} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps)(TransferList);
