import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  AppState,
  AppTheme,
  ProfileReducerState,
  UserAttendanceInterface,
  AttendanceInterface,
  AttendanceReducerState
} from "../../../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Container,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment,
  Avatar
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { DialogModal, ErrorView } from "../../../../components";
import { clearAttendanceError, getUserAttendance, deleteUserAttendance } from "../../../../store/actions";
import { checkUserAccess } from "../../../../util/permission-checker";
import PopupMenuDialog from "../../../../components/PopupMenuDialog";
import AddUserAttendanceDetailsModal from "./AddUserAttendanceDetailsModal";
import userImg from "../../../../assets/user-img.png";

interface UserAttendancesProps {
  attendance: AttendanceReducerState;
  attendanceData: AttendanceInterface | null;
  profile: ProfileReducerState;
  appTheme: AppTheme;
  getUserAttendance: (_id: string, search: string, page: number, per_page: number) => void;
  clearAttendanceError: () => void;
  deleteUserAttendance: (_id: string, user_id: string) => void;
}

interface UserAttendanceModalProps {
  open: boolean;
  attendanceData: AttendanceInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      avatar: {
        height: 80,
        width: 80,
        display: "block",
        margin: "auto",
        border: "1px solid black"
      },
      icon: {
        color: theme.palette.text.primary,
        padding: 10,
        borderRadius: 30,
        backgroundColor: appTheme.iconBackgroundColor,
        "&:hover": {
          backgroundColor: appTheme.iconBackgroundHoverColor
        }
      },
      searchButton: {
        marginRight: "-21px",
        height: "55px",
        borderRadius: "30px",
        width: "100px"
      },
      textInput: {
        marginTop: "5px",
        width: "300px"
      },
      textInputControl: {
        borderRadius: "30px",
        backgroundColor: appTheme.section.backgroundColor
      },
      container: {
        minHeight: "60vh"
      },
      textInputIcon: {
        color: "#000"
      }
    })
  );

const UserAttendances: React.FC<UserAttendancesProps> = (props) => {
  const { appTheme, attendanceData, attendance, profile, getUserAttendance, deleteUserAttendance, clearAttendanceError } = props;

  const [_id] = useState<string>(attendanceData ? attendanceData.attendance_id : "");
  const [search, setSearch] = useState<string>(attendance.search);
  const [page, setPage] = useState<number>(attendance.page);
  const [perPage, setPerPage] = useState<number>(attendance.per_page ?? 20);
  const [selectedRecord, setSelectedRecord] = useState<UserAttendanceInterface | null>(null);
  const [popUpDialogOpen, setPopUpDialogOpen] = useState<boolean>(false);

  const popUpMenuItems = useMemo(() => {
    return [{ text: "Delete User Attendance", permission: "delete-user-attendance" }];
  }, []);

  const [menuItems, setMenuItems] = useState<Array<string>>(
    popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text)
  );

  useEffect(() => {
    setMenuItems(popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text));
  }, [popUpMenuItems, profile.permissions]);

  const [addUserAttendanceDetailsModalOpen, setAddUserAttendanceDetailsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getUserAttendance(_id, search, page, perPage);
  }, [_id, search, page, perPage, getUserAttendance]);

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearAttendanceError();
    };
  }, [clearAttendanceError]);

  useEffect(() => {
    if (!attendance.error && attendance.response && attendance.response.data.status !== "Ok") {
      clearAttendanceError();

      setSelectedRecord(null);

      setAddUserAttendanceDetailsModalOpen(false);

      setDialogOptions({ open: true, body: attendance.response.data.status });

      getUserAttendance(_id, "", 0, perPage);
    }
  }, [attendance.error, attendance.response, _id, perPage, getUserAttendance, clearAttendanceError]);

  useEffect(() => {
    if (addUserAttendanceDetailsModalOpen) {
      return;
    }

    const requestData = attendance.error ? attendance : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [addUserAttendanceDetailsModalOpen, attendance]);

  const onMenuItemSelected = (menuItem: string) => {
    setPopUpDialogOpen(false);

    if (!selectedRecord) {
      return;
    }

    switch (menuItem) {
      case "Delete User Attendance":
        if (window.confirm("Would you like to delete this user attendance ?")) {
          deleteUserAttendance(_id, selectedRecord.user_id);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <AddUserAttendanceDetailsModal
        open={addUserAttendanceDetailsModalOpen}
        onDismissed={() => setAddUserAttendanceDetailsModalOpen(false)}
        attendanceData={attendanceData}
      />
      <PopupMenuDialog
        open={popUpDialogOpen}
        onDismissed={() => setPopUpDialogOpen(false)}
        onMenuItemSelected={onMenuItemSelected}
        menuItems={menuItems}
      />
      <Container maxWidth="lg">
        {!addUserAttendanceDetailsModalOpen && attendance.error ? (
          <ErrorView error={attendance.error} onRetry={() => getUserAttendance(_id, search, page, perPage)} />
        ) : (
          <React.Fragment>
            <Grid container justifyContent="space-between" spacing={0}>
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>{attendance.loading && <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />}</Grid>
                  <Grid item>
                    <Typography variant="h3" color="primary">
                      User Attendances
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  onChange={(event) => {
                    setSearch(event.currentTarget.value);
                  }}
                  error={false}
                  value={search}
                  variant="outlined"
                  placeholder="Search ..."
                  className={styles.textInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search className={styles.textInputIcon} />
                      </InputAdornment>
                    ),
                    classes: { root: styles.textInputControl }
                  }}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer className={styles.container}>
              <Table stickyHeader aria-label="user-attendances">
                <TableHead>
                  <TableRow>
                    {[
                      { label: "Profile Image", minWidth: 50 },
                      { label: "ID", minWidth: 100 },
                      { label: "Name", minWidth: 150 },
                      { label: "Type", minWidth: 100 },
                      { label: "Username", minWidth: 80 },
                      { label: "Email", minWidth: 100 },
                      { label: "Mobile No", minWidth: 100 },
                      { label: "Residence", minWidth: 150 },
                      { label: "Tempreture", minWidth: 150 },
                      { label: "Options", minWidth: 100 }
                    ].map((column) => (
                      <TableCell key={column.label} align="center" style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendance.data.map((userAttendance: UserAttendanceInterface) => {
                    const _id = userAttendance.user_id;
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={_id}>
                        {["img", "user_id", "name", "user_type", "username", "email", "mobile_no", "residence", "temp", "options"].map((column) => {
                          const key = `${_id}-${column}`;

                          const data = (userAttendance as unknown as { [key: string]: any })[column];
                          if (column === "options") {
                            return (
                              <TableCell key={key} align="center">
                                <Button
                                  variant="outlined"
                                  disabled={menuItems.length === 0}
                                  onClick={() => {
                                    if (menuItems.length === 0) {
                                      return;
                                    }
                                    setSelectedRecord(userAttendance);
                                    setPopUpDialogOpen(true);
                                  }}>
                                  Options
                                </Button>
                              </TableCell>
                            );
                          } else if (column === "img") {
                            return (
                              <TableCell key={key} align="center">
                                <Avatar className={styles.avatar} alt={userAttendance.name} src={data ? data : userImg} />
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={key} align="center">
                                {data}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container justifyContent="space-between" style={{ margin: "0px 10px 0px 10px" }}>
              <Grid item>
                <Button
                  disabled={!checkUserAccess("add-user-attendance", profile.permissions)}
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setSelectedRecord(null);
                    setAddUserAttendanceDetailsModalOpen(true);
                  }}>
                  Add New User Attendance
                </Button>
              </Grid>
              <Grid item>
                <TablePagination
                  component="div"
                  count={attendance.total}
                  page={page}
                  onPageChange={(_, page) => setPage(page)}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[20, 50, 100, 1000]}
                  onRowsPerPageChange={(event) => {
                    setPerPage(parseInt(event.target.value));
                    setPage(0);
                  }}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getUserAttendance: (_id: string, search: string, page: number, per_page: number) => dispatch(getUserAttendance(_id, search, page, per_page)),
    clearAttendanceError: () => dispatch(clearAttendanceError()),
    deleteUserAttendance: (_id: string, user_id: string) => dispatch(deleteUserAttendance(_id, user_id))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    attendance: state.attendance,
    profile: state.profile,
    appTheme: state.general.theme
  };
};

const UserAttendancesComponent = connect(mapStateToProps, mapDispatchToProps)(UserAttendances);

const UserAttendanceModal: React.FC<UserAttendanceModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="lg"
      title="User Attendance"
      component={<UserAttendancesComponent attendanceData={props.attendanceData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UserAttendanceModal;
