import React, { useState, useEffect } from "react";
import DialogModal from "../../../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, MinistryMembershipReducerState, UserInterface, UsersReducerState } from "../../../../../../interfaces";
import { createStyles, makeStyles} from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { addMinistryMembership, clearMinistryMembershipError, clearUserError, getUsers } from "../../../../../../store/actions";
import { useParams } from "react-router-dom";

interface AddMembershipDetailsFormProps {
  appTheme: AppTheme;
  ministryMembership: MinistryMembershipReducerState;
  users: UsersReducerState;
  addMinistryMembership: (ministry_id: string, user_id: string) => void;
  clearMinistryMembershipError: () => void;
  clearUserError: () => void;
  getUsers: (search: string) => void;
}

interface AddMembershipDetailsModalProps {
  open: boolean;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      },
      autocompleteItems: {
        position: "absolute",
        border: "1px solid #d4d4d4",
        borderBottom: "none",
        borderTop: "none",
        top: "100%",
        left: "0",
        right: "0",
        zIndex: 16000
      },
      autocompleteItem: {
        padding: "10px",
        cursor: "pointer",
        backgroundColor: "#fff",
        borderBottom: "1px solid #d4d4d4"
      },
      autocomplete: {
        position: "relative",
        display: "inline-block",
        width: "100%"
      }
    })
  );

const AddMembershipDetailsForm: React.FC<AddMembershipDetailsFormProps> = (props) => {
  const { appTheme, users, ministryMembership, addMinistryMembership, clearMinistryMembershipError, clearUserError, getUsers } = props;

  const { ministry_id } = useParams<{ ministry_id: string; ministry_name: string }>();

  const [user, setUser] = useState<UserInterface | null>();
  const [userSearch, setUserSearch] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loading = ministryMembership.loading;

  const styles = useStyles(appTheme)();

  const submitForm = () => {
    if (!user) {
      setErrorMessage("Please select a user before procceding");
    } else {
      addMinistryMembership(ministry_id, user.user_id);
    }
  };

  useEffect(() => {
    const requestData = ministryMembership.error ? ministryMembership : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [ministryMembership]);

  useEffect(() => {
    getUsers("");
    return () => {
      clearMinistryMembershipError();
      clearUserError();
    };
  }, [getUsers, clearMinistryMembershipError, clearUserError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <div className={styles.autocomplete}>
            <InputBase
              required
              type="text"
              value={userSearch}
              onChange={(event) => {
                const value = event.target.value;
                if (user?.name !== value) {
                  if (value.length > 0) {
                    getUsers(value);
                  }

                  setUser(null);

                  setUserSearch(value);
                }
              }}
              placeholder="User (Required)"
              className={styles.textField}
              startAdornment={
                <InputAdornment position="start">
                  <Person className={styles.textFieldIcon} />
                </InputAdornment>
              }
            />

            {!user && userSearch.length > 0 && (
              <div className={styles.autocompleteItems}>
                {users.data.map((user: UserInterface) => (
                  <div
                    key={user?.user_id}
                    className={styles.autocompleteItem}
                    onClick={() => {
                      setUser(user);
                      setUserSearch(user.name);
                    }}>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="body1">{"Name: " + user.name}</Typography>
                      </Grid>
                      {user.mobile_no && (
                        <Grid item>
                          <Typography variant="body1">{"Mobile No: " + user.mobile_no}</Typography>
                        </Grid>
                      )}
                      {user.email && (
                        <Grid item>
                          <Typography variant="body1">{"Email: " + user.email}</Typography>
                        </Grid>
                      )}
                      <Grid item>
                        <Typography variant="body1">{"Type: " + user.user_type}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{"Residence: " + user.residence}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </div>
            )}
          </div>
          {!user && userSearch.length > 0 && <div style={{ height: users.data.length * 130 }}></div>}
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} Add New Membership
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    addMinistryMembership: (ministry_id: string, user_id: string) => dispatch(addMinistryMembership(ministry_id, user_id)),
    clearMinistryMembershipError: () => dispatch(clearMinistryMembershipError()),
    clearUserError: () => dispatch(clearUserError()),
    getUsers: (search: string) => dispatch(getUsers(search, 0, 5))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    ministryMembership: state.ministryMembership,
    users: state.users,
    appTheme: state.general.theme
  };
};

const AddMembershipDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(AddMembershipDetailsForm);

const AddMembershipDetailsModal: React.FC<AddMembershipDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title="Add New Membership"
      component={<AddMembershipDetailsFormComponent />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default AddMembershipDetailsModal;
