import React from "react";
import DialogModal from "../../../components/DialogModal";
import { connect } from "react-redux";
import { createStyles, makeStyles} from "@material-ui/core/styles";
import { AppState,AppTheme,GalleryInterface } from "../../../interfaces";
import { Grid } from "@material-ui/core";

interface GalleryPhotosFormProps {
  appTheme: AppTheme;
  gallery: GalleryInterface | null;
}

interface GalleryPhotosModalProps {
  open: boolean;
  gallery: GalleryInterface | null;
  onDismissed: () => void;
}

const useStyles = () =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%",
        overflowY:"scroll",
        maxHeight: "65vh"
      }
    })
  );

const GalleryPhotosForm: React.FC<GalleryPhotosFormProps> = (props) => {
  const {gallery} = props;

  const styles = useStyles()();

  return (
    <div className={styles.root}>
    <Grid container spacing={3} style={{width:"100%"}}>
      {gallery?.photos.map((photo,index) => (
        <Grid item key={index} xs={12} sm={6} md={3}>
          <img src={photo} alt={"pic"+index} width="100%" height="250px" />
        </Grid>
      ))}
    </Grid>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    discipleshipClass: state.discipleshipClass,
    users: state.users,
    appTheme: state.general.theme
  };
};

const GalleryPhotosFormComponent = connect(mapStateToProps)(GalleryPhotosForm);

const GalleryPhotosModal: React.FC<GalleryPhotosModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xl"
      title="Gallery Photos"
      component={<GalleryPhotosFormComponent gallery={props.gallery} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default GalleryPhotosModal;
