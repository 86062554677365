import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { AppReducerState, AppState, DeviceReducerState } from "./interfaces";
import { initApp } from "./store/actions";
import { Grid, LinearProgress } from "@material-ui/core";
import { ErrorView } from "./components";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import logoImg from "./assets/logo.png";
import AccountLogin from "./containers/Account/LogIn";
import AccountForgot from "./containers/Account/Forgot";
import AccountVerifyResetPasswordCode from "./containers/Account/VerifyResetPasswordCode";
import AccountResetPassword from "./containers/Account/ResetPassword";
import Dashboard from "./containers/Dashboard/Dashboard";
import Home from "./containers/Home";
import Error404 from "./containers/Error404"

interface AppProps {
  loggedIn: boolean;
  app: AppReducerState;
  initApp: () => void;
}

const App: React.FC<AppProps> = (props) => {
  const { initApp, loggedIn } = props;

  const isAdmin: boolean = (process.env["REACT_APP_ADMIN_ALLOWED_URLS"] ?? "").split(",").includes(window.origin);

  useEffect(() => {
    initApp();
  }, [initApp]);

  return (
    <ThemeProvider theme={theme}>
      {isAdmin && props.app.loading ? (
        <Grid container direction="column" style={{ minHeight: "98vh", maxWidth: "100%" }} justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <img alt="logo" src={logoImg} style={{ width: 250 }} />
          </Grid>
          <Grid item>
            <LinearProgress color="primary" style={{ width: 200 }} />
          </Grid>
        </Grid>
      ) : props.app.error ? (
        <ErrorView
          error={props.app.error}
          onRetry={() => {
            initApp();
          }}
        />
      ) : (
        <React.Fragment>
          {isAdmin ? (
            <Switch>
              <Route exact path="/">
                {loggedIn ? <Redirect to="/dashboard/profile" /> : <Redirect to="/account/login" />}
              </Route>
              <Route exact path="/account/login" component={AccountLogin} />
              <Route exact path="/account/forgot" component={AccountForgot} />
              <Route exact path="/account/verify/reset/password/code" component={AccountVerifyResetPasswordCode} />
              <Route exact path="/account/reset/password" component={AccountResetPassword} />
              <Route path="/dashboard" component={Dashboard} />
              <Route component={Error404} />
            </Switch>
          ) : (
            <Switch>
              <Route path="/" component={Home} />
              <Route component={Error404} />
            </Switch>
          )}
        </React.Fragment>
      )}
    </ThemeProvider>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<DeviceReducerState, void, AnyAction>) => {
  return {
    initApp: () => dispatch(initApp())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    loggedIn: state.login.loggedIn,
    app: state.app
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
