import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  AppState,
  AppTheme,
  ProfileReducerState,
  MinistryKittySubtractionInterface,
  MinistryKittySubtractionReducerState,
  MinistryKittySubtractionsReducerState,
  MinistryKittyInterface
} from "../../../../../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Container,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { DialogModal, ErrorView } from "../../../../../../components";
import { clearMinistryKittySubtractionError, getMinistryKittySubtractions, deleteMinistryKittySubtraction } from "../../../../../../store/actions";
import { checkUserAccess } from "../../../../../../util/permission-checker";
import PopupMenuDialog from "../../../../../../components/PopupMenuDialog";
import AddMinistryKittySubtractionDetailsModal from "./AddMinistryKittySubtractionDetailsModal";
import dateformat from "dateformat";
import { useParams } from "react-router-dom";

interface MinistryKittySubtractionsDialogProps {
  ministryKittySubtraction: MinistryKittySubtractionReducerState;
  ministryKittySubtractions: MinistryKittySubtractionsReducerState;
  ministryKittyData: MinistryKittyInterface | null;
  profile: ProfileReducerState;
  appTheme: AppTheme;
  getMinistryKittySubtractions: (_id: string, search: string, page: number, per_page: number) => void;
  clearMinistryKittySubtractionError: () => void;
  deleteMinistryKittySubtraction: (_id: string, subtraction_id: string) => void;
}

interface MinistryKittySubtractionModalProps {
  open: boolean;
  ministryKittyData: MinistryKittyInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      icon: {
        color: theme.palette.text.primary,
        padding: 10,
        borderRadius: 30,
        backgroundColor: appTheme.iconBackgroundColor,
        "&:hover": {
          backgroundColor: appTheme.iconBackgroundHoverColor
        }
      },
      searchButton: {
        marginRight: "-21px",
        height: "55px",
        borderRadius: "30px",
        width: "100px"
      },
      textInput: {
        marginTop: "5px",
        width: "300px"
      },
      textInputControl: {
        borderRadius: "30px",
        backgroundColor: appTheme.section.backgroundColor
      },
      container: {
        minHeight: "60vh"
      },
      textInputIcon: {
        color: "#000"
      }
    })
  );

const MinistryKittySubtractionsDialog: React.FC<MinistryKittySubtractionsDialogProps> = (props) => {
  const {
    appTheme,
    ministryKittyData,
    ministryKittySubtraction,
    ministryKittySubtractions,
    profile,
    getMinistryKittySubtractions,
    deleteMinistryKittySubtraction,
    clearMinistryKittySubtractionError
  } = props;

  const { ministry_id } = useParams<{ ministry_id: string; ministry_name: string }>();

  const [_id] = useState<string>(ministryKittyData ? ministryKittyData.ministry_kitty_id : "");
  const [search, setSearch] = useState<string>(ministryKittySubtractions.search);
  const [page, setPage] = useState<number>(ministryKittySubtractions.page);
  const [perPage, setPerPage] = useState<number>(ministryKittySubtractions.per_page ?? 20);
  const [selectedRecord, setSelectedRecord] = useState<MinistryKittySubtractionInterface | null>(null);
  const [popUpDialogOpen, setPopUpDialogOpen] = useState<boolean>(false);

  const popUpMenuItems = useMemo(() => {
    return [{ text: "Delete Subtraction", permission: "delete-ministry-" + ministry_id + "-kitty-subtraction" }];
  }, [ministry_id]);
  const [menuItems, setMenuItems] = useState<Array<string>>(
    popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text)
  );

  useEffect(() => {
    setMenuItems(popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text));
  }, [popUpMenuItems, profile.permissions]);

  const [addMinistryKittySubtractionDetailsModalOpen, setAddMinistryKittySubtractionDetailsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getMinistryKittySubtractions(_id, search, page, perPage);
  }, [_id, search, page, perPage, getMinistryKittySubtractions]);

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearMinistryKittySubtractionError();
    };
  }, [clearMinistryKittySubtractionError]);

  useEffect(() => {
    if (!ministryKittySubtraction.error && ministryKittySubtraction.response && ministryKittySubtraction.response.data.status !== "Ok") {
      clearMinistryKittySubtractionError();

      setSelectedRecord(null);

      setAddMinistryKittySubtractionDetailsModalOpen(false);

      setDialogOptions({ open: true, body: ministryKittySubtraction.response.data.status });

      getMinistryKittySubtractions(_id, "", 0, perPage);
    }
  }, [
    ministryKittySubtraction.error,
    ministryKittySubtraction.response,
    _id,
    perPage,
    getMinistryKittySubtractions,
    clearMinistryKittySubtractionError
  ]);

  useEffect(() => {
    if (addMinistryKittySubtractionDetailsModalOpen) {
      return;
    }

    const requestData = ministryKittySubtraction.error ? ministryKittySubtraction : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [addMinistryKittySubtractionDetailsModalOpen, ministryKittySubtraction]);

  const onMenuItemSelected = (menuItem: string) => {
    setPopUpDialogOpen(false);

    if (!selectedRecord) {
      return;
    }

    switch (menuItem) {
      case "Delete Subtraction":
        if (window.confirm("Would you like to delete this subtraction ?")) {
          deleteMinistryKittySubtraction(_id, selectedRecord.subtraction_id);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <AddMinistryKittySubtractionDetailsModal
        open={addMinistryKittySubtractionDetailsModalOpen}
        onDismissed={() => {
          setSearch("");
          setPage(0);
          setAddMinistryKittySubtractionDetailsModalOpen(false);
        }}
        ministryKittyData={ministryKittyData}
      />
      <PopupMenuDialog
        open={popUpDialogOpen}
        onDismissed={() => setPopUpDialogOpen(false)}
        onMenuItemSelected={onMenuItemSelected}
        menuItems={menuItems}
      />
      <Container maxWidth="lg">
        {!addMinistryKittySubtractionDetailsModalOpen && ministryKittySubtractions.error ? (
          <ErrorView error={ministryKittySubtractions.error} onRetry={() => getMinistryKittySubtractions(_id, search, page, perPage)} />
        ) : (
          <React.Fragment>
            <Grid container justifyContent="space-between" spacing={0}>
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    {ministryKittySubtraction.loading && <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />}
                  </Grid>
                  <Grid item>
                    <Typography variant="h3" color="primary">
                      Kitty Subtractions
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  onChange={(ministryKittySubtraction) => {
                    setSearch(ministryKittySubtraction.currentTarget.value);
                  }}
                  error={false}
                  value={search}
                  variant="outlined"
                  placeholder="Search ..."
                  className={styles.textInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search className={styles.textInputIcon} />
                      </InputAdornment>
                    ),
                    classes: { root: styles.textInputControl }
                  }}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer className={styles.container}>
              <Table stickyHeader aria-label="ministry-kitty-subtractions">
                <TableHead>
                  <TableRow>
                    {[
                      { label: "ID", minWidth: 100 },
                      { label: "Name", minWidth: 250 },
                      { label: "Date", minWidth: 100 },
                      { label: "Amount", minWidth: 80 },
                      { label: "Transaction Cost", minWidth: 100 },
                      { label: "Type", minWidth: 100 },
                      { label: "Transaction Details", minWidth: 250 },
                      { label: "Options", minWidth: 100 }
                    ].map((column) => (
                      <TableCell key={column.label} align="center" style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ministryKittySubtractions.data.map((ministryKittySubtraction: MinistryKittySubtractionInterface) => {
                    const _id = ministryKittySubtraction.subtraction_id;
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={_id}>
                        {["subtractor_id", "name", "date", "amount", "transaction_cost", "subtractor_type", "transaction_details", "options"].map(
                          (column) => {
                            const key = `${_id}-${column}`;

                            const data = (ministryKittySubtraction as unknown as { [key: string]: any })[column];
                            if (column === "options") {
                              return (
                                <TableCell key={key} align="center">
                                  <Button
                                    variant="outlined"
                                    disabled={menuItems.length === 0}
                                    onClick={() => {
                                      setSelectedRecord(ministryKittySubtraction);
                                      setPopUpDialogOpen(true);
                                    }}>
                                    Options
                                  </Button>
                                </TableCell>
                              );
                            } else if (column === "date") {
                              return (
                                <TableCell key={key} align="center">
                                  {dateformat(data, "ddd mmm dd yyyy")}
                                </TableCell>
                              );
                            } else if (column === "amount" || column === "transaction_cost") {
                              return (
                                <TableCell key={key} align="center">
                                  {new Intl.NumberFormat("en-KE", { style: "currency", currency: "KES" }).format(parseFloat(data))}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={key} align="center">
                                  {data}
                                </TableCell>
                              );
                            }
                          }
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container justifyContent="space-between" style={{ margin: "0px 10px 0px 10px" }}>
              <Grid item>
                <Button
                  disabled={!checkUserAccess("add-ministry-" + ministry_id + "-kitty-subtraction", profile.permissions)}
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setSelectedRecord(null);
                    setAddMinistryKittySubtractionDetailsModalOpen(true);
                  }}>
                  Add New Subtraction
                </Button>
              </Grid>
              <Grid item>
                <TablePagination
                  component="div"
                  count={ministryKittySubtractions.total}
                  page={page}
                  onPageChange={(_, page) => setPage(page)}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[20, 50, 100, 1000]}
                  onRowsPerPageChange={(ministryKittySubtraction) => {
                    setPerPage(parseInt(ministryKittySubtraction.target.value));
                    setPage(0);
                  }}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getMinistryKittySubtractions: (_id: string, search: string, page: number, per_page: number) =>
      dispatch(getMinistryKittySubtractions(_id, search, page, per_page)),
    clearMinistryKittySubtractionError: () => dispatch(clearMinistryKittySubtractionError()),
    deleteMinistryKittySubtraction: (_id: string, subtraction_id: string) => dispatch(deleteMinistryKittySubtraction(_id, subtraction_id))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    ministryKittySubtractions: state.ministryKittySubtractions,
    ministryKittySubtraction: state.ministryKittySubtraction,
    profile: state.profile,
    appTheme: state.general.theme
  };
};

const MinistryKittySubtractionsDialogComponent = connect(mapStateToProps, mapDispatchToProps)(MinistryKittySubtractionsDialog);

const MinistryKittySubtractionsDialogModal: React.FC<MinistryKittySubtractionModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="lg"
      title="Kitty Subtractions"
      component={<MinistryKittySubtractionsDialogComponent ministryKittyData={props.ministryKittyData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default MinistryKittySubtractionsDialogModal;
