import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  alpha,
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import dateformat from "dateformat";
import { useGallery } from "../../hooks/useGallery";
import { ErrorView } from "../../components";
import { GalleryInterface } from "../../interfaces";
import GalleryPhotosModal from "./Modals/GalleryPhotosModal";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%"
      },
      title: {
        color: alpha(theme.palette.common.black, 0.1),
        fontSize: "3rem",
        fontWeight: 700,
        marginBottom: 30
      },
      subTitle: {
        color: theme.palette.common.black,
        fontSize: "1.5rem",
        marginBottom: 30
      },
      grid: {
        marginBottom: 30
      },
      card: {
        width: "100%"
      },
      cardSkeleton: {
        width: "100%",
        backgroundColor: "#5a5a5a29"
      },
      galleryTitle: {
        textOverflow: "ellipsis",
        minHeight: "1.5em",
        maxHeight: "1.5em",
        lineHeight: "1.5em",
        fontSize: "1.5em",
        overflow: "hidden"
      },
      galleryText: {
        textOverflow: "ellipsis",
        minHeight: "1.5em",
        maxHeight: "1.5em",
        lineHeight: "1.5em",
        overflow: "hidden"
      },
      breadcrumbLink: {
        textDecoration: "none",
        color: "#2f89fc",
        "&:hover": {
          color: alpha("#2f89fc", 0.5)
        }
      },
      galleryButton: {
        backgroundColor: "#2f89fc",
        color: theme.palette.common.white,
        textTransform: "none",
        width: "50%",
        marginTop: 5,
        marginBottom: 15,
        "&:hover": {
          backgroundColor: alpha("#2f89fc", 0.9)
        }
      }
    })
  );

const Gallery: React.FC = () => {
  const styles = useStyles()();

  const { galleriesLoading, galleriesError, more, galleries, getGalleries } = useGallery();

  const [skip,setSkip] = useState<number>(galleries.length)
  const [page, setPage] = useState<number>(0);
  const [selectedGallery,setSelectedGallery] = useState<GalleryInterface | null>(null)
  const [galleryPhotosModalOpen,setGalleryPhotosModalOpen] = useState<boolean>(false)

  useEffect(() => {
    window.scroll(0, 0);
    getGalleries("",skip);
  }, [getGalleries,skip]);

  let start = page * 12 - 1;

  if (page === 0) {
    start = 0;
  }

  let end = page * 12 + 11;

  if (page === 0) {
    end = 12;
  }

  const currentGalleries = galleries.slice(start, end);

  return (
    <React.Fragment>
      <Helmet>
        <title>Gallery</title>
        <meta
          name="description"
          content="Get a glimpse of how its like to fellowship at PEFA Church Gimu"
        />
        <link rel="canonical" href={window.location.origin + "/gallery"} />
        <meta property="og:site_name" content="PEFA Church Gimu" />
        <meta property="og:title" content="PEFA Church Gimu | Gallery" />
        <meta
          property="og:description"
          content="Get a glimpse of how its like to fellowship at PEFA Church Gimu"
        />
        <meta property="og:url" content={window.location.origin} />
        <meta property="og:image" content={`${window.location.origin}/logo.png`} />
      </Helmet>
      <div className={styles.root}>
        <GalleryPhotosModal open={galleryPhotosModalOpen}
        onDismissed={() => setGalleryPhotosModalOpen(false)}
        gallery={selectedGallery}/>
        <Container maxWidth="lg">
          <Typography variant="h1" className={styles.title}>
            Gallery
          </Typography>
          <Breadcrumbs>
            <Link color="inherit" to="/" className={styles.breadcrumbLink}>
              Home
            </Link>
            <Typography color="textPrimary">Gallery</Typography>
          </Breadcrumbs>
          <br />
          {galleriesError ? (
            <ErrorView error={galleriesError} onRetry={() => getGalleries("",skip)} />
          ) : (
            <React.Fragment>
              <Grid container spacing={3} className={styles.grid}>
                {galleriesLoading ? (
                  new Array(12).fill(null).map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <div className={styles.cardSkeleton}>
                        <Skeleton style={{ marginBottom: 25 }} variant="rect" height={200} />
                        <div style={{ padding: 15 }}>
                          <Skeleton style={{ marginBottom: 10 }} variant="text" width="25%" />
                          <Skeleton style={{ marginBottom: 25 }} variant="rect" height={20} />
                          <Skeleton style={{ marginBottom: 5 }} variant="text" />
                          <Skeleton style={{ marginBottom: 5 }} variant="text" />
                          <Skeleton style={{ marginBottom: 5 }} variant="text" />
                        </div>
                      </div>
                    </Grid>
                  ))
                ) : currentGalleries.length === 0 ? (
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h3" align="center" color="textSecondary" style={{ marginTop: 150, marginBottom: 150 }}>
                      No Gallery Photos Available
                    </Typography>
                  </Grid>
                ) : (
                  currentGalleries.map((gallery, index) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card className={styles.card}>
                          <CardActionArea>
                            <CardMedia component="img" alt={gallery.title} height="200" image={gallery.photos[0] ?? ""} title={gallery.title} />
                            <CardContent>
                              <Typography variant="h2" className={styles.galleryTitle}>
                                {gallery.title}
                              </Typography>
                              <Typography variant="caption" color="textSecondary">
                                {dateformat(gallery.added_on, "ddd mmm dd yyyy")}
                              </Typography>
                              <br />
                              <br />
                              <Typography variant="body2" color="textSecondary" component="p" align="justify" className={styles.galleryText}>
                                {gallery.description.replace(/<\/?[^>]+(>|$)/g, "")}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                          <CardActions style={{ justifyContent: "center" }}>
                            <Button
                              className={styles.galleryButton}
                              size="large"
                              onClick={() => {
                                setSelectedGallery(gallery as unknown as GalleryInterface)
                                setGalleryPhotosModalOpen(true)
                              }}>
                              View Photos
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })
                )}
                {galleries.length > 0 && !galleriesLoading && (
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={2} style={{ width: "100%" }} justifyContent="space-between">
                      <Grid item>
                        <Button
                          disabled={page === 0}
                          variant="outlined"
                          size="large"
                          onClick={() => {
                            setPage(page - 1);
                            window.scroll(0, 0);
                          }}>
                          Previous
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={more ? false : page * 12 + 12 > galleries.length}
                          variant="outlined"
                          size="large"
                          onClick={() => {
                            if (more) {
                              setPage(page + 1);
                              window.scroll(0, 0);
                              setSkip(galleries.length)
                            } else {
                              setPage(page + 1);
                              window.scroll(0, 0);
                            }
                          }}>
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Gallery;
