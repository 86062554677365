import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import About from "./Sections/About";
import ServiceTimes from "./Sections/ServiceTimes";

const Index: React.FC = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>PEFA Church Gimu - Athi River</title>
        <meta
          name="description"
          content="Welcome to PEFA Church Gimu - Athi River where we touch families to impact their world. Our focus: Fellowship, Worship, Evangelism and Ministry"
        />
        <link rel="canonical" href={window.location.origin} />
        <meta property="og:site_name" content="PEFA Church Gimu" />
        <meta property="og:title" content="PEFA Church Gimu - Athi River" />
        <meta
          property="og:description"
          content="Welcome to PEFA Church Gimu - Athi River where we touch families to impact their world. Our focus: Fellowship, Worship, Evangelism and Ministry"
        />
        <meta property="og:url" content={window.location.origin} />
        <meta property="og:image" content={`${window.location.origin}/logo.png`} />
      </Helmet>
      <About />
      <ServiceTimes />
    </React.Fragment>
  );
};

export default Index;
