import { Dispatch } from "redux";

const errorHandler = (errorActionType: string, error: any, dispatch: Dispatch): void => {
  if (error.response && error.response.status) {
    dispatch({
      type: errorActionType,
      payload: {
        message: error.message,
        status: error.response.status,
        data: error.response.data
      }
    });
  } else {
    dispatch({
      type: errorActionType,
      payload: {
        message: error.message
      }
    });
  }
};

export default errorHandler;
