import { AnyAction } from "redux";
import actions from "../../util/actions";
import { ResetReducerState } from "../../interfaces";

const initialState: ResetReducerState = {
  verified_code: false,
  reset: false,
  initial: false,
  loading: false,
  error: null,
  response: null
};

const reducer = (state = initialState, action: AnyAction): ResetReducerState => {
  switch (action.type) {
    case actions.RESET_HTTP_LOADING:
      return { ...state, response: null, loading: true, error: null };
    case actions.RESET_HTTP_ERROR:
      if (action.payload.clear) {
        return { ...state, response: null, loading: false, error: null };
      }
      return { ...state, response: null, loading: false, error: { ...action.payload } };
    case actions.RESET_HTTP_COMPLETE:
      return { ...state, initial: true, response: action.payload.response, loading: false, error: null };
    case actions.SET_USER_HAS_VERIFIED_RESET_CODE:
      return { ...state, verified_code: true };
    case actions.UNSET_USER_HAS_VERIFIED_RESET_CODE:
      return { ...state, verified_code: false };
    case actions.SET_USER_HAS_RESET_PASSWORD:
      return { ...state, reset: true };
    case actions.UNSET_USER_HAS_RESET_PASSWORD:
      return { ...state, reset: false };
    default:
      return state;
  }
};

export default reducer;
