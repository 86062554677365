import { useCallback, useState } from "react";
import { GalleryInterface } from "../interfaces";
import request from "../util/request";

type Gallery = Omit<GalleryInterface, "gallery_id"> & { _id: string };

const useGallery = () => {
  const [galleriesLoading, setGalleriesLoading] = useState<boolean>(false);
  const [galleriesError, setGalleriesError] = useState<{
    status?: number;
    data?: any;
    message: string;
  } | null>(null);
  const [more, setMore] = useState<boolean>(false);
  const [galleries, setGalleries] = useState<Gallery[]>([]);

  const getGalleries = useCallback(async (search: string = "",skip:number) => {
    try {
      setGalleriesLoading(true);
      setGalleriesError(null);
      const response = await request({ pathname: "searchRawGalleries", method: "GET", params: { search, skip } });
      setGalleries(prev => prev.concat(response.data.content["data"]));
      setMore(response.data.content["more"]);
    } catch (e) {
      const err = e as any;
      if (err.response && err.response.status) {
        setGalleriesError({
          message: err.message,
          status: err.response.status,
          data: err.response.data
        });
      } else {
        setGalleriesError({
          message: err.message
        });
      }
    } finally {
      setGalleriesLoading(false);
    }
  },[])

  return {
    galleriesLoading,
    galleriesError,
    more,
    galleries,
    getGalleries,
  };
};

export { useGallery };
