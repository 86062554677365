import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  AppState,
  AppTheme,
  ProfileReducerState,
  ContributionInterface,
  ContributionReducerState,
  ContributionsReducerState
} from "../../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Container,
  Paper,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { DialogModal, ErrorView } from "../../../components";
import { clearContributionError, getContributions, deleteContribution } from "../../../store/actions";
import { checkUserAccess } from "../../../util/permission-checker";
import PopupMenuDialog from "../../../components/PopupMenuDialog";
import dateformat from "dateformat";
import UpsertContributionDetailsModal from "./Modals/UpsertContributionDetailsDialog";
import UserContributionDialog from "./Modals/UserContributionDialog";

interface ContributionsProps {
  contributions: ContributionsReducerState;
  contribution: ContributionReducerState;
  profile: ProfileReducerState;
  appTheme: AppTheme;
  getContributions: (search: string, page: number, per_page: number) => void;
  clearContributionError: () => void;
  deleteContribution: (_id: string) => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 30,
        marginTop: 20,
        marginBottom: 20
      },
      icon: {
        color: theme.palette.text.primary,
        padding: 10,
        borderRadius: 30,
        backgroundColor: appTheme.iconBackgroundColor,
        "&:hover": {
          backgroundColor: appTheme.iconBackgroundHoverColor
        }
      },
      searchButton: {
        marginRight: "-21px",
        height: "55px",
        borderRadius: "30px",
        width: "100px"
      },
      textInput: {
        marginTop: "5px",
        width: "300px"
      },
      textInputControl: {
        borderRadius: "30px",
        backgroundColor: appTheme.section.backgroundColor
      },
      container: {
        minHeight: "60vh"
      },
      textInputIcon: {
        color: "#000"
      }
    })
  );

const Contributions: React.FC<ContributionsProps> = (props) => {
  const { appTheme, contributions, contribution, profile, getContributions, deleteContribution, clearContributionError } = props;

  const [search, setSearch] = useState<string>(contributions.search);
  const [page, setPage] = useState<number>(contributions.page);
  const [perPage, setPerPage] = useState<number>(contributions.per_page ?? 20);
  const [selectedRecord, setSelectedRecord] = useState<ContributionInterface | null>(null);
  const [popUpDialogOpen, setPopUpDialogOpen] = useState<boolean>(false);

  const [upsertContributionDetailsModelOpen, setUpsertContributionDetailsModelOpen] = useState<boolean>(false);
  const [userContributionModelOpen, setUserContributionModelOpen] = useState<boolean>(false);

  const popUpMenuItems = useMemo(() => {
    return [
      { text: "View User Contributions", permission: "view-user-contributions" },
      { text: "Update Contribution Details", permission: "edit-contribution" },
      { text: "Delete Contribution", permission: "delete-contribution" }
    ];
  }, []);

  const [menuItems, setMenuItems] = useState<Array<string>>(
    popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text)
  );

  useEffect(() => {
    setMenuItems(popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text));
  }, [popUpMenuItems, profile.permissions]);

  useEffect(() => {
    getContributions(search, page, perPage);
  }, [search, page, perPage, getContributions]);

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearContributionError();
    };
  }, [clearContributionError]);

  useEffect(() => {
    if (userContributionModelOpen) {
      return;
    }

    if (!contribution.error && contribution.response) {
      if (contribution.response.data.status === "Ok") {
        clearContributionError();
        return;
      }
      clearContributionError();

      setSelectedRecord(null);

      setUpsertContributionDetailsModelOpen(false);

      setUserContributionModelOpen(false);

      setDialogOptions({ open: true, body: contribution.response.data.status });

      getContributions("", 0, perPage);
    }
  }, [userContributionModelOpen, contribution.error, contribution.response, perPage, getContributions, clearContributionError]);

  useEffect(() => {
    if (upsertContributionDetailsModelOpen || userContributionModelOpen) {
      return;
    }

    const requestData = contribution.error ? contribution : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [contribution, userContributionModelOpen, upsertContributionDetailsModelOpen]);

  const onMenuItemSelected = (menuItem: string) => {
    setPopUpDialogOpen(false);

    if (!selectedRecord) {
      return;
    }

    switch (menuItem) {
      case "View User Contributions":
        setUserContributionModelOpen(true);
        break;
      case "Update Contribution Details":
        setUpsertContributionDetailsModelOpen(true);
        break;
      case "Delete Contribution":
        if (window.confirm("Would you like to delete this contribution ?")) {
          deleteContribution(selectedRecord.contribution_id);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <UpsertContributionDetailsModal
        open={upsertContributionDetailsModelOpen}
        onDismissed={() => setUpsertContributionDetailsModelOpen(false)}
        contributionData={selectedRecord}
      />
      <UserContributionDialog
        open={userContributionModelOpen}
        onDismissed={() => {
          setUserContributionModelOpen(false);
          getContributions(search, page, perPage);
        }}
        contributionData={selectedRecord}
      />
      <PopupMenuDialog
        open={popUpDialogOpen}
        onDismissed={() => setPopUpDialogOpen(false)}
        onMenuItemSelected={onMenuItemSelected}
        menuItems={menuItems}
      />
      <Container maxWidth="lg">
        {contributions.error ? (
          <ErrorView error={contributions.error} onRetry={() => getContributions(search, page, perPage)} />
        ) : (
          <React.Fragment>
            <Paper className={styles.paper}>
              <Grid container justifyContent="space-between" spacing={0}>
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      {(contributions.loading || contribution.loading) && (
                        <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography variant="h3" color="primary">
                        Contributions
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(contribution) => {
                      setSearch(contribution.currentTarget.value);
                    }}
                    error={false}
                    value={search}
                    variant="outlined"
                    placeholder="Search ..."
                    className={styles.textInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search className={styles.textInputIcon} />
                        </InputAdornment>
                      ),
                      classes: { root: styles.textInputControl }
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <TableContainer className={styles.container}>
                <Table stickyHeader aria-label="contributions">
                  <TableHead>
                    <TableRow>
                      {[
                        { label: "ID", minWidth: 100 },
                        { label: "Title", minWidth: 250 },
                        { label: "Target", minWidth: 100 },
                        { label: "Contributions", minWidth: 100 },
                        { label: "Contribution Instrcutions", minWidth: 250 },
                        { label: "From Date", minWidth: 200 },
                        { label: "To Date", minWidth: 200 },
                        { label: "Options", minWidth: 100 }
                      ].map((column) => (
                        <TableCell key={column.label} align="center" style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {contributions.data.map((contribution: ContributionInterface) => {
                      const _id = contribution.contribution_id;
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={_id}>
                          {["contribution_id", "title", "target", "contributions", "instructions", "from_date", "to_date", "options"].map(
                            (column) => {
                              const key = `${_id}-${column}`;

                              const data = (contribution as unknown as { [key: string]: any })[column];
                              if (column === "options") {
                                if (menuItems.length === 0) return <TableCell key={key} align="center"></TableCell>;
                                return (
                                  <TableCell key={key} align="center">
                                    <Button
                                      variant="outlined"
                                      disabled={menuItems.length === 0}
                                      onClick={() => {
                                        setSelectedRecord(contribution);
                                        setPopUpDialogOpen(true);
                                      }}>
                                      Options
                                    </Button>
                                  </TableCell>
                                );
                              } else if (column === "from_date" || column === "to_date") {
                                return (
                                  <TableCell key={key} align="center">
                                    {dateformat(data, "ddd mmm dd yyyy")}
                                  </TableCell>
                                );
                              } else if (column === "target" || column === "contributions") {
                                return (
                                  <TableCell key={key} align="center">
                                    {new Intl.NumberFormat("en-KE", { style: "currency", currency: "KES" }).format(parseFloat(data))}
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell key={key} align="center">
                                    {data}
                                  </TableCell>
                                );
                              }
                            }
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container justifyContent="space-between" style={{ margin: "0px 10px 0px 10px" }}>
                <Grid item>
                  <Button
                    disabled={!checkUserAccess("add-contribution", profile.permissions)}
                    variant="outlined"
                    size="large"
                    onClick={() => {
                      setSelectedRecord(null);
                      setUpsertContributionDetailsModelOpen(true);
                    }}>
                    Add New Contribution
                  </Button>
                </Grid>
                <Grid item>
                  <TablePagination
                    component="div"
                    count={contributions.total}
                    page={page}
                    onPageChange={(_, page) => setPage(page)}
                    rowsPerPage={perPage}
                    rowsPerPageOptions={[20, 50, 100, 1000]}
                    onRowsPerPageChange={(contribution) => {
                      setPerPage(parseInt(contribution.target.value));
                      setPage(0);
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getContributions: (search: string, page: number, per_page: number) => dispatch(getContributions(search, page, per_page)),
    clearContributionError: () => dispatch(clearContributionError()),
    deleteContribution: (_id: string) => dispatch(deleteContribution(_id))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    contributions: state.contributions,
    contribution: state.contribution,
    profile: state.profile,
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contributions);
