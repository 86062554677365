import { AnyAction } from "redux";
import actions from "../../util/actions";
import { ProfileReducerState } from "../../interfaces";

const initialState: ProfileReducerState = {
  initial: false,
  loading: false,
  error: null,
  response: null,
  name: "",
  user_id: "",
  email: "",
  img: "",
  permissions: [],
  mobile_no: "",
  dob: "",
  username: "",
  status: "deactivated",
  residence: "",
  user_type: "Guest",
  gender: "Male"
};

const reducer = (state = initialState, action: AnyAction): ProfileReducerState => {
  switch (action.type) {
    case actions.PROFILE_HTTP_LOADING:
      return { ...state, response: null, loading: true, error: null };
    case actions.PROFILE_HTTP_ERROR:
      if (action.payload.clear) {
        return { ...state, response: null, loading: false, error: null };
      }
      return { ...state, response: null, loading: false, error: { ...action.payload } };
    case actions.PROFILE_HTTP_COMPLETE:
      return { ...state, initial: true, response: action.payload.response, loading: false, error: null };
    case actions.LOGGING_IN_USER:
      return {
        ...action.payload
      };
    case actions.LOG_OUT_USER:
      return {
        initial: false,
        loading: false,
        error: null,
        response: null,
        name: "",
        user_id: "",
        email: "",
        img: "",
        permissions: [],
        mobile_no: "",
        dob: "",
        username: "",
        status: "deactivated",
        residence: "",
        user_type: "Guest",
        gender: "Male"
      };
    case actions.CHANGE_USER_PROFILE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
