import React, { useState, useEffect } from "react";
import DialogModal from "../../../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  AppState,
  AppTheme,
  MinistryContributionsReducerState,
  MinistryKittyLinkedContributionReducerState,
  MinistryContributionInterface,
  MinistryKittyInterface
} from "../../../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import {
  addMinistryKittyLinkedContribution,
  clearMinistryKittyLinkedContributionError,
  clearUserError,
  getMinistryContributions
} from "../../../../../../store/actions";
import { useParams } from "react-router-dom";
import dateformat from "dateformat";

interface AddMinistryKittyLinkedContributionDetailsFormProps {
  appTheme: AppTheme;
  ministryKittyLinkedContribution: MinistryKittyLinkedContributionReducerState;
  ministryContributions: MinistryContributionsReducerState;
  ministryKittyData: MinistryKittyInterface | null;
  addMinistryKittyLinkedContribution: (ministry_id: string, ministryContribution_id: string) => void;
  clearMinistryKittyLinkedContributionError: () => void;
  clearUserError: () => void;
  getMinistryContributions: (ministry_id: string, search: string) => void;
}

interface AddMinistryKittyLinkedContributionDetailsModalProps {
  open: boolean;
  ministryKittyData: MinistryKittyInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      },
      autocompleteItems: {
        position: "absolute",
        border: "1px solid #d4d4d4",
        borderBottom: "none",
        borderTop: "none",
        top: "100%",
        left: "0",
        right: "0",
        zIndex: 16000
      },
      autocompleteItem: {
        padding: "10px",
        cursor: "pointer",
        backgroundColor: "#fff",
        borderBottom: "1px solid #d4d4d4"
      },
      autocomplete: {
        position: "relative",
        display: "inline-block",
        width: "100%"
      }
    })
  );

const AddMinistryKittyLinkedContributionDetailsForm: React.FC<AddMinistryKittyLinkedContributionDetailsFormProps> = (props) => {
  const {
    appTheme,
    ministryContributions,
    ministryKittyData,
    ministryKittyLinkedContribution,
    addMinistryKittyLinkedContribution,
    clearMinistryKittyLinkedContributionError,
    clearUserError,
    getMinistryContributions
  } = props;

  const { ministry_id } = useParams<{ ministry_id: string; ministry_name: string }>();

  const [ministryContribution, setContribution] = useState<MinistryContributionInterface | null>();
  const [ministryContributionSearch, setContributionSearch] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loading = ministryKittyLinkedContribution.loading;

  const styles = useStyles(appTheme)();

  const submitForm = () => {
    if (!ministryContribution) {
      setErrorMessage("Please select a ministry contribution before procceding");
    } else {
      addMinistryKittyLinkedContribution(ministryKittyData ? ministryKittyData.ministry_kitty_id : "", ministryContribution.contribution_id);
    }
  };

  useEffect(() => {
    const requestData = ministryKittyLinkedContribution.error ? ministryKittyLinkedContribution : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [ministryKittyLinkedContribution]);

  useEffect(() => {
    getMinistryContributions(ministry_id, "");
    return () => {
      clearMinistryKittyLinkedContributionError();
      clearUserError();
    };
  }, [ministry_id, getMinistryContributions, clearMinistryKittyLinkedContributionError, clearUserError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <div className={styles.autocomplete}>
            <InputBase
              required
              type="text"
              value={ministryContributionSearch}
              onChange={(event) => {
                const value = event.target.value;
                if (ministryContribution?.title !== value) {
                  if (value.length > 0) {
                    getMinistryContributions(ministry_id, value);
                  }

                  setContribution(null);

                  setContributionSearch(value);
                }
              }}
              placeholder="Contribution (Required)"
              className={styles.textField}
              startAdornment={
                <InputAdornment position="start">
                  <Person className={styles.textFieldIcon} />
                </InputAdornment>
              }
            />

            {!ministryContribution && ministryContributionSearch.length > 0 && (
              <div className={styles.autocompleteItems}>
                {ministryContributions.data.map((ministryContribution: MinistryContributionInterface) => (
                  <div
                    key={ministryContribution?.contribution_id}
                    className={styles.autocompleteItem}
                    onClick={() => {
                      setContribution(ministryContribution);
                      setContributionSearch(ministryContribution.title);
                    }}>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="body1">{"Title: " + ministryContribution.title}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {"Target: " + new Intl.NumberFormat("en-KE", { style: "currency", currency: "KES" }).format(ministryContribution.target)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{"From Date: " + dateformat(ministryContribution.from_date, "ddd mmm dd yyyy")}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{"To Date: " + dateformat(ministryContribution.to_date, "ddd mmm dd yyyy")}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </div>
            )}
          </div>
          {!ministryContribution && ministryContributionSearch.length > 0 && <div style={{ height: ministryContributions.data.length * 100 }}></div>}
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} Add New Ministry Kitty Linked Contribution
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    addMinistryKittyLinkedContribution: (ministry_id: string, ministryContribution_id: string) =>
      dispatch(addMinistryKittyLinkedContribution(ministry_id, ministryContribution_id)),
    clearMinistryKittyLinkedContributionError: () => dispatch(clearMinistryKittyLinkedContributionError()),
    clearUserError: () => dispatch(clearUserError()),
    getMinistryContributions: (ministry_id: string, search: string) => dispatch(getMinistryContributions(ministry_id, search, 0, 10))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    ministryKittyLinkedContribution: state.ministryKittyLinkedContribution,
    ministryContributions: state.ministryContributions,
    appTheme: state.general.theme
  };
};

const AddMinistryKittyLinkedContributionDetailsFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMinistryKittyLinkedContributionDetailsForm);

const AddMinistryKittyLinkedContributionDetailsModal: React.FC<AddMinistryKittyLinkedContributionDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title="Add New Ministry Kitty Linked Contribution"
      component={<AddMinistryKittyLinkedContributionDetailsFormComponent ministryKittyData={props.ministryKittyData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default AddMinistryKittyLinkedContributionDetailsModal;
