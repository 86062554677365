import { AnyAction } from "redux";
import actions from "../../util/actions";
import { MinistryContributionReducerState } from "../../interfaces";

const initialState: MinistryContributionReducerState = {
  initial: false,
  loading: false,
  error: null,
  response: null
};

const reducer = (state = initialState, action: AnyAction): MinistryContributionReducerState => {
  switch (action.type) {
    case actions.MINISTRY_CONTRIBUTION_HTTP_LOADING:
      return { ...state, response: null, loading: true, error: null };
    case actions.MINISTRY_CONTRIBUTION_HTTP_ERROR:
      if (action.payload.clear) {
        return { ...state, response: null, loading: false, error: null };
      }
      return { ...state, response: null, loading: false, error: { ...action.payload } };
    case actions.MINISTRY_CONTRIBUTION_HTTP_COMPLETE:
      return { ...state, initial: true, response: action.payload.response, loading: false, error: null };
    default:
      return state;
  }
};

export default reducer;
