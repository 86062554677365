import { AnyAction } from "redux";
import actions from "../../util/actions";
import { ForgotReducerState } from "../../interfaces";

const initialState: ForgotReducerState = {
  forgot: false,
  initial: false,
  loading: false,
  error: null,
  response: null
};

const reducer = (state = initialState, action: AnyAction): ForgotReducerState => {
  switch (action.type) {
    case actions.FORGOT_HTTP_LOADING:
      return { ...state, response: null, loading: true, error: null };
    case actions.FORGOT_HTTP_ERROR:
      if (action.payload.clear) {
        return { ...state, response: null, loading: false, error: null };
      }
      return { ...state, response: null, loading: false, error: { ...action.payload } };
    case actions.FORGOT_HTTP_COMPLETE:
      return { ...state, initial: true, response: action.payload.response, loading: false, error: null };
    case actions.SET_USER_HAS_FORGOT:
      return { ...state, forgot: true };
    case actions.UNSET_USER_HAS_FORGOT:
      return { ...state, forgot: false };
    default:
      return state;
  }
};

export default reducer;
