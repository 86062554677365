import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  alpha,
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import dateformat from "dateformat";
import { useProject } from "../../hooks/useProject";
import { ErrorView } from "../../components";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%"
      },
      title: {
        color: alpha(theme.palette.common.black, 0.1),
        fontSize: "3rem",
        fontWeight: 700,
        marginBottom: 30
      },
      subTitle: {
        color: theme.palette.common.black,
        fontSize: "1.5rem",
        marginBottom: 30
      },
      grid: {
        marginBottom: 30
      },
      card: {
        width: "100%"
      },
      cardSkeleton: {
        width: "100%",
        backgroundColor: "#5a5a5a29"
      },
      projectTitle: {
        textOverflow: "ellipsis",
        minHeight: "1.5em",
        maxHeight: "1.5em",
        lineHeight: "1.5em",
        fontSize: "1.5em",
        overflow: "hidden"
      },
      projectText: {
        textOverflow: "ellipsis",
        minHeight: "6em",
        maxHeight: "6em",
        lineHeight: "1.5em",
        overflow: "hidden"
      },
      breadcrumbLink: {
        textDecoration: "none",
        color: "#2f89fc",
        "&:hover": {
          color: alpha("#2f89fc", 0.5)
        }
      },
      projectButton: {
        backgroundColor: "#2f89fc",
        color: theme.palette.common.white,
        textTransform: "none",
        width: "50%",
        marginTop: 5,
        marginBottom: 15,
        "&:hover": {
          backgroundColor: alpha("#2f89fc", 0.9)
        }
      }
    })
  );

const Project: React.FC = () => {
  const styles = useStyles()();

  const { projectsLoading, projectsError, more, projects, getProjects } = useProject();

  const [skip,setSkip] = useState<number>(projects.length)
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    window.scroll(0, 0);
    getProjects("",skip);
  }, [getProjects,skip]);

  let start = page * 12 - 1;

  if (page === 0) {
    start = 0;
  }

  let end = page * 12 + 11;

  if (page === 0) {
    end = 12;
  }

  const currentProjects = projects.slice(start, end);

  return (
    <React.Fragment>
      <Helmet>
        <title>Projects</title>
        <meta
          name="description"
          content="Get the all the inclusive projects with regard to various topics to help you in your day to day spiritual life"
        />
        <link rel="canonical" href={window.location.origin + "/project"} />
        <meta property="og:site_name" content="PEFA Church Gimu" />
        <meta property="og:title" content="Projects" />
        <meta
          property="og:description"
          content="Get the all the inclusive projects with regard to various topics to help you in your day to day spiritual life"
        />
        <meta property="og:url" content={window.location.origin} />
        <meta property="og:image" content={`${window.location.origin}/logo.png`} />
      </Helmet>
      <div className={styles.root}>
        <Container maxWidth="lg">
          <Typography variant="h1" className={styles.title}>
            Projects
          </Typography>
          <Breadcrumbs>
            <Link color="inherit" to="/" className={styles.breadcrumbLink}>
              Home
            </Link>
            <Typography color="textPrimary">Projects</Typography>
          </Breadcrumbs>
          <br />
          {projectsError ? (
            <ErrorView error={projectsError} onRetry={() => getProjects("",skip)} />
          ) : (
            <React.Fragment>
              <Grid container spacing={3} className={styles.grid}>
                {projectsLoading ? (
                  new Array(12).fill(null).map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <div className={styles.cardSkeleton}>
                        <Skeleton style={{ marginBottom: 25 }} variant="rect" height={200} />
                        <div style={{ padding: 15 }}>
                          <Skeleton style={{ marginBottom: 10 }} variant="text" width="25%" />
                          <Skeleton style={{ marginBottom: 25 }} variant="rect" height={20} />
                          <Skeleton style={{ marginBottom: 5 }} variant="text" />
                          <Skeleton style={{ marginBottom: 5 }} variant="text" />
                          <Skeleton style={{ marginBottom: 5 }} variant="text" />
                        </div>
                      </div>
                    </Grid>
                  ))
                ) : currentProjects.length === 0 ? (
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h3" align="center" color="textSecondary" style={{ marginTop: 150, marginBottom: 150 }}>
                      No Projects Available
                    </Typography>
                  </Grid>
                ) : (
                  currentProjects.map((project, index) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card className={styles.card}>
                          <CardActionArea>
                            <CardMedia component="img" alt={project.title} height="200" image={project.cover_img} title={project.title} />
                            <CardContent>
                              <Typography variant="h2" className={styles.projectTitle}>
                                {project.title}
                              </Typography>
                              <Typography variant="caption" color="textSecondary">
                                {dateformat(project.added_on, "ddd mmm dd yyyy")}
                              </Typography>
                              <br />
                              <br />
                              <Typography variant="body2" color="textSecondary" component="p" align="justify" className={styles.projectText}>
                                {project.content.replace(/<\/?[^>]+(>|$)/g, "")}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                          <CardActions style={{ justifyContent: "center" }}>
                            <Button
                              className={styles.projectButton}
                              size="large"
                              component={Link}
                              to={`/projects/${project.title
                                .replace(/[^a-zA-Z0-9 ]/g, "")
                                .replace(/ /g, "-")
                                .toLowerCase()}-${project._id}`}>
                              Read More
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })
                )}
                {projects.length > 0 && !projectsLoading && (
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={2} style={{ width: "100%" }} justifyContent="space-between">
                      <Grid item>
                        <Button
                          disabled={page === 0}
                          variant="outlined"
                          size="large"
                          onClick={() => {
                            setPage(page - 1);
                            window.scroll(0, 0);
                          }}>
                          Previous
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={more ? false : page * 12 + 12 > projects.length}
                          variant="outlined"
                          size="large"
                          onClick={() => {
                            if (more) {
                              setPage(page + 1);
                              window.scroll(0, 0);
                              setSkip(projects.length)
                            } else {
                              setPage(page + 1);
                              window.scroll(0, 0);
                            }
                          }}>
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Project;
