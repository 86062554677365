import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearMinistryKittyLinkedContributionError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_KITTY_LINKED_CONTRIBUTION_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const addMinistryKittyLinkedContribution = (_id: string, contribution_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_KITTY_LINKED_CONTRIBUTION_HTTP_LOADING
    });

    request({ method: "post", pathname: "addMinistryKittyLinkedContribution", data: { _id, contribution_id } })
      .then((response) => {
        dispatch({
          type: actions.MINISTRY_KITTY_LINKED_CONTRIBUTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.MINISTRY_KITTY_LINKED_CONTRIBUTION_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteMinistryKittyLinkedContribution = (_id: string, contribution_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_KITTY_LINKED_CONTRIBUTION_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteMinistryKittyLinkedContribution", data: { _id, contribution_id } })
      .then((response) => {
        dispatch({
          type: actions.MINISTRY_KITTY_LINKED_CONTRIBUTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.MINISTRY_KITTY_LINKED_CONTRIBUTION_HTTP_ERROR, error, dispatch);
      });
  };
};
