import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, VerseInterface, VerseReducerState } from "../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress, Select } from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
import validator from "validator";
import { addVerse, clearVerseError, updateVerse } from "../../../../store/actions";
import { getBooks, getChapterCount, getVerseCount } from "../../../../util/bible";

interface UpsertVerseDetailsFormProps {
  appTheme: AppTheme;
  verse: VerseReducerState;
  verseData: VerseInterface | null;
  updateVerse: (
    _id: string,
    verse_date: string,
    verse_bId_from: number,
    verse_cId_from: number,
    verse_vId_from: number,
    verse_bId_to: number,
    verse_cId_to: number,
    verse_vId_to: number
  ) => void;
  addVerse: (
    verse_date: string,
    verse_bId_from: number,
    verse_cId_from: number,
    verse_vId_from: number,
    verse_bId_to: number,
    verse_cId_to: number,
    verse_vId_to: number
  ) => void;
  clearVerseError: () => void;
}

interface UpsertVerseDetailsModalProps {
  open: boolean;
  verseData: VerseInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      }
    })
  );

const UpsertVerseDetailsForm: React.FC<UpsertVerseDetailsFormProps> = (props) => {
  const { appTheme, verse, verseData, addVerse, updateVerse, clearVerseError } = props;

  const [_id] = useState<string>(verseData ? verseData.verse_id : "");
  const [verseDate, setVerseDate] = useState<string>(verseData ? verseData.verse_date : "");
  const [verseBIdFrom, setVerseBIdFrom] = useState<number>(verseData ? verseData.verse_bId_from : -1);
  const [verseCIdFrom, setVerseCIdFrom] = useState<number>(verseData ? verseData.verse_cId_from : -1);
  const [verseVIdFrom, setVerseVIdFrom] = useState<number>(verseData ? verseData.verse_vId_from : -1);
  const [verseBIdTo, setVerseBIdTo] = useState<number>(verseData ? verseData.verse_bId_to : -1);
  const [verseCIdTo, setVerseCIdTo] = useState<number>(verseData ? verseData.verse_cId_to : -1);
  const [verseVIdTo, setVerseVIdTo] = useState<number>(verseData ? verseData.verse_vId_to : -1);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [books] = useState<Array<string>>(getBooks());
  const [fromChapters, setFromChapters] = useState<Array<number>>([]);
  const [fromVerses, setFromVerses] = useState<Array<number>>([]);
  const [toChapters, setToChapters] = useState<Array<number>>([]);
  const [toVerses, setToVerses] = useState<Array<number>>([]);

  useEffect(() => {
    if (verseBIdFrom < 0) {
      return;
    }
    setFromChapters(Array(getChapterCount(verseBIdFrom)).fill(0));
  }, [verseBIdFrom]);

  useEffect(() => {
    if (verseBIdFrom < 0 || verseCIdFrom < 0) {
      return;
    }
    setFromVerses(Array(getVerseCount(verseBIdFrom, verseCIdFrom)).fill(0));
  }, [verseBIdFrom, verseCIdFrom]);

  useEffect(() => {
    if (verseBIdTo < 0) {
      return;
    }
    setToChapters(Array(getChapterCount(verseBIdTo)).fill(0));
  }, [verseBIdTo]);

  useEffect(() => {
    if (verseBIdTo < 0 || verseCIdTo < 0) {
      return;
    }
    setToVerses(Array(getVerseCount(verseBIdTo, verseCIdTo)).fill(0));
  }, [verseBIdTo, verseCIdTo]);

  const loading = verse.loading;

  const styles = useStyles(appTheme)();

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "verseDate":
        setVerseDate(value);
        break;
      case "verseBIdFrom":
        setVerseBIdFrom(parseInt(value));
        break;
      case "verseCIdFrom":
        setVerseCIdFrom(parseInt(value));
        break;
      case "verseVIdFrom":
        setVerseVIdFrom(parseInt(value));
        break;
      case "verseBIdTo":
        setVerseBIdTo(parseInt(value));
        break;
      case "verseCIdTo":
        setVerseCIdTo(parseInt(value));
        break;
      case "verseVIdTo":
        setVerseVIdTo(parseInt(value));
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (validator.isEmpty(verseDate)) {
      setErrorMessage("The verse date entered is not valid");
    } else if (verseBIdFrom < 0) {
      setErrorMessage("Please select the from book before proceeding");
    } else if (verseCIdFrom < 0) {
      setErrorMessage("Please select the from chapter before proceeding");
    } else if (verseVIdFrom < 0) {
      setErrorMessage("Please select the from verse before proceeding");
    } else if (verseBIdTo < 0) {
      setErrorMessage("Please select the to book before proceeding");
    } else if (verseCIdTo < 0) {
      setErrorMessage("Please select the to chapter before proceeding");
    } else if (verseVIdTo < 0) {
      setErrorMessage("Please select the to verse before proceeding");
    } else {
      setErrorMessage("");

      if (verseData) {
        updateVerse(_id, verseDate, verseBIdFrom, verseCIdFrom, verseVIdFrom, verseBIdTo, verseCIdTo, verseVIdTo);
      } else {
        addVerse(verseDate, verseBIdFrom, verseCIdFrom, verseVIdFrom, verseBIdTo, verseCIdTo, verseVIdTo);
      }
    }
  };

  useEffect(() => {
    const requestData = verse.error ? verse : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [verse]);

  useEffect(() => {
    return () => {
      clearVerseError();
    };
  }, [clearVerseError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Verse Date (Required)
          </Typography>
          <InputBase
            required
            type="date"
            defaultValue={verseDate}
            onChange={(event) => {
              textFieldValueChanged("verseDate", event.target.value);
            }}
            placeholder="Verse Date (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <CalendarToday className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            From Verse
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Book (Required)
          </Typography>
          <Select
            native
            className={styles.textField}
            value={verseBIdFrom}
            onChange={(event) => {
              textFieldValueChanged("verseBIdFrom", event.target.value as string);
              textFieldValueChanged("verseCIdFrom", "-1");
              textFieldValueChanged("verseVIdFrom", "-1");
            }}>
            <option aria-label="Not Selected" value={-1} />
            {books.map((book, index) => (
              <option key={index} value={index}>
                {book}
              </option>
            ))}
          </Select>
        </Grid>
        {verseBIdFrom >= 0 && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Chapter (Required)
            </Typography>
            <Select
              native
              className={styles.textField}
              value={verseCIdFrom}
              onChange={(event) => {
                textFieldValueChanged("verseCIdFrom", event.target.value as string);
                textFieldValueChanged("verseVIdFrom", "-1");
              }}>
              <option aria-label="Not Selected" value={-1} />
              {fromChapters.map((_, index) => (
                <option key={index} value={index}>
                  {index + 1}
                </option>
              ))}
            </Select>
          </Grid>
        )}
        {verseBIdFrom >= 0 && verseCIdFrom >= 0 && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Verse (Required)
            </Typography>
            <Select
              native
              className={styles.textField}
              value={verseVIdFrom}
              onChange={(event) => {
                textFieldValueChanged("verseVIdFrom", event.target.value as string);
              }}>
              <option aria-label="Not Selected" value={-1} />
              {fromVerses.map((_, index) => (
                <option key={index} value={index}>
                  {index + 1}
                </option>
              ))}
            </Select>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            To Verse
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Book (Required)
          </Typography>
          <Select
            native
            className={styles.textField}
            value={verseBIdTo}
            onChange={(event) => {
              textFieldValueChanged("verseBIdTo", event.target.value as string);
              textFieldValueChanged("verseCIdTo", "-1");
              textFieldValueChanged("verseVIdTo", "-1");
            }}>
            <option aria-label="Not Selected" value={-1} />
            {books.map((book, index) => (
              <option key={index} value={index}>
                {book}
              </option>
            ))}
          </Select>
        </Grid>
        {verseBIdTo >= 0 && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Chapter (Required)
            </Typography>
            <Select
              native
              className={styles.textField}
              value={verseCIdTo}
              onChange={(event) => {
                textFieldValueChanged("verseCIdTo", event.target.value as string);
                textFieldValueChanged("verseVIdTo", "-1");
              }}>
              <option aria-label="Not Selected" value={-1} />
              {toChapters.map((_, index) => (
                <option key={index} value={index}>
                  {index + 1}
                </option>
              ))}
            </Select>
          </Grid>
        )}
        {verseBIdTo >= 0 && verseCIdTo >= 0 && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Verse (Required)
            </Typography>
            <Select
              native
              className={styles.textField}
              value={verseVIdTo}
              onChange={(event) => {
                textFieldValueChanged("verseVIdTo", event.target.value as string);
              }}>
              <option aria-label="Not Selected" value={-1} />
              {toVerses.map((_, index) => (
                <option key={index} value={index}>
                  {index + 1}
                </option>
              ))}
            </Select>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} {props.verseData ? "Update Verse Details" : "Add New Verse"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    updateVerse: (
      _id: string,
      verse_date: string,
      verse_bId_from: number,
      verse_cId_from: number,
      verse_vId_from: number,
      verse_bId_to: number,
      verse_cId_to: number,
      verse_vId_to: number
    ) => dispatch(updateVerse(_id, verse_date, verse_bId_from, verse_cId_from, verse_vId_from, verse_bId_to, verse_cId_to, verse_vId_to)),
    addVerse: (
      verse_date: string,
      verse_bId_from: number,
      verse_cId_from: number,
      verse_vId_from: number,
      verse_bId_to: number,
      verse_cId_to: number,
      verse_vId_to: number
    ) => dispatch(addVerse(verse_date, verse_bId_from, verse_cId_from, verse_vId_from, verse_bId_to, verse_cId_to, verse_vId_to)),
    clearVerseError: () => dispatch(clearVerseError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    file: state.file,
    verse: state.verse,
    appTheme: state.general.theme
  };
};

const UpsertVerseDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(UpsertVerseDetailsForm);

const UpsertVerseDetailsModal: React.FC<UpsertVerseDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="lg"
      title={props.verseData ? "Update Verse Details" : "Add New Verse"}
      component={<UpsertVerseDetailsFormComponent verseData={props.verseData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UpsertVerseDetailsModal;
