import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearDiscipleshipClassError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.DISCIPLESHIP_CLASS_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const addDiscipleshipClass = (title: string, status: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.DISCIPLESHIP_CLASS_HTTP_LOADING
    });

    request({ method: "post", pathname: "addDiscipleshipClass", data: { title, status } })
      .then((response) => {
        dispatch({
          type: actions.DISCIPLESHIP_CLASS_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.DISCIPLESHIP_CLASS_HTTP_ERROR, error, dispatch);
      });
  };
};

export const updateDiscipleshipClass = (_id: string, title: string, status: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.DISCIPLESHIP_CLASS_HTTP_LOADING
    });

    request({ method: "post", pathname: "updateDiscipleshipClass", data: { _id, title, status } })
      .then((response) => {
        dispatch({
          type: actions.DISCIPLESHIP_CLASS_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.DISCIPLESHIP_CLASS_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteDiscipleshipClass = (_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.DISCIPLESHIP_CLASS_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteDiscipleshipClass", data: { _id } })
      .then((response) => {
        dispatch({
          type: actions.DISCIPLESHIP_CLASS_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.DISCIPLESHIP_CLASS_HTTP_ERROR, error, dispatch);
      });
  };
};

export const getUserDiscipleshipClass = (_id: string, search: string, page: number, per_page: number) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.DISCIPLESHIP_CLASS_HTTP_LOADING
    });

    request({
      method: "get",
      pathname: "getUserDiscipleshipClass",
      params: { _id, search, page, per_page }
    })
      .then((response) => {
        dispatch({
          type: actions.DISCIPLESHIP_CLASS_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });

        dispatch({
          type: actions.SET_DISCIPLESHIP_CLASS_DETAILS,
          payload: {
            ...response.data.content
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.DISCIPLESHIP_CLASS_HTTP_ERROR, error, dispatch);
      });
  };
};

export const addUserDiscipleshipClass = (_id: string, user_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.DISCIPLESHIP_CLASS_HTTP_LOADING
    });

    request({
      method: "post",
      pathname: "addUserDiscipleshipClass",
      data: { _id, user_id }
    })
      .then((response) => {
        dispatch({
          type: actions.DISCIPLESHIP_CLASS_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.DISCIPLESHIP_CLASS_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteUserDiscipleshipClass = (_id: string, user_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.DISCIPLESHIP_CLASS_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteUserDiscipleshipClass", data: { _id, user_id } })
      .then((response) => {
        dispatch({
          type: actions.DISCIPLESHIP_CLASS_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.DISCIPLESHIP_CLASS_HTTP_ERROR, error, dispatch);
      });
  };
};
