import { AnyAction } from "redux";
import { detect } from "detect-browser";
import { v4 as uuidv4 } from "uuid";
import { getItem, setItem } from "../../util/storage";
import { DeviceReducerState } from "../../interfaces";

const browser = detect();

const udid: string = getItem("udid") ?? uuidv4();

if (udid) {
  setItem("udid", udid);
}

const model: string = browser ? browser.name + " " + browser.version + " " + browser.os : window.navigator.userAgent;

const initialState: DeviceReducerState = {
  appVersion: 1,
  udid: udid,
  model: model,
  os: "web"
};

const reducer = (state = initialState, action: AnyAction): DeviceReducerState => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;
