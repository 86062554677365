import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  AppState,
  AppTheme,
  ProfileReducerState,
  MinistryMemberContributionInterface,
  MinistryMemberContributionReducerState,
  MinistryContributionInterface
} from "../../../../../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Container,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { DialogModal, ErrorView } from "../../../../../../components";
import { clearMinistryContributionError, getMinistryMemberContribution, deleteMinistryMemberContribution } from "../../../../../../store/actions";
import { checkUserAccess } from "../../../../../../util/permission-checker";
import PopupMenuDialog from "../../../../../../components/PopupMenuDialog";
import AddMinistryMemberContributionDetailsModal from "./AddMinistryMemberContributionDetailsModal";
import dateformat from "dateformat";
import { useParams } from "react-router-dom";

interface MinistryMemberContributionsProps {
  ministryMemberContribution: MinistryMemberContributionReducerState;
  ministryContributionData: MinistryContributionInterface | null;
  profile: ProfileReducerState;
  appTheme: AppTheme;
  getMinistryMemberContribution: (_id: string, search: string, page: number, per_page: number) => void;
  clearMinistryContributionError: () => void;
  deleteMinistryMemberContribution: (_id: string, contribution_id: string) => void;
}

interface MinistryMemberContributionModalProps {
  open: boolean;
  ministryContributionData: MinistryContributionInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      icon: {
        color: theme.palette.text.primary,
        padding: 10,
        borderRadius: 30,
        backgroundColor: appTheme.iconBackgroundColor,
        "&:hover": {
          backgroundColor: appTheme.iconBackgroundHoverColor
        }
      },
      searchButton: {
        marginRight: "-21px",
        height: "55px",
        borderRadius: "30px",
        width: "100px"
      },
      textInput: {
        marginTop: "5px",
        width: "300px"
      },
      textInputControl: {
        borderRadius: "30px",
        backgroundColor: appTheme.section.backgroundColor
      },
      container: {
        minHeight: "60vh"
      },
      textInputIcon: {
        color: "#000"
      }
    })
  );

const MinistryMemberContributions: React.FC<MinistryMemberContributionsProps> = (props) => {
  const {
    appTheme,
    ministryContributionData,
    ministryMemberContribution,
    profile,
    getMinistryMemberContribution,
    deleteMinistryMemberContribution,
    clearMinistryContributionError
  } = props;

  const { ministry_id } = useParams<{ ministry_id: string; ministry_name: string }>();

  const [_id] = useState<string>(ministryContributionData ? ministryContributionData.contribution_id : "");
  const [search, setSearch] = useState<string>(ministryMemberContribution.search);
  const [page, setPage] = useState<number>(ministryMemberContribution.page);
  const [perPage, setPerPage] = useState<number>(ministryMemberContribution.per_page ?? 20);
  const [selectedRecord, setSelectedRecord] = useState<MinistryMemberContributionInterface | null>(null);
  const [popUpDialogOpen, setPopUpDialogOpen] = useState<boolean>(false);

  const popUpMenuItems = useMemo(() => {
    return [{ text: "Delete Member Contribution", permission: "delete-ministry-" + ministry_id + "-member-contribution" }];
  }, [ministry_id]);
  const [menuItems, setMenuItems] = useState<Array<string>>(
    popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text)
  );

  useEffect(() => {
    setMenuItems(popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text));
  }, [popUpMenuItems, profile.permissions]);

  const [addMinistryMemberContributionDetailsModalOpen, setAddMinistryMemberContributionDetailsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getMinistryMemberContribution(_id, search, page, perPage);
  }, [_id, search, page, perPage, getMinistryMemberContribution]);

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearMinistryContributionError();
    };
  }, [clearMinistryContributionError]);

  useEffect(() => {
    if (!ministryMemberContribution.error && ministryMemberContribution.response && ministryMemberContribution.response.data.status !== "Ok") {
      clearMinistryContributionError();

      setSelectedRecord(null);

      setAddMinistryMemberContributionDetailsModalOpen(false);

      setDialogOptions({ open: true, body: ministryMemberContribution.response.data.status });

      getMinistryMemberContribution(_id, "", 0, perPage);
    }
  }, [
    ministryMemberContribution.error,
    ministryMemberContribution.response,
    _id,
    perPage,
    getMinistryMemberContribution,
    clearMinistryContributionError
  ]);

  useEffect(() => {
    if (addMinistryMemberContributionDetailsModalOpen) {
      return;
    }

    const requestData = ministryMemberContribution.error ? ministryMemberContribution : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [addMinistryMemberContributionDetailsModalOpen, ministryMemberContribution]);

  const onMenuItemSelected = (menuItem: string) => {
    setPopUpDialogOpen(false);

    if (!selectedRecord) {
      return;
    }

    switch (menuItem) {
      case "Delete Member Contribution":
        if (window.confirm("Would you like to delete this member contribution ?")) {
          deleteMinistryMemberContribution(_id, selectedRecord.contribution_id);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <AddMinistryMemberContributionDetailsModal
        open={addMinistryMemberContributionDetailsModalOpen}
        onDismissed={() => {
          setSearch("");
          setPage(0);
          setAddMinistryMemberContributionDetailsModalOpen(false);
        }}
        ministryContributionData={ministryContributionData}
      />
      <PopupMenuDialog
        open={popUpDialogOpen}
        onDismissed={() => setPopUpDialogOpen(false)}
        onMenuItemSelected={onMenuItemSelected}
        menuItems={menuItems}
      />
      <Container maxWidth="lg">
        {!addMinistryMemberContributionDetailsModalOpen && ministryMemberContribution.error ? (
          <ErrorView error={ministryMemberContribution.error} onRetry={() => getMinistryMemberContribution(_id, search, page, perPage)} />
        ) : (
          <React.Fragment>
            <Grid container justifyContent="space-between" spacing={0}>
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    {ministryMemberContribution.loading && <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />}
                  </Grid>
                  <Grid item>
                    <Typography variant="h3" color="primary">
                      Member Contributions
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  onChange={(ministryMemberContribution) => {
                    setSearch(ministryMemberContribution.currentTarget.value);
                  }}
                  error={false}
                  value={search}
                  variant="outlined"
                  placeholder="Search ..."
                  className={styles.textInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search className={styles.textInputIcon} />
                      </InputAdornment>
                    ),
                    classes: { root: styles.textInputControl }
                  }}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer className={styles.container}>
              <Table stickyHeader aria-label="ministry-member-contributions">
                <TableHead>
                  <TableRow>
                    {[
                      { label: "ID", minWidth: 100 },
                      { label: "Name", minWidth: 250 },
                      { label: "Date", minWidth: 100 },
                      { label: "Amount", minWidth: 80 },
                      { label: "Type", minWidth: 100 },
                      { label: "Transaction Details", minWidth: 250 },
                      { label: "Options", minWidth: 100 }
                    ].map((column) => (
                      <TableCell key={column.label} align="center" style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ministryMemberContribution.data.map((memberMinistryContribution: MinistryMemberContributionInterface) => {
                    const _id = memberMinistryContribution.contribution_id;
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={_id}>
                        {["contributor_id", "name", "date", "amount", "contributor_type", "transaction_details", "options"].map((column) => {
                          const key = `${_id}-${column}`;

                          const data = (memberMinistryContribution as unknown as { [key: string]: any })[column];
                          if (column === "options") {
                            return (
                              <TableCell key={key} align="center">
                                <Button
                                  variant="outlined"
                                  disabled={menuItems.length === 0}
                                  onClick={() => {
                                    setSelectedRecord(memberMinistryContribution);
                                    setPopUpDialogOpen(true);
                                  }}>
                                  Options
                                </Button>
                              </TableCell>
                            );
                          } else if (column === "date") {
                            return (
                              <TableCell key={key} align="center">
                                {dateformat(data, "ddd mmm dd yyyy")}
                              </TableCell>
                            );
                          } else if (column === "amount") {
                            return (
                              <TableCell key={key} align="center">
                                {new Intl.NumberFormat("en-KE", { style: "currency", currency: "KES" }).format(parseFloat(data))}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={key} align="center">
                                {data}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container justifyContent="space-between" style={{ margin: "0px 10px 0px 10px" }}>
              <Grid item>
                <Button
                  disabled={!checkUserAccess("add-ministry-" + ministry_id + "-member-contribution", profile.permissions)}
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setSelectedRecord(null);
                    setAddMinistryMemberContributionDetailsModalOpen(true);
                  }}>
                  Add New Member Contribution
                </Button>
              </Grid>
              <Grid item>
                <TablePagination
                  component="div"
                  count={ministryMemberContribution.total}
                  page={page}
                  onPageChange={(_, page) => setPage(page)}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[20, 50, 100, 1000]}
                  onRowsPerPageChange={(ministryMemberContribution) => {
                    setPerPage(parseInt(ministryMemberContribution.target.value));
                    setPage(0);
                  }}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getMinistryMemberContribution: (_id: string, search: string, page: number, per_page: number) =>
      dispatch(getMinistryMemberContribution(_id, search, page, per_page)),
    clearMinistryContributionError: () => dispatch(clearMinistryContributionError()),
    deleteMinistryMemberContribution: (_id: string, contribution_id: string) => dispatch(deleteMinistryMemberContribution(_id, contribution_id))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    ministryMemberContribution: state.ministryMemberContribution,
    profile: state.profile,
    appTheme: state.general.theme
  };
};

const MinistryMemberContributionsComponent = connect(mapStateToProps, mapDispatchToProps)(MinistryMemberContributions);

const MinistryMemberContributionsModal: React.FC<MinistryMemberContributionModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="lg"
      title="Member Contributions"
      component={<MinistryMemberContributionsComponent ministryContributionData={props.ministryContributionData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default MinistryMemberContributionsModal;
