import { AnyAction } from "redux";
import actions from "../../util/actions";
import { LogInReducerState } from "../../interfaces";
import { getItem } from "../../util/storage";

const loggedIn = getItem("refresh_token") ? true : false;

const initialState: LogInReducerState = {
  loggedIn,
  initial: false,
  loading: false,
  error: null,
  response: null
};

const reducer = (state = initialState, action: AnyAction): LogInReducerState => {
  switch (action.type) {
    case actions.LOGIN_HTTP_LOADING:
      return { ...state, response: null, loading: true, error: null };
    case actions.LOGIN_HTTP_ERROR:
      if (action.payload.clear) {
        return { ...state, response: null, loading: false, error: null };
      }
      return { ...state, response: null, loading: false, error: { ...action.payload } };
    case actions.LOGIN_HTTP_COMPLETE:
      return { ...state, initial: true, response: action.payload.response, loading: false, error: null };
    case actions.LOG_IN_USER:
      return { ...state, loggedIn: true };
    case actions.LOG_OUT_USER:
      return { ...state, loggedIn: false };
    default:
      return state;
  }
};

export default reducer;
