import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, PasswordReducerState, AppTheme } from "../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { Lock } from "@material-ui/icons";
import validator from "validator";
import { changePassword, clearPasswordError } from "../../../../store/actions";

interface ChangePasswordFormProps {
  appTheme: AppTheme;
  password: PasswordReducerState;
  changePassword: (current_password: string, new_password: string) => void;
  clearPasswordError: () => void;
}

interface ChangePasswordModalProps {
  open: boolean;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      }
    })
  );

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = (props) => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { appTheme, changePassword, password, clearPasswordError } = props;

  const loading = password.loading;

  const styles = useStyles(appTheme)();

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "currentPassword":
        setCurrentPassword(value);
        break;
      case "newPassword":
        setNewPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (!validator.isByteLength(currentPassword, { min: 6 })) {
      setErrorMessage("Current password entered should be atleast 6 characters in length");
    } else if (!validator.isByteLength(newPassword, { min: 6 })) {
      setErrorMessage("New password entered should be atleast 6 characters in length");
    } else if (newPassword === currentPassword) {
      setErrorMessage("New password cannot be the same as the current password");
    } else if (!validator.isByteLength(confirmPassword, { min: 6 })) {
      setErrorMessage("The confirmed password entered should be atleast 6 characters in length");
    } else if (newPassword !== confirmPassword) {
      setErrorMessage("The new and confirmed passwords entered are not the same");
    } else {
      setErrorMessage("");

      changePassword(currentPassword, newPassword);
    }
  };

  useEffect(() => {
    const requestData = password.error ? password : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [password]);

  useEffect(() => {
    return () => {
      clearPasswordError();
    };
  }, [clearPasswordError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputBase
            required
            type="password"
            value={currentPassword}
            onChange={(event) => {
              textFieldValueChanged("currentPassword", event.target.value);
            }}
            placeholder="Current Password"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Lock className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            required
            type="password"
            value={newPassword}
            onChange={(event) => {
              textFieldValueChanged("newPassword", event.target.value);
            }}
            placeholder="New Password"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Lock className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            required
            type="password"
            value={confirmPassword}
            onChange={(event) => {
              textFieldValueChanged("confirmPassword", event.target.value);
            }}
            placeholder="Confirm Password"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Lock className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} Update Password
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    changePassword: (current_password: string, new_password: string) => dispatch(changePassword(current_password, new_password)),
    clearPasswordError: () => dispatch(clearPasswordError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    appTheme: state.general.theme,
    password: state.password
  };
};

const ChangePasswordFormComponent = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title="Change Password Dialog"
      component={<ChangePasswordFormComponent />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default ChangePasswordModal;
