import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, ProfileReducerState } from "../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress, Select } from "@material-ui/core";
import { CalendarToday, Lock, Mail, Person, Call, Home } from "@material-ui/icons";
import validator from "validator";
import { clearProfileError, updateProfile } from "../../../../store/actions";
import dateformat from "dateformat";

interface ChangeProfileFormProps {
  appTheme: AppTheme;
  profile: ProfileReducerState;
  updateProfile: (name: string, username: string, email: string, mobile_no: string, dob: string, residence: string, gender: string) => void;
  clearProfileError: () => void;
}

interface ChangeProfileModalProps {
  open: boolean;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      }
    })
  );

const ChangeProfileForm: React.FC<ChangeProfileFormProps> = (props) => {
  const { appTheme, updateProfile, profile, clearProfileError } = props;

  const [name, setName] = useState<string>(profile.name);
  const [gender, setGender] = useState<string>(profile.gender);
  const [username, setUsername] = useState<string>(profile.username);
  const [email, setEmail] = useState<string>(profile.email);
  const [mobileNo, setMobileNo] = useState<string>(profile.mobile_no);
  const [dob, setDOB] = useState<string>(profile.dob);
  const [residence, setResidence] = useState<string>(profile.residence);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loading = profile.loading;

  const styles = useStyles(appTheme)();

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "name":
        setName(value);
        break;
      case "username":
        setUsername(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "mobileNo":
        setMobileNo(value);
        break;
      case "dob":
        setDOB(value);
        break;
      case "residence":
        setResidence(value);
        break;
      case "gender":
        setGender(value);
        break;
      default:
        break;
    }
  };

  const validateUnrequired = (field: string, value: string) => {
    if (validator.isEmpty(value)) {
      return true;
    } else {
      if (field === "email") {
        if (!validator.isEmail(value)) {
          setErrorMessage("The email address entered is not valid");
          return false;
        } 
        return true;
      }

      if (field === "mobile_no") {
        if (value.length !== 10) {
          setErrorMessage("The mobile number entered is not valid");
          return false;
        }
        return true;
      }

      return false
    }
  };

  const submitForm = () => {
    if (validator.isEmpty(name)) {
      setErrorMessage("The name entered is not valid");
    } else if (validator.isEmpty(username)) {
      setErrorMessage("The username entered is not valid");
    } else if (!validateUnrequired("email", email)) {
    } else if (!validateUnrequired("mobile_no", mobileNo)) {
    } else if (validator.isEmpty(gender)) {
      setErrorMessage("The gender entered is not valid");
    } else if (validator.isEmpty(dob)) {
      setErrorMessage("The date of birth entered is not valid");
    } else if (validator.isEmpty(residence)) {
      setErrorMessage("The residence entered is not valid");
    } else {
      setErrorMessage("");
      updateProfile(name, username, email, mobileNo, dob, residence, gender);
    }
  };

  useEffect(() => {
    const requestData = profile.error ? profile : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [profile]);

  useEffect(() => {
    return () => {
      clearProfileError();
    };
  }, [clearProfileError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            value={name}
            onChange={(event) => {
              textFieldValueChanged("name", event.target.value);
            }}
            placeholder="Name (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Person className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            value={username}
            onChange={(event) => {
              textFieldValueChanged("username", event.target.value);
            }}
            placeholder="Username (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Lock className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            type="email"
            value={email}
            onChange={(event) => {
              textFieldValueChanged("email", event.target.value);
            }}
            placeholder="Email"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Mail className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            type="number"
            value={mobileNo}
            onChange={(event) => {
              textFieldValueChanged("mobileNo", event.target.value);
            }}
            placeholder="Mobile Number"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Call className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Gender (Required)
          </Typography>
          <Select
            native
            className={styles.textField}
            value={gender}
            onChange={(event) => {
              textFieldValueChanged("gender", event.target.value as string);
            }}>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Date of Birth (Required)
          </Typography>
          <InputBase
            required
            inputProps={{ max: dateformat(new Date(), "yyyy-mm-dd") }}
            type="date"
            defaultValue={dob}
            onChange={(event) => {
              textFieldValueChanged("dob", event.target.value);
            }}
            placeholder="Date of Birth (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <CalendarToday className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            defaultValue={residence}
            onChange={(event) => {
              textFieldValueChanged("residence", event.target.value);
            }}
            placeholder="Residence (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Home className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} Update Profile
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    updateProfile: (name: string, username: string, email: string, mobile_no: string, dob: string, residence: string, gender: string) =>
      dispatch(updateProfile({ name, username, email, mobile_no, dob, residence, gender })),
    clearProfileError: () => dispatch(clearProfileError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    profile: state.profile,
    appTheme: state.general.theme
  };
};

const ChangeProfileFormComponent = connect(mapStateToProps, mapDispatchToProps)(ChangeProfileForm);

const ChangeProfileModal: React.FC<ChangeProfileModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title="Update Profile Details"
      component={<ChangeProfileFormComponent />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default ChangeProfileModal;
