import React, { useState, useEffect } from "react";
import { DialogModal } from "../../../../../../components";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, CircularProgress } from "@material-ui/core";
import validator from "validator";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { generateFileName } from "../../../../../../util/generate-file-name";
import { v4 as uuidv4 } from "uuid";
import request from "../../../../../../util/request";
import axios from "axios";
import { updateMinistryLandingPage, clearMinistryLandingPageError } from "../../../../../../store/actions";
import { AppState, AppTheme, MinistryLandingPageReducerState } from "../../../../../../interfaces";

interface UpsertLandingPageDetailsFormProps {
  appTheme: AppTheme;
  ministryLandingPage: MinistryLandingPageReducerState;
  contentData: string;
  ministryID: string;
  updateMinistryLandingPage: (ministry_id: string, content: string) => void;
  clearMinistryLandingPageError: () => void;
  onDismissed: () => void;
}

interface UpsertLandingPageDetailsModalProps {
  open: boolean;
  ministryID: string;
  contentData: string;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      }
    })
  );

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote", "alignment"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"]
  ]
};

const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image"];

const UpsertLandingPageDetailsForm: React.FC<UpsertLandingPageDetailsFormProps> = (props) => {
  const { appTheme, ministryID, contentData, ministryLandingPage, updateMinistryLandingPage, clearMinistryLandingPageError, onDismissed } = props;

  const [content, setContent] = useState<string>(contentData);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [uploadingContentImages, setUploadingContentImages] = useState<boolean>(false);

  const loading = ministryLandingPage.loading || uploadingContentImages;

  const styles = useStyles(appTheme)();

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "content":
        setContent(value);
        break;
      default:
        break;
    }
  };

  const submitForm = async () => {
    try {
      if (validator.isEmpty(content)) {
        setErrorMessage("The blog content entered is not valid");
      } else {
        setErrorMessage("");

        const images = content.split(`<img src="data:`);

        let parsedContent: string = content;

        for (let i = 0; i < images.length; i++) {
          if (i === 0) {
            continue;
          }
          const [fileType, fileData] = images[i].split('"').shift()?.split(";") ?? ["", ""];

          const url = await uploadImage(fileType, fileData.split(",")?.pop() ?? "");
          if (!(url instanceof Error)) parsedContent = parsedContent.replace(`<img src="data:${fileType};${fileData}`, `<img src="${url}`);
        }

        updateMinistryLandingPage(ministryID, parsedContent);

        onDismissed();
      }
    } catch (error) {
      setErrorMessage("An error occurred while trying to upload images. Error: " + (error as Error).message);
    }
  };

  const uploadImage = async (fileType: string, fileData: string): Promise<string | Error> => {
    try {
      setUploadingContentImages(true);
      const ext = fileType.split("/").pop() ?? "png";
      const filePath = generateFileName("landing-page", "content-image/" + uuidv4(), ext);
      const file = b64toBlob(fileData, "image/png");
      const result = await request({ method: "post", pathname: "getS3UploadUrl", data: { filePath, fileType } });
      await axios.put(result.data.content.url, file, { headers: { "Content-Type": fileType } });
      return `https://cdn.pefachurchgimu.org/${filePath}`;
    } catch (error) {
      return error as Error;
    } finally {
      setUploadingContentImages(false);
    }
  };

  const b64toBlob = (b64Data: string, contentType: string = "", sliceSize = 512): Blob => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  useEffect(() => {
    const requestData = ministryLandingPage.error ? ministryLandingPage : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [ministryLandingPage]);

  useEffect(() => {
    return () => {
      clearMinistryLandingPageError();
    };
  }, [clearMinistryLandingPageError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <ReactQuill
            readOnly={loading}
            onChange={(text) => textFieldValueChanged("content", text)}
            value={content}
            theme="snow"
            modules={modules}
            formats={formats}></ReactQuill>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} Update Landing Page Details
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    updateMinistryLandingPage: (ministry_id: string, content: string) => dispatch(updateMinistryLandingPage(ministry_id, content)),
    clearMinistryLandingPageError: () => dispatch(clearMinistryLandingPageError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    appTheme: state.general.theme,
    ministryLandingPage: state.ministryLandingPage
  };
};

const UpsertLandingPageDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(UpsertLandingPageDetailsForm);

const UpsertLandingPageDetailsModal: React.FC<UpsertLandingPageDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="lg"
      title="Manage Landing Page"
      component={
        <UpsertLandingPageDetailsFormComponent contentData={props.contentData} ministryID={props.ministryID} onDismissed={props.onDismissed} />
      }
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UpsertLandingPageDetailsModal;
