import { AnyAction } from "redux";
import actions from "../../util/actions";
import { AttendancesReducerState } from "../../interfaces";

const initialState: AttendancesReducerState = {
  initial: false,
  loading: true,
  error: null,
  response: null,
  search: "",
  page: 0,
  pages: 0,
  data: [],
  total: 0,
  per_page: 20
};

const reducer = (state = initialState, action: AnyAction): AttendancesReducerState => {
  switch (action.type) {
    case actions.ATTENDANCES_HTTP_LOADING:
      return { ...state, response: null, loading: true, error: null };
    case actions.ATTENDANCES_HTTP_ERROR:
      if (action.payload.clear) {
        return { ...state, response: null, loading: false, error: null };
      }
      return { ...state, response: null, loading: false, error: { ...action.payload } };
    case actions.ATTENDANCES_HTTP_COMPLETE:
      return { ...state, initial: true, response: action.payload.response, loading: false, error: null };
    case actions.SET_ATTENDANCES_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
