import React, { useState, useEffect } from "react";
import DialogModal from "../../../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, MinistryKittyInterface, MinistryKittyReducerState } from "../../../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { Create } from "@material-ui/icons";
import validator from "validator";
import { addMinistryKitty, clearMinistryKittyError, updateMinistryKitty } from "../../../../../../store/actions";
import { useParams } from "react-router-dom";

interface UpsertMinistryKittyDetailsFormProps {
  appTheme: AppTheme;
  ministryKitty: MinistryKittyReducerState;
  ministryKittyData: MinistryKittyInterface | null;
  updateMinistryKitty: (ministry_kitty_id: string, ministry_id: string, kitty_name: string) => void;
  addMinistryKitty: (ministry_id: string, kitty_name: string) => void;
  clearMinistryKittyError: () => void;
}

interface UpsertMinistryKittyDetailsModalProps {
  open: boolean;
  ministryKittyData: MinistryKittyInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      }
    })
  );

const UpsertMinistryKittyDetailsForm: React.FC<UpsertMinistryKittyDetailsFormProps> = (props) => {
  const { appTheme, ministryKitty, ministryKittyData, addMinistryKitty, updateMinistryKitty, clearMinistryKittyError } = props;

  const { ministry_id } = useParams<{ ministry_id: string; ministry_name: string }>();

  const [_id] = useState<string>(ministryKittyData ? ministryKittyData.ministry_kitty_id : "");
  const [kittyName, setKittyName] = useState<string>(ministryKittyData ? ministryKittyData.kitty_name : "");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loading = ministryKitty.loading;

  const styles = useStyles(appTheme)();

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "kittyName":
        setKittyName(value);
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (validator.isEmpty(kittyName)) {
      setErrorMessage("The kitty name entered is not valid");
    } else {
      setErrorMessage("");

      if (ministryKittyData) {
        updateMinistryKitty(_id, ministry_id, kittyName);
      } else {
        addMinistryKitty(ministry_id, kittyName);
      }
    }
  };

  useEffect(() => {
    const requestData = ministryKitty.error ? ministryKitty : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [ministryKitty]);

  useEffect(() => {
    return () => {
      clearMinistryKittyError();
    };
  }, [clearMinistryKittyError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            value={kittyName}
            onChange={(event) => {
              textFieldValueChanged("kittyName", event.target.value);
            }}
            placeholder="Kitty Name (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Create className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} {props.ministryKittyData ? "Update Kitty Details" : "Add New Kitty"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    updateMinistryKitty: (ministry_kitty_id: string, ministry_id: string, kitty_name: string) =>
      dispatch(updateMinistryKitty(ministry_kitty_id, ministry_id, kitty_name)),
    addMinistryKitty: (ministry_id: string, kitty_name: string) => dispatch(addMinistryKitty(ministry_id, kitty_name)),
    clearMinistryKittyError: () => dispatch(clearMinistryKittyError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    ministryKitty: state.ministryKitty,
    appTheme: state.general.theme
  };
};

const UpsertMinistryKittyDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(UpsertMinistryKittyDetailsForm);

const UpsertMinistryKittyDetailsModal: React.FC<UpsertMinistryKittyDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title={props.ministryKittyData ? "Update Kitty Details" : "Add New Kitty"}
      component={<UpsertMinistryKittyDetailsFormComponent ministryKittyData={props.ministryKittyData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UpsertMinistryKittyDetailsModal;
