import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, GalleryInterface, GalleryReducerState } from "../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { Create } from "@material-ui/icons";
import validator from "validator";
import { addGallery, clearGalleryError, updateGallery } from "../../../../store/actions";

interface UpsertGalleryDetailsFormProps {
  appTheme: AppTheme;
  gallery: GalleryReducerState;
  galleryData: GalleryInterface | null;
  updateGallery: (_id: string, title: string, description: string) => void;
  addGallery: (title: string, description: string) => void;
  clearGalleryError: () => void;
}

interface UpsertGalleryDetailsModalProps {
  open: boolean;
  galleryData: GalleryInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      }
    })
  );

const UpsertGalleryDetailsForm: React.FC<UpsertGalleryDetailsFormProps> = (props) => {
  const { appTheme, gallery, galleryData, addGallery, updateGallery, clearGalleryError } = props;

  const [_id] = useState<string>(galleryData ? galleryData.gallery_id : "");
  const [title, setTitle] = useState<string>(galleryData ? galleryData.title : "");
  const [description, setDescription] = useState<string>(galleryData ? galleryData.description : "");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loading = gallery.loading;

  const styles = useStyles(appTheme)();

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "title":
        setTitle(value);
        break;
      case "description":
        setDescription(value);
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (validator.isEmpty(title)) {
      setErrorMessage("The gallery title entered is not valid");
    } else if (validator.isEmpty(description)) {
      setErrorMessage("The gallery description entered is not valid");
    } else {
      setErrorMessage("");

      if (galleryData) {
        updateGallery(_id, title, description);
      } else {
        addGallery(title, description);
      }
    }
  };

  useEffect(() => {
    const requestData = gallery.error ? gallery : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [gallery]);

  useEffect(() => {
    return () => {
      clearGalleryError();
    };
  }, [clearGalleryError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            value={title}
            onChange={(event) => {
              textFieldValueChanged("title", event.target.value);
            }}
            placeholder="Title (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Create className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            value={description}
            onChange={(event) => {
              textFieldValueChanged("description", event.target.value);
            }}
            placeholder="Description (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Create className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} {props.galleryData ? "Update Gallery Details" : "Add New Gallery"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    updateGallery: (_id: string, title: string, description: string) => dispatch(updateGallery(_id, title, description)),
    addGallery: (title: string, description: string) => dispatch(addGallery(title, description)),
    clearGalleryError: () => dispatch(clearGalleryError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    gallery: state.gallery,
    appTheme: state.general.theme
  };
};

const UpsertGalleryDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(UpsertGalleryDetailsForm);

const UpsertGalleryDetailsModal: React.FC<UpsertGalleryDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title={props.galleryData ? "Update Gallery Details" : "Add New Gallery"}
      component={<UpsertGalleryDetailsFormComponent galleryData={props.galleryData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UpsertGalleryDetailsModal;
