import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearUserError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.USER_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const resetUserPassword = (_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.USER_HTTP_LOADING
    });

    request({ method: "post", pathname: "resetUserPassword", data: { _id } })
      .then((response) => {
        dispatch({
          type: actions.USER_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.USER_HTTP_ERROR, error, dispatch);
      });
  };
};

export const enableDisableUser = (_id: string, status: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.USER_HTTP_LOADING
    });

    request({ method: "post", pathname: "enableDisableUser", data: { _id, status } })
      .then((response) => {
        dispatch({
          type: actions.USER_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.USER_HTTP_ERROR, error, dispatch);
      });
  };
};

export const addUser = (name: string, email: string, mobile_no: string, dob: string, residence: string, user_type: string, gender: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.USER_HTTP_LOADING
    });

    request({ method: "post", pathname: "addUser", data: { name, email, mobile_no, dob, residence, user_type, gender } })
      .then((response) => {
        dispatch({
          type: actions.USER_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.USER_HTTP_ERROR, error, dispatch);
      });
  };
};

export const updateUser = (
  _id: string,
  name: string,
  username: string,
  email: string,
  mobile_no: string,
  dob: string,
  residence: string,
  user_type: string,
  gender: string
) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.USER_HTTP_LOADING
    });

    request({ method: "post", pathname: "updateUser", data: { _id, name, username, email, mobile_no, dob, residence, user_type, gender } })
      .then((response) => {
        dispatch({
          type: actions.USER_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.USER_HTTP_ERROR, error, dispatch);
      });
  };
};

export const updateUserPermissions = (_id: string, permissions: Array<string>) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.USER_HTTP_LOADING
    });

    request({ method: "post", pathname: "updateUserPermissions", data: { _id, permissions: JSON.stringify(permissions) } })
      .then((response) => {
        dispatch({
          type: actions.USER_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.USER_HTTP_ERROR, error, dispatch);
      });
  };
};
