import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearMinistryContributionError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_CONTRIBUTION_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const addMinistryContribution = (
  ministry_id: string,
  title: string,
  instructions: string,
  from_date: string,
  to_date: string,
  target: number
) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_CONTRIBUTION_HTTP_LOADING
    });

    request({ method: "post", pathname: "addMinistryContribution", data: { ministry_id, title, instructions, from_date, to_date, target } })
      .then((response) => {
        dispatch({
          type: actions.MINISTRY_CONTRIBUTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.MINISTRY_CONTRIBUTION_HTTP_ERROR, error, dispatch);
      });
  };
};

export const updateMinistryContribution = (
  _id: string,
  ministry_id: string,
  title: string,
  instructions: string,
  from_date: string,
  to_date: string,
  target: number
) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_CONTRIBUTION_HTTP_LOADING
    });

    request({ method: "post", pathname: "updateMinistryContribution", data: { _id, ministry_id, title, instructions, from_date, to_date, target } })
      .then((response) => {
        dispatch({
          type: actions.MINISTRY_CONTRIBUTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.MINISTRY_CONTRIBUTION_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteMinistryContribution = (_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.MINISTRY_CONTRIBUTION_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteMinistryContribution", data: { _id } })
      .then((response) => {
        dispatch({
          type: actions.MINISTRY_CONTRIBUTION_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.MINISTRY_CONTRIBUTION_HTTP_ERROR, error, dispatch);
      });
  };
};
