import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const getEntities = (search: string, page: number, per_page: number) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.ENTITIES_HTTP_LOADING
    });

    request({
      method: "get",
      pathname: "getEntities",
      params: { search, page, per_page }
    })
      .then((response) => {
        dispatch({
          type: actions.ENTITIES_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });

        dispatch({
          type: actions.SET_ENTITIES_DETAILS,
          payload: {
            ...response.data.content
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.ENTITIES_HTTP_ERROR, error, dispatch);
      });
  };
};
