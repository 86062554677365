import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearAttendanceError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.ATTENDANCE_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const addAttendance = (title: string, date: string, requires_temp_check: boolean) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.ATTENDANCE_HTTP_LOADING
    });

    request({ method: "post", pathname: "addAttendance", data: { title, date, requires_temp_check } })
      .then((response) => {
        dispatch({
          type: actions.ATTENDANCE_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.ATTENDANCE_HTTP_ERROR, error, dispatch);
      });
  };
};

export const updateAttendance = (_id: string, title: string, date: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.ATTENDANCE_HTTP_LOADING
    });

    request({ method: "post", pathname: "updateAttendance", data: { _id, title, date } })
      .then((response) => {
        dispatch({
          type: actions.ATTENDANCE_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.ATTENDANCE_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteAttendance = (_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.ATTENDANCE_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteAttendance", data: { _id } })
      .then((response) => {
        dispatch({
          type: actions.ATTENDANCE_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.ATTENDANCE_HTTP_ERROR, error, dispatch);
      });
  };
};

export const getUserAttendance = (_id: string, search: string, page: number, per_page: number) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.ATTENDANCE_HTTP_LOADING
    });

    request({
      method: "get",
      pathname: "getUserAttendance",
      params: { _id, search, page, per_page }
    })
      .then((response) => {
        dispatch({
          type: actions.ATTENDANCE_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });

        dispatch({
          type: actions.SET_ATTENDANCE_DETAILS,
          payload: {
            ...response.data.content
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.ATTENDANCE_HTTP_ERROR, error, dispatch);
      });
  };
};

export const addUserAttendance = (_id: string, user_id: string, temp?: number) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.ATTENDANCE_HTTP_LOADING
    });

    request({ method: "post", pathname: "addUserAttendance", data: { _id, user_id, temp } })
      .then((response) => {
        dispatch({
          type: actions.ATTENDANCE_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.ATTENDANCE_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteUserAttendance = (_id: string, user_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.ATTENDANCE_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteUserAttendance", data: { _id, user_id } })
      .then((response) => {
        dispatch({
          type: actions.ATTENDANCE_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.ATTENDANCE_HTTP_ERROR, error, dispatch);
      });
  };
};
