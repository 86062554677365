import React from "react";
import { connect } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ErrorRounded } from "@material-ui/icons";
import { Grid, Typography, Button } from "@material-ui/core";
import { AppState, AppTheme } from "../interfaces";

interface ErrorViewProps {
  viewSize?: "small" | "large";
  width?: number | string;
  error: {
    status?: number;
    data?: any;
    message: string;
  };
  onRetry: () => void;
  appTheme: AppTheme;
}

const useStyles = (viewSize: string, width: number | string, appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        display: "block",
        margin: "auto",
        width: width,
        paddingTop: 20,
        paddingBottom: 40
      },
      icon: {
        fontSize: viewSize === "large" ? 150 : 75,
        display: "block",
        margin: "auto"
      },
      title: {
        color: appTheme.titleTextColor,
        display: "block",
        margin: "auto"
      },
      body: {
        color: theme.palette.text.primary,
        display: "block",
        margin: "auto"
      }
    })
  );

const ErroView: React.FC<ErrorViewProps> = (props) => {
  let viewSize = "large";

  if (props.viewSize) {
    viewSize = props.viewSize;
  }

  const styles = useStyles(viewSize, props.width ? props.width : "100%", props.appTheme)();

  const isNetworkError: boolean = props.error.status ? false : props.error.message === "Network Error";

  return (
    <Grid container spacing={3} justifyContent="center" className={styles.root}>
      <Grid item xs={12}>
        <ErrorRounded color="primary" className={styles.icon} />
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" variant={viewSize === "large" ? "h4" : "h5"} className={styles.title}>
          Oops!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" variant={viewSize === "large" ? "subtitle1" : "subtitle2"} className={styles.body}>
          {isNetworkError
            ? "We are unable to reach our servers due to no internet connection"
            : "Seems an error has occurred on our end. Bug report has already been submitted to our engineers"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          size={viewSize === "large" ? "medium" : "small"}
          style={{ display: "block", margin: "auto" }}
          variant="contained"
          color="primary"
          onClick={props.onRetry}>
          Retry Connection
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps)(React.memo(ErroView));
