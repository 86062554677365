import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppState, AppTheme, MinistryLandingPageReducerState, ProfileReducerState } from "../../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, Container, Paper, Typography, Button, CircularProgress } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { checkUserAccess } from "../../../../../util/permission-checker";
import { clearMinistryLandingPageError, getMinistryLandingPage } from "../../../../../store/actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ErrorView } from "../../../../../components";
import UpsertLandingPageDetailsModal from "./Modals/UpsertLandingPageDetailsDialog";

interface LandingPageProps {
  profile: ProfileReducerState;
  appTheme: AppTheme;
  ministryLandingPage: MinistryLandingPageReducerState;
  getMinistryLandingPage: (ministry_id: string) => void;
  clearMinistryLandingPageError: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 15,
        marginTop: 10,
        marginBottom: 10
      }
    })
  );

const LandingPage: React.FC<LandingPageProps> = (props) => {
  const { ministry_id, ministry_name } = useParams<{ ministry_id: string; ministry_name: string }>();

  const { appTheme, profile, ministryLandingPage, getMinistryLandingPage, clearMinistryLandingPageError } = props;

  const [upsertLandingPageDetailsModelOpen, setUpsertLandingPageDetailsModelOpen] = useState<boolean>(false);

  const styles = useStyles(appTheme)();

  useEffect(() => {
    getMinistryLandingPage(ministry_id);
    return () => {
      clearMinistryLandingPageError();
    };
  }, [ministry_id, getMinistryLandingPage, clearMinistryLandingPageError]);

  return (
    <div className={styles.root}>
      <UpsertLandingPageDetailsModal
        open={upsertLandingPageDetailsModelOpen}
        onDismissed={() => setUpsertLandingPageDetailsModelOpen(false)}
        contentData={ministryLandingPage.content}
        ministryID={ministry_id}
      />
      <Container maxWidth="md">
        <Paper className={styles.paper}>
          <Grid container justifyContent="space-between" spacing={0}>
            <Grid item>
              <Typography variant="h3" color="primary">
                Landing Page
              </Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    disabled={!checkUserAccess(`edit-ministry-${ministry_id}-landing-page`, profile.permissions)}
                    variant="outlined"
                    size="large"
                    onClick={() => setUpsertLandingPageDetailsModelOpen(true)}>
                    Edit
                  </Button>
                </Grid>
                <Grid item>
                  <Button component={Link} to={`/dashboard/ministries/${ministry_id}/${ministry_name}`} variant="outlined" size="large">
                    Go Back
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          {ministryLandingPage.error ? (
            <ErrorView error={ministryLandingPage.error} onRetry={() => getMinistryLandingPage(ministry_id)} />
          ) : ministryLandingPage.loading ? (
            <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />
          ) : !!ministryLandingPage.content ? (
            <div style={{ width: "100%", maxWidth: "100%" }}>
              <div dangerouslySetInnerHTML={{ __html: ministryLandingPage.content.replace(/<br>/g, "") }} />
            </div>
          ) : (
            <Typography variant="h6" color="primary" align="center" style={{ marginTop: "30vh", marginBottom: "30vh" }}>
              No Content Available
            </Typography>
          )}
        </Paper>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getMinistryLandingPage: (ministry_id: string) => dispatch(getMinistryLandingPage(ministry_id)),
    clearMinistryLandingPageError: () => dispatch(clearMinistryLandingPageError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    profile: state.profile,
    appTheme: state.general.theme,
    ministryLandingPage: state.ministryLandingPage
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
