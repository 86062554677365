import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, UserInterface, UserReducerState } from "../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress, Select } from "@material-ui/core";
import { CalendarToday, Lock, Mail, Person, Call, Home } from "@material-ui/icons";
import validator from "validator";
import { addUser, clearUserError, updateUser } from "../../../../store/actions";
import dateformat from "dateformat";
import { Switch } from "../../../../components";

interface UpsertUserDetailsFormProps {
  appTheme: AppTheme;
  user: UserReducerState;
  userData: UserInterface | null;
  updateUser: (
    _id: string,
    name: string,
    username: string,
    email: string,
    mobile_no: string,
    dob: string,
    residence: string,
    user_type: string,
    gender: string
  ) => void;
  addUser: (name: string, email: string, mobile_no: string, dob: string, residence: string, user_type: string, gender: string) => void;
  clearUserError: () => void;
}

interface UpsertUserDetailsModalProps {
  open: boolean;
  userData: UserInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      }
    })
  );

const UpsertUserDetailsForm: React.FC<UpsertUserDetailsFormProps> = (props) => {
  const { appTheme, user, userData, addUser, updateUser, clearUserError } = props;

  const [_id] = useState<string>(userData ? userData.user_id : "");
  const [name, setName] = useState<string>(userData ? userData.name : "");
  const [username, setUsername] = useState<string>(userData ? userData.username : "");
  const [email, setEmail] = useState<string>(userData ? userData.email : "");
  const [mobileNo, setMobileNo] = useState<string>(userData ? userData.mobile_no : "");
  const [gender, setGender] = useState<string>(userData ? userData.gender ?? "Male" : "Male");
  const [dob, setDOB] = useState<string>(userData ? userData.dob : "");
  const [residence, setResidence] = useState<string>(userData ? userData.residence : "");
  const [userType, setUserType] = useState<string>(userData ? userData.user_type ?? "Member" : "Member");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loading = user.loading;

  const styles = useStyles(appTheme)();

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "name":
        setName(value);
        break;
      case "username":
        setUsername(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "mobileNo":
        setMobileNo(value);
        break;
      case "dob":
        setDOB(value);
        break;
      case "residence":
        setResidence(value);
        break;
      case "userType":
        setUserType(value);
        break;
      case "gender":
        setGender(value);
        break;
      default:
        break;
    }
  };

  const validateUnrequired = (field: string, value: string) => {
    if (field === "username") {
      if (userData) {
        if (validator.isEmpty(value)) {
          setErrorMessage("The username entered is not valid");
          return false;
        } else {
          return true;
        }
      } 

      return true;
    }

    if (validator.isEmpty(value)) {
      return true;
    } else {
      if (field === "email") {
        if (!validator.isEmail(value)) {
          setErrorMessage("The email address entered is not valid");
          return false;
        } else {
          return true;
        }
      }

      if (field === "mobile_no") {
        if (value.length !== 10) {
          setErrorMessage("The mobile number entered is not valid");
          return false;
        } else {
          return true;
        }
      }

      return false
    }
  };

  const submitForm = () => {
    if (validator.isEmpty(name)) {
      setErrorMessage("The name entered is not valid");
    } else if (!validateUnrequired("username", username)) {
    } else if (!validateUnrequired("email", email)) {
    } else if (!validateUnrequired("mobile_no", mobileNo)) {
    } else if (validator.isEmpty(dob)) {
      setErrorMessage("The date of birth entered is not valid");
    } else if (validator.isEmpty(residence)) {
      setErrorMessage("The residence entered is not valid");
    } else if (validator.isEmpty(userType)) {
      setErrorMessage("The user type entered is not valid");
    } else {
      setErrorMessage("");
      if (userData) {
        updateUser(_id, name, username, email, mobileNo, dob, residence, userType, gender);
      } else {
        addUser(name, email, mobileNo, dob, residence, userType, gender);
      }
    }
  };

  useEffect(() => {
    const requestData = user.error ? user : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [user]);

  useEffect(() => {
    return () => {
      clearUserError();
    };
  }, [clearUserError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            value={name}
            onChange={(event) => {
              textFieldValueChanged("name", event.target.value);
            }}
            placeholder="Name (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Person className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        {userData && (
          <Grid item xs={12}>
            <InputBase
              required
              type="text"
              value={username}
              onChange={(event) => {
                textFieldValueChanged("username", event.target.value);
              }}
              placeholder="Username (Required)"
              className={styles.textField}
              startAdornment={
                <InputAdornment position="start">
                  <Lock className={styles.textFieldIcon} />
                </InputAdornment>
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <InputBase
            type="email"
            value={email}
            onChange={(event) => {
              textFieldValueChanged("email", event.target.value);
            }}
            placeholder="Email"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Mail className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            type="number"
            value={mobileNo}
            onChange={(event) => {
              textFieldValueChanged("mobileNo", event.target.value);
            }}
            placeholder="Mobile Number"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Call className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Gender (Required)
          </Typography>
          <Select
            native
            className={styles.textField}
            value={gender}
            onChange={(event) => {
              textFieldValueChanged("gender", event.target.value as string);
            }}>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Date of Birth (Required)
          </Typography>
          <InputBase
            required
            inputProps={{ max: dateformat(new Date(), "yyyy-mm-dd") }}
            type="date"
            defaultValue={dob}
            onChange={(event) => {
              textFieldValueChanged("dob", event.target.value);
            }}
            placeholder="Date of Birth (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <CalendarToday className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            defaultValue={residence}
            onChange={(event) => {
              textFieldValueChanged("residence", event.target.value);
            }}
            placeholder="Residence (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Home className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            User Type
          </Typography>
          <Typography variant="body1" onClick={() => setUserType(userType === "Member" ? "Guest" : "Member")}>
            Is An Official Member <Switch checked={userType === "Member"} />
          </Typography>
          <br />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} {props.userData ? "Update User Details" : "Add New User"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    updateUser: (
      _id: string,
      name: string,
      username: string,
      email: string,
      mobile_no: string,
      dob: string,
      residence: string,
      user_type: string,
      gender: string
    ) => dispatch(updateUser(_id, name, username, email, mobile_no, dob, residence, user_type, gender)),
    addUser: (name: string, email: string, mobile_no: string, dob: string, residence: string, user_type: string, gender: string) =>
      dispatch(addUser(name, email, mobile_no, dob, residence, user_type, gender)),
    clearUserError: () => dispatch(clearUserError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    user: state.user,
    appTheme: state.general.theme
  };
};

const UpsertUserDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(UpsertUserDetailsForm);

const UpsertUserDetailsModal: React.FC<UpsertUserDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title={props.userData ? "Update User Details" : "Add New User"}
      component={<UpsertUserDetailsFormComponent userData={props.userData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UpsertUserDetailsModal;
