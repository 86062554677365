import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { alpha, Container, Grid, Typography } from "@material-ui/core";

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        paddingTop: 60,
        paddingBottom:60
      },
      title: {
        color: theme.palette.common.black,
        fontSize: "2rem"
      },
      text: {
        color: alpha(theme.palette.common.black, 0.5),
        wordWrap: "break-word",
        fontSize: 18,
        lineHeight: 2,
        display: "block"
      },
      img: {
        width: "100%",
        height: 750,
        objectFit: "contain",
        [theme.breakpoints.down("sm")]: {
          height: 250,
          objectFit: "contain"
        }
      }
    })
  );

const ServiceTimes: React.FC = () => {
  const styles = useStyles()();

  return (
    <div className={styles.root}>
      <Container maxWidth="md">
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h2" className={styles.title} align="center">
                    {/*Our Services*/}
                </Typography>
            </Grid>
            <Grid item>
            <Typography variant="body1" className={styles.text} align="center">
                {/*We have the following services that take place*/}
            </Typography>
            </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ServiceTimes;
