import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearProjectError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.PROJECT_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const addProject = (title: string, status: string, content: string, cover_img: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.PROJECT_HTTP_LOADING
    });

    request({ method: "post", pathname: "addProject", data: { title, status, content, cover_img } })
      .then((response) => {
        dispatch({
          type: actions.PROJECT_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.PROJECT_HTTP_ERROR, error, dispatch);
      });
  };
};

export const updateProject = (_id: string, title: string, status: string, content: string, cover_img: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.PROJECT_HTTP_LOADING
    });

    request({ method: "post", pathname: "updateProject", data: { _id, title, status, content, cover_img } })
      .then((response) => {
        dispatch({
          type: actions.PROJECT_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.PROJECT_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteProject = (_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.PROJECT_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteProject", data: { _id } })
      .then((response) => {
        dispatch({
          type: actions.PROJECT_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.PROJECT_HTTP_ERROR, error, dispatch);
      });
  };
};
