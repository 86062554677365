import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, ContributionInterface, ContributionReducerState } from "../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { CalendarToday, Create, Edit } from "@material-ui/icons";
import validator from "validator";
import { addContribution, clearContributionError, updateContribution } from "../../../../store/actions";

interface UpsertContributionDetailsFormProps {
  appTheme: AppTheme;
  contribution: ContributionReducerState;
  contributionData: ContributionInterface | null;
  updateContribution: (contribution_id: string, title: string, instructions: string, from_date: string, to_date: string, target: number) => void;
  addContribution: (title: string, instructions: string, from_date: string, to_date: string, target: number) => void;
  clearContributionError: () => void;
}

interface UpsertContributionDetailsModalProps {
  open: boolean;
  contributionData: ContributionInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      }
    })
  );

const UpsertContributionDetailsForm: React.FC<UpsertContributionDetailsFormProps> = (props) => {
  const { appTheme, contribution, contributionData, addContribution, updateContribution, clearContributionError } = props;

  const [_id] = useState<string>(contributionData ? contributionData.contribution_id : "");
  const [title, setTitle] = useState<string>(contributionData ? contributionData.title : "");
  const [instructions, setInstructions] = useState<string>(contributionData ? contributionData.instructions : "");
  const [fromDate, setFromDate] = useState<string>(contributionData ? contributionData.from_date : "");
  const [toDate, setToDate] = useState<string>(contributionData ? contributionData.to_date : "");
  const [target, setTarget] = useState<string>(contributionData ? contributionData.target.toString() ?? "" : "");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loading = contribution.loading;

  const styles = useStyles(appTheme)();

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "title":
        setTitle(value);
        break;
      case "instructions":
        setInstructions(value);
        break;
      case "fromDate":
        setFromDate(value);
        break;
      case "toDate":
        setToDate(value);
        break;
      case "target":
        setTarget(value);
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (validator.isEmpty(title)) {
      setErrorMessage("The contribution title entered is not valid");
    } else if (validator.isEmpty(instructions)) {
      setErrorMessage("The contribution instructions entered are not valid");
    } else if (instructions.length > 150) {
      setErrorMessage("The contribution instructions entered cannot be more than 150 characters in length");
    } else if (validator.isEmpty(fromDate)) {
      setErrorMessage("The contribution from date entered is not valid");
    } else if (validator.isEmpty(toDate)) {
      setErrorMessage("The contribution to date entered is not valid");
    } else if (validator.isEmpty(target)) {
      setErrorMessage("The contribution target entered is not valid");
    } else {
      const start_time = new Date(fromDate + " 00:00:00").getTime(),
        end_time = new Date(toDate + " 00:00:00").getTime();

      if (start_time > end_time) {
        setErrorMessage("Start contribution cannot be greater than to contribution date");
        return;
      }

      if (Number.isNaN(parseFloat(target))) {
        setErrorMessage("The contribution target entered is not valid");
        return;
      }

      setErrorMessage("");

      if (contributionData) {
        updateContribution(_id, title, instructions, fromDate, toDate, parseFloat(target));
      } else {
        addContribution(title, instructions, fromDate, toDate, parseFloat(target));
      }
    }
  };

  useEffect(() => {
    const requestData = contribution.error ? contribution : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [contribution]);

  useEffect(() => {
    return () => {
      clearContributionError();
    };
  }, [clearContributionError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            value={title}
            onChange={(event) => {
              textFieldValueChanged("title", event.target.value);
            }}
            placeholder="Title (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Create className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            required
            multiline
            rows={4}
            type="text"
            value={instructions}
            onChange={(event) => {
              textFieldValueChanged("instructions", event.target.value);
            }}
            placeholder="Contribution Instrcutions (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Edit className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Contributions From (Required)
          </Typography>
          <InputBase
            required
            type="date"
            defaultValue={fromDate}
            onChange={(event) => {
              textFieldValueChanged("fromDate", event.target.value);
            }}
            placeholder="Contributions From (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <CalendarToday className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Contributions To (Required)
          </Typography>
          <InputBase
            required
            type="date"
            defaultValue={toDate}
            onChange={(event) => {
              textFieldValueChanged("toDate", event.target.value);
            }}
            placeholder="Contributions To (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <CalendarToday className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            required
            type="number"
            value={target}
            onChange={(event) => {
              textFieldValueChanged("target", event.target.value);
            }}
            placeholder="Target (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Create className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} {props.contributionData ? "Update Contribution Details" : "Add New Contribution"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    updateContribution: (contribution_id: string, title: string, instructions: string, from_date: string, to_date: string, target: number) =>
      dispatch(updateContribution(contribution_id, title, instructions, from_date, to_date, target)),
    addContribution: (title: string, instructions: string, from_date: string, to_date: string, target: number) =>
      dispatch(addContribution(title, instructions, from_date, to_date, target)),
    clearContributionError: () => dispatch(clearContributionError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    contribution: state.contribution,
    appTheme: state.general.theme
  };
};

const UpsertContributionDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(UpsertContributionDetailsForm);

const UpsertContributionDetailsModal: React.FC<UpsertContributionDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title={props.contributionData ? "Update Contribution Details" : "Add New Contribution"}
      component={<UpsertContributionDetailsFormComponent contributionData={props.contributionData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UpsertContributionDetailsModal;
