import { createTheme, ThemeOptions } from "@material-ui/core";

const theme: ThemeOptions = {
    typography: {
      fontFamily: `${"Poppins"}, Arial, sans-serif`
    },
    palette: {
      type: "light",
      primary: {
        main: "#0587b2",
        dark: "#18c5fa",
        light: "#139ec8"
      },
      background: {
        paper: "#fff",
        default: "#fafafa"
      }
    }
  };


export default createTheme(theme)