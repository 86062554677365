import { Dispatch } from "redux";
import actions from "../../util/actions";
import axios from "axios";
import errorHandler from "../../util/error-handler";
import request from "../../util/request";

export const clearFileError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.FILE_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const uploadFiles = (filePaths: Array<string>, files: FileList) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.FILE_HTTP_LOADING
    });

    let promises = [];

    for (let i = 0; i < filePaths.length; i++) {
      promises.push(request({ method: "post", pathname: "getS3UploadUrl", data: { filePath: filePaths[i], fileType: files[i].type } }));
    }

    Promise.all(promises)
      .then((response) => {
        promises = [];

        for (let i = 0; i < response.length; i++) {
          promises.push(
            axios.put((response[i].data as any).content.url, files[i], {
              headers: {
                "Content-Type": files[i].type
              }
            })
          );
        }

        return Promise.all(promises);
      })
      .then((response) => {
        dispatch({
          type: actions.FILE_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.FILE_HTTP_ERROR, error, dispatch);
      });
  };
};
