import { AnyAction } from "redux";
import actions from "../../util/actions";
import { NotificationReducerState } from "../../interfaces";

const initialState: NotificationReducerState = {
  initial: false,
  loading: false,
  error: null,
  response: null
};

const reducer = (state = initialState, action: AnyAction): NotificationReducerState => {
  switch (action.type) {
    case actions.NOTIFICATION_HTTP_LOADING:
      return { ...state, response: null, loading: true, error: null };
    case actions.NOTIFICATION_HTTP_ERROR:
      if (action.payload.clear) {
        return { ...state, response: null, loading: false, error: null };
      }
      return { ...state, response: null, loading: false, error: { ...action.payload } };
    case actions.NOTIFICATION_HTTP_COMPLETE:
      return { ...state, initial: true, response: action.payload.response, loading: false, error: null };
    default:
      return state;
  }
};

export default reducer;
