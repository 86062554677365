import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, ProfileReducerState, BlogInterface, BlogReducerState, BlogsReducerState } from "../../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Container,
  Paper,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { DialogModal, ErrorView } from "../../../components";
import { clearBlogError, getBlogs, deleteBlog } from "../../../store/actions";
import { checkUserAccess } from "../../../util/permission-checker";
import PopupMenuDialog from "../../../components/PopupMenuDialog";
import UpsertBlogDetailsModal from "./Modals/UpsertBlogDetailsDialog";
import dateformat from "dateformat";
import ViewBlogContentDialog from "./Modals/ViewBlogContentDialog";

interface BlogsProps {
  blogs: BlogsReducerState;
  blog: BlogReducerState;
  profile: ProfileReducerState;
  appTheme: AppTheme;
  getBlogs: (search: string, page: number, per_page: number) => void;
  clearBlogError: () => void;
  deleteBlog: (_id: string) => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 30,
        marginTop: 20,
        marginBottom: 20
      },
      icon: {
        color: theme.palette.text.primary,
        padding: 10,
        borderRadius: 30,
        backgroundColor: appTheme.iconBackgroundColor,
        "&:hover": {
          backgroundColor: appTheme.iconBackgroundHoverColor
        }
      },
      searchButton: {
        marginRight: "-21px",
        height: "55px",
        borderRadius: "30px",
        width: "100px"
      },
      textInput: {
        marginTop: "5px",
        width: "300px"
      },
      textInputControl: {
        borderRadius: "30px",
        backgroundColor: appTheme.section.backgroundColor
      },
      container: {
        minHeight: "60vh"
      },
      textInputIcon: {
        color: "#000"
      }
    })
  );

const Blogs: React.FC<BlogsProps> = (props) => {
  const { appTheme, blogs, blog, profile, getBlogs, deleteBlog, clearBlogError } = props;

  const [search, setSearch] = useState<string>(blogs.search);
  const [page, setPage] = useState<number>(blogs.page);
  const [perPage, setPerPage] = useState<number>(blogs.per_page ?? 20);
  const [selectedRecord, setSelectedRecord] = useState<BlogInterface | null>(null);
  const [popUpDialogOpen, setPopUpDialogOpen] = useState<boolean>(false);

  const [upsertBlogDetailsModelOpen, setUpsertBlogDetailsModelOpen] = useState<boolean>(false);
  const [viewBlogContentModelOpen, setViewBlogContentModelOpen] = useState<boolean>(false);
  const popUpMenuItems = useMemo(() => {
    return [
      { text: "Update Blog Details", permission: "edit-blog" },
      { text: "Delete Blog", permission: "delete-blog" }
    ];
  }, []);

  const [menuItems, setMenuItems] = useState<Array<string>>(
    popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text)
  );

  useEffect(() => {
    setMenuItems(popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text));
  }, [popUpMenuItems, profile.permissions]);

  useEffect(() => {
    getBlogs(search, page, perPage);
  }, [search, page, perPage, getBlogs]);

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearBlogError();
    };
  }, [clearBlogError]);

  useEffect(() => {
    if (!blog.error && blog.response) {
      clearBlogError();

      setSelectedRecord(null);

      setUpsertBlogDetailsModelOpen(false);

      setViewBlogContentModelOpen(false);

      setDialogOptions({ open: true, body: blog.response.data.status });

      getBlogs("", 0, perPage);
    }
  }, [blog.error, blog.response, perPage, getBlogs, clearBlogError]);

  useEffect(() => {
    if (upsertBlogDetailsModelOpen || viewBlogContentModelOpen) {
      return;
    }

    const requestData = blog.error ? blog : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [blog, upsertBlogDetailsModelOpen, viewBlogContentModelOpen]);

  const onMenuItemSelected = (menuItem: string) => {
    setPopUpDialogOpen(false);

    if (!selectedRecord) {
      return;
    }

    switch (menuItem) {
      case "Update Blog Details":
        setUpsertBlogDetailsModelOpen(true);
        break;
      case "Delete Blog":
        if (window.confirm("Would you like to delete this blog ?")) {
          deleteBlog(selectedRecord.blog_id);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <UpsertBlogDetailsModal open={upsertBlogDetailsModelOpen} onDismissed={() => setUpsertBlogDetailsModelOpen(false)} blogData={selectedRecord} />
      <ViewBlogContentDialog open={viewBlogContentModelOpen} onDismissed={() => setViewBlogContentModelOpen(false)} blogData={selectedRecord} />
      <PopupMenuDialog
        open={popUpDialogOpen}
        onDismissed={() => setPopUpDialogOpen(false)}
        onMenuItemSelected={onMenuItemSelected}
        menuItems={menuItems}
      />
      <Container maxWidth="lg">
        {blogs.error ? (
          <ErrorView error={blogs.error} onRetry={() => getBlogs(search, page, perPage)} />
        ) : (
          <React.Fragment>
            <Paper className={styles.paper}>
              <Grid container justifyContent="space-between" spacing={0}>
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      {(blogs.loading || blog.loading) && <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />}
                    </Grid>
                    <Grid item>
                      <Typography variant="h3" color="primary">
                        Blogs
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(blog) => {
                      setSearch(blog.currentTarget.value);
                    }}
                    error={false}
                    value={search}
                    variant="outlined"
                    placeholder="Search ..."
                    className={styles.textInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search className={styles.textInputIcon} />
                        </InputAdornment>
                      ),
                      classes: { root: styles.textInputControl }
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <TableContainer className={styles.container}>
                <Table stickyHeader aria-label="blogs">
                  <TableHead>
                    <TableRow>
                      {[
                        { label: "ID", minWidth: 100 },
                        { label: "Title", minWidth: 150 },
                        { label: "Author", minWidth: 150 },
                        { label: "Views", minWidth: 50 },
                        { label: "Created At", minWidth: 150 },
                        { label: "Cover Image", minWidth: 100 },
                        { label: "Content", minWidth: 100 },
                        { label: "Options", minWidth: 100 }
                      ].map((column) => (
                        <TableCell key={column.label} align="center" style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {blogs.data.map((blog: BlogInterface) => {
                      const _id = blog.blog_id;
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={_id}>
                          {["blog_id", "title", "author_name", "views", "added_on", "cover_img", "content", "options"].map((column) => {
                            const key = `${_id}-${column}`;

                            const data = (blog as unknown as { [key: string]: any })[column];
                            if (column === "options") {
                              if (menuItems.length === 0) return <TableCell key={key} align="center"></TableCell>;
                              return (
                                <TableCell key={key} align="center">
                                  <Button
                                    variant="outlined"
                                    disabled={menuItems.length === 0}
                                    onClick={() => {
                                      setSelectedRecord(blog);
                                      setPopUpDialogOpen(true);
                                    }}>
                                    Options
                                  </Button>
                                </TableCell>
                              );
                            } else if (column === "added_on") {
                              return (
                                <TableCell key={key} align="center">
                                  {dateformat(data, "ddd mmm dd yyyy h:MM TT")}
                                </TableCell>
                              );
                            } else if (column === "content") {
                              return (
                                <TableCell key={key} align="center">
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      setSelectedRecord(blog);
                                      setViewBlogContentModelOpen(true);
                                    }}>
                                    Show Content
                                  </Button>
                                </TableCell>
                              );
                            } else if (column === "cover_img") {
                              return (
                                <TableCell key={key} align="center">
                                  <a href={data} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "blue" }}>
                                    Click To View
                                  </a>
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={key} align="center">
                                  {data}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container justifyContent="space-between" style={{ margin: "0px 10px 0px 10px" }}>
                <Grid item>
                  <Button
                    disabled={!checkUserAccess("add-blog", profile.permissions)}
                    variant="outlined"
                    size="large"
                    onClick={() => {
                      setSelectedRecord(null);
                      setUpsertBlogDetailsModelOpen(true);
                    }}>
                    Add New Blog
                  </Button>
                </Grid>
                <Grid item>
                  <TablePagination
                    component="div"
                    count={blogs.total}
                    page={page}
                    onPageChange={(_, page) => setPage(page)}
                    rowsPerPage={perPage}
                    rowsPerPageOptions={[20, 50, 100, 1000]}
                    onRowsPerPageChange={(blog) => {
                      setPerPage(parseInt(blog.target.value));
                      setPage(0);
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getBlogs: (search: string, page: number, per_page: number) => dispatch(getBlogs(search, page, per_page)),
    clearBlogError: () => dispatch(clearBlogError()),
    deleteBlog: (_id: string) => dispatch(deleteBlog(_id))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    blogs: state.blogs,
    blog: state.blog,
    profile: state.profile,
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
