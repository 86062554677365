import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";

export const clearVerseError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.VERSE_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const addVerse = (
  verse_date: string,
  verse_bId_from: number,
  verse_cId_from: number,
  verse_vId_from: number,
  verse_bId_to: number,
  verse_cId_to: number,
  verse_vId_to: number
) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.VERSE_HTTP_LOADING
    });

    request({
      method: "post",
      pathname: "addVerse",
      data: { verse_date, verse_bId_from, verse_cId_from, verse_vId_from, verse_bId_to, verse_cId_to, verse_vId_to }
    })
      .then((response) => {
        dispatch({
          type: actions.VERSE_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.VERSE_HTTP_ERROR, error, dispatch);
      });
  };
};

export const updateVerse = (
  _id: string,
  verse_date: string,
  verse_bId_from: number,
  verse_cId_from: number,
  verse_vId_from: number,
  verse_bId_to: number,
  verse_cId_to: number,
  verse_vId_to: number
) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.VERSE_HTTP_LOADING
    });

    request({
      method: "post",
      pathname: "updateVerse",
      data: { _id, verse_date, verse_bId_from, verse_cId_from, verse_vId_from, verse_bId_to, verse_cId_to, verse_vId_to }
    })
      .then((response) => {
        dispatch({
          type: actions.VERSE_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.VERSE_HTTP_ERROR, error, dispatch);
      });
  };
};

export const deleteVerse = (_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.VERSE_HTTP_LOADING
    });

    request({ method: "post", pathname: "deleteVerse", data: { _id } })
      .then((response) => {
        dispatch({
          type: actions.VERSE_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });
      })
      .catch((error) => {
        errorHandler(actions.VERSE_HTTP_ERROR, error, dispatch);
      });
  };
};

export const getFullVerse = (_id: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.VERSE_HTTP_LOADING
    });

    request({
      method: "get",
      pathname: "getFullVerse",
      params: { _id }
    })
      .then((response) => {
        dispatch({
          type: actions.VERSE_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });

        dispatch({
          type: actions.SET_FULL_VERSE_DETAILS,
          payload: response.data.content
        });
      })
      .catch((error) => {
        errorHandler(actions.VERSE_HTTP_ERROR, error, dispatch);
      });
  };
};
