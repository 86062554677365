import React, { useState, useEffect } from "react";
import DialogModal from "../../../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  AppState,
  AppTheme,
  MinistryMemberContributionReducerState,
  MinistryContributionInterface,
  EntitiesReducerState,
  EntityInterface,
  UserInterface,
  MinistryMembershipReducerState
} from "../../../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { ListAlt, Person, CalendarToday, AttachMoney } from "@material-ui/icons";
import validator from "validator";
import {
  addMinistryMemberContribution,
  clearMinistryContributionError,
  clearUserError,
  getMinistryMembership,
  getEntities
} from "../../../../../../store/actions";
import dateformat from "dateformat";
import { Switch } from "../../../../../../components";
import { useParams } from "react-router-dom";

interface AddMinistryMemberMinistryContributionDetailsFormProps {
  appTheme: AppTheme;
  ministryMemberContribution: MinistryMemberContributionReducerState;
  ministryContributionData: MinistryContributionInterface | null;
  ministryMembership: MinistryMembershipReducerState;
  entities: EntitiesReducerState;
  addMinistryMemberContribution: (
    _id: string,
    subtractor_id: string,
    subtractor_type: "Other" | "User",
    amount: number,
    date: string,
    transaction_details: string
  ) => void;
  clearMinistryContributionError: () => void;
  clearUserError: () => void;
  getMinistryMembership: (ministry_id: string, search: string) => void;
  getEntities: (search: string) => void;
}

interface AddMinistryMemberMinistryContributionDetailsModalProps {
  open: boolean;
  ministryContributionData: MinistryContributionInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      },
      autocompleteItems: {
        position: "absolute",
        border: "1px solid #d4d4d4",
        borderBottom: "none",
        borderTop: "none",
        top: "100%",
        left: "0",
        right: "0",
        zIndex: 16000
      },
      autocompleteItem: {
        padding: "10px",
        cursor: "pointer",
        backgroundColor: "#fff",
        borderBottom: "1px solid #d4d4d4"
      },
      autocomplete: {
        position: "relative",
        display: "inline-block",
        width: "100%"
      }
    })
  );

const AddMinistryMemberMinistryContributionDetailsForm: React.FC<AddMinistryMemberMinistryContributionDetailsFormProps> = (props) => {
  const {
    appTheme,
    ministryMemberContribution,
    ministryContributionData,
    ministryMembership,
    entities,
    addMinistryMemberContribution,
    clearMinistryContributionError,
    clearUserError,
    getMinistryMembership,
    getEntities
  } = props;

  const { ministry_id } = useParams<{ ministry_id: string; ministry_name: string }>();

  const [userEntity, setUserEntity] = useState<UserInterface | EntityInterface | null>();
  const [userEntitySearch, setUserEntityEntitySearch] = useState<string>("");
  const [userType, setUserEntityType] = useState<"User" | "Other">("User");
  const [date, setDate] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [transactionDetails, setTransactionDetails] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loading = ministryMemberContribution.loading;

  const styles = useStyles(appTheme)();

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "date":
        setDate(value);
        break;
      case "amount":
        setAmount(value);
        break;
      case "transactionDetails":
        setTransactionDetails(value);
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (userType === "User" && !userEntity) {
      setErrorMessage("Please select a member/entity contributing before procceding");
    } else if (validator.isEmpty(date)) {
      setErrorMessage("The contribution date entered is not valid");
    } else if (validator.isEmpty(amount)) {
      setErrorMessage("The contribution amount entered is not valid");
    } else {
      if (Number.isNaN(parseFloat(amount))) {
        setErrorMessage("The contribution amount entered is not valid");
        return;
      }

      setErrorMessage("");

      const _id = ministryContributionData ? ministryContributionData.contribution_id : "",
        subtractor_id =
          userType === "User"
            ? (userEntity as UserInterface).user_id
            : userEntity
            ? (userEntity as EntityInterface).user_id
            : "NAME:" + userEntitySearch;

      addMinistryMemberContribution(_id, subtractor_id, userType, parseFloat(amount), date, transactionDetails);
    }
  };

  useEffect(() => {
    const requestData = ministryMemberContribution.error ? ministryMemberContribution : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [ministryMemberContribution]);

  useEffect(() => {
    getMinistryMembership(ministry_id, "");
    getEntities("");
    return () => {
      clearMinistryContributionError();
      clearUserError();
    };
  }, [ministry_id, getMinistryMembership, getEntities, clearMinistryContributionError, clearUserError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Member Type (Required)
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Typography
                variant="body1"
                onClick={() => {
                  setUserEntityType("User");
                  setUserEntity(null);
                  setUserEntityEntitySearch("");
                }}>
                <Switch checked={userType === "User"} /> Member
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                onClick={() => {
                  setUserEntityType("Other");
                  setUserEntity(null);
                  setUserEntityEntitySearch("");
                }}>
                <Switch checked={userType === "Other"} /> Other
              </Typography>
            </Grid>
          </Grid>
          <br />
        </Grid>
        {userType === "User" ? (
          <Grid item xs={12}>
            <div className={styles.autocomplete}>
              <InputBase
                required
                type="text"
                value={userEntitySearch}
                onChange={(event) => {
                  const value = event.target.value;
                  if (userEntity?.name !== value) {
                    if (value.length > 0) {
                      getMinistryMembership(ministry_id, value);
                    }

                    setUserEntity(null);

                    setUserEntityEntitySearch(value);
                  }
                }}
                placeholder="Member (Required)"
                className={styles.textField}
                startAdornment={
                  <InputAdornment position="start">
                    <Person className={styles.textFieldIcon} />
                  </InputAdornment>
                }
              />

              {!userEntity && userEntitySearch.length > 0 && (
                <div className={styles.autocompleteItems}>
                  {ministryMembership.data.map((user: UserInterface) => (
                    <div
                      key={user?.user_id}
                      className={styles.autocompleteItem}
                      onClick={() => {
                        setUserEntity(user);
                        setUserEntityEntitySearch(user.name);
                      }}>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="body1">{"Name: " + user.name}</Typography>
                        </Grid>
                        {user.mobile_no && (
                          <Grid item>
                            <Typography variant="body1">{"Mobile No: " + user.mobile_no}</Typography>
                          </Grid>
                        )}
                        {user.email && (
                          <Grid item>
                            <Typography variant="body1">{"Email: " + user.email}</Typography>
                          </Grid>
                        )}
                        <Grid item>
                          <Typography variant="body1">{"Type: " + user.user_type}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">{"Residence: " + user.residence}</Typography>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <div className={styles.autocomplete}>
              <InputBase
                required
                type="text"
                value={userEntitySearch}
                onChange={(event) => {
                  const value = event.target.value;
                  if (userEntity?.name !== value) {
                    if (value.length > 0) {
                      getEntities(value);
                    }

                    setUserEntityEntitySearch(value);
                  }
                }}
                placeholder="Entity (Required)"
                className={styles.textField}
                startAdornment={
                  <InputAdornment position="start">
                    <Person className={styles.textFieldIcon} />
                  </InputAdornment>
                }
              />

              {!userEntity && userEntitySearch.length > 0 && (
                <div className={styles.autocompleteItems}>
                  {entities.data.map((entity: EntityInterface) => (
                    <div
                      key={entity?.user_id}
                      className={styles.autocompleteItem}
                      onClick={() => {
                        setUserEntity(entity);
                        setUserEntityEntitySearch(entity?.name);
                      }}>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="body1">{"Name: " + entity.name}</Typography>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Contribution Date (Required)
          </Typography>
          <InputBase
            required
            inputProps={{ min: ministryContributionData?.from_date, max: dateformat(new Date(), "yyyy-mm-dd") }}
            type="date"
            defaultValue={date}
            onChange={(event) => {
              textFieldValueChanged("date", event.target.value);
            }}
            placeholder="Contribution Date (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <CalendarToday className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            type="number"
            value={amount}
            onChange={(event) => {
              textFieldValueChanged("amount", event.target.value);
            }}
            placeholder="Amount (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <AttachMoney className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            type="text"
            value={transactionDetails}
            onChange={(event) => {
              textFieldValueChanged("transactionDetails", event.target.value);
            }}
            placeholder="Transaction Details"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <ListAlt className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} Add New Member Contribution
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    addMinistryMemberContribution: (
      _id: string,
      subtractor_id: string,
      subtractor_type: "Other" | "User",
      amount: number,
      date: string,
      transaction_details: string
    ) => dispatch(addMinistryMemberContribution(_id, subtractor_id, subtractor_type, amount, date, transaction_details)),
    clearMinistryContributionError: () => dispatch(clearMinistryContributionError()),
    clearUserError: () => dispatch(clearUserError()),
    getMinistryMembership: (ministry_id: string, search: string) => dispatch(getMinistryMembership(ministry_id, search, 0, 5)),
    getEntities: (search: string) => dispatch(getEntities(search, 0, 5))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    ministryMemberContribution: state.ministryMemberContribution,
    ministryMembership: state.ministryMembership,
    entities: state.entities,
    appTheme: state.general.theme
  };
};

const AddMinistryMemberMinistryContributionDetailsFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMinistryMemberMinistryContributionDetailsForm);

const AddMinistryMemberMinistryContributionDetailsModal: React.FC<AddMinistryMemberMinistryContributionDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title="Add New Member Contribution"
      component={<AddMinistryMemberMinistryContributionDetailsFormComponent ministryContributionData={props.ministryContributionData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default AddMinistryMemberMinistryContributionDetailsModal;
