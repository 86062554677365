import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, ForgotReducerState } from "../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Grid, Container, Paper, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { sendResetPasswordCode, clearForgotPasswordError } from "../../store/actions";
import { DialogModal } from "../../components";
import validator from "validator";

interface ForgotProps {
  loggedIn: boolean;
  forgot: ForgotReducerState;
  appTheme: AppTheme;
  sendResetPasswordCode: (username: string) => void;
  clearForgotPasswordError: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 30,
        marginTop: 20,
        marginBottom: 20
      },
      title: {
        color: appTheme.titleTextColor
      },
      text: {
        color: theme.palette.text.primary
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      }
    })
  );

const Forgot: React.FC<ForgotProps> = (props) => {
  const history = useHistory();

  const [username, setUsername] = useState<string>("");

  const { forgot, loggedIn, appTheme, clearForgotPasswordError, sendResetPasswordCode } = props;

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  const submitForm = () => {
    if (validator.isEmpty(username)) {
      setDialogOptions({ open: true, body: "Username | Email | Mobile No entered is not valid" });
    } else {
      sendResetPasswordCode(username);
    }
  };

  useEffect(() => {
    const requestData = forgot.error ? forgot : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [forgot]);

  useEffect(() => {
    if (loggedIn) {
      history.push("/");
    }
  }, [loggedIn, history]);

  useEffect(() => {
    if (forgot.forgot) {
      history.push("/account/verify/reset/password/code");
    }
  }, [forgot.forgot, history]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearForgotPasswordError();
    };
  }, [clearForgotPasswordError]);

  let loadingIndicator = null;

  const loading = forgot.loading;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <Container maxWidth="xs">
        <Grid container alignItems="center" style={{ minHeight: "85vh" }}>
          <Grid item xs={11}>
            <Paper className={styles.paper}>
              <form
                style={{ width: "100%" }}
                onSubmit={(event) => {
                  event.preventDefault();
                  submitForm();
                }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography variant="h5" align="center" className={styles.title}>
                      Forgot Password
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" align="center" className={styles.text}>
                      Enter your username,email or mobile number so that we can send you a reset password code to your mobile number and/or email
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      required
                      type="text"
                      value={username}
                      onChange={(event) => {
                        setUsername(event.target.value);
                      }}
                      placeholder="Username | Email Address | Mobile No"
                      className={styles.textField}
                      startAdornment={
                        <InputAdornment position="start">
                          <Person className={styles.textFieldIcon} />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
                      {loadingIndicator}Reset Password
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth disabled={loading} component={Link} to="/account/login">
                      Back To Log In
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    sendResetPasswordCode: (username: string) => dispatch(sendResetPasswordCode(username)),
    clearForgotPasswordError: () => dispatch(clearForgotPasswordError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    loggedIn: state.login.loggedIn,
    appTheme: state.general.theme,
    forgot: state.forgot
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
