import axios, { Method, AxiosPromise, AxiosRequestConfig } from "axios";
import queryString from "query-string";

const client = axios.create();

interface AxiosData {
  url?: string | undefined;
  method: Method;
  pathname: string;
  params?: any;
  data?: any;
  headers?: any;
}

const request = ({ url, method, pathname, data, params, headers }: AxiosData): AxiosPromise<any> => {
  if (!url) {
    url = process.env["REACT_APP_API_URL"];
  }

  if (window.location.pathname.split("/dashboard/ministries/").length === 2) {
    const ministry_id = window.location.pathname.split("/dashboard/ministries/").pop()?.split("/").shift() ?? "";
    if (params) {
      params = { ministry_id, ...params };
    } else {
      params = { ministry_id };
    }
  }

  let requestHeaders = {
    "Content-Type": "application/x-www-form-urlencoded"
  };

  if (headers) {
    requestHeaders = { ...requestHeaders, ...headers };
  }

  const requestDetails: AxiosRequestConfig = {
    method: method,
    params: params,
    url: url + pathname,
    headers: requestHeaders,
    withCredentials: true
  };

  if (data) {
    requestDetails["data"] = queryString.stringify(data);
  }

  return client(requestDetails);
};

export { client };

export default request;
