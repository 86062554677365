import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, DiscipleshipClassInterface, DiscipleshipClassReducerState } from "../../../../interfaces";
import { createStyles, makeStyles} from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress, Select } from "@material-ui/core";
import { Create } from "@material-ui/icons";
import validator from "validator";
import { addDiscipleshipClass, clearDiscipleshipClassError, updateDiscipleshipClass } from "../../../../store/actions";

interface UpsertDiscipleshipClassDetailsFormProps {
  appTheme: AppTheme;
  discipleshipClass: DiscipleshipClassReducerState;
  discipleshipClassData: DiscipleshipClassInterface | null;
  updateDiscipleshipClass: (discipleship_class_id: string, title: string, status: string) => void;
  addDiscipleshipClass: (title: string, status: string) => void;
  clearDiscipleshipClassError: () => void;
}

interface UpsertDiscipleshipClassDetailsModalProps {
  open: boolean;
  discipleshipClassData: DiscipleshipClassInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      }
    })
  );

const UpsertDiscipleshipClassDetailsForm: React.FC<UpsertDiscipleshipClassDetailsFormProps> = (props) => {
  const { appTheme, discipleshipClass, discipleshipClassData, addDiscipleshipClass, updateDiscipleshipClass, clearDiscipleshipClassError } = props;

  const [_id] = useState<string>(discipleshipClassData ? discipleshipClassData.discipleship_class_id : "");
  const [title, setTitle] = useState<string>(discipleshipClassData ? discipleshipClassData.title : "");
  const [status, setStatus] = useState<string>(discipleshipClassData ? discipleshipClassData.status : "registration");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loading = discipleshipClass.loading;

  const styles = useStyles(appTheme)();

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "title":
        setTitle(value);
        break;
      case "status":
        setStatus(value);
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (validator.isEmpty(title)) {
      setErrorMessage("The discipleship class title entered is not valid");
    } else if (validator.isEmpty(status)) {
      setErrorMessage("The discipleship class status selected is not valid");
    } else {
      setErrorMessage("");

      if (discipleshipClassData) {
        updateDiscipleshipClass(_id, title, status);
      } else {
        addDiscipleshipClass(title, status);
      }
    }
  };

  useEffect(() => {
    const requestData = discipleshipClass.error ? discipleshipClass : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [discipleshipClass]);

  useEffect(() => {
    return () => {
      clearDiscipleshipClassError();
    };
  }, [clearDiscipleshipClassError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            value={title}
            onChange={(event) => {
              textFieldValueChanged("title", event.target.value);
            }}
            placeholder="Title (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Create className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Status (Required)
          </Typography>
          <Select
            native
            className={styles.textField}
            value={status}
            onChange={(event) => {
              textFieldValueChanged("status", event.target.value as string);
            }}>
            <option value="registration">Registration Ongoing</option>
            <option value="ongoing">Class Ongoing</option>
            <option value="complete">Class Completed</option>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} {props.discipleshipClassData ? "Update Discipleship Class Details" : "Add New Discipleship Class"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    updateDiscipleshipClass: (discipleship_class_id: string, title: string, status: string) =>
      dispatch(updateDiscipleshipClass(discipleship_class_id, title, status)),
    addDiscipleshipClass: (title: string, status: string) => dispatch(addDiscipleshipClass(title, status)),
    clearDiscipleshipClassError: () => dispatch(clearDiscipleshipClassError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    discipleshipClass: state.discipleshipClass,
    appTheme: state.general.theme
  };
};

const UpsertDiscipleshipClassDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(UpsertDiscipleshipClassDetailsForm);

const UpsertDiscipleshipClassDetailsModal: React.FC<UpsertDiscipleshipClassDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title={props.discipleshipClassData ? "Update Discipleship Class Details" : "Add New Discipleship Class"}
      component={<UpsertDiscipleshipClassDetailsFormComponent discipleshipClassData={props.discipleshipClassData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UpsertDiscipleshipClassDetailsModal;
