import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, MinistriesReducerState, MinistryInterface, ProfileReducerState } from "../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Paper,
  Typography,
  CircularProgress,
  ListItem,
  List,
  ListItemText,
  Button,
  Divider,
  ListItemSecondaryAction
} from "@material-ui/core";
import { DialogModal, ErrorView } from "../../../components";
import { getMinistries } from "../../../store/actions";
import { Link } from "react-router-dom";
import { checkUserAccess } from "../../../util/permission-checker";
import { ArrowForwardIos } from "@material-ui/icons";

interface MinistriesProps {
  ministries: MinistriesReducerState;
  profile: ProfileReducerState;
  appTheme: AppTheme;
  getMinistries: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 15,
        marginTop: 10,
        marginBottom: 10,
        height: "60vh",
        overflowY: "scroll"
      }
    })
  );

const Ministries: React.FC<MinistriesProps> = (props) => {
  const { appTheme, profile, ministries, getMinistries } = props;

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [ministrySelected, setMinistrySelected] = useState<MinistryInterface | null>(null);

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getMinistries();
  }, [getMinistries]);

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <Container maxWidth="md">
        {ministries.error ? (
          <ErrorView error={ministries.error} onRetry={() => getMinistries()} />
        ) : (
          <React.Fragment>
            <div>
              <Paper className={styles.paper}>
                <Grid container justifyContent="space-between" spacing={0}>
                  <Grid item>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>{ministries.loading && <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />}</Grid>
                      <Grid item>
                        <Typography variant="h3" color="primary">
                          {ministrySelected ? ministrySelected.name : "Ministries"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {ministrySelected && (
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={() => {
                          setMinistrySelected(null);
                        }}>
                        Back To Other Ministries
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <br />
                <List component="div">
                  {ministrySelected
                    ? ministrySelected.sub_ministries.map((subMinistry) => {
                        const hasPermission = checkUserAccess(`view-ministry-${subMinistry.id}`, profile.permissions);
                        if (!hasPermission) return null;

                        return (
                          <React.Fragment key={subMinistry.id}>
                            <ListItem button component={Link} to={`/dashboard/ministries/${subMinistry.id}/${encodeURIComponent(subMinistry.name)}`}>
                              <ListItemText primary={subMinistry.name} />
                              <ListItemSecondaryAction>
                                <ArrowForwardIos />
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                          </React.Fragment>
                        );
                      })
                    : ministries.data.map((ministry: MinistryInterface) => {
                        if (ministry.sub_ministries.length > 0) {
                          return (
                            <React.Fragment key={ministry.id}>
                              <ListItem button onClick={() => setMinistrySelected(ministry)}>
                                <ListItemText primary={ministry.name} />
                                <ListItemSecondaryAction>
                                  <ArrowForwardIos />
                                </ListItemSecondaryAction>
                              </ListItem>
                              <Divider />
                            </React.Fragment>
                          );
                        }

                        const hasPermission = checkUserAccess(`view-ministry-${ministry.id}`, profile.permissions);
                        if (!hasPermission) return null;

                        return (
                          <React.Fragment key={ministry.id}>
                            <ListItem button component={Link} to={`/dashboard/ministries/${ministry.id}/${encodeURIComponent(ministry.name)}`}>
                              <ListItemText
                                primary={ministry.name}
                                secondary={ministry.sub_ministries.length === 0 ? "" : `Has ${ministry.sub_ministries.length} Sub Ministries`}
                              />
                              <ListItemSecondaryAction>
                                <ArrowForwardIos />
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                          </React.Fragment>
                        );
                      })}
                </List>
              </Paper>
            </div>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getMinistries: () => dispatch(getMinistries())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    profile: state.profile,
    ministries: state.ministries,
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ministries);
