import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  AppState,
  AppTheme,
  ProfileReducerState,
  KittyLinkedContributionInterface,
  KittyLinkedContributionReducerState,
  KittyLinkedContributionsReducerState,
  KittyInterface
} from "../../../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Container,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { DialogModal, ErrorView } from "../../../../components";
import { clearKittyLinkedContributionError, getKittyLinkedContributions, deleteKittyLinkedContribution } from "../../../../store/actions";
import { checkUserAccess } from "../../../../util/permission-checker";
import PopupMenuDialog from "../../../../components/PopupMenuDialog";
import AddKittyLinkedContributionDetailsModal from "./AddKittyLinkedContributionDetailsModal";
import dateformat from "dateformat";

interface KittyLinkedContributionsDialogProps {
  kittyLinkedContribution: KittyLinkedContributionReducerState;
  kittyLinkedContributions: KittyLinkedContributionsReducerState;
  kittyData: KittyInterface | null;
  profile: ProfileReducerState;
  appTheme: AppTheme;
  getKittyLinkedContributions: (_id: string, search: string, page: number, per_page: number) => void;
  clearKittyLinkedContributionError: () => void;
  deleteKittyLinkedContribution: (_id: string, subtraction_id: string) => void;
}

interface KittyLinkedContributionModalProps {
  open: boolean;
  kittyData: KittyInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      icon: {
        color: theme.palette.text.primary,
        padding: 10,
        borderRadius: 30,
        backgroundColor: appTheme.iconBackgroundColor,
        "&:hover": {
          backgroundColor: appTheme.iconBackgroundHoverColor
        }
      },
      searchButton: {
        marginRight: "-21px",
        height: "55px",
        borderRadius: "30px",
        width: "100px"
      },
      textInput: {
        marginTop: "5px",
        width: "300px"
      },
      textInputControl: {
        borderRadius: "30px",
        backgroundColor: appTheme.section.backgroundColor
      },
      container: {
        minHeight: "60vh"
      },
      textInputIcon: {
        color: "#000"
      }
    })
  );

const KittyLinkedContributionsDialog: React.FC<KittyLinkedContributionsDialogProps> = (props) => {
  const {
    appTheme,
    kittyData,
    kittyLinkedContribution,
    kittyLinkedContributions,
    profile,
    getKittyLinkedContributions,
    deleteKittyLinkedContribution,
    clearKittyLinkedContributionError
  } = props;

  const [_id] = useState<string>(kittyData ? kittyData.kitty_id : "");
  const [search, setSearch] = useState<string>(kittyLinkedContributions.search);
  const [page, setPage] = useState<number>(kittyLinkedContributions.page);
  const [perPage, setPerPage] = useState<number>(kittyLinkedContributions.per_page ?? 20);
  const [selectedRecord, setSelectedRecord] = useState<KittyLinkedContributionInterface | null>(null);
  const [popUpDialogOpen, setPopUpDialogOpen] = useState<boolean>(false);

  const popUpMenuItems = useMemo(() => {
    return [{ text: "Delete Kitty Linked Contribution", permission: "delete-kitty-linked-contribution" }];
  }, []);
  const [menuItems, setMenuItems] = useState<Array<string>>(
    popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text)
  );

  useEffect(() => {
    setMenuItems(popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text));
  }, [popUpMenuItems, profile.permissions]);

  const [addKittyLinkedContributionDetailsModalOpen, setAddKittyLinkedContributionDetailsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getKittyLinkedContributions(_id, search, page, perPage);
  }, [_id, search, page, perPage, getKittyLinkedContributions]);

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearKittyLinkedContributionError();
    };
  }, [clearKittyLinkedContributionError]);

  useEffect(() => {
    if (!kittyLinkedContribution.error && kittyLinkedContribution.response && kittyLinkedContribution.response.data.status !== "Ok") {
      clearKittyLinkedContributionError();

      setSelectedRecord(null);

      setAddKittyLinkedContributionDetailsModalOpen(false);

      setDialogOptions({ open: true, body: kittyLinkedContribution.response.data.status });

      getKittyLinkedContributions(_id, "", 0, perPage);
    }
  }, [kittyLinkedContribution.error, kittyLinkedContribution.response, _id, perPage, getKittyLinkedContributions, clearKittyLinkedContributionError]);

  useEffect(() => {
    if (addKittyLinkedContributionDetailsModalOpen) {
      return;
    }

    const requestData = kittyLinkedContribution.error ? kittyLinkedContribution : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [addKittyLinkedContributionDetailsModalOpen, kittyLinkedContribution]);

  const onMenuItemSelected = (menuItem: string) => {
    setPopUpDialogOpen(false);

    if (!selectedRecord) {
      return;
    }

    switch (menuItem) {
      case "Delete Kitty Linked Contribution":
        if (window.confirm("Would you like to delete this kitty linked contribution ?")) {
          deleteKittyLinkedContribution(_id, selectedRecord.contribution_id);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <AddKittyLinkedContributionDetailsModal
        open={addKittyLinkedContributionDetailsModalOpen}
        onDismissed={() => {
          setSearch("");
          setPage(0);
          setAddKittyLinkedContributionDetailsModalOpen(false);
        }}
        kittyData={kittyData}
      />
      <PopupMenuDialog
        open={popUpDialogOpen}
        onDismissed={() => setPopUpDialogOpen(false)}
        onMenuItemSelected={onMenuItemSelected}
        menuItems={menuItems}
      />
      <Container maxWidth="lg">
        {!addKittyLinkedContributionDetailsModalOpen && kittyLinkedContributions.error ? (
          <ErrorView error={kittyLinkedContributions.error} onRetry={() => getKittyLinkedContributions(_id, search, page, perPage)} />
        ) : (
          <React.Fragment>
            <Grid container justifyContent="space-between" spacing={0}>
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    {kittyLinkedContribution.loading && <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />}
                  </Grid>
                  <Grid item>
                    <Typography variant="h3" color="primary">
                      Kitty Linked Contributions
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  onChange={(kittyLinkedContribution) => {
                    setSearch(kittyLinkedContribution.currentTarget.value);
                  }}
                  error={false}
                  value={search}
                  variant="outlined"
                  placeholder="Search ..."
                  className={styles.textInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search className={styles.textInputIcon} />
                      </InputAdornment>
                    ),
                    classes: { root: styles.textInputControl }
                  }}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer className={styles.container}>
              <Table stickyHeader aria-label="kitty-linked-contributions">
                <TableHead>
                  <TableRow>
                    {[
                      { label: "ID", minWidth: 100 },
                      { label: "Title", minWidth: 250 },
                      { label: "Target", minWidth: 100 },
                      { label: "Contributions", minWidth: 100 },
                      { label: "Contribution Instructions", minWidth: 250 },
                      { label: "From Date", minWidth: 200 },
                      { label: "To Date", minWidth: 200 },
                      { label: "Options", minWidth: 100 }
                    ].map((column) => (
                      <TableCell key={column.label} align="center" style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {kittyLinkedContributions.data.map((kittyLinkedContribution: KittyLinkedContributionInterface) => {
                    const _id = kittyLinkedContribution.contribution_id;
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={_id}>
                        {["contribution_id", "title", "target", "contributions", "instructions", "from_date", "to_date", "options"].map((column) => {
                          const key = `${_id}-${column}`;

                          const data = (kittyLinkedContribution as unknown as { [key: string]: any })[column];
                          if (column === "options") {
                            if (menuItems.length === 0) return <TableCell key={key} align="center"></TableCell>;
                            return (
                              <TableCell key={key} align="center">
                                <Button
                                  variant="outlined"
                                  disabled={menuItems.length === 0}
                                  onClick={() => {
                                    setSelectedRecord(kittyLinkedContribution);
                                    setPopUpDialogOpen(true);
                                  }}>
                                  Options
                                </Button>
                              </TableCell>
                            );
                          } else if (column === "from_date" || column === "to_date") {
                            return (
                              <TableCell key={key} align="center">
                                {dateformat(data, "ddd mmm dd yyyy")}
                              </TableCell>
                            );
                          } else if (column === "target" || column === "contributions") {
                            return (
                              <TableCell key={key} align="center">
                                {new Intl.NumberFormat("en-KE", { style: "currency", currency: "KES" }).format(parseFloat(data))}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={key} align="center">
                                {data}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container justifyContent="space-between" style={{ margin: "0px 10px 0px 10px" }}>
              <Grid item>
                <Button
                  disabled={!checkUserAccess("add-kitty-linked-contribution", profile.permissions)}
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setSelectedRecord(null);
                    setAddKittyLinkedContributionDetailsModalOpen(true);
                  }}>
                  Add New Kitty Linked Contribution
                </Button>
              </Grid>
              <Grid item>
                <TablePagination
                  component="div"
                  count={kittyLinkedContributions.total}
                  page={page}
                  onPageChange={(_, page) => setPage(page)}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[20, 50, 100, 1000]}
                  onRowsPerPageChange={(kittyLinkedContribution) => {
                    setPerPage(parseInt(kittyLinkedContribution.target.value));
                    setPage(0);
                  }}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getKittyLinkedContributions: (_id: string, search: string, page: number, per_page: number) =>
      dispatch(getKittyLinkedContributions(_id, search, page, per_page)),
    clearKittyLinkedContributionError: () => dispatch(clearKittyLinkedContributionError()),
    deleteKittyLinkedContribution: (_id: string, subtraction_id: string) => dispatch(deleteKittyLinkedContribution(_id, subtraction_id))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    kittyLinkedContributions: state.kittyLinkedContributions,
    kittyLinkedContribution: state.kittyLinkedContribution,
    profile: state.profile,
    appTheme: state.general.theme
  };
};

const KittyLinkedContributionsDialogComponent = connect(mapStateToProps, mapDispatchToProps)(KittyLinkedContributionsDialog);

const KittyLinkedContributionsDialogModal: React.FC<KittyLinkedContributionModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="lg"
      title="Kitty Linked Contributions"
      component={<KittyLinkedContributionsDialogComponent kittyData={props.kittyData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default KittyLinkedContributionsDialogModal;
