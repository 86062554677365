import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, ResetReducerState } from "../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Grid, Container, Paper, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { Lock } from "@material-ui/icons";
import { resetPassword, clearResetPasswordCodeError } from "../../store/actions";
import { DialogModal } from "../../components";
import validator from "validator";

interface ResetPasswordProps {
  loggedIn: boolean;
  reset: ResetReducerState;
  appTheme: AppTheme;
  resetPassword: (password: string) => void;
  clearResetPasswordCodeError: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 30,
        marginTop: 20,
        marginBottom: 20
      },
      title: {
        color: appTheme.titleTextColor
      },
      text: {
        color: theme.palette.text.primary
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      }
    })
  );

const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
  const history = useHistory();

  const { appTheme, reset, loggedIn, resetPassword, clearResetPasswordCodeError } = props;

  const styles = useStyles(appTheme)();

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  const submitForm = () => {
    if (!validator.isByteLength(password, { min: 6 })) {
      setDialogOptions({ open: true, body: "New password entered should be atleast 6 characters in length" });
    } else if (!validator.isByteLength(confirmPassword, { min: 6 })) {
      setDialogOptions({ open: true, body: "Confirm password entered should be atleast 6 characters in length" });
    } else if (password !== confirmPassword) {
      setDialogOptions({ open: true, body: "Both the password and confirmation passwords are not equal" });
    } else {
      resetPassword(password);
    }
  };

  useEffect(() => {
    const requestData = reset.error ? reset : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [reset]);

  useEffect(() => {
    if (loggedIn) {
      history.push("/dashboard/account/profile");
    }
  }, [history, loggedIn]);

  useEffect(() => {
    if (reset.reset) {
      history.push("/account/login#account-reset");
    }
  }, [history, reset.reset]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearResetPasswordCodeError();
    };
  }, [clearResetPasswordCodeError]);

  let loadingIndicator = null;

  const loading = reset.loading;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <Container maxWidth="xs">
        <Grid container alignItems="center" style={{ minHeight: "85vh" }}>
          <Grid item xs={11}>
            <Paper className={styles.paper}>
              <form
                style={{ width: "100%" }}
                onSubmit={(event) => {
                  event.preventDefault();
                  submitForm();
                }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography variant="h5" align="center" className={styles.title}>
                      Reset Password
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" align="center" className={styles.text}>
                      Enter a new password for your account
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      required
                      type="password"
                      value={password}
                      onChange={(event) => {
                        textFieldValueChanged("password", event.target.value);
                      }}
                      placeholder="New Password"
                      className={styles.textField}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock className={styles.textFieldIcon} />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      required
                      type="password"
                      value={confirmPassword}
                      onChange={(event) => {
                        textFieldValueChanged("confirmPassword", event.target.value);
                      }}
                      placeholder="Confirm Password"
                      className={styles.textField}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock className={styles.textFieldIcon} />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
                      {loadingIndicator}Reset Password
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    resetPassword: (password: string) => dispatch(resetPassword(password)),
    clearResetPasswordCodeError: () => dispatch(clearResetPasswordCodeError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    loggedIn: state.login.loggedIn,
    appTheme: state.general.theme,
    reset: state.reset
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
