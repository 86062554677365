import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, LogInReducerState, AppTheme } from "../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Grid, Container, Paper, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { Person, Lock } from "@material-ui/icons";
import { normalLogIn, clearLogInError } from "../../store/actions";
import { DialogModal } from "../../components";
import validator from "validator";
import queryString from "query-string";

interface LogInProps {
  login: LogInReducerState;
  appTheme: AppTheme;
  normalLogIn: (username: string, password: string) => void;
  clearLogInError: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 30,
        marginTop: 20,
        marginBottom: 20
      },
      title: {
        color: appTheme.titleTextColor
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      facebookButton: {
        height: 50,
        width: "100%",
        fontFamily: `${"Montserrat"},${"Helvetica"},${"Arial"},sans-serif`,
        backgroundColor: "#153f77",
        color: theme.palette.common.white,
        fontSize: "1em",
        textAlign: "left",
        padding: "0px 10px 0px 10px"
      }
    })
  );

const LogIn: React.FC<LogInProps> = (props) => {
  const history = useHistory();

  const { appTheme, normalLogIn, login, clearLogInError } = props;

  const styles = useStyles(appTheme)();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const loading = login.loading;

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "username":
        setUsername(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (validator.isEmpty(username)) {
      setDialogOptions({ open: true, body: "Username | Email | Mobile No entered is not valid" });
    } else if (!validator.isByteLength(password, { min: 6 })) {
      setDialogOptions({ open: true, body: "Password entered should be atleast 6 characters in length" });
    } else {
      normalLogIn(username, password);
    }
  };

  useEffect(() => {
    const requestData = login.error ? login : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [login]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (login.loggedIn) {
      const params = queryString.parse(history.location.search);

      if (params.continueTo) {
        history.push(params.continueTo.toString());
      } else {
        history.push("/");
      }
    }

    if (history.location.hash === "#logged-out-successfully") {
      setDialogOptions({ open: true, body: "You have been logged out successfully. Please log in back into your account to access it" });
    }

    if (history.location.hash === "#account-reset") {
      setDialogOptions({ open: true, body: "Password reset successfully. You can now log in with your new password" });
    }
  }, [login.loggedIn, history, history.location.search]);

  useEffect(() => {
    return () => {
      clearLogInError();
    };
  }, [clearLogInError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <Container maxWidth="xs">
        <Grid container alignItems="center" style={{ minHeight: "85vh" }}>
          <Grid item xs={11}>
            <Paper className={styles.paper}>
              <form
                style={{ width: "100%" }}
                onSubmit={(event) => {
                  event.preventDefault();
                  submitForm();
                }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography variant="h5" align="center" className={styles.title}>
                      Sign In to Admin Dashboard
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      required
                      type="username"
                      value={username}
                      onChange={(event) => {
                        textFieldValueChanged("username", event.target.value);
                      }}
                      placeholder="Username | Email Address | Mobile No"
                      className={styles.textField}
                      startAdornment={
                        <InputAdornment position="start">
                          <Person className={styles.textFieldIcon} />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      required
                      type="password"
                      value={password}
                      onChange={(event) => {
                        textFieldValueChanged("password", event.target.value);
                      }}
                      placeholder="Password"
                      className={styles.textField}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock className={styles.textFieldIcon} />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
                      {loadingIndicator}Sign In
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth disabled={loading} component={Link} to="/account/forgot">
                      Forgot Password
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    normalLogIn: (username: string, password: string) => dispatch(normalLogIn(username, password)),
    clearLogInError: () => dispatch(clearLogInError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    login: state.login,
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
