import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";
import CryptoES from "crypto-es";

export const clearLogInError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.LOGIN_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const normalLogIn = (username: string, password: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.LOGIN_HTTP_LOADING
    });

    request({
      method: "post",
      pathname: "logInUser",
      data: { username, password: CryptoES.SHA256(password) }
    })
      .then((response) => {
        dispatch({
          type: actions.LOGIN_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });

        dispatch({
          type: actions.LOGGING_IN_USER,
          payload: {
            ...response.data.content
          }
        });

        dispatch({
          type: actions.LOG_IN_USER
        });
      })
      .catch((error) => {
        errorHandler(actions.LOGIN_HTTP_ERROR, error, dispatch);
      });
  };
};

export const logOut = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.LOG_OUT_USER
    });
  };
};
