import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, ProfileReducerState, ProjectInterface, ProjectReducerState, ProjectsReducerState } from "../../../interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Container,
  Paper,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { DialogModal, ErrorView } from "../../../components";
import { clearProjectError, getProjects, deleteProject } from "../../../store/actions";
import { checkUserAccess } from "../../../util/permission-checker";
import PopupMenuDialog from "../../../components/PopupMenuDialog";
import UpsertProjectDetailsModal from "./Modals/UpsertProjectDetailsDialog";
import dateformat from "dateformat";
import ViewProjectContentDialog from "./Modals/ViewProjectContentDialog";

interface ProjectsProps {
  projects: ProjectsReducerState;
  project: ProjectReducerState;
  profile: ProfileReducerState;
  appTheme: AppTheme;
  getProjects: (search: string, page: number, per_page: number) => void;
  clearProjectError: () => void;
  deleteProject: (_id: string) => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      paper: {
        backgroundColor: appTheme.section.backgroundColor,
        width: "100%",
        padding: 30,
        marginTop: 20,
        marginBottom: 20
      },
      icon: {
        color: theme.palette.text.primary,
        padding: 10,
        borderRadius: 30,
        backgroundColor: appTheme.iconBackgroundColor,
        "&:hover": {
          backgroundColor: appTheme.iconBackgroundHoverColor
        }
      },
      searchButton: {
        marginRight: "-21px",
        height: "55px",
        borderRadius: "30px",
        width: "100px"
      },
      textInput: {
        marginTop: "5px",
        width: "300px"
      },
      textInputControl: {
        borderRadius: "30px",
        backgroundColor: appTheme.section.backgroundColor
      },
      container: {
        minHeight: "60vh"
      },
      textInputIcon: {
        color: "#000"
      }
    })
  );

const Projects: React.FC<ProjectsProps> = (props) => {
  const { appTheme, projects, project, profile, getProjects, deleteProject, clearProjectError } = props;

  const [search, setSearch] = useState<string>(projects.search);
  const [page, setPage] = useState<number>(projects.page);
  const [perPage, setPerPage] = useState<number>(projects.per_page ?? 20);
  const [selectedRecord, setSelectedRecord] = useState<ProjectInterface | null>(null);
  const [popUpDialogOpen, setPopUpDialogOpen] = useState<boolean>(false);

  const [upsertProjectDetailsModelOpen, setUpsertProjectDetailsModelOpen] = useState<boolean>(false);
  const [viewProjectContentModelOpen, setViewProjectContentModelOpen] = useState<boolean>(false);
  const popUpMenuItems = useMemo(() => {
    return [
      { text: "Update Project Details", permission: "edit-project" },
      { text: "Delete Project", permission: "delete-project" }
    ];
  }, []);

  const [menuItems, setMenuItems] = useState<Array<string>>(
    popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text)
  );

  useEffect(() => {
    setMenuItems(popUpMenuItems.filter((menuItem) => checkUserAccess(menuItem.permission, profile.permissions)).map((menuItem) => menuItem.text));
  }, [popUpMenuItems, profile.permissions]);

  useEffect(() => {
    getProjects(search, page, perPage);
  }, [search, page, perPage, getProjects]);

  const styles = useStyles(appTheme)();

  type DialogOptions = {
    open: boolean;
    body: string;
  };

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({ open: false, body: "" });

  const dismissDialogModal = useCallback(() => {
    setDialogOptions({ open: false, body: "" });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearProjectError();
    };
  }, [clearProjectError]);

  useEffect(() => {
    if (!project.error && project.response) {
      clearProjectError();

      setSelectedRecord(null);

      setUpsertProjectDetailsModelOpen(false);

      setViewProjectContentModelOpen(false);

      setDialogOptions({ open: true, body: project.response.data.status });

      getProjects("", 0, perPage);
    }
  }, [project.error, project.response, perPage, getProjects, clearProjectError]);

  useEffect(() => {
    if (upsertProjectDetailsModelOpen || viewProjectContentModelOpen) {
      return;
    }

    const requestData = project.error ? project : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setDialogOptions({ open: true, body: body });
    } else {
      if (requestData.error && requestData.error.message) {
        setDialogOptions({ open: true, body: requestData.error.message });
      }
    }
  }, [project, upsertProjectDetailsModelOpen, viewProjectContentModelOpen]);

  const onMenuItemSelected = (menuItem: string) => {
    setPopUpDialogOpen(false);

    if (!selectedRecord) {
      return;
    }

    switch (menuItem) {
      case "Update Project Details":
        setUpsertProjectDetailsModelOpen(true);
        break;
      case "Delete Project":
        if (window.confirm("Would you like to delete this project ?")) {
          deleteProject(selectedRecord.project_id);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.root}>
      <DialogModal open={dialogOptions.open} body={dialogOptions.body} onDismissed={dismissDialogModal} />
      <UpsertProjectDetailsModal
        open={upsertProjectDetailsModelOpen}
        onDismissed={() => setUpsertProjectDetailsModelOpen(false)}
        projectData={selectedRecord}
      />
      <ViewProjectContentDialog
        open={viewProjectContentModelOpen}
        onDismissed={() => setViewProjectContentModelOpen(false)}
        projectData={selectedRecord}
      />
      <PopupMenuDialog
        open={popUpDialogOpen}
        onDismissed={() => setPopUpDialogOpen(false)}
        onMenuItemSelected={onMenuItemSelected}
        menuItems={menuItems}
      />
      <Container maxWidth="lg">
        {projects.error ? (
          <ErrorView error={projects.error} onRetry={() => getProjects(search, page, perPage)} />
        ) : (
          <React.Fragment>
            <Paper className={styles.paper}>
              <Grid container justifyContent="space-between" spacing={0}>
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      {(projects.loading || project.loading) && <CircularProgress style={{ display: "block", margin: "auto" }} color="primary" />}
                    </Grid>
                    <Grid item>
                      <Typography variant="h3" color="primary">
                        Projects
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(project) => {
                      setSearch(project.currentTarget.value);
                    }}
                    error={false}
                    value={search}
                    variant="outlined"
                    placeholder="Search ..."
                    className={styles.textInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search className={styles.textInputIcon} />
                        </InputAdornment>
                      ),
                      classes: { root: styles.textInputControl }
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <TableContainer className={styles.container}>
                <Table stickyHeader aria-label="projects">
                  <TableHead>
                    <TableRow>
                      {[
                        { label: "ID", minWidth: 100 },
                        { label: "Title", minWidth: 150 },
                        { label: "Author", minWidth: 150 },
                        { label: "Status", minWidth: 50 },
                        { label: "Created At", minWidth: 150 },
                        { label: "Completed on", minWidth: 150 },
                        { label: "Cover Image", minWidth: 100 },
                        { label: "Content", minWidth: 100 },
                        { label: "Options", minWidth: 100 }
                      ].map((column) => (
                        <TableCell key={column.label} align="center" style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projects.data.map((project: ProjectInterface) => {
                      const _id = project.project_id;
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={_id}>
                          {["project_id", "title", "author_name", "status", "added_on", "completed_on", "cover_img", "content", "options"].map(
                            (column) => {
                              const key = `${_id}-${column}`;

                              const data = (project as unknown as { [key: string]: any })[column];
                              if (column === "options") {
                                if (menuItems.length === 0) return <TableCell key={key} align="center"></TableCell>;
                                return (
                                  <TableCell key={key} align="center">
                                    <Button
                                      variant="outlined"
                                      disabled={menuItems.length === 0}
                                      onClick={() => {
                                        setSelectedRecord(project);
                                        setPopUpDialogOpen(true);
                                      }}>
                                      Options
                                    </Button>
                                  </TableCell>
                                );
                              } else if (column === "added_on" || column === "completed_on") {
                                return (
                                  <TableCell key={key} align="center">
                                    {data ? dateformat(data, "ddd mmm dd yyyy") : ""}
                                  </TableCell>
                                );
                              } else if (column === "status") {
                                return (
                                  <TableCell key={key} align="center">
                                    {data === "complete" ? "Completed" : data === "ongoing" ? "Ongoing" : "Pending"}
                                  </TableCell>
                                );
                              } else if (column === "content") {
                                return (
                                  <TableCell key={key} align="center">
                                    <Button
                                      variant="outlined"
                                      onClick={() => {
                                        setSelectedRecord(project);
                                        setViewProjectContentModelOpen(true);
                                      }}>
                                      Show Content
                                    </Button>
                                  </TableCell>
                                );
                              } else if (column === "cover_img") {
                                return (
                                  <TableCell key={key} align="center">
                                    <a href={data} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "blue" }}>
                                      Click To View
                                    </a>
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell key={key} align="center">
                                    {data}
                                  </TableCell>
                                );
                              }
                            }
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container justifyContent="space-between" style={{ margin: "0px 10px 0px 10px" }}>
                <Grid item>
                  <Button
                    disabled={!checkUserAccess("add-project", profile.permissions)}
                    variant="outlined"
                    size="large"
                    onClick={() => {
                      setSelectedRecord(null);
                      setUpsertProjectDetailsModelOpen(true);
                    }}>
                    Add New Project
                  </Button>
                </Grid>
                <Grid item>
                  <TablePagination
                    component="div"
                    count={projects.total}
                    page={page}
                    onPageChange={(_, page) => setPage(page)}
                    rowsPerPage={perPage}
                    rowsPerPageOptions={[20, 50, 100, 1000]}
                    onRowsPerPageChange={(project) => {
                      setPerPage(parseInt(project.target.value));
                      setPage(0);
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    getProjects: (search: string, page: number, per_page: number) => dispatch(getProjects(search, page, per_page)),
    clearProjectError: () => dispatch(clearProjectError()),
    deleteProject: (_id: string) => dispatch(deleteProject(_id))
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    projects: state.projects,
    project: state.project,
    profile: state.profile,
    appTheme: state.general.theme
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
