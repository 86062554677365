import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, KittyInterface, KittyReducerState } from "../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, InputBase, InputAdornment, CircularProgress } from "@material-ui/core";
import { Create } from "@material-ui/icons";
import validator from "validator";
import { addKitty, clearKittyError, updateKitty } from "../../../../store/actions";

interface UpsertKittyDetailsFormProps {
  appTheme: AppTheme;
  kitty: KittyReducerState;
  kittyData: KittyInterface | null;
  updateKitty: (kitty_id: string, kitty_name: string) => void;
  addKitty: (kitty_name: string) => void;
  clearKittyError: () => void;
}

interface UpsertKittyDetailsModalProps {
  open: boolean;
  kittyData: KittyInterface | null;
  onDismissed: () => void;
}

const useStyles = (appTheme: AppTheme) =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      textField: {
        width: "100%",
        padding: 10,
        backgroundColor: appTheme.textField.backgroundColor,
        color: appTheme.textField.color
      },
      textFieldIcon: {
        color: appTheme.iconColor
      },
      img: {
        width: "auto",
        height: "200px",
        display: "block",
        margin: "auto"
      }
    })
  );

const UpsertKittyDetailsForm: React.FC<UpsertKittyDetailsFormProps> = (props) => {
  const { appTheme, kitty, kittyData, addKitty, updateKitty, clearKittyError } = props;

  const [_id] = useState<string>(kittyData ? kittyData.kitty_id : "");
  const [kittyName, setKittyName] = useState<string>(kittyData ? kittyData.kitty_name : "");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loading = kitty.loading;

  const styles = useStyles(appTheme)();

  const textFieldValueChanged: (textFieldName: string, value: string) => void = (textFieldName: string, value: string) => {
    switch (textFieldName) {
      case "kittyName":
        setKittyName(value);
        break;
      default:
        break;
    }
  };

  const submitForm = () => {
    if (validator.isEmpty(kittyName)) {
      setErrorMessage("The kitty name entered is not valid");
    } else {
      setErrorMessage("");

      if (kittyData) {
        updateKitty(_id, kittyName);
      } else {
        addKitty(kittyName);
      }
    }
  };

  useEffect(() => {
    const requestData = kitty.error ? kitty : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [kitty]);

  useEffect(() => {
    return () => {
      clearKittyError();
    };
  }, [clearKittyError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputBase
            required
            type="text"
            value={kittyName}
            onChange={(event) => {
              textFieldValueChanged("kittyName", event.target.value);
            }}
            placeholder="Kitty Name (Required)"
            className={styles.textField}
            startAdornment={
              <InputAdornment position="start">
                <Create className={styles.textFieldIcon} />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} {props.kittyData ? "Update Kitty Details" : "Add New Kitty"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    updateKitty: (kitty_id: string, kitty_name: string) => dispatch(updateKitty(kitty_id, kitty_name)),
    addKitty: (kitty_name: string) => dispatch(addKitty(kitty_name)),
    clearKittyError: () => dispatch(clearKittyError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    kitty: state.kitty,
    appTheme: state.general.theme
  };
};

const UpsertKittyDetailsFormComponent = connect(mapStateToProps, mapDispatchToProps)(UpsertKittyDetailsForm);

const UpsertKittyDetailsModal: React.FC<UpsertKittyDetailsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="xs"
      title={props.kittyData ? "Update Kitty Details" : "Add New Kitty"}
      component={<UpsertKittyDetailsFormComponent kittyData={props.kittyData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default UpsertKittyDetailsModal;
