import React, { useState, useEffect } from "react";
import DialogModal from "../../../../components/DialogModal";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, AppTheme, UserInterface, UserReducerState } from "../../../../interfaces";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, CircularProgress, List, ListItem, ListItemText, Divider } from "@material-ui/core";
import { clearUserError, updateUserPermissions } from "../../../../store/actions";
import { AppPermissions } from "../../../../util/permission-checker";
import { Switch } from "../../../../components";

interface ChangeUserPermissionsFormProps {
  appTheme: AppTheme;
  user: UserReducerState;
  userData: UserInterface | null;
  updateUserPermissions: (_id: string, permissions: Array<string>) => void;
  clearUserError: () => void;
}

interface ChangeUserPermissionsModalProps {
  open: boolean;
  userData: UserInterface | null;
  onDismissed: () => void;
}

const useStyles = () =>
  makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
        width: "100%"
      },
      list: {
        maxHeight: "60vh",
        overflowY: "scroll"
      },
      listSection: {
        backgroundColor: "#f5f5f5"
      },
      listSectionText: {
        fontSize: 25,
        fontWeight: "bold"
      }
    })
  );

const ChangeUserPermissionsForm: React.FC<ChangeUserPermissionsFormProps> = (props) => {
  const { user, userData, updateUserPermissions, clearUserError } = props;

  const [_id] = useState<string>(userData ? userData.user_id : "");
  const [permissions, setPermissions] = useState<Array<string>>(userData ? userData.permissions ?? [] : []);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const loading = user.loading;

  const styles = useStyles()();

  const submitForm = () => {
    updateUserPermissions(_id, permissions);
  };

  useEffect(() => {
    const requestData = user.error ? user : null;

    if (!requestData) {
      return;
    }

    if (requestData.error && requestData.error.data && requestData.error.status) {
      let body: string = requestData.error.data.status;

      if (requestData.error.status === 500) {
        body = requestData.error.data.content;
      }

      setErrorMessage(body);
    } else {
      if (requestData.error && requestData.error.message) {
        setErrorMessage(requestData.error.message);
      }
    }
  }, [user]);

  useEffect(() => {
    return () => {
      clearUserError();
    };
  }, [clearUserError]);

  let loadingIndicator = null;

  if (loading) {
    loadingIndicator = <CircularProgress style={{ marginRight: 5 }} color="inherit" />;
  }

  return (
    <form
      className={styles.root}
      onSubmit={(event) => {
        event.preventDefault();
        submitForm();
      }}>
      <Grid container spacing={2} justifyContent="center">
        {errorMessage && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center" color="error">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <List className={styles.list}>
            {AppPermissions.map((appPermission, index) => {
              const checked = permissions.filter((permission) => permission === appPermission.permission).length > 0;

              let appPermissionDependancies = appPermission.dependancies.length === 0 ? "None " : appPermission.dependancies.join(" ,");

              appPermissionDependancies = appPermissionDependancies.substr(0, appPermissionDependancies.length - 1);

              let categoryVisible = false;

              if (index === 0) {
                categoryVisible = true;
              } else {
                categoryVisible = appPermission.category !== AppPermissions[index - 1].category;
              }

              return (
                <React.Fragment key={index}>
                  {categoryVisible && (
                    <ListItem className={styles.listSection}>
                      <ListItemText classes={{ primary: styles.listSectionText }} primary={appPermission.category} />
                    </ListItem>
                  )}
                  <ListItem
                    button
                    onClick={() => {
                      if (checked) {
                        let newPermissions = permissions.filter((permission) => permission !== appPermission.permission);

                        AppPermissions.forEach((aP) => {
                          if (aP.dependancies.filter((apDependancy) => apDependancy === appPermission.permission).length > 0) {
                            newPermissions = newPermissions.filter((permission) => permission !== aP.permission);
                          }
                        });

                        setPermissions(newPermissions);
                      } else {
                        const jointPermissions: Array<string> = [...permissions, ...appPermission.dependancies];

                        jointPermissions.push(appPermission.permission);

                        setPermissions(Array.from(new Set(jointPermissions)));
                      }
                    }}>
                    <ListItemText primary={appPermission.name} secondary={"Also Requires: " + appPermissionDependancies} />
                    <Switch checked={checked} />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth disabled={loading} size="large" type="submit" variant="contained" color="primary">
            {loadingIndicator} Update User Permissions
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>) => {
  return {
    updateUserPermissions: (_id: string, permissions: Array<string>) => dispatch(updateUserPermissions(_id, permissions)),
    clearUserError: () => dispatch(clearUserError())
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    user: state.user,
    appTheme: state.general.theme
  };
};

const ChangeUserPermissionsFormComponent = connect(mapStateToProps, mapDispatchToProps)(ChangeUserPermissionsForm);

const ChangeUserPermissionsModal: React.FC<ChangeUserPermissionsModalProps> = (props) => {
  return (
    <DialogModal
      maxWidth="md"
      title="Update User Permissions"
      component={<ChangeUserPermissionsFormComponent userData={props.userData} />}
      contentType="custom"
      open={props.open}
      onDismissed={props.onDismissed}
    />
  );
};

export default ChangeUserPermissionsModal;
