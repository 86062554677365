import { Dispatch } from "redux";
import request from "../../util/request";
import actions from "../../util/actions";
import errorHandler from "../../util/error-handler";
import CryptoES from "crypto-es";

export const clearResetPasswordCodeError = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.RESET_HTTP_ERROR,
      payload: {
        clear: true
      }
    });
  };
};

export const verifyResetPasswordCode = (code: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.RESET_HTTP_LOADING
    });

    request({ method: "post", pathname: "verifyResetPasswordCode", data: { code: code } })
      .then((response) => {
        dispatch({
          type: actions.RESET_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });

        dispatch({
          type: actions.SET_USER_HAS_VERIFIED_RESET_CODE
        });

        dispatch({
          type: actions.UNSET_USER_HAS_VERIFIED_RESET_CODE
        });
      })
      .catch((error) => {
        errorHandler(actions.RESET_HTTP_ERROR, error, dispatch);
      });
  };
};

export const resetPassword = (password: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: actions.RESET_HTTP_LOADING
    });

    request({ method: "post", pathname: "resetPassword", data: { password: CryptoES.SHA256(password) } })
      .then((response) => {
        dispatch({
          type: actions.RESET_HTTP_COMPLETE,
          payload: {
            response: response
          }
        });

        dispatch({
          type: actions.SET_USER_HAS_RESET_PASSWORD
        });

        dispatch({
          type: actions.UNSET_USER_HAS_RESET_PASSWORD
        });
      })
      .catch((error) => {
        if (error.retry) {
          resetPassword(password);
        } else {
          errorHandler(actions.RESET_HTTP_ERROR, error, dispatch);
        }
      });
  };
};
